export const applyDoubleCurlyFormat = (editorRef: any, run: boolean) => {
    if (!run) return;
    if (!editorRef.current) return;

    const quill = editorRef.current.getEditor();
    const text = quill.getText();

    // Remove all existing background formats
    quill.formatText(0, text.length, { "background-color": false, tooltip: false }, "silent");

    //background color to formatted {{...}} blocks
    const regex = /{{[^{}]*}}/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
        const matchStart = match.index;
        const matchLength = match[0].length;
        quill.formatText(matchStart, matchLength, { "background-color": "#BBE9FF", tooltip: "AI Generated" }, "silent");
    }
};
