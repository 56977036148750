import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/Store";

export type Tag = "MUST" | "GOOD";

export type Variant = "NORMAL" | "SEARCH" | "FILTER" | "GENERATE";

export type CriteriaType =
    | "Company Intelligence"
    | "Educational background"
    | "Certifications"
    | "Location"
    | "Years of experience"
    | "Work experience"
    | "Job Title"
    | "Skills"
    | "Custom";

type ICriteriaValue = {
    id: string;
    value: CriteriaType;
    bgColor: string;
};

export type FetchVettingCriteriaPayload = PayloadAction<{
    id: string | number;
}>;

export const criteriaTypeValues: ICriteriaValue[] = [
    {
        id: nanoid(),
        value: "Certifications",
        bgColor: "#d7f4ff",
    },
    {
        id: nanoid(),
        value: "Company Intelligence",
        bgColor: "#8fd8f452",
    },
    {
        id: nanoid(),
        value: "Custom",
        bgColor: "#97e2ff4f",
    },
    {
        id: nanoid(),
        value: "Educational background",
        bgColor: "#BBE9FF",
    },
    {
        id: nanoid(),
        value: "Job Title",
        bgColor: "#92c3d6c2",
    },
    {
        id: nanoid(),
        value: "Location",
        bgColor: "#CAF4FF",
    },
    {
        id: nanoid(),
        value: "Skills",
        bgColor: "#A0DEFF",
    },
    {
        id: nanoid(),
        value: "Work experience",
        bgColor: "#C4E4FF",
    },
    {
        id: nanoid(),
        value: "Years of experience",
        bgColor: "#c8e5eaf7",
    },
];

export interface ICriteria {
    id: string;
    type: CriteriaType;
    text: string;
    tag: Tag;
    appendText?: string;
    placeholder: string | null;
    suggestion?: string;
    variant?: Variant;
    currentProjectFilters? : any;
    setCurrentProjectFilters? : any;
}

export interface SetCriteriaPayload extends ICriteria {
    _id: string;
}

type IRootState = {
    criteria: ICriteria[];
    enableAppendText: boolean;
    isErrorWhileFetchingVettingCriteria: boolean;
};

const SearchCriteria: ICriteria[] = [
    {
        id: nanoid(),
        type: "Location",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should be residing in X",
    },
    {
        id: nanoid(),
        type: "Years of experience",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should have X years of experience",
    },
    {
        id: nanoid(),
        type: "Job Title",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should have X job title",
    },
    {
        id: nanoid(),
        type: "Skills",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X,Y,Z skills",
    },
    {
        id: nanoid(),
        type: "Company Intelligence",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X years in role/company",
    },
    {
        id: nanoid(),
        type: "Work experience",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should not have held positions in more than X companies in the past Y years",
    },
];

const initialState: IRootState = {
    criteria: [],
    enableAppendText: false,
    isErrorWhileFetchingVettingCriteria: false,
};

const reducers = {
    setErrorWhileFetchingVettingCriteria(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingVettingCriteria = action.payload;
    },
    addCriteria(state: typeof initialState) {
        state.criteria.push({
            id: nanoid(),
            type: "Custom",
            text: "",
            tag: "GOOD",
            placeholder: null,
        });

        setTimeout(() => {
            const container = document.getElementById("vetting-criteria-container");
            if (!container) return;
            const lastChild = container.childNodes[container.childNodes?.length - 1];
            //@ts-ignore
            if (lastChild) lastChild?.scrollIntoView({ behaviour: "smooth" });
        }, 100);
    },
    editCriteria(state: typeof initialState, action: PayloadAction<{ id: string; text: string }>) {
        const { id, text } = action.payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            criteria.text = text;
        }
    },
    editAppendText(state: typeof initialState, action: PayloadAction<{ id: string; text: string }>) {
        const { id, text } = action.payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            criteria["appendText"] = text;
        }
    },
    deleteCriteria(state: typeof initialState, { payload }: { payload: string }) {
        state.criteria = state.criteria.filter(({ id }) => payload !== id);
    },
    setCriteria(state: typeof initialState, action: PayloadAction<SetCriteriaPayload[]>) {
        const { payload } = action;
        const newCriteria = payload.map((item) => {
            const { _id, ...rest } = item;
            return { ...rest, id: _id };
        });
        state.criteria = newCriteria;
    },
    setPointTag(state: typeof initialState, action: PayloadAction<{ id: string; value: Tag }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                item.tag = value;
            }
        });
    },
    resetCriteria(state: typeof initialState) {
        state.criteria = [];
    },
    toggleAppendText(state: typeof initialState) {
        state.enableAppendText = !state.enableAppendText;
    },
    changeCriteriaType(state: typeof initialState, action: PayloadAction<{ id: string; value: CriteriaType }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                item.type = value;
            }
        });
    },
    fetchVettingCriteria(state: typeof initialState, action: FetchVettingCriteriaPayload) {},
    addSearchCriteria(state: typeof initialState) {
        const findCriteria = (type: CriteriaType) => state.criteria.find((criterion) => criterion.type === type);

        const updatedSearchCriteria = SearchCriteria.map((item) => {
            const criteria = findCriteria(item.type);
            return {
                ...item,
                text: criteria?.text || "",
                suggestion: criteria?.text ? "" : criteria?.suggestion || "",
            };
        });
        state.criteria = updatedSearchCriteria;
    },
    cancelSagas() {},
};

export const vettingCriteriaSlice = createSlice({
    name: "vettingCriteria",
    initialState,
    reducers,
});

export default vettingCriteriaSlice.reducer;

export const {
    setErrorWhileFetchingVettingCriteria,
    addCriteria,
    editAppendText,
    editCriteria,
    deleteCriteria,
    resetCriteria,
    setCriteria,
    setPointTag,
    changeCriteriaType,
    toggleAppendText,
    fetchVettingCriteria,
    addSearchCriteria,
    cancelSagas,
} = vettingCriteriaSlice.actions;

export const selectVettingCriteria = (state: RootState) => state.vettingCriteria.criteria;
export const selectToggleAppendText = (state: RootState) => state.vettingCriteria.enableAppendText;
export const checkErrorWhileFetchingVettingCriteria = (state: RootState) =>
    state.vettingCriteria.isErrorWhileFetchingVettingCriteria;
