import IconButton from "@mui/material/IconButton";
import useMenu from "../../../../hooks/useMenu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { selectContactOverviewActivitiesSortValues, setActivitySortValues } from "./contact-overview-activities.slice";
import { useAppDispatch } from "../../../../store/Store";
import { ContactOverviewActivitiesSortStateKeys } from "./ContactOverviewActivities.types";
import { capitalize } from "lodash";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

export function SortActivities() {
    const dispatch = useAppDispatch();
    const sortValues = useSelector(selectContactOverviewActivitiesSortValues);
    const { anchorEl, open, handleClose, handleClick, menuSX } = useMenu();

    const handleMenuItemClick = (key: ContactOverviewActivitiesSortStateKeys) => {
        dispatch(setActivitySortValues({ key }));
        handleClose();
    };

    return (
        <>
            <Tooltip title="Sort activities">
                <IconButton onClick={handleClick}>
                    <FilterListRoundedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={menuSX}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {Object.entries(sortValues).map(([key, value]) => {
                    return (
                        <MenuItem
                            key={key}
                            onClick={() => handleMenuItemClick(key as ContactOverviewActivitiesSortStateKeys)}
                            selected={value}
                        >
                            {capitalize(key)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
