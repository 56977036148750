import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import JoyButton from "@mui/joy/Button";
import JoyStack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import { alpha, Popover, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { blue, deepOrange, grey, red } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";

import CustomButton from "../../../components/CustomButton";
import { JoyProvider } from "../../../components/JoyProvider";
import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { TextButton } from "../../../components/TextButton";
import useMenu from "../../../hooks/useMenu";
import { useUpdateContactOverviewActivityMutation } from "../../../store/reducers/all-candidates.api.slice";
import {
    dateTimePickerStyles,
    ScheduleReminderEmailForActivity,
} from "../../all-candidates-reachout/components/ScheduleReminderEmailForActivity";
import { trimText } from "../../project/components/exportCandidates/pdfDataTemplate";
import { selectUser } from "../../Signin/Signin.reducer";
import { formatDueDate } from "../all-candidates.utils";
import { ActivityColumn, PriorityType } from "./ContactOverviewActivities/ContactOverviewActivities.types";

const criticalityOptions: PriorityType[] = ["HIGH", "MEDIUM", "LOW"];

function Priority({
    value,
    handlePriorityUpdate,
}: {
    value: Exclude<PriorityType, undefined>;
    handlePriorityUpdate: (value: PriorityType) => void;
}) {
    const { anchorEl, handleClick, handleClose, open, menuSX } = useMenu();
    const [criticality, setCriticality] = useState<PriorityType>(() => value);

    useEffect(() => {
        setCriticality(value as PriorityType);
    }, [value]);

    const handleMenuItemClick = (value: string) => {
        const priorityValue = value as PriorityType;
        handleClose();
        setCriticality(priorityValue);
        handlePriorityUpdate(priorityValue);
    };

    return (
        <>
            <Chip
                size="small"
                component="button"
                label={criticality}
                onClick={handleClick}
                sx={(theme) => {
                    const backgroundColor =
                        criticality === "HIGH" ? red[300] : criticality === "MEDIUM" ? deepOrange[300] : blue[300];

                    const color =
                        criticality === "HIGH" ? red[500] : criticality === "MEDIUM" ? deepOrange[500] : blue[600];
                    return {
                        fontWeight: 600,
                        fontSize: theme.typography.pxToRem(10),
                        backgroundColor: alpha(backgroundColor, 0.3),
                        color,
                        "&:hover": {
                            backgroundColor: alpha(backgroundColor, 0.2),
                        },
                    };
                }}
            />
            <Menu sx={menuSX} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {criticalityOptions.map((i) => {
                    if (!i) {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={i}
                            onClick={() => handleMenuItemClick(i)}
                            sx={{
                                "&.MuiMenuItem-root": {
                                    fontSize: "12px",
                                },
                            }}
                        >
                            {i}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

export type ActivityDetailsProps = ActivityColumn["activityDetails"] & {
    id: ActivityColumn["_id"];
    enableEdit?: () => void;
    closeEdit?: () => void;
};

const dueDateFormSchema = z.object({
    dueDate: z.custom<Dayjs>((val) => {
        if (!(val instanceof dayjs)) return false;

        return true;
    }, "Enter valid time"),
});

type DueDateFormType = z.infer<typeof dueDateFormSchema>;

const DueDate = ({
    dueDate,
    handleDueDateUpdate,
    isLoading,
}: {
    dueDate: string;
    handleDueDateUpdate: (value: string) => void;
    isLoading: boolean;
}) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<DueDateFormType>({
        resolver: zodResolver(dueDateFormSchema),
        defaultValues: {
            dueDate: dayjs(dueDate),
        },
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const onSubmit = (data: DueDateFormType) => {
        const { dueDate } = data;
        handleDueDateUpdate(dueDate.toISOString());
    };

    return (
        <>
            <Typography
                onClick={handleClick}
                variant="caption"
                sx={{
                    fontSize: "11px",
                    padding: "0 0.5rem",
                    backgroundColor: grey[200],
                    borderRadius: "4px",
                    height: "fit-content",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                {formatDueDate(dueDate)}
            </Typography>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-root": {
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        minWidth: "250px",
                        mt: 1,
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack p={2} gap={1.7} onSubmit={handleSubmit(onSubmit)}>
                    <Stack component="form" gap={1.5}>
                        <Controller
                            control={control}
                            name="dueDate"
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        ampm={false}
                                        label="Due date"
                                        slotProps={{
                                            textField: {
                                                helperText: errors.dueDate?.message,
                                                FormHelperTextProps: {
                                                    sx: { marginX: "3px" },
                                                },
                                                error: Boolean(errors?.dueDate?.message),
                                            },
                                        }}
                                        sx={dateTimePickerStyles}
                                        {...field}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <CustomButton
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            sx={{ alignSelf: "flex-end", padding: "0.3rem 1.1rem" }}
                        >
                            Submit
                        </CustomButton>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
};

const EditActivityBody = ({
    body,
    handleBodyUpdate,
    stopEditing,
    isLoading,
}: {
    body: string;
    handleBodyUpdate: (value: string) => void;
    stopEditing: () => void;
    isLoading: boolean;
}) => {
    const [data, setData] = useState(body);

    useEffect(() => {
        setData(body);
    }, [body]);

    return (
        <JoyProvider>
            <Textarea
                onChange={(e) => setData(e.target.value)}
                sx={{ minHeight: "200px", fontSize: "12px" }}
                value={data}
            />
            <JoyStack mt={1} direction={"row"} gap={1} alignItems={"center"} justifyContent={"flex-end"}>
                <JoyButton onClick={stopEditing} size="sm" variant="outlined">
                    Cancel
                </JoyButton>
                <JoyButton loading={isLoading} onClick={() => handleBodyUpdate(data)} size="sm" variant="solid">
                    Save
                </JoyButton>
            </JoyStack>
        </JoyProvider>
    );
};

export function ActivityDetails(props: ActivityDetailsProps & { referToId: number }) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [editing, setEditing] = useState(false);
    const userId = useSelector(selectUser)._id;
    const [updateContactOverviewActivityMutation, { isLoading: isUpdating }] =
        useUpdateContactOverviewActivityMutation();
    const handleMarkAsComplete = (value: boolean) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            read: value,
        });
    };

    const handlePriorityUpdate = (value: PriorityType) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            priority: value,
        });
    };

    const handleDueDateUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            dueDate: value,
        });
    };

    const handleBodyUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            body: value,
        })
            .unwrap()
            .then(() => {
                dispatch(setSuccessNotification("Content updated successfully"));
            })
            .catch(() => {
                dispatch(setErrorNotification("Error while updating content"));
            })
            .finally(() => {
                setEditing(false);
            });
    };

    const isExpandable = props?.body?.length > 300;

    return (
        <Stack width="100%" padding={1} gap={0.3} justifyContent="space-between" onClick={(e) => e.stopPropagation()}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" gap={0.5}>
                    {props?.priority && <Priority value={props.priority} handlePriorityUpdate={handlePriorityUpdate} />}
                    {props?.dueDate && (
                        <DueDate
                            isLoading={isUpdating}
                            handleDueDateUpdate={handleDueDateUpdate}
                            dueDate={props.dueDate}
                        />
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" gap={0.7}>
                    <Typography variant="caption" sx={{ fontSize: "11px" }}>
                        <span style={{ color: grey[500] }}>Project</span>{" "}
                        <Tooltip title={props.project.name}>
                            <span>{trimText(props.project.name, 30)}</span>
                        </Tooltip>
                    </Typography>
                    {props.scheduledFor?.email ? (
                        <Tooltip
                            title={`Scheduled for ${props.scheduledFor?.email} at ${dayjs(
                                props.scheduledFor.timestamp
                            ).format("D MMMM, YYYY h:mmA")}. `}
                        >
                            <ScheduleRoundedIcon
                                fontSize="small"
                                color="primary"
                                sx={(theme) => ({
                                    stroke: theme.palette.primary.main,
                                    strokeWidth: 0.3,
                                })}
                            />
                        </Tooltip>
                    ) : (
                        <ScheduleReminderEmailForActivity activityId={props.id} />
                    )}
                </Stack>
            </Stack>
            {editing === true ? (
                <EditActivityBody
                    body={props.body}
                    handleBodyUpdate={handleBodyUpdate}
                    stopEditing={() => setEditing(false)}
                    isLoading={isUpdating}
                />
            ) : (
                <Typography
                    variant="caption"
                    sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.common.black,
                        textWrap: "wrap",
                        py: 1,
                    })}
                    onClick={() => setEditing(true)}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: expanded
                                ? (props?.body?.replace(/\n/g, "<br />") as string)
                                : (props?.body?.replace(/\n/g, "<br />")?.slice(0, 300) as string),
                        }}
                    />
                    {!expanded && isExpandable && "..."}
                    {isExpandable && (
                        <TextButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(!expanded);
                            }}
                            sx={{ padding: 0 }}
                        >
                            {expanded ? " Read Less" : " Read More"}
                        </TextButton>
                    )}
                </Typography>
            )}

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption" sx={{ color: grey[500], fontSize: "11px" }}>
                    Created on {dayjs(props.timestamp).format("DD MMM YYYY, hh:mmA")}
                </Typography>
                <Stack flexDirection="row" alignItems="center" gap={0.7}>
                    <Typography variant="caption" sx={{ fontSize: "11px", color: grey[500] }}>
                        by {props.createdBy.name}
                    </Typography>
                    {props.read ? (
                        <Chip size="small" label="Completed" color="primary" sx={{ fontSize: "9px" }} />
                    ) : props.referToId === userId ? (
                        <Chip
                            size="small"
                            label="Mark as complete"
                            color="primary"
                            sx={{ fontSize: "9px" }}
                            onClick={() => {
                                if (!isUpdating) {
                                    handleMarkAsComplete(true);
                                }
                            }}
                        />
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}
