import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setSelectAllCandidates } from "../project/index.reducer";
import {
    cancelSagas,
    getUserEmail,
    getUserLinkedinMsgs,
    postEmailReadReceipt,
    sendEmail,
    setLinkedinMsgs,
    setUserEmail,
    getUserSmsConversations,
    setSMSDetails,
    markAsRead,
    submitSMSResponse,
    addToConversation,
    setCandidateSMSStatus,
} from "./message.slice";
import {
    GetUserSMSConversationsPayload,
    IGetUserEmailPayload,
    IMessage,
    ISendEmailPayload,
    MarkAsReadPayload,
    MarkAsReadResponse,
    SmsResponse,
    SubmitSMSResponsePayload,
    SubmitSmsResponse,
} from "./message.types";

function* getUserEmailSaga(action: PayloadAction<IGetUserEmailPayload>): SagaIterator {
    const { payload, type } = action;
    try {
        yield put(startAction({ action: type }));

        let url = "/communication/get-user-emails";
        if (payload.projectId) {
            url += `?projectId=${payload.projectId}`;
        }
        const response = yield call(new API().get, url);

        if ("error" in response) {
            throw new Error();
        }

        yield put(setUserEmail(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification("error while fetching user emails"));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* sendEmailSaga(action: PayloadAction<ISendEmailPayload>): SagaIterator {
    const { payload, type } = action;
    try {
        const { onSuccess, ...rest } = payload;

        yield put(startAction({ action: type }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload = {
            ...rest,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidates = [];
        }

        const response = yield call(new API().post, "/communication/send-email", triggerPayload);
        if (!response) return;

        if (onSuccess) onSuccess();
        yield put(setSelectAllCandidates(false));
        yield put(getUserEmail({}));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* postEmailReadReceiptSaga(action: PayloadAction<{ threadId: string }>): SagaIterator {
    const { payload, type } = action;
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, `/communication/read-receipt/${payload.threadId}`);
        if (!response) return;

        const state = yield select();
        const userEmailsStateValue = get(state, "allProjects.userEmail");
        const tmpUserEmails = userEmailsStateValue.map((msg: IMessage) => {
            if (msg.threadId === payload.threadId) {
                return {
                    ...msg,
                    read: true,
                };
            }
            return msg;
        });
        yield put(setUserEmail(tmpUserEmails));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getUserLinkedinMsgsSaga(action: PayloadAction<IGetUserEmailPayload>): SagaIterator {
    const { payload, type } = action;
    try {
        yield put(startAction({ action: type }));

        let url = "/communication/get-li-msgs";
        if (payload.projectId) {
            url += `?projectId=${payload.projectId}`;
        }
        const response = yield call(new API().get, url);

        if ("error" in response) {
            throw new Error();
        }

        yield put(setLinkedinMsgs(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification("error while fetching user linkedin msgs"));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getUserSmsConversationsSaga({ payload, type }: GetUserSMSConversationsPayload): SagaIterator {
    try {
        const { projectId } = payload;
        let url = "/sms/list";
        if (projectId) {
            url = url + `?projectId=${projectId}`;
        }

        yield put(startAction({ action: type }));

        const response: SmsResponse = yield call(new API().get, url);

        if (response.success) {
            const { data } = response;
            yield put(setSMSDetails(data));
        } else {
            yield put(setSMSDetails([]));
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Unexpected error while fetching sms conversation please try again!"));
    } finally {
        yield put(stopAction({ action: type }));
        payload.onFinally?.();
    }
}

function* markAsReadSaga(action: MarkAsReadPayload): SagaIterator {
    try {
        const { candidateId, projectIds } = action.payload;

        yield put(startAction({ action: action.type }));

        yield put(setCandidateSMSStatus({ candidateId }));
        const response: MarkAsReadResponse = yield call(new API().post, "/sms/mark-as-read", {
            candidateId,
            projectIds,
        });

        if (!response.success) {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitSMSResponseSaga(action: SubmitSMSResponsePayload): SagaIterator {
    try {
        const { body, id, successCallback, projectId } = action.payload;

        yield put(startAction({ action: action.type }));
        const response: SubmitSmsResponse = yield call(new API().post, "/sms/send-sms", {
            candidateId: id,
            smsBody: body,
            projectId,
        });

        if (response.success) {
            successCallback();
            yield put(
                addToConversation({
                    candidateId: id,
                    data: response.data,
                })
            );
            yield put(setSuccessNotification("responded successfully"));
        } else {
            if (response?.message) {
                yield put(setErrorNotification(response.message));
            } else {
                throw new Error();
            }
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("error while responding to the sms"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getUserEmail.type, getUserEmailSaga),
        // @ts-ignore
        yield takeLatest(sendEmail.type, sendEmailSaga),
        // @ts-ignore
        yield takeLatest(getUserLinkedinMsgs.type, getUserLinkedinMsgsSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postEmailReadReceipt.type,
            postEmailReadReceiptSaga
        ),
        // @ts-ignore
        yield takeLatest(getUserSmsConversations.type, getUserSmsConversationsSaga),
        // @ts-ignore
        yield takeLatest(markAsRead.type, markAsReadSaga),
        // @ts-ignore
        yield takeLatest(submitSMSResponse.type, submitSMSResponseSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
