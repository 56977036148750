import React, { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import githubIcon from "../../../assets/img/email-providers-logo/githubIcon.png";
import { getProject } from "../../../pages/allProjects/index.reducer";
import { tooltipSetting } from "../../../pages/analytics-v2/utils";
import style from "../../../pages/project/components/candidateModal/candidateModal.module.scss";
import { selectProjectFilters } from "../../../pages/project/index.reducer";
import {
    useFetchBetaAICandidatesMutation,
    useFetchBetaGitCandidatesMutation,
} from "../../../store/reducers/all-candidates.api.slice";
import { selectGeography } from "../../../store/reducers/search/search.slice";
import { addGitcandidatesToProject } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ISampleMessage } from "../../../store/reducers/searchCandidates/searchCandidates.types";
import { setErrorNotification, setSuccessNotification } from "../../Notification/index.reducer";
import LocationPicker from "./LocationPicker";

type GitCandidate = {
    name: string;
    htmlurl: string;
    location: string;
    bio: string;
    userScore: number;
    company?: string;
};

type AISearchModalProps = {
    open: boolean;
    onClose: () => void;
    projectId: string;
};

const AISearchModal: React.FC<AISearchModalProps> = ({ open, onClose, projectId }) => {
    const [inputValue, setInputValue] = useState("");
    const [isFinding, setIsFinding] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [candidates, setCandidates] = useState<GitCandidate[]>([]);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [noCandidatesFound, setNoCandidatesFound] = useState<boolean | null>(null);
    const [searchId, setSearchId] = useState<string>("");
    const [expanded, setExpanded] = useState(true);

    const [role, setRole] = useState("Machine Learning");
    // const [location, setLocation] = useState("USA");
    const dispatch = useDispatch();
    let locn = "USA";
    const [fetchBetaGitCandidates] = useFetchBetaGitCandidatesMutation();

    const handleFindClick = async () => {
        // setExpanded(false);
        setIsFinding(true);
        setFetchError(false);
        try {
            const { data } = await fetchBetaGitCandidates({
                jobTitle: role,
                location: locn,
            }).unwrap();
            if (data && data.total <= 1) {
                setFetchError(false);
                setNoCandidatesFound(true);
                setShowCandidateDetails(false);
            } else if (data && data.profiles) {
                const mappedCandidates = data.profiles.map((candidate: any) => ({
                    htmlurl: candidate.html_url,
                    name: candidate.name,
                    location: candidate.location,
                    bio: candidate.bio,
                    userScore: candidate.userScore,
                    company: candidate.company,
                }));
                setCandidates(mappedCandidates);
                setShowCandidateDetails(true);
                setNoCandidatesFound(false);
            }
            setSearchId(data.searchId);
        } catch (error) {
            console.error("Failed to fetch candidates:", error);
            setFetchError(true);
            setShowCandidateDetails(false);
        } finally {
            setIsFinding(false);
        }
    };

    const handleAddGitCandidates = () => {
        if (projectId) {
            try {
                dispatch(
                    addGitcandidatesToProject({
                        projectId,
                        searchId,
                        projectName: null,
                        navigate: () => {},
                        canNavigateNextStep: false,
                        onSuccess() {
                            dispatch(
                                getProject({
                                    projectId,
                                    action: getProject.type,
                                })
                            );
                        },
                    })
                );
                onClose();
                setShowCandidateDetails(false);
                dispatch(setSuccessNotification("Adding candidates to project"));
            } catch (error) {
                console.error("Error adding AI candidates to project:", error);
                dispatch(setErrorNotification("Error adding AI candidates to project"));
            }
        }
    };

    const handleGithubClick = (url: string) => {
        if (url) {
            window.open(url);
        } else {
            dispatch(setErrorNotification("No Github url exists"));
        }
    };

    function SampleMessage(props: ISampleMessage) {
        const { message, onClick } = props;
        return (
            <Typography
                color="#068093"
                fontSize={12}
                p={0.5}
                sx={{
                    border: "1px solid #068093",
                    borderRadius: 1,
                    cursor: "pointer",
                    marginBottom: "5px",
                    width: "94%",
                    marginLeft: "18px",
                }}
                onClick={() => onClick(message)}
            >
                <span style={{ fontSize: "14px" }}>💡</span> <i>{message}</i>
            </Typography>
        );
    }

    function CustomMessage(props: ISampleMessage) {
        const { message, onClick } = props;
        const { values } = useSelector(selectGeography);
        locn = values[0]?.value;
        // const [position, setPosition] = useState("PhD");

        const roles = [
            "Artificial Intelligence",
            "Machine Learning",
            "Deep Learning",
            "Neural Networks",
            "Generative AI",
            "Computer Vision",
            "Natural Language Processing",
            "Reinforcement Learning",
            "Representation Learning",
            "AI Explainability",
            "Data Efficiency",
            "Conversational AI",
            "Multimodal Data Intelligence",
            "Multitask Learning",
            "Supervised Learning",
            "Unsupervised Learning",
            "Semi Supervised Learning",
            "Transfer Learning",
            "Meta Learning",
            "Graph Neural Networks",
            "Convolutional Neural Networks",
            "Recurrent Neural Networks",
            "Generative Adversarial Networks",
            "Self Supervised Learning",
            "Explainable Ai",
            "Ai Ethics",
            "Ai Safety",
            "Data Science",
            "Medical Image Analysis",
            "Image Recognition",
            "Machine Translation",
            "Speech Recognition",
            "Speech Synthesis",
            "Text To Speech",
            "Information Retrieval",
            "Sentiment Analysis",
            "Language Modeling",
            "Embedding Models",
            "Transformer Models",
        ];

        const handleClick = () => {
            const customizedMessage = `Find top candidates in the fields of ${role} in the areas of ${values[0]?.value}.`;
            setInputValue(customizedMessage);
        };

        return (
            <div style={{ display: "flex" }}>
                <Box
                    fontSize={15}
                    p={0.5}
                    sx={{
                        cursor: "pointer",
                        marginBottom: "5px",
                        width: "80%",
                        marginLeft: "18px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        textAlign: "center",
                        height: "auto",
                    }}
                >
                    <Box fontSize={15} marginLeft={1}>
                        <i>Find top candidates in the field of </i>
                    </Box>

                    <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="standard"
                        sx={{
                            mx: 1,
                            fontSize: "14px",
                            width: "120px",
                            maxWidth: "120px",
                            border: "1px solid lightgrey",
                            padding: "3px",
                            borderRadius: "5px",
                        }}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>

                    <Box fontSize={15} marginLeft={1}>
                        <i>in the areas of</i>
                    </Box>

                    <LocationPicker id={"geography"} multiple={false} />
                </Box>

                <Box
                    fontSize={14}
                    p={0.5}
                    sx={{
                        cursor: "pointer",
                        marginBottom: "5px",
                        width: "20%",
                        marginLeft: "18px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        textAlign: "center",
                        height: "auto",
                        justifyContent: "center",
                        justifyItems: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleFindClick}
                        sx={{ height: "40px", marginRight: "15px" }}
                        disabled={isFinding}
                    >
                        {isFinding ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Find"}
                    </Button>
                </Box>
            </div>
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                position="absolute"
                top="5%"
                left="54%"
                sx={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: 2,
                    maxHeight: "88vh",
                    width: "575px",
                    overflow: "hidden",
                    transform: "translateX(-50%)",
                    outline: "none",
                }}
            >
                <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
                    <Typography variant="h6" sx={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
                        Github Recruiter: Find the top coding talent
                        <Tooltip
                            {...tooltipSetting}
                            title={
                                "Easysource GitRecruiter allows you to source top coding talent from sites like Github high in repo content."
                            }
                        >
                            <InfoIcon
                                fontSize="small"
                                color="disabled"
                                sx={{ marginLeft: "6px", marginBottom: "-3px" }}
                            />
                        </Tooltip>
                    </Typography>
                </Box>

                {expanded && (
                    <>
                        <Typography sx={{ textAlign: "left", marginLeft: "18px", fontSize: "14px", marginTop: "10px" }}>
                            Customize your query
                        </Typography>

                        <CustomMessage
                            message="I am looking for XYZ engineer who have skills in XYZ from XYZ."
                            onClick={(val) => setInputValue(val)}
                        />
                    </>
                )}
                <Box display="flex" gap={1} mt={2} sx={{ marginBottom: "20px" }}></Box>

                {isFinding ? (
                    <Typography
                        sx={{
                            textAlign: "center",
                            marginTop: "5px",
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "#5a5656",
                        }}
                    >
                        This should take a few moments...
                    </Typography>
                ) : null}

                {fetchError ? (
                    <>
                        <Typography
                            sx={{
                                textAlign: "center",
                                marginTop: "5px",
                                fontSize: "12px",
                                fontStyle: "italic",
                                color: "#5a5656",
                            }}
                        >
                            Results were not fetched successfully at this point in time.
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontStyle: "italic",
                                color: "#5a5656",
                            }}
                        >
                            Please try again in a while or contact your Account Manager
                        </Typography>
                    </>
                ) : null}

                {!fetchError && noCandidatesFound === true && !isFinding ? (
                    <Typography
                        sx={{
                            textAlign: "center",
                            marginTop: "5px",
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "#5a5656",
                        }}
                    >
                        Unfortunately, no relevant results were found for your query. Please try changing and refreshing
                        your results.
                    </Typography>
                ) : null}

                {showCandidateDetails && (
                    <>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: "center",
                                padding: "10px",
                                borderTop: "2px solid lightgrey",
                                paddingBottom: "10px",
                            }}
                        >
                            Candidate details ({candidates?.length} candidates)
                        </Typography>
                        <Container
                            sx={{
                                maxHeight: "400px",
                                overflowY: "auto",
                                scrollBehavior: "smooth",
                                marginBottom: "10px",
                            }}
                        >
                            {candidates.map((candidate, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    border="1px solid rgba(0, 0, 0, 0.2)"
                                    borderRadius={2}
                                    p={2}
                                    mt={2}
                                    sx={{ backgroundColor: "#f8fcfd" }}
                                >
                                    <Box flexGrow={1}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {candidate?.name && (
                                                <Typography
                                                    sx={{
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        color: "black",
                                                        display: "inline",
                                                    }}
                                                >
                                                    {candidate.name?.length > 20
                                                        ? `${candidate.name.substring(0, 20)}...`
                                                        : candidate.name}

                                                    {candidate?.htmlurl && (
                                                        <Tooltip title="Github profile" placement="bottom">
                                                            <img
                                                                src={githubIcon}
                                                                alt=""
                                                                width={20}
                                                                height={20}
                                                                style={{ marginBottom: "-3.5px", marginLeft: "5px" }}
                                                                onClick={() => handleGithubClick(candidate.htmlurl)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>

                                        {candidate?.bio && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                About:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.bio}{" "}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.location && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "4px" }}>
                                                Location:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.location}
                                                </span>{" "}
                                            </Typography>
                                        )}

                                        {candidate?.company && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "4px" }}>
                                                Company:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.company}
                                                </span>{" "}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Container>

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            mt={4}
                            sx={{
                                position: "fixed",
                                bottom: 1,
                                right: 20,
                                backgroundColor: "white",
                                width: "100%",
                                paddingTop: "5px",
                                paddingBottom: "4px",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleAddGitCandidates}
                                sx={{
                                    height: "35px",
                                    alignSelf: "flex-end",
                                }}
                            >
                                Add list to project
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default AISearchModal;
