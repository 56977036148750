import { Fragment, useMemo, useState } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Checkbox, Chip, ChipProps, styled, TableRow, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { candidateStageItemsMUI } from "../../constant/Constant";
import useCopyToClipboard from "../../hooks/useCopyToClipboard.hook";
import { RootState } from "../../store/Store";
import {
    CandidatesResponse,
    useFetchActivityTagsQuery,
    useFetchCandidatesQuery,
} from "../../store/reducers/all-candidates.api.slice";
import { objectEntries } from "../../utils/helper";
import { detailedProjectStageOptionsList } from "../all-candidates-reachout/all-candidates-reachout.types";
import { getComparator, stableSort } from "../all-candidates/all-candidates.utils";
import {
    fetchCandidates,
    handleRowClickInAllCandidates,
    selectAllCandidatesFilters,
    selectAllCandidatesPage,
    selectAllCandidatesRowsPerPage,
    selectAllCandidatesSearchResults,
    selectAllCandidatesSelected,
    selectAllCandidatesTableOrder,
    selectAllCandidatesTableOrderBy,
    selectAllFiltersWithValue,
    selectColumnFilter,
    selectFilterValue,
    selectPagination,
    selectSearchQueryInAllCandidates,
    selectSorting,
    selectTotalRows,
} from "./all-candidates.slice";
import { Candidate, CandidateFilterValue, isCandidateProject, isString } from "./all-candidates.types";
import AllContactsTableHead from "./AllCandidatesTableHead";
import AllContactsToolbar from "./AllCandidatesToolbar";

function ContactListName({ labelId, id, name }: { labelId: string; id: string; name?: string }) {
    return (
        <CustomTableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            align="left"
            sx={{ width: "30%" }}
            disablebottomborder
        >
            <Stack direction="row" spacing={3} alignItems="center">
                <Link
                    to={`/candidate-reachout/${id}`}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    target="_blank"
                >
                    <Typography
                        variant="body2"
                        sx={(_) => ({
                            color: "#072f6c",
                            fontWeight: 600,
                        })}
                    >
                        {name || "-"}
                    </Typography>
                </Link>

                {/* {isHover && (
                    <ContactListMenu
                        removeHover={() => setHover(false)}
                        id={id}
                        handleLinkClick={handleLinkClick}
                    />
                )} */}
            </Stack>
            {/* <CSVLink
                headers={headers}
                data={csvData}
                filename={`${name}.csv`}
                style={{ display: "none" }}
                ref={exportRef}
                target="_blank"
            /> */}
        </CustomTableCell>
    );
}
interface CustomTableCellProps extends TableCellProps {
    sticky?: boolean;
    defaultwidth?: boolean;
    disablebottomborder?: boolean;
}

const CustomTableCell = styled(TableCell)<CustomTableCellProps>(
    ({ theme, sticky, defaultwidth = true, disablebottomborder = false }) => ({
        ...(defaultwidth && {
            minWidth: "200px",
        }),
        ...(sticky && {
            position: "sticky",
            left: 0,
            backgroundColor: theme.palette.common.white,
            zIndex: theme.zIndex.appBar,
        }),
        ...(disablebottomborder && {
            borderBottom: 0,
        }),
    })
);

function Empty(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6" {...props}>
            <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z" />
            <path
                fillRule="evenodd"
                d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.163 3.75A.75.75 0 0110 12h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function extractRowsBasedOnFilters(data: CandidatesResponse | undefined, filters: CandidateFilterValue): Candidate[] {
    if (!data) {
        return [];
    }

    return data.candidates.map((val) => {
        const candidateWithFilters: Candidate = {
            _id: "",
        };

        Object.keys(filters).forEach((key) => {
            if (val?._id) {
                candidateWithFilters._id = val._id;
            }

            if (key in val) {
                candidateWithFilters[key] = val[key];
            } else {
                candidateWithFilters[key] = "-";
            }
        });

        return candidateWithFilters;
    });
}

function LinkedInURL({ link }: { link: string }) {
    const dispatch = useDispatch();
    const [, copy] = useCopyToClipboard({
        onSuccessfulCopy: (_: string) => {
            dispatch(setSuccessNotification("Url copied successfully"));
        },
        onError: (_: string) => dispatch(setErrorNotification("Unexpected error occurred while copying url")),
    });
    return (
        <CustomTableCell defaultwidth={false}>
            <Stack direction="row" alignItems="center" sx={{ position: "relative", width: "fit-content" }}>
                <a href={link} target="_blank" rel="noreferrer" style={{ marginLeft: "1.2rem", marginTop: "0.5rem" }}>
                    <LinkedInIcon fontSize="medium" sx={{ color: "#0A66C2" }} />
                </a>
                <Tooltip title="Copy URL">
                    <IconButton
                        sx={{
                            padding: 0,
                            position: "absolute",
                            top: 0,
                            right: "-1rem",
                        }}
                        onClick={() => copy(link)}
                    >
                        <ContentCopyRoundedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
        </CustomTableCell>
    );
}

function getChipColorBasedOnValue(value: string): ChipProps["color"] {
    const lowerCaseValue = value.toLowerCase();
    const otherVariants: ChipProps["color"][] = ["secondary", "info", "success"];

    if (lowerCaseValue === "network") {
        return "primary";
    }

    if (lowerCaseValue === "candidate") {
        return "default";
    }

    const randomIndex = Math.floor(Math.random() * otherVariants.length);
    return otherVariants[randomIndex];
}

function getProjectStageColorBasedOnValue(value: string): ChipProps["color"] {
    const stage = candidateStageItemsMUI.find(({ key }) => key === value)?.label?.toLowerCase();

    if (stage === "shortlisted") {
        return "success";
    }

    if (stage === "rejected") {
        return "error";
    }

    if (stage === "not interested") {
        return "warning";
    }

    if (stage === "to be reviewed") {
        return "primary";
    }

    return "default";
}

function getCandidateStageLabel(stage: string) {
    return detailedProjectStageOptionsList.find(({ value }) => value === stage)?.label || "-";
}

function getCandidateStageColor(stage: string): ChipProps["color"] {
    const detailStage = detailedProjectStageOptionsList.reduce(
        (acc: { [key: string]: ChipProps["color"] }, { value }) => {
            if (value === "in_play") {
                acc[value] = "success";
            }

            if (value === "recommend_meet") {
                acc[value] = "primary";
            }

            if (value === "Engaged") {
                acc[value] = "secondary";
            }

            if (value === "reached_out") {
                acc[value] = "warning";
            }

            if (value === "vetting_underway") {
                acc[value] = "info";
            }

            if (
                value === "candidate_bowed_out" ||
                value === "client_not_moving_forward" ||
                value === "candidate_recently_switched" ||
                value === "not_interested"
            ) {
                acc[value] = "default";
            }

            if (value === "not_fit") {
                acc[value] = "error";
            }

            return acc;
        },
        {}
    );

    return detailStage[stage];
}

function ActivityValues({ value }: { value: string }) {
    const [readMore, setReadMore] = useState(false);
    const valueWithoutSpaces = value.trim();

    if (!valueWithoutSpaces) {
        return null;
    }

    return (
        <Typography variant="subtitle2">
            {valueWithoutSpaces === "-" ? (
                valueWithoutSpaces
            ) : (
                <>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: readMore ? valueWithoutSpaces : valueWithoutSpaces.slice(0, 200) + "...",
                        }}
                    />
                    <Typography
                        sx={{
                            display: "inline-block",
                            textDecoration: "underline",
                            marginLeft: "3px",
                            fontSize: "12px",
                            fontWeight: "500",
                        }}
                        onClick={() => setReadMore((prev) => !prev)}
                    >
                        Read {readMore ? "Less" : "More"}
                    </Typography>
                </>
            )}
        </Typography>
    );
}

export function useFetchCandidatesWithPayload() {
    const pagination = useSelector(selectPagination);
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const sorting = useSelector(selectSorting);
    const filters = useSelector(selectAllFiltersWithValue);
    const columnFilter = useSelector(selectColumnFilter);
    const { ...rest } = useFetchCandidatesQuery({
        pageSize: pagination.pageSize,
        start: pagination.pageIndex,
        search: searchQuery,
        filterBy: filters,
        sortBy: sorting[0],
        filterByColumn: columnFilter,
    });

    return { ...rest };
}
export function useFetchActivityTags({
    defaultTags = true,
    defaultProjectIds = [],
}: {
    defaultTags?: boolean;
    defaultProjectIds?: number[];
} = {}) {
    const value = useSelector((state: RootState) => selectFilterValue(state, "List of projects"));
    const { ...rest } = useFetchActivityTagsQuery(
        {
            projectIds: defaultProjectIds?.length
                ? defaultProjectIds
                : value?.map((s) => (s?.value ? Number(s.value) : 0))?.filter(Boolean) || [],
            defaultTags,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    return { ...rest };
}

function TableContent() {
    const dispatch = useDispatch();
    const filters = useSelector(selectAllCandidatesFilters);
    const page = useSelector(selectAllCandidatesPage);
    const order = useSelector(selectAllCandidatesTableOrder);
    const orderBy = useSelector(selectAllCandidatesTableOrderBy);
    const rowsPerPage = useSelector(selectAllCandidatesRowsPerPage);
    const selected = useSelector(selectAllCandidatesSelected);
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const { data } = useFetchCandidatesWithPayload();
    const isAllResultsSelected = useSelector(selectAllCandidatesSearchResults);

    const isRowSelected = (id: string): boolean => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(() => {
        const extractedRows = extractRowsBasedOnFilters(data, filters);

        return stableSort(extractedRows, getComparator(order, orderBy));
    }, [order, orderBy, page, rowsPerPage, searchQuery, data, filters]);

    return (
        <>
            {visibleRows.length ? (
                visibleRows.map(({ _id, Name, Label, ...rest }, index) => {
                    if (!_id) {
                        return null;
                    }

                    const isItemSelected = isRowSelected(_id) || isAllResultsSelected;
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const label = Label && isArray(Label) ? Label : null;

                    const name = Name && !isArray(Name) ? Name : null;

                    return (
                        <Fragment key={_id}>
                            <TableRow hover tabIndex={-1} selected={isItemSelected}>
                                <CustomTableCell padding="checkbox" sticky defaultwidth={false}>
                                    <CustomTableCell defaultwidth={false} disablebottomborder>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onChange={(_) => dispatch(handleRowClickInAllCandidates(_id))}
                                        />
                                    </CustomTableCell>
                                    {label && (
                                        <CustomTableCell
                                            defaultwidth={false}
                                            disablebottomborder
                                            sx={{ minWidth: "83px" }}
                                        >
                                            <Stack direction="row" gap={1} flexWrap="wrap">
                                                {label.map((v, index) => {
                                                    return (
                                                        <Chip
                                                            label={v}
                                                            sx={{
                                                                "& .MuiChip-label": {
                                                                    fontSize: "11px",
                                                                },
                                                            }}
                                                            size="small"
                                                            key={`${v}-${index}`}
                                                            color={getChipColorBasedOnValue(v)}
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                        </CustomTableCell>
                                    )}

                                    {name && isString(name) && (
                                        <CustomTableCell defaultwidth={false} disablebottomborder={true}>
                                            <ContactListName labelId={labelId} id={_id} name={name} />
                                        </CustomTableCell>
                                    )}
                                </CustomTableCell>
                                {objectEntries(rest).map(([key, value], index) => {
                                    if (isArray(value)) {
                                        const combinedValues = value.reduce((acc: string, val, idx) => {
                                            acc = `
                                                    ${acc}
                                                    ${idx !== 0 ? "<br />" : ""}
                                                    ${val}
                                                `;

                                            return acc;
                                        }, "");

                                        return (
                                            <CustomTableCell key={index} sx={{ minWidth: "600px" }}>
                                                {combinedValues}
                                            </CustomTableCell>
                                        );
                                    }

                                    const isActivityKey =
                                        key === "Interview" ||
                                        key === "Call" ||
                                        key === "Conversation" ||
                                        key === "Notes";

                                    if (key === "List of projects" && isCandidateProject(value)) {
                                        return <CustomTableCell key={index}>{value.name}</CustomTableCell>;
                                    }

                                    if (key === "Project Stage" && isString(value)) {
                                        return (
                                            <CustomTableCell key={index}>
                                                <Chip
                                                    sx={{
                                                        "& .MuiChip-label": {
                                                            fontSize: "11px",
                                                        },
                                                    }}
                                                    label={value}
                                                    size="small"
                                                    color={getProjectStageColorBasedOnValue(value)}
                                                />
                                            </CustomTableCell>
                                        );
                                    }

                                    if (key === "Contact Overview" && isString(value)) {
                                        const label = getCandidateStageLabel(value);
                                        const color = getCandidateStageColor(value);
                                        return (
                                            <CustomTableCell key={index}>
                                                {value && value !== "-" ? (
                                                    <Chip
                                                        sx={{
                                                            "& .MuiChip-label": {
                                                                fontSize: "11px",
                                                            },
                                                        }}
                                                        label={label}
                                                        size="small"
                                                        color={color}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </CustomTableCell>
                                        );
                                    }

                                    if (key === "Linkedin URL" && isString(value)) {
                                        return <LinkedInURL key={index} link={value} />;
                                    }

                                    if (isActivityKey && isString(value)) {
                                        return (
                                            <CustomTableCell
                                                key={index}
                                                defaultwidth={false}
                                                sx={{
                                                    backgroundColor: "#fafafa",
                                                    minWidth: value ? "600px" : "200px",
                                                }}
                                            >
                                                <ActivityValues value={value} />
                                            </CustomTableCell>
                                        );
                                    }

                                    if (isString(value)) {
                                        return <CustomTableCell key={index}>{value || "-"}</CustomTableCell>;
                                    }

                                    return null;
                                })}
                            </TableRow>
                        </Fragment>
                    );
                })
            ) : (
                <TableRow>
                    <TableCell colSpan={Object.keys(filters).length + 1}>
                        <Stack alignItems="center">
                            <Empty
                                style={{
                                    height: "100px",
                                    width: "100px",
                                    color: "rgba(0, 0, 0, 0.1)",
                                }}
                            />
                            <Typography textAlign="center" color="rgba(0, 0, 0, 0.2)">
                                Data Unavailable...
                            </Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default function AllContactsContent() {
    const dispatch = useDispatch();
    const totalCount = useSelector(selectTotalRows);
    const pageSize = useSelector(selectAllCandidatesRowsPerPage);
    const currentPage = useSelector(selectAllCandidatesPage);

    return (
        <Paper
            sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateRows: "auto 1fr auto",
                gridTemplateColumns: "1fr",
                gap: 0.5,
            }}
            elevation={0}
        >
            <AllContactsToolbar />
            {/* you need to do height on both container and table to preventing single column to expand entire table. */}
            <TableContainer>
                <Table stickyHeader sx={{ height: "100%" }} size="small">
                    <AllContactsTableHead />
                    <TableBody>
                        <TableContent />
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100, 500]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={currentPage}
                onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) =>
                    dispatch(
                        fetchCandidates({
                            start: page,
                            pageSize,
                        })
                    )
                }
                onRowsPerPageChange={(e) =>
                    dispatch(
                        fetchCandidates({
                            start: 0,
                            pageSize: parseInt(e.target.value),
                        })
                    )
                }
            />
        </Paper>
    );
}
