import { createContext, ReactNode, useContext, useState } from "react";

import { TActionbot, TChatbot } from "./types";
import { createChatBotMessage } from "react-chatbot-kit";

type ChatbotContextType = {
    tabIndex: number;
    setTabIndex: (index: number) => void;

    anchorEl: null | HTMLElement;
    setAnchorEl: (element: null | HTMLElement) => void;

    chatbot: TChatbot;
    actionbot: TActionbot;

    setChatbot: (state: TChatbot) => void;
    setActionbot: (state: TActionbot) => void;

    openChatbot: (chatId?: string) => void;
    closeChatbot: () => void;

    openActionbot: (threadId?: string) => void;
    closeActionbot: () => void;

    savedMessageState: { messages?: any[]; projectId?: string | null };
    setSavedMessageState: (state: any) => void;

    isClearAll: boolean;
    setIsClearAll: (state: boolean) => void;

    clearAllChats: () => void;
};

const defaultValue = {
    tabIndex: 0,
    setTabIndex: () => {},

    anchorEl: null,
    setAnchorEl: () => {},

    chatbot: { open: false },
    actionbot: { open: false },

    setChatbot: () => {},
    setActionbot: () => {},

    openChatbot: () => {},
    closeChatbot: () => {},

    openActionbot: () => {},
    closeActionbot: () => {},

    savedMessageState: {messages: [], projectId: null},
    setSavedMessageState: () => {},

    isClearAll: false,
    setIsClearAll: () => {},

    clearAllChats: () => {}
};

const Context = createContext<ChatbotContextType>(defaultValue);

export const useChatbotContext = () => useContext(Context);

interface ChatbotContextProps {
    children: ReactNode;
}

function ChatbotContext({ children }: ChatbotContextProps) {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [chatbot, setChatbot] = useState<{ open: boolean; chatId?: string }>({ open: false });
    const [actionbot, setActionbot] = useState<{ open: boolean; threadId?: string }>({ open: false });
    const [savedMessageState, setSavedMessageState] = useState<any>({ messages: [], projectId: null });
    const [isClearAll, setIsClearAll] = useState<boolean>(false);

    const openChatbot = (chatId?: string) => {
        setChatbot({ open: true, chatId });
    };

    const closeChatbot = () => {
        setChatbot({ open: false });
    };

    const openActionbot = (threadId?: string) => {
        setActionbot({ open: true, threadId });
    };

    const closeActionbot = () => {
        setActionbot({ open: false });
    };

    const clearAllChats = () => {
        setSavedMessageState({ messages: [] });
        setIsClearAll(true);    
    }

    const defaultValues = {
        tabIndex,
        setTabIndex,
        anchorEl,
        setAnchorEl,
        chatbot,
        actionbot,
        setChatbot,
        setActionbot,
        openChatbot,
        closeChatbot,
        openActionbot,
        closeActionbot,
        savedMessageState,
        setSavedMessageState,
        isClearAll, 
        setIsClearAll,
        clearAllChats
    };

    return <Context.Provider value={defaultValues}>{children}</Context.Provider>;
}

export default ChatbotContext;
