import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MultiValue } from "react-select";

import { Option } from "../../../../common";
import { RootState } from "../../../../store/Store";
import { CompanySuggestion, errorsType, optionType, scrapPayloadType, scrapeCompanyType } from "./Filters.type";

const initialState = {
    jobFunction: { label: "", value: "" },
    jobTitles: [],
    jobTitleOptions: [],
    jobTitleSuggestions: [],
    skills: [],
    skillSuggestions: [],
    skillOptions: [],
    roleFunction: [],
    roleFunctionOptions: [],
    experience: [],
    experienceOptions: [
        { value: "0-1", label: "Less than 1 year" },
        { value: "1-2", label: "1 to 2 Years" },
        { value: "3-5", label: "3 to 5 Years" },
        { value: "6-10", label: "6 to 10 Years" },
        { value: "10", label: "More than 10 years" },
    ],
    industry: [],
    industryOptions: [],
    industrySuggestions: [],
    location: [{ label: "United States (USA)", value: "103644278" }],
    locationOptions: [
        { label: "United States (USA)", value: "103644278" },
        { label: "New York, United States", value: "105080838" },
        { label: "California, United States", value: "102095887" },
    ],
    locationSuggestions: [],
    visa: { label: "Yes", value: "yes" },
    visaOptions: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
    ],
    course: "",
    projectName: "My Project",
    education: [],
    educationOptions: [],
    educationSuggestions: [],
    currentCompany: [],
    companySuggestions: [],
    pastCompany: [],
    errors: {
        title: "",
        location: "",
    },
    showOtherLinkedinUrlPopup: false,
    showScrapLinkedinPopup: false,
    showScrapeCompanyPopup: false,
    showAICompanyPopup: false,
    otherLinkedinUrlList: [],
    selectedLinkedinUrl: "",
    scrapeCompanyRequestId: "",
    scrappedCompany: [],
};

const reducers = {
    setErrors(state: typeof initialState, { payload }: { payload: errorsType }) {
        state.errors = payload;
    },
    setJobFunction(state: typeof initialState, { payload }: { payload: optionType }) {
        state.jobFunction = payload;
    },
    getTypeAhead(state: any, { payload }: { payload: optionType }) {},
    setJobTitleOptions(state: any, { payload }: { payload: optionType[] }) {
        state.jobTitleOptions = payload;
    },
    setLocationOptions(state: any, { payload }: { payload: optionType[] }) {
        state.locationOptions = payload;
    },
    setSkillOptions(state: any, { payload }: { payload: optionType[] }) {
        state.skillOptions = payload;
    },
    setIndustryOptions(state: any, { payload }: { payload: optionType[] }) {
        state.industryOptions = payload;
    },

    setEducationOptions(state: any, { payload }: { payload: optionType[] }) {
        state.educationOptions = payload;
    },
    setRoleFunctionOptions(state: any, { payload }: { payload: optionType[] }) {
        state.roleFunctionOptions = payload;
    },
    setRoleFunction(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.roleFunction = payload;
    },
    addJobTitle(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.jobTitles = payload;
    },
    addLocation(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.location = payload;
    },
    addEducation(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.education = payload;
    },
    addSkills(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.skills = payload;
    },
    addIndustry(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.industry = payload;
    },
    addExperience(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.experience = payload;
    },
    addCurrentCompany(state: any, { payload }: { payload: MultiValue<optionType> }) {
        const values = payload.map((item) => ({
            value: item.value,
            label: item.label,
        }));
        state.currentCompany = values;
    },
    pastCurrentCompany(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.pastCompany = payload;
    },
    setShowOtherLinkedinUrlPopup(state: any, { payload }: { payload: boolean }) {
        state.showOtherLinkedinUrlPopup = payload;
    },
    searchCandidates(state: any) {},
    getOtherLinkedinUrlList(state: any) {},
    setOtherLinkedinUrlList(state: any, { payload }: { payload: optionType[] }) {
        state.otherLinkedinUrlList = payload;
    },
    setSelectedLinkedinUrl(state: any, { payload }: { payload: string }) {
        state.selectedLinkedinUrl = payload;
    },
    setShowScrapLinkedinPopup(state: any, { payload }: { payload: boolean }) {
        state.showScrapLinkedinPopup = payload;
    },
    scrapLinkedin(state: any, { payload }: { payload: scrapPayloadType }) {},
    setCompanySuggestions(state: any, { payload }: { payload: CompanySuggestion[] }) {
        state.companySuggestions = payload;
    },
    addCurrentCompanySuggestion(state: any, action: PayloadAction<Option>) {
        const isValueAlreadyPresent = state.currentCompany.find(
            (option: Option) => option.value.toLocaleLowerCase() === action.payload.value.toLocaleLowerCase()
        );

        if (!isValueAlreadyPresent) {
            state.currentCompany = state.currentCompany.concat(action.payload);
        }
    },
    fetchCompanySuggestions(state: any, action: PayloadAction) {},
    getCompanyLinkedinUrlList(state: any) {},
    setShowScrapeCompanyPopup(state: any, { payload }: { payload: boolean }) {
        state.showScrapeCompanyPopup = payload;
    },
    setScrapeCompanyRequestId(state: any, { payload }: { payload: string }) {
        state.scrapeCompanyRequestId = payload;
    },
    scrapCompaniesLinkedin(state: any, { payload }: { payload: scrapPayloadType }) {},
    getScrappedCompany(state: any) {},
    setScrappedCompany(state: any, { payload }: { payload: scrapeCompanyType[] }) {
        state.scrappedCompany = payload;
    },
    getAiCompany(state: any, { payload }: { payload: string }) {},
    setShowAICompanyPopup(state: any, { payload }: { payload: boolean }) {
        state.showAICompanyPopup = payload;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "advancedFilters",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    addCurrentCompanySuggestion,
    setCompanySuggestions,
    setErrors,
    setJobFunction,
    addJobTitle,
    addSkills,
    addEducation,
    addLocation,
    addIndustry,
    addExperience,
    getTypeAhead,
    setJobTitleOptions,
    setLocationOptions,
    setSkillOptions,
    setEducationOptions,
    setIndustryOptions,
    searchCandidates,
    addCurrentCompany,
    pastCurrentCompany,
    getOtherLinkedinUrlList,
    setShowOtherLinkedinUrlPopup,
    setOtherLinkedinUrlList,
    setSelectedLinkedinUrl,
    setShowScrapLinkedinPopup,
    scrapLinkedin,
    getCompanyLinkedinUrlList,
    setShowScrapeCompanyPopup,
    scrapCompaniesLinkedin,
    setScrapeCompanyRequestId,
    getScrappedCompany,
    setScrappedCompany,
    leavePage,
    fetchCompanySuggestions,
    getAiCompany,
    setShowAICompanyPopup,
    setRoleFunctionOptions,
    setRoleFunction,
} = page.actions;

export const selectJobFunction = (state: RootState) => state.advancedFilters.jobFunction;

export const selectJobTitles = (state: RootState) => state.advancedFilters.jobTitles;
export const selectSkills = (state: RootState) => state.advancedFilters.skills;
export const selectExperience = (state: RootState) => state.advancedFilters.experience;
export const selectIndustry = (state: RootState) => state.advancedFilters.industry;
export const selectLocations = (state: RootState) => state.advancedFilters.location;

export const selectJobTitleOptions = (state: RootState) => state.advancedFilters.jobTitleOptions;
export const selectLocationOptions = (state: RootState) => state.advancedFilters.locationOptions;
export const selectSkillOptions = (state: RootState) => state.advancedFilters.skillOptions;
export const selectEducationOptions = (state: RootState) => state.advancedFilters.educationOptions;
export const selectIndustryOptions = (state: RootState) => state.advancedFilters.industryOptions;
export const selectVisaOptions = (state: RootState) => state.advancedFilters.visaOptions;
export const selectExperienceOptions = (state: RootState) => state.advancedFilters.experienceOptions;
export const selectRoleFunctionOptions = (state: RootState) => state.advancedFilters.roleFunctionOptions;
export const selectErrors = (state: RootState) => state.advancedFilters.errors;
export const selectShowOtherLinkedinUrlPopup = (state: RootState) => state.advancedFilters.showOtherLinkedinUrlPopup;
export const selectOtherLinkedinUrlList = (state: RootState) => state.advancedFilters.otherLinkedinUrlList;
export const selectSelectedLinkedinUrl = (state: RootState) => state.advancedFilters.selectedLinkedinUrl;
export const selectShowScrapLinkedinPopup = (state: RootState) => state.advancedFilters.showScrapLinkedinPopup;
export const selectCompanySuggestions = (state: RootState) => {
    const selectedCompanies = state.advancedFilters.currentCompany.map((val: Option) => val.value.toLowerCase());

    return state.advancedFilters.companySuggestions.filter(
        (i: CompanySuggestion) => !selectedCompanies.includes(i.company_name.toLowerCase())
    );
};

export const selectShowScrapeCompanyPopup = (state: RootState) => state.advancedFilters.showScrapeCompanyPopup;
export const selectScrapeCompanyRequestId = (state: RootState) => state.advancedFilters.scrapeCompanyRequestId;
export const selectScrappedCompany = (state: RootState) => state.advancedFilters.scrappedCompany;
export const selectCurrentCompany = (state: RootState) => state.advancedFilters.currentCompany;
export const selectShowAICompanyPopup = (state: RootState) => state.advancedFilters.showAICompanyPopup;
