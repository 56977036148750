import { alpha, styled, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { nanoid } from "@reduxjs/toolkit";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { SELECT_CANDIDATE } from "../../../utils/outreach.helpers";
import { checkEasySource } from "../../Signin/Signin.reducer";
import { changeModalState } from "../../workflow/index.reducer";
import {
    changeCharacterCount,
    changeToneOfVoice,
    resetPersonalizedInputs,
    selectCharacterCount,
    selectToneOfVoice,
    setOutreachIntent,
    submitPersonalizedInputs,
} from "../reducers/personalizedWorkflow.slice";
import { CHARACTER_COUNT, TONE_OF_VOICE } from "../types";
import BaseModal from "./BaseModal";

interface CustomMUIInputProps extends InputBaseProps {
    modalVariant?: string;
    bgColor?: string;
}

export const CustomMUIInput = styled(InputBase)<CustomMUIInputProps>(({ theme, modalVariant, bgColor }) => ({
    "& .MuiInputBase-root": {
        padding: "1rem",
    },
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        fontFamily: "Poppins",
        borderRadius: 4,
        position: "relative",
        backgroundColor:
            modalVariant === "FILTER" && bgColor ? bgColor : theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: "14px !important",
        // padding: "10px 16px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "14px",
}));

const CustomMUITextField = styled(TextField)(() => ({
    "& .MuiInputBase-root": {
        padding: 0,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
};

type SelectValues = {
    id: string;
    value: string;
    label?: string;
    bgColor?: string;
};

const toneOfVoiceValues: SelectValues[] = [
    {
        id: nanoid(),
        value: "Professional",
    },
    {
        id: nanoid(),
        value: "Friendly",
    },
    {
        id: nanoid(),
        value: "Encouraging",
    },
    {
        id: nanoid(),
        value: "Casual",
    },
];

const characterCountValues: SelectValues[] = [
    {
        id: nanoid(),
        value: "short",
    },
    {
        id: nanoid(),
        value: "medium",
    },
    {
        id: nanoid(),
        value: "long",
    },
];

export type MUISelectProps = SelectProps & {
    options: SelectValues[];
    values: null | string;
    modalVariant?: null | string;
    handleChange: (event: SelectChangeEvent<unknown>) => void;
};

export function MUISelect({ options, modalVariant, values, handleChange, ...rest }: MUISelectProps) {
    const isEasySource = useSelector(checkEasySource);
    const selectedOption = options.find((option) => option.value === values);

    return (
        <Select
            {...rest}
            value={values ? (values as string) : ""}
            onChange={(e) => handleChange(e)}
            displayEmpty
            input={<CustomMUIInput modalVariant={modalVariant ?? undefined} bgColor={selectedOption?.bgColor} />}
            MenuProps={MenuProps}
        >
            <CustomMenuItem
                value=""
                disabled
                sx={{
                    "&.Mui-disabled": {
                        color: grey[900],
                        opacity: 0.6,
                    },
                }}
            >
                {isEasySource
                    ? modalVariant === "FILTER"
                        ? "Select criteria"
                        : "Select candidate"
                    : "Select prospect"}
            </CustomMenuItem>
            {options.map(({ id, value, label, bgColor }) => {
                return (
                    <CustomMenuItem value={value} key={id}>
                        {label ? label : value}
                    </CustomMenuItem>
                );
            })}
        </Select>
    );
}

function ToneOfVoice() {
    const dispatch = useDispatch();
    const toneOfVoice = useSelector(selectToneOfVoice);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as TONE_OF_VOICE;
        dispatch(changeToneOfVoice(value));
    };

    return (
        <SelectionControl>
            <Typography variant="body2">Tone of Voice</Typography>
            <MUISelect variant="filled" values={toneOfVoice} handleChange={handleChange} options={toneOfVoiceValues} />
        </SelectionControl>
    );
}

function CharacterCount() {
    const dispatch = useDispatch();
    const characterCount = useSelector(selectCharacterCount);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value;
        dispatch(changeCharacterCount(value as CHARACTER_COUNT));
    };

    return (
        <SelectionControl>
            <Typography variant="body2">Word Count</Typography>
            <MUISelect
                variant="filled"
                values={characterCount}
                handleChange={handleChange}
                options={characterCountValues}
            />
        </SelectionControl>
    );
}

type SelectionControlProps = {
    children: React.ReactNode;
};

function SelectionControl({ children }: SelectionControlProps) {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 1.5fr",
                gridTemplateRows: "1fr",
                gap: "2rem",
                alignItems: "center",
            }}
        >
            {children}
        </div>
    );
}

type PersonalizationInputsModalProps = {
    candidateIDs: string[];
    projectID: string;
};

export default function PersonalizedInputsModal({ candidateIDs, projectID }: PersonalizationInputsModalProps) {
    const dispatch = useDispatch();
    const toneOfVoice = useSelector(selectToneOfVoice);
    const characterCount = useSelector(selectCharacterCount);
    const isSubmitting = useSelector(checkIfLoading(submitPersonalizedInputs.type));

    const closeModal = () => dispatch(changeModalState("DEFAULT"));

    const handleSubmit = () => {
        if (!toneOfVoice) {
            dispatch(setErrorNotification("Please select tone of voice."));
            return;
        }

        if (!characterCount) {
            dispatch(setErrorNotification("Please select word limit"));
            return;
        }

        // dispatch(
        //     submitPersonalizedInputs({
        //         candidateIDs,
        //         projectID,
        //         fetchNamesAction: FETCH_CANDIDATE_NAMES,
        //     })
        // );
    };

    return (
        <BaseModal onClose={() => null} hideCloseButton={true}>
            <Stack spacing={3}>
                <Typography variant="h6">Customise your outreach</Typography>
                <Stack spacing={2.5}>
                    <ToneOfVoice />
                    <CharacterCount />
                    <SelectionControl>
                        <Typography variant="body2">Outreach Intent</Typography>
                        <CustomMUITextField
                            multiline={true}
                            onChange={(e) => dispatch(setOutreachIntent(e.target.value))}
                            sx={{ padding: 0 }}
                            inputProps={{
                                style: {
                                    padding: "5px 10px",
                                    backgroundColor: "#F3F6F9",
                                },
                            }}
                        />
                    </SelectionControl>
                    <Button
                        variant="contained"
                        sx={{
                            width: "fit-content",
                            alignSelf: "center",
                            justifySelf: "center",
                            padding: "0.4rem 1.5rem",
                        }}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                    >
                        <ButtonTextWithLoading isLoading={isSubmitting} text="Next" />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
