import { Fragment, ReactNode } from "react";

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
const escapeRegExp = (string: string) => string?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

/**
 * Lowercase a string
 * @param {string} str String
 * @returns string
 */
const toLower = (str: string) => str.toLocaleLowerCase();

interface WrapperProps {
    children: ReactNode;
    [key: string]: any;
}

const DefaultWrapper = ({ children, ...rest }: WrapperProps) => (
    <b style={{ backgroundColor: "yellow" }} {...rest}>
        {children}
    </b>
);

/**
 * Highlights the selected text by Splitting text on highlight term
 * @param {string} text complete text
 * @param {string} highlight text to be highlighted
 * @param {Component} wrapper component with which the highlighted element is wrapped
 * @returns jsx nodes to be rendered
 */

interface HighlightedTextProps {
    text: string;
    highlight: string;
    wrapper?: (props: WrapperProps) => JSX.Element;
}

export function getHighlightedText(text = "", highlight = "", wrapper = DefaultWrapper) {
    const Wrapper = wrapper;
    const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
    return (
        <>
            {parts.map((part, index) => (
                <Fragment key={`${part + index}`}>
                    {toLower(part) === toLower(highlight) ? <Wrapper>{part}</Wrapper> : part}
                </Fragment>
            ))}
        </>
    );
}

export function HighlightedText({ text = "", highlight = "", wrapper = DefaultWrapper }: HighlightedTextProps) {
    return getHighlightedText(text, highlight, wrapper);
}
