import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/joy";
import { lighten } from "@mui/material";
import Markdown from "react-markdown";

import { TCitation } from "../../types";

interface RenderProps {
    message: string;
    citations?: TCitation[];
}

const boxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    borderBottom: "0.75px solid lightgrey",
    "&:hover": {
        backgroundColor: lighten("#d8e7ea", 0.5),
        cursor: "pointer",
    },
};

function Render(props: RenderProps) {
    const { citations = [], message } = props;

    // if (type === "text")

    return (
        <>
            <Markdown>{message}</Markdown>
            {citations?.length > 0 && (
                <>
                    <Typography pt={2} fontWeight={600}>
                        References
                    </Typography>
                    {citations.map((item, index) => {
                        return (
                            <Box key={index} sx={boxProps} onClick={() => window.open(item.url, "_blank")}>
                                <Typography sx={{ fontWeight: "400", fontSize: "12px", color: "black" }}>
                                    <i>{item.text}</i>
                                </Typography>
                                <KeyboardArrowRight htmlColor="#0d7a89" fontSize="small" />
                            </Box>
                        );
                    })}
                </>
            )}
        </>
    );
}

export default Render;
