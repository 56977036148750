import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { getProject } from "../allProjects/index.reducer";
import { ICandidate } from "../project/project.types";
import { setUser } from "../Signin/Signin.reducer";
import { signinType } from "../Signin/Signin.types";
import {
    addJazzHRJob,
    addToBambooHR,
    addToGreenhouseAgency,
    addToJobvite,
    addToLever,
    addToLoxoAgency,
    addToZohoRecruit,
    fetchLoxoCredentials,
    getBambooHRJobs,
    getBambooHRStatuses,
    getFromBambooHR,
    getFromGreenhouse,
    getFromLever,
    getFromZohoRecruit,
    getGreenhouseJobs,
    getGreenhouseJobStages,
    getJazzHRJobs,
    getJazzHRJobStages,
    getJobviteJobs,
    getLeverJobs,
    getLeverStages,
    getZohoHRJobs,
    getZohoHRStatuses,
    postBambooHRCredentials,
    postGreenhouseCredentials,
    postJobviteCredentials,
    postLoxoCredentials,
    pushJazzHRJobs,
    resetIntegration,
    revokeSlackInvitation,
    sendSlackInvitation,
    setBambooHRJobs,
    setBambooHRStatuses,
    setGreenhouseJobs,
    setGreenhouseJobStages,
    setJazzHRJobs,
    setJazzHRJobStages,
    setJobviteJobs,
    setLeverExportCandidates,
    setLeverJobs,
    setLeverStages,
    setLoxoCredentials,
    setZohoHRJobs,
    setZohoHRStatuses,
} from "./integrations.reducer";
import {
    FetchFromLeverResponse,
    IAddToATSPayload,
    IAddToBambooHRPayload,
    IAddToGreenhousePayload,
    IAddToLeverPayload,
    IAddToLeverResponse,
    IAddToZohoRecruitPayload,
    IGetFromBambooHRPayload,
    IGetFromGreenhouse,
    IGetFromLever,
    IGetGreenhouseJobStagesPayload,
    IJazzHRJob,
    ILeverFetchCandidatePayload,
    ILeverPostCandidatePayload,
    ILoxoCredentials,
    ILoxoCredentialsResponse,
    IPostBambooHRCredentialsPayload,
    IPostGreenhouseCredentialsPayload,
    IPostJobviteCredentialsPayload,
    ISendSlackInvitationPayload,
    ISlackAuthResponse,
    IZohoAddPayload,
    LeverExportCandidate,
} from "./integrations.types";

function* fetchLoxoCredentialsSaga({ payload }: { payload: ILoxoCredentials & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse = yield call(new API().get, "/loxo/get-creds");

        const loxoCreds = {
            agencySlug: response?.data.agencySlug || "",
            apiKey: response?.data.apiKey || "",
            apiPassword: response?.data.apiPassword || "",
        };
        yield put(setLoxoCredentials(loxoCreds));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postLoxoCredentialsSaga({ payload }: { payload: ILoxoCredentials & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse & ILoxoCredentials = yield call(new API().post, "/loxo/on-board", {
            agencySlug: payload.agencySlug,
            loxoAPIKey: payload.apiKey,
            loxoAPISecret: payload.apiPassword,
        });

        const loxoCreds = {
            agencySlug: payload.agencySlug,
            apiKey: payload.apiKey,
            apiPassword: payload.apiPassword,
        };
        yield put(setLoxoCredentials(loxoCreds));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLoxoAgencySaga({ payload }: { payload: IAddToATSPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload: IAddToATSPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response: { message: string } = yield call(new API().post, "/loxo/add-to-agency", triggerPayload);

        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToGreenhouseAgencySaga(action: PayloadAction<IAddToGreenhousePayload>): SagaIterator {
    const { payload, type } = action;
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { candidateIds, projectId, jobId, stageId, onSuccess } = payload;

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds,
            projectId,
        };
        if (stageId) triggerPayload.stageId = stageId;
        if (jobId) triggerPayload.jobId = jobId;

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "/greenhouse/post-candidate", triggerPayload);
        if (!response?.data) return;

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* postGreenhouseCredentialsSaga({
    payload,
}: {
    payload: IPostGreenhouseCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/greenhouse/post-creds", {
            harvestApiKey: payload.harvestApiKey,
            apiKey: payload.apiKey,
            serviceUser: payload.serviceUser,
            clientGreenHouseUrl: payload?.clientGreenHouseUrl,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const { apiKey, serviceUser , harvestApiKey , clientGreenHouseUrl} = response.data;
        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                greenHouse: {
                    apiKey,
                    serviceUser,
                    harvestApiKey,
                    clientGreenHouseUrl 
                }
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getGreenhouseJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/greenhouse/fetch-jobs");
        if (!response) return;

        yield put(setGreenhouseJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getGreenhouseJobStagesSaga({ payload, type }: PayloadAction<IGetGreenhouseJobStagesPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, `/greenhouse/fetch-stages/${payload.jobId}`);
        if (!response?.data) return;

        yield put(setGreenhouseJobStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getFromZohoRecruitSaga({ payload, type }: PayloadAction<IZohoAddPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "/zohoRecruit/getCandidates", {
            projectId: payload.projectId,
            jobId: payload.jobId,
            ...(payload.statusId && { statusId: payload.statusId }),
        });
        if (isEmpty(response.message)) {
            throw new Error("Error fetching the candidate data. Please try again after some time.");
        }
        if (!response.data.candidateCount) {
            throw new Error("No candidate found in the project.");
        }
        yield put(
            setSuccessNotification(
                `${response.message} , We have started fetching ${response.data.candidateCount} candidates`
            )
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        payload.onSuccess?.();
        yield put(stopAction({ action: type }));
    }
}

function* addToZohoRecruitSaga({ payload, type }: PayloadAction<IAddToZohoRecruitPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { projectId, jobId, statusId, candidateIds, subStatusId } = payload;

        const triggerPayload: any = {
            projectId,
            jobId,
            statusId,
            candidateIds,
            subStatusId,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/zohoRecruit/postCandidates", triggerPayload);

        if (isEmpty(response.message)) {
            throw new Error("An error occured. Please try again after some time.");
        }

        yield put(setSuccessNotification(`${payload.candidateIds.length} candidates are begin pushed to zoho recruit`));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        payload.onSuccess?.();
        yield put(stopAction({ action: type }));
    }
}

function* sendSlackInvitationSaga({ payload }: { payload: ISendSlackInvitationPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ISlackAuthResponse = yield call(new API().get, "/slack/auth");
        const { data } = response;
        window.location.href = data;
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* revokeSlackInvitationSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        const response = yield call(new API().get, "/slack/revoke-access");
        if (!response) return;

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                isSlackConnected: false,
            })
        );
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/jobs");
        if (!response) return;

        yield put(setLeverJobs(response.data));
    } catch (err: any) {
        console.log(err);
        yield put(setErrorNotification(err.respose.data.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverStagesSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/stages");
        if (!response) return;

        yield put(setLeverStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err?.response?.data?.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLeverSaga({ payload }: { payload: IAddToLeverPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );
        const triggerPayload: ILeverPostCandidatePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }
        if (payload.job.jobId) {
            triggerPayload.jobId = payload.job.jobId;
        }
        if (payload.stage.stageId) {
            triggerPayload.stageId = payload.stage.stageId;
        }

        const response: IAddToLeverResponse = yield call(new API().post, "/lever/post-candidate", triggerPayload);

        if (!response) return;

        payload.onSuccess();
        if (response?.duplicate) yield put(setErrorNotification(response.message));
        else yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getFromLeverSaga({ payload }: { payload: IGetFromLever & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const triggerPayload: ILeverFetchCandidatePayload = {
            jobId: payload.job.jobId,
        };

        if (payload?.stage?.stageId) {
            triggerPayload.stageId = payload.stage.stageId;
        }
        if (payload?.createdAtStart) {
            triggerPayload.createdAtStart = payload.createdAtStart;
        }
        if (payload?.createdAtEnd) {
            triggerPayload.createdAtEnd = payload.createdAtEnd;
        }
        if (payload?.projectId) {
            triggerPayload.projectId = payload.projectId;
        }
        const response: FetchFromLeverResponse = yield call(new API().post, "/lever/get-candidate", triggerPayload);

        if (!response) return;

        const exportLeverCandidates: LeverExportCandidate[] = response.data.map(
            ({ name, id, resume, emails, links }) => ({
                emails: emails.toString(),
                id,
                resume,
                name,
                linkedinUrl: links.toString(),
            })
        );

        yield put(setLeverExportCandidates(exportLeverCandidates));
        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: String(triggerPayload.projectId),
                action: getProject.type,
            })
        );
        // // if (response?.duplicate)
        // //     yield put(setErrorNotification(response.message));
        // else yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        console.log(err);
        yield put(setErrorNotification(err?.response?.data?.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postJobviteCredentialsSaga({
    payload,
}: {
    payload: IPostJobviteCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().post, "/job-vite/add-creds", {
            apiKey: payload.apiKey,
            apiSecret: payload.apiSecret,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                jobVites: {
                    apiKey: payload.apiKey,
                    apiSecret: payload.apiSecret,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToJobviteSaga({ payload }: { payload: IAddToGreenhousePayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }
        if (payload.jobId) triggerPayload.jobId = payload.jobId;

        const response = yield call(new API().post, "/job-vite/add-candidates", triggerPayload);
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getJobviteJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/job-vite/get-job");
        if (!response) return;

        yield put(setJobviteJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getFromGreenhouseSaga(action: PayloadAction<IGetFromGreenhouse>): SagaIterator {
    const { payload, type } = action;

    try {
        yield put(startAction({ action: type }));

        const triggerPayload: any = {
            jobId: payload.job,
            projectId: payload.projectId,
        };
        if (payload.stage) {
            triggerPayload.stageId = payload.stage;
        }
        if (payload.createdAtStart) {
            triggerPayload.createdAtStart = payload.createdAtStart;
        }
        if (payload.createdAtEnd) {
            triggerPayload.createdAtEnd = payload.createdAtEnd;
        }
        if (payload.isProspect) {
            triggerPayload.isProspect = payload.isProspect;
        }

        const response: {
            message: string;
            data: ICandidate[];
        } = yield call(new API().post, "/greenhouse/get-candidates", triggerPayload);

        if (!response?.data) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: payload.projectId.toString(),
                action: getProject.type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* postBambooHRCredentialsSaga({ type, payload }: PayloadAction<IPostBambooHRCredentialsPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response: { data: signinType; message: string } = yield call(new API().post, "/bambooHR/postCreds", {
            apiKey: payload.apiKey,
            companyDomain: payload.companyDomain,
        });

        if (!response?.data) return;

        payload.onSuccess();
        yield put(setUser(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getBambooHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, "/bambooHR/fetchJobs");
        if (!response) return;

        yield put(setBambooHRJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getBambooHRStatusesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, "/bambooHR/fetchStatuses");
        if (!response) return;

        yield put(setBambooHRStatuses(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getZohoHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/zohoRecruit/fetchJobs");
        if (!response) return;
        yield put(setZohoHRJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getZohoHRStatusesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/zohoRecruit/fetchStatuses");
        if (!response) return;
        yield put(setZohoHRStatuses(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getFromBambooHRSaga({ payload, type }: PayloadAction<IGetFromBambooHRPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const { onSuccess, ...rest } = payload;

        const response = yield call(new API().post, "/bambooHR/getCandidates", rest);

        if (!response?.data) return;

        onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: rest.projectId.toString(),
                action: getProject.type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addToBambooHRSaga({ payload, type }: PayloadAction<IAddToBambooHRPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { onSuccess, candidateIds, jobId, projectId, statusId } = payload;

        const triggerPayload: any = {
            candidateIds,
            projectId,
            jobId,
            statusId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/bambooHR/postCandidates", triggerPayload);

        if (!response?.data) return;

        onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}
function* getJazzHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/jazzHR/get-jobs");
        if (!response) return;

        yield put(setJazzHRJobs(response.data));
        // yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        // yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}
function* getJazzHRJobStagesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/jazzHR/get-stages");
        if (!response) return;

        yield put(setJazzHRJobStages(response.data));
        // yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        // yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addJazzHRJobsSaga(action: PayloadAction<IJazzHRJob>): SagaIterator {
    // const cancelTokenSource = axios.CancelToken.source();
    try {
        yield put(startAction({ action: action.type }));
        const { id, title, onSuccess } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");

        const response = yield call(new API().post, "/jazzHR/job-project-map", {
            projectId: Number(currentProject),
            jobId: id,
            jobName: title,
        });

        if (!response) return;
        if (title === "") {
            yield put(setSuccessNotification("You have disconnected this EasySource project from JazzHR jobs"));
            if (onSuccess) onSuccess();
            return;
        }
        yield put(setSuccessNotification("Job mapped to EasySource project successfully"));
        if (onSuccess) onSuccess();
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
// for add to ATS
function* pushJazzHRJobsSaga(action: PayloadAction<IJazzHRJob>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { id, candidateIds, stageID, stageName, title, onSuccess } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");

        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const response = yield call(new API().post, "/jazzHR/post-candidate", {
            projectId: Number(currentProject),
            jobId: id,
            stageId: stageID,
            jobName: title,
            stageName: stageName,
            ...(selectAllCandidatesStateValue ? { candidateIds: [] } : { candidateIds }),
            ...(selectAllCandidatesStateValue && { filterBy: projectFiltersPayloadValue }),
            ...(selectAllCandidatesStateValue && { search: projectSearchStateValue }),
            ...(selectAllCandidatesStateValue && { selectAll: true }),
        });
        if (!response) return;

        yield put(setSuccessNotification(response?.message));
        if (onSuccess) onSuccess();
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchLoxoCredentials.type, fetchLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(postLoxoCredentials.type, postLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(addToLoxoAgency.type, addToLoxoAgencySaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postGreenhouseCredentials.type,
            postGreenhouseCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(addToGreenhouseAgency.type, addToGreenhouseAgencySaga),
        // @ts-ignore
        yield takeLatest(getGreenhouseJobs.type, getGreenhouseJobsSaga),
        // @ts-ignore
        yield takeLatest(getGreenhouseJobStages.type, getGreenhouseJobStagesSaga),
        // @ts-ignore
        yield takeLatest(getFromGreenhouse.type, getFromGreenhouseSaga),
        // @ts-ignore
        yield takeLatest(sendSlackInvitation.type, sendSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(revokeSlackInvitation.type, revokeSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(getFromZohoRecruit.type, getFromZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(addToZohoRecruit.type, addToZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(getLeverJobs.type, getLeverJobsSaga),
        // @ts-ignore
        yield takeLatest(getLeverStages.type, getLeverStagesSaga),
        // @ts-ignore
        yield takeLatest(addToLever.type, addToLeverSaga),
        // @ts-ignore
        yield takeLatest(getFromLever.type, getFromLeverSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postJobviteCredentials.type,
            postJobviteCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(getJobviteJobs.type, getJobviteJobsSaga),
        // @ts-ignore
        yield takeLatest(addToJobvite.type, addToJobviteSaga),
        // @ts-ignore
        yield takeLatest(postBambooHRCredentials.type, postBambooHRCredentialsSaga),
        // @ts-ignore
        yield takeLatest(getBambooHRJobs.type, getBambooHRJobsSaga),
        // @ts-ignore
        yield takeLatest(getBambooHRStatuses.type, getBambooHRStatusesSaga),
        // @ts-ignore
        yield takeLatest(getFromBambooHR.type, getFromBambooHRSaga),
        // @ts-ignore
        yield takeLatest(addToBambooHR.type, addToBambooHRSaga),
        // @ts-ignore
        yield takeLatest(getZohoHRJobs.type, getZohoHRJobsSaga),
        // @ts-ignore
        yield takeLatest(getZohoHRStatuses.type, getZohoHRStatusesSaga),
        // @ts-ignore
        yield takeLatest(getJazzHRJobs.type, getJazzHRJobsSaga),
        // @ts-ignore
        yield takeLatest(addJazzHRJob.type, addJazzHRJobsSaga),
        // @ts-ignore
        yield takeLatest(pushJazzHRJobs.type, pushJazzHRJobsSaga),

        // @ts-ignore
        yield takeLatest(getJazzHRJobStages.type, getJazzHRJobStagesSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetIntegration.type, CancelSagas, tasks);
}
