import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { IMembersData } from "../../../pages/manageTeams/manageTeams.types";
import { fetchBaseQueryWithZod } from "../../../utils/api-utils";
import { Queries } from "./enum";

export const organizationsApi = createApi({
    reducerPath: "organizationsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [Queries.MEMBERS],
    endpoints: (builder) => ({
        getOrganizationMembers: builder.query<IMembersData, void>({
            query: () => ({
                url: "/organization/members",
                method: "GET",
            }),
            providesTags: [Queries.MEMBERS],
            transformResponse(baseQueryReturnValue: { data: IMembersData }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getAllOrganizationMembers: builder.query<any, void>({
            query: () => ({
                url: "/crons/users-tracking",
                method: "GET",
            }),
            providesTags: [Queries.MEMBERS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data;
            },
        }),
        getAllOrgMembers: builder.query<any, void>({
            query: () => ({
                url: "/organization/all-members",
                method: "GET",
            }),
            providesTags: [Queries.MEMBERS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data;
            },
        }),
    }),
});

export const { useGetOrganizationMembersQuery, useGetAllOrganizationMembersQuery, useGetAllOrgMembersQuery } = organizationsApi;
