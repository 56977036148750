import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import { ZodSchema } from "zod";

import { findCookie } from "./cookie";
import { getFirebaseWebToken } from "./firebase";
import handleException from "./sentry";

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === "object" && error != null && "status" in error;
}
const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;

export const baseQueryWithTokens = fetchBaseQuery({
    baseUrl: baseUrl === "http://localhost:4000" ? baseUrl : baseUrl + "/api/",
    prepareHeaders: async (headers) => {
        const token = findCookie("accessToken");
        const webToken = await getFirebaseWebToken();
        headers.set("appType", "web");
        if (token) {
            headers.set("x-authorization", token);
        }
        if (webToken) {
            headers.set("x-webAuthorization", webToken);
        }
        return headers;
    },
});

type TBaseQuery = BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    { dataSchema?: ZodSchema },
    FetchBaseQueryMeta
>;

/**
 * HOF that wraps a base query function with additional functionality for data validation using zod
 *
 * @param baseQuery The base query function to be wrapped.
 * @returns A modified version of the baseQuery with added data validation.
 */
const baseQueryWithZodValidation: (baseQuery: TBaseQuery) => TBaseQuery =
    (baseQuery: TBaseQuery) => async (args, api, extraOptions) => {
        // Call the original baseQuery function with the provided arguments
        const returnValue = await baseQuery(args, api, extraOptions);

        // Retrieve the data schema from the extraOptions object
        const zodSchema = extraOptions?.dataSchema;

        if ("error" in returnValue && isFetchBaseQueryError(returnValue.error)) {
            handleException(returnValue.error);
            throw returnValue.error;
        }

        const { data } = returnValue;

        // Check if both 'data' and 'zodSchema' are defined
        if (data && zodSchema) {
            // throws Validation error if the 'data' fails validation.
            try {
                zodSchema.parse(data);
            } catch (error) {
                throw error;
            }
        }

        // Return the original returnValue object
        return returnValue;
    };

export const fetchBaseQueryWithZod = baseQueryWithZodValidation(baseQueryWithTokens);
