import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { useDispatch } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import type { Config } from "redux-state-sync";

import { autoPilotApi } from "../components/AutoPilot/autopilot.slice";
import { chatbotApi } from "../components/Chatbot/api/chatbot.api.slice";
import { deleteProjectFeedbackApi } from "../components/DeleteProjectFeedbackModal/deleteProjectFeedback.api";
import { setErrorNotification, setSuccessNotification } from "../components/Notification/index.reducer";
import { analyticsApi } from "../pages/analytics-v2/apis/analytics.api.slice";
import { invitationsApi } from "../pages/Invitations/invitations.api.slice";
import { linkedinWorkflowApi } from "../pages/linkedin-workflow/apis/linkedin-workflow.api.slice";
import { userCreditApi } from "../pages/myAccount/components/stats/credit.api.slice";
import { outreachApi } from "../pages/OutReach/outreach.api.slice";
import { setCalendlyState } from "../pages/Signin/Signin.reducer";
import { superInboxApi } from "../pages/super-inbox/apis/super-inbox.api.slice";
import { cachingMiddleware, getPreloadedState } from "./middlewares/cachingMiddleware";
import reducers from "./reducers/Reducers";
import { allCandidatesApi } from "./reducers/all-candidates.api.slice";
import { companyDetailsApi } from "./reducers/companyDetails.api.slice";
import { externalConnectApis } from "./reducers/externalConnect.api.slice";
import { organizationsApi } from "./reducers/organizations/organizations.api.slice";
import { projectsApi } from "./reducers/projects/projects.api.slice";
import { watchSagas } from "./sagas/Sagas";

const reduxSyncConfig: Config = {
    whitelist: [setSuccessNotification.type, setErrorNotification.type, setCalendlyState.type],
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    preloadedState: getPreloadedState(),
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: true,
                ignoredPaths: ["workflow.workflowData.date", "mailAlert.continueFlow", "payload.user"],
            },
        }).concat([
            outreachApi.middleware,
            companyDetailsApi.middleware,
            allCandidatesApi.middleware,
            externalConnectApis.middleware,
            deleteProjectFeedbackApi.middleware,
            projectsApi.middleware,
            organizationsApi.middleware,
            analyticsApi.middleware,
            superInboxApi.middleware,
            linkedinWorkflowApi.middleware,
            autoPilotApi.middleware,
            invitationsApi.middleware,
            userCreditApi.middleware,
            sagaMiddleware,
            cachingMiddleware,
            chatbotApi.middleware,
            createStateSyncMiddleware(reduxSyncConfig),
        ]),
});

initMessageListener(store);
sagaMiddleware.run(watchSagas);
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;

function UnloadHandler() {
    window.removeEventListener("unload", UnloadHandler, false);
}

window.addEventListener("unload", UnloadHandler, false);

window.addEventListener(
    "pageshow",
    function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    },
    false
);