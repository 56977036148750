import { forwardRef, useRef, useState } from "react";

import "react-quill/dist/quill.snow.css";

import { zodResolver } from "@hookform/resolvers/zod";
import JoiStack from "@mui/joy/Stack";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import CustomButton from "../../../../components/CustomButton";
import { JoyProvider } from "../../../../components/JoyProvider";
import {
    ActivitiesData,
    useCreateActivityMutation,
    useFetchCandidateActivitiesQuery,
} from "../../../../store/reducers/all-candidates.api.slice";
import { objectValues } from "../../../../utils/helper";
import {
    ActivitiesLoader,
    ActivityCard,
    ActivitySection,
    EmptyActivitiesPlaceholder,
} from "../../../all-candidates-reachout/components/Activity";
import { createActivityFormSchema } from "../../../all-candidates/components/ContactOverviewActivities/EditActivityModal";
import { selectCurrProject } from "../../../allProjects/index.reducer";
import { ICandidate } from "../../project.types";
import AssignedToMenu from "../notesTab/components/AssignedtoMenu";
import { ActivitiesContext, useCandidateProfileActivities } from "./activities.context";
import { ActivityTagsMenu } from "./components/ActivityTagsMenu";
import { CreateActivityBody } from "./components/CreateActivityBody";
import { DueDateSelect } from "./components/DueDate";
import PriorityMenu from "./components/PriorityMenu";
import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { useDispatch } from "react-redux";

type CreateActivityForm = z.infer<typeof createActivityFormSchema>;

export function useCreateActivityFormState() {
    const { ...rest } = useFormContext<CreateActivityForm>();

    return { ...rest };
}

function ActivitiesList({ candidateId }: { candidateId: string }) {
    const { handleSelectedActivity, handleScrollIntoView } = useCandidateProfileActivities();
    const { id: projectId } = useParams();
    const projectIdAsNumber = Number(projectId);
    const { isLoading, isError, data = {} } = useFetchCandidateActivitiesQuery(candidateId || "");

    if (isLoading) {
        return <ActivitiesLoader />;
    }

    if (isError || isEmpty(data)) {
        return <EmptyActivitiesPlaceholder />;
    }

    const activitiesFilteredByProject = Object.entries(data)?.reduce((acc: ActivitiesData, [key, value]) => {
        acc[key] = value.filter(
            ({ project: { _id } }) =>
                // by default all projects has value = 0, so we don't need to filter when all projects is selected
                _id === projectIdAsNumber || projectIdAsNumber === 0
        );
        return acc;
    }, {});

    const handleEditClick = (value: string) => {
        handleScrollIntoView();
        handleSelectedActivity(value);
    };

    return (
        <JoiStack py={1}>
            {Object.entries(activitiesFilteredByProject).map(([year, activities]) => {
                return (
                    <ActivitySection year={year} key={year}>
                        {activities.map((i) => {
                            return (
                                <ActivityCard
                                    {...i}
                                    key={i._id}
                                    handleEdit={handleEditClick}
                                    variant="CANDIDATE_PROFILE_CARD"
                                />
                            );
                        })}
                    </ActivitySection>
                );
            })}
        </JoiStack>
    );
}

function getActivityFormDefaultValues({
    candidate,
    project,
}: {
    candidate: { name: string; id: string };
    project?: { name: string; id: string };
}): CreateActivityForm {
    return {
        body: "",
        tag: "",
        dueDate: null,
        candidate: { label: candidate.name, value: candidate.id },
        project: project?.id ? { value: String(project.id), label: project.name } : { label: "", value: "" },
        priority: "",
        referTo: { _id: -1, name: "" },
    };
}

export type Ref = HTMLFormElement | null;

export const ActivityForm = forwardRef<Ref, { onClose?: () => void }>(({ onClose }, ref) => {
    // console.log('red', _)
    const dispatch = useDispatch();
    const { candidateName, candidateId, selectedActivity, handleSelectedActivity } = useCandidateProfileActivities();
    const { data = {} } = useFetchCandidateActivitiesQuery(candidateId || "");
    const activityData = objectValues(data)
        .flat()
        .find((i) => i._id === selectedActivity);
    const selectedProject = useSelector(selectCurrProject);
    const initialValues = getActivityFormDefaultValues({
        candidate: { name: candidateName, id: candidateId },
        project: selectedProject?._id ? { id: String(selectedProject._id), name: selectedProject.name } : undefined,
    });

    const methods = useForm<CreateActivityForm>({
        resolver: zodResolver(createActivityFormSchema),
        defaultValues: initialValues,
        values:
            activityData !== undefined
                ? {
                    body: activityData.body,
                    tag: activityData.tag,
                    dueDate: activityData?.dueDate ? dayjs(activityData?.dueDate) : null,
                    candidate: { label: candidateName, value: candidateId },
                    project: selectedProject?._id
                        ? { value: String(selectedProject._id), label: selectedProject.name }
                        : { label: "", value: "" },
                    referTo: { _id: activityData?.referTo?._id || -1, name: activityData?.referTo?.name || "" },
                    priority: activityData?.priority || "",
                }
                : undefined,
    });

    const [createActivity, { isLoading }] = useCreateActivityMutation();

    const handleResetClick = () => {
        handleSelectedActivity("");
        methods.reset();
    };

    const onSubmit: SubmitHandler<CreateActivityForm> = (data) => {
        const { body, tag, dueDate, priority, referTo, candidate, project } = data;
        createActivity({
            body,
            dueDate: dueDate?.toISOString(),
            title: tag,
            priority,
            referTo: referTo?._id,
            projectId: Number(project.value),
            candidateId: candidate.value,
        })
            .unwrap()
            .then(() => {
                handleSelectedActivity("");
                methods.reset(initialValues);
                onClose?.();
                dispatch(setSuccessNotification("Activity Logged successfully"));
            }).catch((error) => {
                dispatch(setSuccessNotification("Activity Logging failed"));
            });
    };

    return (
        <FormProvider {...methods}>
            <Stack component="form" gap={1.5} onSubmit={methods.handleSubmit(onSubmit)} ref={ref} pr={1.5}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" gap={1}>
                        <AssignedToMenu />
                        <ActivityTagsMenu />
                    </Stack>
                    <PriorityMenu />
                </Stack>
                <CreateActivityBody />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DueDateSelect />
                    <Stack direction="row" gap={0.3}>
                        <CustomButton
                            type="reset"
                            variant="contained"
                            sx={{ alignSelf: "flex-end", marginRight: "0.7rem" }}
                            onClick={handleResetClick}
                        >
                            Reset
                        </CustomButton>
                        <CustomButton
                            type="submit"
                            variant="contained"
                            sx={{ alignSelf: "flex-end", marginRight: "0.7rem" }}
                        >
                            <ButtonTextWithLoading isLoading={isLoading} text="Save" progressSize={16} />
                        </CustomButton>
                    </Stack>
                </Stack>
            </Stack>
        </FormProvider>
    );
});

export default function NotesTab({
    id: candidateId,
    name: candidateName,
}: {
    id: ICandidate["_id"];
    name: ICandidate["name"];
}) {
    const [selectedActivity, setSelectedActivity] = useState("");
    const elementRef = useRef<HTMLFormElement | null>(null);

    const handleSelectedActivity = (value: string) => {
        setSelectedActivity(value);
    };

    const handleScrollIntoView = () => {
        elementRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    return (
        <ActivitiesContext.Provider
            value={{ candidateId, candidateName, selectedActivity, handleSelectedActivity, handleScrollIntoView }}
        >
            <Stack py={2} gap={1}>
                <ActivityForm ref={elementRef} />
                <JoyProvider>
                    <ActivitiesList candidateId={candidateId} />
                </JoyProvider>
            </Stack>
        </ActivitiesContext.Provider>
    );
}
