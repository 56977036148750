import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";
import {
    AddContactListToProjectPayload,
    ContactListState,
    ContactListTableItem,
    CreateContactListPayload,
    DeleteContactListPayload,
    ExportContactListCSVDataPayload,
    ListItem,
    UpdateContactListNamePayload,
} from "./contact-list.type";

const initialState: ContactListState = {
    tableState: {
        order: "desc",
        orderBy: "lastUpdated",
        page: 0,
        rowsPerPage: 25,
        selected: [],
        searchQuery: "",
        contactListIdForProject: "",
    },
    rows: [],
    csvData: [],
};

export const contactListSlice = createSlice({
    name: "contactList",
    initialState,
    reducers: {
        setContactListIdForProject(state, action: PayloadAction<string>) {
            state.tableState.contactListIdForProject = action.payload;
        },
        handleRowClick(state, action: PayloadAction<string>) {
            const id = action.payload;
            const selected = state.tableState.selected;
            const selectedIndex = state.tableState.selected.indexOf(id);
            let newSelected: readonly string[] = [];

            if (selectedIndex === -1) {
                state.tableState.selected = newSelected?.concat(selected, id);
            } else if (selectedIndex === 0) {
                state.tableState.selected = newSelected?.concat(selected?.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                state.tableState.selected = newSelected?.concat(selected?.slice(0, -1));
            } else if (selectedIndex > 0) {
                state.tableState.selected = newSelected?.concat(
                    selected?.slice(0, selectedIndex),
                    selected?.slice(selectedIndex + 1)
                );
            }
        },
        handleSelectAllClick(state, action: PayloadAction<boolean>) {
            if (action.payload) {
                state.tableState.selected = state.rows.map((n) => n.id);
            } else {
                state.tableState.selected = [];
            }
        },
        handleRequestSort(state, action: PayloadAction<keyof ContactListTableItem>) {
            const property = action.payload;
            const tableState = state.tableState;
            const isAsc = tableState.orderBy === property && tableState.order === "asc";
            state.tableState.order = isAsc ? "desc" : "asc";
            state.tableState.orderBy = property;
        },
        handlePageChange(state, action: PayloadAction<number>) {
            const newPage = action.payload;
            state.tableState.page = newPage;
        },
        handleChangeRowsPerPage(state, action: PayloadAction<string>) {
            const value = action.payload;
            state.tableState.rowsPerPage = Number(value);
            state.tableState.page = 0;
        },
        fetchContactList(state) {},
        setContactList(state, action: PayloadAction<ListItem[]>) {
            state.rows = action.payload;
        },
        addContactList(state, action: PayloadAction<ListItem>) {
            state.rows.unshift(action.payload);
        },
        setContactListCSVData(state, action: PayloadAction<any>) {
            state.csvData = action.payload;
        },
        setContactListSearchQuery(state, action: PayloadAction<string>) {
            state.tableState.searchQuery = action.payload;
        },
        setContactListAfterDeletion(state, action: DeleteContactListPayload) {
            const ids = action.payload;
            for (const id of ids) {
                const contactListId = state.rows.findIndex((i) => i.id === id);
                if (contactListId !== -1) {
                    state.rows.splice(contactListId, 1);
                }
            }
        },
        setUpdatedContactList(state, action: UpdateContactListNamePayload) {
            const { id: contactListId, name: newName } = action.payload;
            state.rows.forEach((i) => {
                const id = i.id;
                if (id === contactListId) {
                    i.name = newName;
                }
            });
        },
        createContactList(state, action: CreateContactListPayload) {},
        exportContactListCSV(state, action: ExportContactListCSVDataPayload) {},
        addContactListToProject(state, action: AddContactListToProjectPayload) {},
        deleteContactList(state, action: DeleteContactListPayload) {},
        updateContactListName(state, action: UpdateContactListNamePayload) {},
        cancelActions(state) {},
    },
});

export default contactListSlice.reducer;

export const {
    deleteContactList,
    addContactListToProject,
    setContactListIdForProject,
    handleRequestSort,
    handleSelectAllClick,
    handleRowClick,
    handlePageChange,
    handleChangeRowsPerPage,
    fetchContactList,
    cancelActions,
    createContactList,
    setContactList,
    exportContactListCSV,
    setContactListCSVData,
    setContactListSearchQuery,
    addContactList,
    setContactListAfterDeletion,
    updateContactListName,
    setUpdatedContactList,
} = contactListSlice.actions;

// Select the full contact list state
export const selectContactList = (state: RootState) => state.contactList;

// Select the page number
export const selectPage = (state: RootState) => state.contactList.tableState.page;

export const selectTableOrder = (state: RootState) => state.contactList.tableState.order;

export const selectTableOrderBy = (state: RootState) => state.contactList.tableState.orderBy;

// Select the number of rows per page
export const selectRowsPerPage = (state: RootState) => state.contactList.tableState.rowsPerPage;

// Select the array of selected rows
export const selectSelected = (state: RootState) => state.contactList.tableState.selected;

// Select the array of all rows
export const selectRows = (state: RootState) => state.contactList.rows;

// returns number of selected rows
export const numberOfSelectedRows = (state: RootState) => state.contactList.tableState.selected.length;

export const totalRowCount = (state: RootState) => state.contactList.rows.length;

export const selectContactListCSVData = (state: RootState) => state.contactList.csvData;

export const selectContactListSearchQuery = (state: RootState) => state.contactList.tableState.searchQuery;

export const selectContactListIdForProject = (state: RootState) => state.contactList.tableState.contactListIdForProject;

export const selectSingleContactList = (state: RootState) => {
    const contactListId = state.contactList.tableState.contactListIdForProject;
    const contactListItem = state.contactList.rows.find(({ id }) => id === contactListId);

    return contactListItem;
};
