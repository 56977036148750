import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import hqWhiteLogo from "../../assets/img/hq-white-logo.png";
import ButtonTextWithLoading from "../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { getCompanyPage, selectCompanyPage } from "../CompanyPage/CompanyPage.reducer";
import "./ApplyForJobs.css";
import Box, { type BoxProps } from "@mui/material/Box";

import { useAsyncFn } from "../../hooks/useAsyncFn";

import JobDescriptionViewer from "./JobDescriptionViewer";
const CustomGrid = styled((props: BoxProps) => <Box {...props} />)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1.5fr 2fr",
    gridTemplateRows: "1fr",
    padding: "2rem",
    gap: "2rem",
    [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
    },
}));

export const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
export const apiBase = `${baseUrl}/api`;

const baseStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "200px",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export function useAsync<T>(execute: () => Promise<T>) {
    const { submit, ...rest } = useAsyncFn(execute, {
        status: "IDLE",
        error: "",
        data: "",
    });

    useEffect(() => {
        submit();
    }, []);

    return { ...rest };
}

type UploadProps = {
    projectId: string | null;
};

type FileTileProps = {
    name: string;
};

export function FileTile({ name }: FileTileProps) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                textDecoration: "none",
                color: "#000",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "0.7rem",
            }}
            p={2}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <DescriptionRoundedIcon />
                <Typography>{name}</Typography>
            </Stack>
        </Stack>
    );
}

function Upload({ projectId }: UploadProps) {
    const [resume, setResume] = useState<FileWithPath>();
    const [inputValues, setInputValues] = useState({
        name: "",
        email: "",
        linkedin: "",
        errors: {},
    });
    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        const file = acceptedFiles[0];
        if (file) {
            setResume(file);
        }
    }, []);
    const {
        data: _,
        error,
        status,
        submit,
    } = useAsyncFn(async () => {
        const formData = new FormData();
        if (inputValues.email && inputValues.name && projectId) {
            // @ts-ignore
            formData.append("resume", resume);
            formData.append("name", inputValues.name);
            formData.append("email", inputValues.email);
            formData.append("linkedin", inputValues.linkedin);
            formData.append("projectId", projectId);
            const response = await axios.post(`${apiBase}/upload/candidateToProject`, formData);
            // @ts-ignore
            if (response && response?.data?.success) {
                return Promise.resolve(response);
            } else {
                throw new Error("something went wrong");
            }
        } else {
            throw new Error("invalid input values");
        }
    });

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        },
        maxSize: 50000000,
        onDrop,
    });

    const handleChange = (key: "name" | "email" | "linkedin", value: string) => {
        setInputValues((prev) => ({ ...prev, [key]: value }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submit();
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const isLoading = status === "LOADING";
    const isError = status === "ERROR" && error;
    return (
        <Stack spacing={2}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} disabled={isLoading} />
                <Stack alignItems="center" sx={{ position: "relative" }}>
                    <Typography variant="h4" align="center" sx={{ fontSize: "18px" }}>
                        Drop your resume document here, or{" "}
                        <Typography
                            component="button"
                            variant="h4"
                            sx={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                fontWeight: "600",
                                fontSize: "16px",
                            }}
                        >
                            click to upload
                        </Typography>
                    </Typography>
                    <Typography variant="body1" align="center">
                        (Supported formats :.pdf, .docx, .doc)
                    </Typography>
                </Stack>
            </div>
            {resume ? <FileTile name={resume?.name} /> : null}
            <form
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
                onSubmit={handleSubmit}
            >
                <TextField
                    required={true}
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={inputValues.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    disabled={isLoading}
                />
                <TextField
                    required={true}
                    label="Personal Email"
                    variant="outlined"
                    type="email"
                    value={inputValues.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    disabled={isLoading}
                />
                <TextField
                    // required={!resume}
                    label="Linkedin"
                    variant="outlined"
                    type="text"
                    value={inputValues.linkedin}
                    onChange={(e) => handleChange("linkedin", e.target.value)}
                    disabled={isLoading}
                />
                {isError && <Alert severity="error">{error || "Error while Submitting. Please try again."}</Alert>}
                {status !== "SUCCESS" ? (
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            width: "fit-content",
                            alignSelf: "flex-end",
                        }}
                    >
                        <ButtonTextWithLoading isLoading={isLoading} text="Apply" />
                    </Button>
                ) : (
                    <Alert severity="success">
                        You have successfully submitted your application. You will be contacted once your application is
                        reviewed.
                    </Alert>
                )}
            </form>
        </Stack>
    );
}

function Loader() {
    return (
        <CircularProgress
            sx={{
                alignSelf: "center",
            }}
            size={50}
        />
    );
}

function UploadForm() {
    const [numPages, setNumPages] = useState<number>(0);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("project");
    const isLoading = useSelector(checkIfLoading(getCompanyPage.type));
    const project: any = useSelector(selectCompanyPage);

    useEffect(() => {
        if (projectId) {
            dispatch(getCompanyPage(projectId));
        }
    }, []);

    return (
        <CustomGrid>
            <Stack spacing={3}>
                <Typography variant="h5" fontWeight={500}>
                    Enter your application details below
                </Typography>
                <Upload projectId={projectId} />
            </Stack>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" fontWeight={500}>
                        Job Description
                    </Typography>
                    {!isLoading && (
                        <Typography
                            variant="h6"
                            component="a"
                            href={project?.jd}
                            sx={{
                                marginTop: "0px !important",
                                fontSize: "20px",
                                textDecoration:"none",
                                color:"#489cd4",
                            }}
                        >
                            Download
                        </Typography>
                    )}
                </Stack>
                <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                    <Suspense fallback={<Loader />}>
                        {isLoading ? <Loader /> : <JobDescriptionViewer jd={project?.jd} />}
                    </Suspense>
                </Stack>
            </Stack>
        </CustomGrid>
    );
}

function Navbar() {
    return (
        <Stack alignItems="center" justifyContent="center" sx={{ background: "#088397" }} p={1.5}>
            <Box component="img" src={hqWhiteLogo} alt="" sx={{ maxHeight: "180px", maxWidth: "180px" }} />
        </Stack>
    );
}

export default function ApplyForJobs() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("project");

    if (!projectId) {
        return <Navigate to="*" />;
    }

    return (
        <Box
            sx={{
                backgroundColor: "#f5f6f8",
                minHeight: "100vh",
                width: "100vw",
            }}
        >
            <Navbar />
            <UploadForm />
        </Box>
    );
}
