import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Card, Typography } from "@mui/joy";
import { lighten } from "@mui/material";

const faqItems = [
    { title: "Excluding candidates who have worked at certain companies", fileId: "8LFbu3TEssEqt1l5wbvEHVXe" },
    { title: "How to manage Project Access?", fileId: "Hh83V1a0GyC5GQ7gBCvxdyOe" },
    { title: "How to track candidate responses on EasySource", fileId: "LQH1bz40dfkoyQPVgKz7iOun" },
    { title: "How to create a project?", fileId: "sf7TbwTOfULDHN64HkBC2ArG" },
    { title: "Our AI prompt templates cheat sheet", fileId: "dGipn5DivlixcpqMlPrSlC1N" },
    { title: "Maximizing your outreach results", fileId: "Kv7V39ZZNBxB64eCC1UZXPrs" },
    { title: "Leveraging your Account Manager", fileId: "W9Yy5VlytwPgu36zh3QacSqx" },
    { title: "A quick refresher for candidate review and outreach", fileId: "mj011ppv4qYLf2cU6q5R1Osp" },
    { title: "Set search filters in your project", fileId: "eharX6CHMIc0XWf8G6hjXYp1" },
    { title: "EasySource contact data control", fileId: "FN7OSF8Y8liwb73l8F6fWNiN" },
    { title: "Missing LinkedIn Responses", fileId: "4ETYpkbSXyRZsTzc7O4v5JCX" },
    { title: "EasySource <> Zoho Recruit Integration", fileId: "aQa6Y3y6KSAIoLZlUfHHGgPo" },
    { title: "Generate a Smart Report of 5-star candidates", fileId: "lCPAmwTNYyRLMFvybIkByyiz" },
    { title: "Change a project status", fileId: "XQZ0sdaHyZERPNzbqaeaNF1H" },
    { title: "EasySource x BambooHR Integration", fileId: "BIyZ599oJHBVH0xG0AVa9xAb" },
    { title: "Collaborate with your team", fileId: "gXoCtkDU9oarZY5qSBEOE25A" },
    { title: "How to view all of your team’s responses in one place?", fileId: "ThdhwetqdnWdW6f8MOpSzb3D" },
    { title: "View your hiring analytics", fileId: "Ltj39EsbpRHobLAXQQkhWtgD" },
    { title: "How to check your access in Zoho Recruit?", fileId: "CkpR4cYe2ZnPMvfRMBtxagkA" },
    { title: "How to improve candidate relevancy?", fileId: "5NL5KCt9mFIWp6fDF5yxHpGI" },
    { title: "How to build a candidate pool?", fileId: "xmB5I1IqlwPwBtieATA8omld" },
    { title: "How to see the updated scores after vetting is run?", fileId: "86ylPodHKNvV1eiSX3Rs4amW" },
    { title: "How to connect with Calendly?", fileId: "aabJ7cz0bST5aa2vsSfqxy5r" },
    { title: "How to add external candidates to an EasySource project?", fileId: "6q1Yl7qRJiu6UJD8t1SZ52UV" },
    { title: "How to do add multiple email followups in one campaign?", fileId: "l64VaCnVp9kw8uuKmPHvzWjY" },
    { title: "How to search a candidate?", fileId: "rkPmyN9zpwiXDAOeLTYclDZi" },
    { title: "How to see the AI vetting insights?", fileId: "VZmQ9gsV6wratCpFxcVbFxoU" },
    { title: "How to check positive responses?", fileId: "CmM1ZTni40M0pbnyMGX0bOe4" },
    { title: "How to exclude specific candidates from being reached out?", fileId: "KvyAX9QagVkyMZCkCTb61FM1" },
    { title: "How to use filters on EasySource?", fileId: "gDC5gH1ZZ9QdOaIGmAEWhCkE" },
    { title: "How to update AI vetting criteria?", fileId: "WZeTprxBk7LzPlTgOl0apDZC" },
    { title: "How to generate insights on candidates?", fileId: "VBWCwu3ewXZcxns2MOPEswO3" },
    { title: "How to delete candidates?", fileId: "MsarGkKl5cbnE8nodxHZ3oO6" },
    { title: "How to update candidate's pipeline status?", fileId: "UybdfImC2zKhhYw31265FxbF" },
    { title: "How to manage all your contact data on EasySource?", fileId: "673u2yWEc8oAKha4VSxo5o8g" },
];

function generateLink(fileId: string) {
    if (fileId === "#") return "#";
    return `https://openainew.s3.ap-southeast-1.amazonaws.com/file-${fileId}.pdf`;
}

function FAQ() {
    const boxProps = {
        display: "flex",
        alignItems: "center",
        padding: "16px",
        backgroundColor: "white",
        transition: "background-color 0.3s ease",
        // borderLeft: "6px solid #0d7a89",

        borderBottom: "0.75px solid lightgrey",
        "&:hover": {
            backgroundColor: lighten("#d8e7ea", 0.5),
            cursor: "pointer",
        },
    };

    return (
        <Box
            sx={{
                height: "40rem",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                border: "0px",
            }}
        >
            <Card
                sx={{
                    backgroundColor: "#f6fafa",
                    borderRadius: "0px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    border: "0px",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "black",
                        fontSize: "18px",
                        border: "0px",
                    }}
                >
                    FAQs
                </Typography>
            </Card>

            <Box
                sx={{
                    overflowY: "auto",
                    flex: 1,
                    // paddingRight: "9px",
                }}
            >
                {faqItems.map((item, index) => (
                    <Box key={index} sx={boxProps} onClick={() => window.open(generateLink(item.fileId), "_blank")}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Box
                                lineHeight={1}
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "black",
                                }}
                            >
                                <Typography sx={{ fontWeight: "400", fontSize: "14px", color: "black" }}>
                                    {item.title}
                                </Typography>
                            </Box>
                        </div>

                        <KeyboardArrowRightIcon htmlColor="#0d7a89" fontSize="small" />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default FAQ;
