import Chatbot from "react-chatbot-kit";

import "./chatbotkit.css";

import { Box } from "@mui/joy";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";
import { IMessage } from "react-chatbot-kit/build/src/interfaces/IMessages";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";

import { selectUser } from "../../../../pages/Signin/Signin.reducer";
import { JoyProvider } from "../../../JoyProvider";
import { useGetChatQuery } from "../../api/chatbot.api.slice";
import { useChatbotContext } from "../../Context";
import ActionProvider from "./ActionProvider";
import ChatbotLoader from "./ChatbotLoader";
import defaultConfig, { getConfig } from "./Config";
import Header from "./Header";
import MessageParser from "./MessageParser";
import Render from "./Render";

interface ChatbotProps {
    actionProvider: any;
    messageParser: any;
    config: IConfig;
    headerText?: string;
    placeholderText?: string;
    saveMessages?: (ref: any) => any;
    messageHistory?: IMessage[] | string;
    validator?: (input: string) => Boolean;
    runInitialMessagesWithHistory?: Boolean;
    disableScrollToBottom?: boolean;
}

interface ChatbotKitProps {}

export default function ChatbotKit(props: ChatbotKitProps) {
    const { closeChatbot, chatbot } = useChatbotContext();
    const { data, isLoading, isFetching } = useGetChatQuery(chatbot.chatId, {
        skip: !chatbot.chatId || chatbot.reload === false,
    });

    const config = getConfig({
        ...defaultConfig,
        customComponents: {
            header(props) {
                return <Header onBack={closeChatbot} />;
            },
        },
    });

    if (!chatbot.open) {
        return null;
    }

    if (isLoading || isFetching) {
        return (
            <JoyProvider>
                <Box sx={{ display: "grid", height: "100%", placeItems: "center" }}>
                    <ChatbotLoader color="#0d7a89" />
                </Box>
            </JoyProvider>
        );
    }

    const chatbotProps: ChatbotProps = {
        placeholderText: "Ask a question...",
        actionProvider: ActionProvider,
        config,
        messageParser: MessageParser,
    };

    if (chatbot.chatId) {
        const chatsList = Array.isArray(data?.chats)
            ? data?.chats?.flatMap((chat) => [
                  {
                      message: <Render message={chat.question} />,
                      type: "user",
                      id: `${chat.chat_id}-user`,
                  },
                  {
                      message: <Render message={chat.response} citations={chat.citations} />,
                      type: "bot",
                      id: `${chat.chat_id}-bot`,
                  },
              ])
            : [];

        if (chatsList?.length) {
            chatbotProps.messageHistory = chatsList as any[];
        }
    }

    return <Chatbot {...chatbotProps} />;
}
