import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";

type IMailAlertRootState = {
    authorizeEmail: boolean;
    canContinueFlow: boolean;
    continueFlow: () => void;
};

const initialState: IMailAlertRootState = {
    authorizeEmail: false,
    canContinueFlow: false,
    continueFlow: () => null,
};

const reducers = {
    setAuthorizeModal(state: typeof initialState, { payload }: { payload: boolean }) {
        state.authorizeEmail = payload;
    },
    setCanContinueFlow(state: typeof initialState, { payload }: { payload: boolean }) {
        state.canContinueFlow = payload;
    },
    setContinueFlow(state: typeof initialState, { payload }: { payload: () => void }) {
        state.continueFlow = payload;
    },
};

export const mailAlertSlice = createSlice({
    name: "mailAlert",
    initialState,
    reducers,
});

export default mailAlertSlice.reducer;

export const { setAuthorizeModal, setCanContinueFlow, setContinueFlow } = mailAlertSlice.actions;

export const selectAuthorizeEmail = (state: RootState) => state.mailAlert.authorizeEmail;
export const selectCanContinueFlow = (state: RootState) => state.mailAlert.canContinueFlow;
export const selectContinueFlow = (state: RootState) => state.mailAlert.continueFlow;
