import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export function AddCandidate({ handleOpen }: { handleOpen: () => void }) {
    return (
        <Tooltip title="Add contact">
            <IconButton onClick={handleOpen}>
                <AddRoundedIcon />
            </IconButton>
        </Tooltip>
    );
}
