import { MutableRefObject, useRef, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";

import { checkCalendlyAuthorized } from "../pages/Signin/Signin.reducer";
import { useGetCalendlyEventTypesQuery } from "../store/reducers/externalConnect.api.slice";

type PersonalizeTag = {
    label: string;
    value: string;
};

type PersonalizeTagActions = PersonalizeTag[];

const defaultActions: PersonalizeTagActions = [
    {
        label: "{name}",
        value: "{name}",
    },
    {
        label: "{role}",
        value: "{role}",
    },
    {
        label: "{company}",
        value: "{company}",
    },
    {
        label: "{senderName}",
        value: "{senderName}",
    },
];

export const actionsWithSignature: PersonalizeTagActions = [
    ...defaultActions,
    { label: "{signature}", value: "{signature}" },
];

export type PersonalizeTagActionsWithMutation = {
    key: string;
    label: string;
    onClick?: () => void;
    url?: string;
};

export default function usePersonalizeValues({
    actions = defaultActions,
    handleBodyChange,
    handleSubjectChange,
}: {
    handleSubjectChange?: (cursorPosition: number, label: string) => void;
    handleBodyChange?: (cursorPosition: number, label: string) => void;
    actions?: PersonalizeTagActions;
} = {}) {
    const bodyRef = useRef<any>(null);
    const subjectRef = useRef<HTMLInputElement | null>(null);
    const [addTo, setAddTo] = useState<"SUBJECT" | "BODY">("SUBJECT");
    const isCalendlyAuthorized = useSelector(checkCalendlyAuthorized);
    const { data, ...rest } = useGetCalendlyEventTypesQuery(undefined, {
        skip: !isCalendlyAuthorized,
    });
    const { isLoading, isError } = rest;
    const showCalendlyMenu = isCalendlyAuthorized && !isLoading && !isError;

    const handlePersonalizeValues = (label: string) => {
        if (addTo === "SUBJECT" && subjectRef?.current) {
            const cursorPosition = subjectRef.current?.selectionStart || 0;
            handleSubjectChange?.(cursorPosition, label);
            // subjectRef.current?.setSelectionRange(
            //     cursorPosition + 1,
            //     cursorPosition + 1
            // );
            subjectRef.current.focus();
            const textBeforeCursor = subjectRef.current.value.substring(0, cursorPosition);
            const textAfterCursor = subjectRef.current.value.substring(cursorPosition);
            subjectRef.current.value = textBeforeCursor + label + textAfterCursor;
            const newCursorPosition = cursorPosition + label.length;
            subjectRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            const event = new Event("input", { bubbles: true });
            subjectRef.current.dispatchEvent(event);
        }

        if (addTo === "BODY" && bodyRef?.current && bodyRef?.current?.editor) {
            bodyRef.current.editor.focus();
            const selection = bodyRef.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            handleBodyChange?.(cursorPosition, label);
            bodyRef.current?.editor.insertText(cursorPosition, label);
        }
    };

    const handleAddCalendlyLink = (text: string, link: string) => {
        if (addTo === "BODY" && bodyRef?.current && bodyRef?.current?.editor) {
            bodyRef.current.editor.focus();
            const selection = bodyRef.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            // bodyRef.current?.editor.insertText(cursorPosition, " ");
            bodyRef.current?.editor.insertText(cursorPosition, text, {
                link,
            });
            // bodyRef.current?.editor.insertText(
            //     cursorPosition + text.length,
            //     " "
            // );
        }
    };

    const actionsWithStateMutations: PersonalizeTagActionsWithMutation[] = actions.map(({ label, value }) => ({
        key: nanoid(),
        label,
        onClick: () => handlePersonalizeValues(value),
    }));

    const calendlyActions: PersonalizeTagActionsWithMutation[] =
        data?.map(({ name, scheduling_url }) => ({
            key: nanoid(),
            label: name,
            url: scheduling_url,
            onClick: () => handlePersonalizeValues(` ${scheduling_url} `),
        })) || [];

    return {
        handlePersonalizeValues,
        setAddTo,
        subjectRef,
        bodyRef,
        actions: actionsWithStateMutations,
        calendly: {
            calendlyActions,
            showCalendlyMenu,
            handleAddCalendlyLink,
            ...rest,
        },
    };
}
