import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";
import { ContactListItem, ContactListItemsState, ContactListItemsTableItem } from "./contact-list-items.type";

const initialState: ContactListItemsState = {
    tableState: {
        order: "asc",
        orderBy: "name",
        page: 0,
        rowsPerPage: 25,
        selected: [],
        searchQuery: "",
    },
    rows: [],
};

export const contactListItemsSlice = createSlice({
    name: "contactListItems",
    initialState,
    reducers: {
        setContactListItemsSearchQuery(state, action: PayloadAction<string>) {
            state.tableState.searchQuery = action.payload;
        },
        handleContactListItemsRowClick(state, action: PayloadAction<string>) {
            const id = action.payload;
            const selected = state.tableState.selected;
            const selectedIndex = state.tableState.selected.indexOf(id);
            let newSelected: readonly string[] = [];

            if (selectedIndex === -1) {
                state.tableState.selected = newSelected?.concat(selected, id);
            } else if (selectedIndex === 0) {
                state.tableState.selected = newSelected?.concat(selected?.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                state.tableState.selected = newSelected?.concat(selected?.slice(0, -1));
            } else if (selectedIndex > 0) {
                state.tableState.selected = newSelected?.concat(
                    selected?.slice(0, selectedIndex),
                    selected?.slice(selectedIndex + 1)
                );
            }
        },
        handleContactListItemsSelectAllClick(state, action: PayloadAction<boolean>) {
            if (action.payload) {
                state.tableState.selected = state.rows.map((n) => n.id);
            } else {
                state.tableState.selected = [];
            }
        },
        handleContactListItemsRequestSort(state, action: PayloadAction<keyof ContactListItemsTableItem>) {
            const property = action.payload;
            const tableState = state.tableState;
            const isAsc = tableState.orderBy === property && tableState.order === "asc";
            state.tableState.order = isAsc ? "desc" : "asc";
            state.tableState.orderBy = property;
        },
        handleContactListItemsPageChange(state, action: PayloadAction<number>) {
            const newPage = action.payload;
            state.tableState.page = newPage;
        },
        handleContactListItemsChangeRowsPerPage(state, action: PayloadAction<string>) {
            const value = action.payload;
            state.tableState.rowsPerPage = Number(value);
            state.tableState.page = 0;
        },
        fetchContactListItems(state, action: PayloadAction<string>) {},
        setContactListItems(state, action: PayloadAction<ContactListItem[]>) {
            state.rows = action.payload;
        },
        cancelActions(state) {},
    },
});

export default contactListItemsSlice.reducer;

export const {
    handleContactListItemsRequestSort,
    handleContactListItemsSelectAllClick,
    handleContactListItemsRowClick,
    handleContactListItemsPageChange,
    handleContactListItemsChangeRowsPerPage,
    cancelActions,
    fetchContactListItems,
    setContactListItems,
    setContactListItemsSearchQuery,
} = contactListItemsSlice.actions;

export const selectContactListItems = (state: RootState) => state.contactList;

// Select the page number
export const selectContactListItemsPage = (state: RootState) => state.contactListItems.tableState.page;

export const selectContactListItemsTableOrder = (state: RootState) => state.contactListItems.tableState.order;

export const selectContactListItemsTableOrderBy = (state: RootState) => state.contactListItems.tableState.orderBy;

// Select the number of rows per page
export const selectContactListItemsRowsPerPage = (state: RootState) => state.contactListItems.tableState.rowsPerPage;

// Select the array of selected rows
export const selectContactListItemsSelected = (state: RootState) => state.contactListItems.tableState.selected;

// Select the array of all rows
export const selectContactListItemsRows = (state: RootState) => state.contactListItems.rows;

// returns number of selected rows
export const selectContactListItemsNumberOfSelectedRows = (state: RootState) =>
    state.contactListItems.tableState.selected.length;

export const selectContactListItemsTotalRowCount = (state: RootState) => state.contactListItems.rows.length;

export const selectContactListItemsSearchQuery = (state: RootState) => state.contactListItems.tableState.searchQuery;
