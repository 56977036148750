import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import { POPOVER_BOX_SHADOW } from "../../../../utils/Constants";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import {
    resetActivityFilters,
    selectContactOverviewActivitiesFilters,
    setActivityFilter,
} from "./contact-overview-activities.slice";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import { useAppDispatch } from "../../../../store/Store";
import {
    ContactOverviewActivitiesStateFilters,
    ContactOverviewActivitiesStateFiltersKeys,
    SubFilterKey,
} from "./ContactOverviewActivities.types";
import { objectEntries, objectKeys } from "../../../../utils/helper";
import { useFetchOrganizationMembersQuery } from "../../../../store/reducers/all-candidates.api.slice";
import CustomButton from "../../../../components/CustomButton";

function CheckBoxSection({
    filterName,
    subFilters,
}: {
    filterName: ContactOverviewActivitiesStateFiltersKeys;
    subFilters: Record<string, boolean>;
}) {
    const dispatch = useAppDispatch();
    const { data = [] } = useFetchOrganizationMembersQuery();

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        dispatch(
            setActivityFilter({
                value: checked,
                filterKey: filterName as ContactOverviewActivitiesStateFiltersKeys,
                subFilterKey: name as SubFilterKey,
            })
        );
    };

    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel
                component="legend"
                sx={(theme) => ({
                    fontSize: theme.typography.body2.fontSize,
                    paddingBottom: "0.3rem",
                })}
            >
                {capitalize(filterName === "activity Types" ? "activity Tags" : filterName)}
            </FormLabel>
            <FormGroup
                sx={{
                    maxHeight: "300px",
                    overflow: filterName === "activity Types" ? "scroll" : "auto",
                    flexWrap: "nowrap",
                    overflowX: "hidden",
                }}
            >
                {objectKeys(subFilters).map((i) => {
                    const checked = subFilters[i];
                    const label =
                        filterName === "assignee"
                            ? data.find((member) => String(member._id) === i)?.name
                            : capitalize(i);
                    return (
                        <FormControlLabel
                            sx={(theme) => ({
                                marginLeft: 0,
                                "& .MuiTypography-root": {
                                    fontSize: theme.typography.body2.fontSize,
                                },
                                "& .MuiCheckbox-root": {
                                    "& .MuiSvgIcon-root": {
                                        fontSize: theme.typography.body1.fontSize,
                                    },
                                },
                            })}
                            key={i}
                            control={<Checkbox checked={checked} onChange={(e) => handleCheck(e)} name={i} />}
                            label={label}
                        />
                    );
                })}
            </FormGroup>
        </FormControl>
    );
}

export function ActivityFilters() {
    const dispatch = useAppDispatch();
    const selectFilters = useSelector(selectContactOverviewActivitiesFilters);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    useFetchOrganizationMembersQuery();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFiltersReset = () => {
        dispatch(resetActivityFilters());
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title="Filter activities">
                <IconButton onClick={handleClick}>
                    <FilterAltRoundedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        boxShadow: POPOVER_BOX_SHADOW,
                    },
                }}
            >
                <Stack
                    p={2}
                    gap={1}
                    display="grid"
                    maxWidth="70vw"
                    gridTemplateColumns="repeat(auto-fit, minmax(80px, max-content))"
                    gridTemplateRows="1fr"
                >
                    {objectEntries<ContactOverviewActivitiesStateFilters>(selectFilters).map(
                        ([filterName, subFilters]) => {
                            return <CheckBoxSection filterName={filterName} subFilters={subFilters} key={filterName} />;
                        }
                    )}
                    <Stack
                        direction="row"
                        sx={{
                            gridColumnStart: "5",
                            gridColumnEnd: "6",
                            justifySelf: "end",
                        }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                        <CustomButton variant="contained" onClick={handleFiltersReset}>
                            Reset
                        </CustomButton>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}
