import { Fragment, useState } from "react";
import Skeleton from "@mui/joy/Skeleton";
import Stack, { StackProps } from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { styled } from "@mui/joy/styles";
import Chip from "@mui/joy/Chip";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import { CandidateInfoContentContainer } from "./ContactInfo";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";
import { Thread } from "../../all-candidates/all-candidates.types";
import { grey } from "@mui/material/colors";
import { useAllCandidatesEmailsQuery } from "../../../store/reducers/all-candidates.api.slice";
import { useParams } from "react-router-dom";
import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import CustomAccordion, { CustomAccordionDetails, CustomAccordionSummary } from "./CustomAccordion";
import dayjs from "dayjs";

export function Interactions() {
    return (
        <CandidateInfoContentContainer>
            <AllCandidatesReachOutBodyContainer>
                {/* <InteractionsHeader /> */}
                <Stack
                    m={2}
                    pb={2}
                    sx={{
                        overflow: "auto",
                        height: "calc(100vh - 165px)",
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <InteractionsMain />
                </Stack>
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

// function InteractionsProjectFilter() {
//     const { ...rest } = useSelectProjectValues();
//     return <SelectProject {...rest} />;
// }

// export function InteractionsHeader() {
//     return (
//         <Stack
//             px={2}
//             py={1.5}
//             sx={(theme) => ({
//                 borderBottom: `1px solid ${theme.palette.divider}`,
//             })}
//         >
//             <InteractionsProjectFilter />
//         </Stack>
//     );
// }

function InteractionsMain() {
    const params = useParams();
    const { data = {}, isError, isLoading } = useAllCandidatesEmailsQuery(params?.id);

    if (isLoading) {
        return <InteractionsLoading />;
    }

    if (isError) {
        return <InsightEmptyPlaceholder />;
    }

    const interactions = Object.entries(data);

    if (!interactions.length) {
        return <InsightEmptyPlaceholder />;
    }

    return (
        <Stack gap={2}>
            {interactions.map(([key, value]) => {
                return (
                    <Stack gap={2}>
                        {value.map((t) => {
                            return <InteractionsSingleThread key={key} {...t} />;
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
}

const InteractionsSingleThreadContainer = styled((props: StackProps) => {
    return (
        <Stack
            gap={1}
            p={2}
            sx={{
                backgroundColor: "#fbfcfe",
                border: 1,
                borderColor: "divider",
                borderRadius: "4px",
            }}
            {...props}
        />
    );
})({});

function InteractionsLoading() {
    const skeleton = (
        <InteractionsSingleThreadContainer>
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rectangular" height={200} />
        </InteractionsSingleThreadContainer>
    );

    return (
        <Stack gap={1.5}>
            {Array(5)
                .fill(1)
                .map((_, idx) => {
                    return <Fragment key={`interactions-loading-${idx}`}>{skeleton}</Fragment>;
                })}
        </Stack>
    );
}

function InteractionsSingleThread(props: Thread) {
    const { body, subject, from, to, updatedAt, cc } = props;
    const [isExpanded, setExpanded] = useState(false);
    return (
        <CustomAccordion expanded={isExpanded} onChange={() => setExpanded((prev) => !prev)}>
            <CustomAccordionSummary indicator={<UnfoldMoreDoubleIcon />} isExpanded={isExpanded}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                    <Stack gap={0.5}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography level="body-sm" fontWeight={500}>
                                Subject: {subject}
                            </Typography>
                        </Stack>
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            <FromTo label="from" text={from} />
                            <FromTo label="to" text={to} />
                            <FromTo label="cc" text={cc} />
                        </Stack>
                    </Stack>
                    <Typography level="body-xs" pr={1}>
                        Last Updated on: {dayjs(updatedAt).format("MMM D, h:mm A")}
                    </Typography>
                </Stack>
            </CustomAccordionSummary>
            <CustomAccordionDetails isExpanded={isExpanded}>
                <p
                    style={{
                        color: grey["A700"],
                        fontSize: "14px",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: body.replaceAll("\r\n", "<br>").replaceAll("\n", "<br>"),
                    }}
                />
            </CustomAccordionDetails>
        </CustomAccordion>
    );
}

function FromTo({ label, text }: { label: string; text: string }) {
    return (
        <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography component="span" level="body-sm">
                {label}
            </Typography>
            <Chip
                size="sm"
                sx={(theme) => ({
                    "& .MuiChip-label": {
                        // fontSize: theme.typography.pxToRem(10),
                        fontSize: "10px",
                    },
                })}
            >
                {text}
            </Chip>
        </Stack>
    );
}

function InsightEmptyPlaceholder() {
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<FeedbackRoundedIcon sx={{ fontSize: "4rem", color: grey[400] }} />}
            title="No Interactions found"
            message="There are no interactions available for this candidate."
        />
    );
}
