import React, { useEffect, useRef, useState } from "react";
import Close from "@mui/icons-material/Close";
import { IconButton, Input, InputProps, List, Option, Select, SelectProps } from "@mui/joy";
import CircularProgress from "@mui/material/CircularProgress";
import { matchSorter } from "match-sorter";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { SELECT_CONTAINER } from "../constant/Constant";
import useOnClickOutside from "../hooks/useClickOutside";
import { JoyProvider } from "./JoyProvider";
import VirtualizedList from "./VirtualizedList"; // Import your VirtualizedList component

const ErrorFallback = ({ onReset }) => {
    useEffect(() => {
        onReset();
    }, []);
    return (
        <div style={{ fontSize: "0.75rem", alignItems: "center", display: "flex", gap: "8px" }}>
            <CircularProgress size={15} /> Loading...
        </div>
    );
};

/**
 * Represents an item in the dropdown.
 */
export type Item = {
    label: string;
    value: string;
    [key: string]: any;
};

/**
 * Props for the VirtualizedSelect component.
 */
export type VirtualizedSelectProps = {
    /**
     * An array of options to be displayed in the dropdown.
     */
    options: Item[];
    /**
     * Additional props for the search Input component.
     */
    inputProps?: InputProps;
    /**
     * If true, a search input is enabled above the dropdown options.
     */
    enableSearch?: boolean;
    /**
     * Function to customize the rendering of each option.
     */
    renderOption?: (item: Item) => React.ReactNode;
    /**
     * Callback function called when the selected option changes.
     */
    onChange?: (item: Item) => void;
    /**
     * If true, adds a button to clear the selected option.
     */
    clearable?: boolean;
    /**
     * The default option that is selected initially or when cleared.
     */
    defaultOption: Item;
    /**
     * Additional keys to be used for searching options.
     */
    searchKeys?: string[];
    /**
     * Search bar position
     */
    inputPosition?: "top" | "right";
    // item height
    itemHeight?: number;
} & Omit<SelectProps<Item | string, false>, "onChange" | "component">;

/**
 * VirtualizedSelect is a custom React component that wraps the Select component from @mui/joy.
 * It handles large lists of options efficiently by virtualizing the list rendering process.
 * The component supports search functionality, custom rendering of options, and a clearable option feature.
 *
 * @component
 * @param {VirtualizedSelectProps} props - Props for the VirtualizedSelect component.
 * @returns {JSX.Element} The rendered VirtualizedSelect component.
 *
 * @example
 * <VirtualizedSelect
 *   options={[
 *     { label: 'Option 1', value: '1' },
 *     { label: 'Option 2', value: '2' },
 *   ]}
 *   enableSearch={true}
 *   placeholder="Select an option"
 *   defaultOption={{ label: 'Default Option', value: '' }}
 *   onChange={(item) => console.log('Selected:', item)}
 *   clearable={true}
 *   searchKeys={['label', 'value', "object.key", "object.nested.key"]}
 * />
 */
const VirtualizedSelect: React.FC<VirtualizedSelectProps> = (props) => {
    const {
        inputPosition = "top",
        options = [],
        inputProps,
        enableSearch,
        placeholder = "Select...",
        renderOption = (item) => item.label,
        onChange,
        clearable,
        size = "md",
        value,
        defaultOption,
        searchKeys = [],
        itemHeight = 36,
        ...selectProps
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<Item>(defaultOption);
    const [listboxOpen, setOpenListbox] = useState<boolean>(false);
    const [enableVirtualization, setEnableVirtualization] = useState<boolean>(true);

    const selectRef = useRef<HTMLButtonElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);
    const searchBarRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(listboxRef, () => setOpenListbox(false), [searchBarRef.current, selectRef.current]);

    const filteredOptions = matchSorter(options, searchTerm, {
        keys: ["label", ...searchKeys],
    });

    /**
     * Handle search input change.
     * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
     */
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value);
    };

    /**
     * Reset the search term to an empty string.
     */
    const resetSearchTerm = (): void => setSearchTerm("");

    /**
     * Handle selection of an option.
     * @param {Item} item - The selected item.
     */
    const handleSelectChange = (item: Item): void => {
        setSelectedOption(item);
        onChange?.(item);
        setOpenListbox(false);
    };

    return (
        <ReactErrorBoundary
            FallbackComponent={(fallbackProps) => (
                <ErrorFallback
                    onReset={() => {
                        fallbackProps?.resetErrorBoundary();
                        setEnableVirtualization(false);
                    }}
                />
            )}
        >
            <JoyProvider>
                <div style={{ position: "relative" }}>
                    <Select<Item | string, false>
                        size={size}
                        value={selectedOption?.value || value}
                        placeholder={selectedOption?.label || placeholder}
                        slotProps={{
                            listbox: {
                                ref: listboxRef,
                            },
                        }}
                        listboxOpen={listboxOpen}
                        listboxId={SELECT_CONTAINER}
                        onClick={() => setOpenListbox(!listboxOpen)}
                        ref={selectRef}
                        endDecorator={
                            clearable &&
                            selectedOption && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectChange(defaultOption);
                                    }}
                                    variant="soft"
                                    sx={{ borderRadius: "50%", mr: 0 }}
                                >
                                    <Close />
                                </IconButton>
                            )
                        }
                        {...selectProps}
                    >
                        {enableSearch && (
                            <Input
                                onKeyDown={(e) => e.stopPropagation()}
                                ref={searchBarRef}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search..."
                                sx={{ textWrap: "nowrap", position: "sticky", top: 0, zIndex: 2 }}
                                endDecorator={
                                    searchTerm && (
                                        <IconButton
                                            onClick={resetSearchTerm}
                                            variant="soft"
                                            sx={{ borderRadius: "50%", mr: 0 }}
                                        >
                                            <Close />
                                        </IconButton>
                                    )
                                }
                                {...inputProps}
                            />
                        )}

                        {defaultOption && (
                            <Option
                                value={defaultOption.value}
                                style={{ textWrap: "nowrap", position: "sticky", top: 0, zIndex: 1 }}
                                onClick={() => {
                                    handleSelectChange(defaultOption);
                                }}
                            >
                                {defaultOption.label}
                            </Option>
                        )}

                        {enableVirtualization ? (
                            <VirtualizedList
                                containerRef={listboxRef}
                                numItems={filteredOptions.length}
                                itemHeight={itemHeight}
                                component={(props) => {
                                    const { innerContainerStyle, items } = props;
                                    return (
                                        <List
                                            style={{ ...innerContainerStyle, width: listboxRef.current?.scrollWidth }}
                                        >
                                            {items}
                                        </List>
                                    );
                                }}
                                renderItem={({ index, style }) => (
                                    <Option
                                        key={index}
                                        value={filteredOptions[index].value}
                                        style={{
                                            ...style,
                                            textWrap: "nowrap",
                                        }}
                                        onClick={() => {
                                            handleSelectChange(filteredOptions[index]);
                                            resetSearchTerm();
                                        }}
                                    >
                                        {renderOption(filteredOptions[index])}
                                    </Option>
                                )}
                            />
                        ) : (
                            filteredOptions.map((item, index) => (
                                <Option
                                    key={index}
                                    value={item.value}
                                    onClick={() => {
                                        handleSelectChange(item);
                                        resetSearchTerm();
                                    }}
                                >
                                    {renderOption(item)}
                                </Option>
                            ))
                        )}
                    </Select>
                </div>
            </JoyProvider>
        </ReactErrorBoundary>
    );
};

export default VirtualizedSelect;
