import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { fetchBaseQueryWithZod } from "../../utils/api-utils";
import { QUERIES } from "./enums";
import { TAutoPilotStats, TPauseAutoPilotBody, TRestartAutoPilotBody, TTriggerAutoPilotBody } from "./types";

export const autoPilotApi = createApi({
    reducerPath: "autoPilotApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [QUERIES.AUTO_PILOT_STATS, QUERIES.AUTO_PILOT_PREVOIUS_STATS],
    endpoints: (builder) => ({
        getProjectAutopilotData: builder.query<TAutoPilotStats[], number>({
            query: (projectId) => ({
                url: `/searches/autopilot-stats/${projectId}`,
                method: "GET",
            }),
            providesTags: [QUERIES.AUTO_PILOT_STATS],
            transformResponse(baseQueryReturnValue: { data: TAutoPilotStats[] }) {
                return baseQueryReturnValue?.data;
            },
        }),
        getPrevAutoPilotData: builder.query<TAutoPilotStats[], number>({
            query: (projectId) => ({
                url: `/searches/autopilot-stats/${projectId}?limit=${100}`,
                method: "GET",
            }),
            providesTags: [QUERIES.AUTO_PILOT_PREVOIUS_STATS],
            transformResponse(baseQueryReturnValue: { data: TAutoPilotStats[] }) {
                return baseQueryReturnValue?.data;
            },
        }),
        triggerAutoPilot: builder.mutation<any, TTriggerAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/trigger-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
        pauseAutoPilot: builder.mutation<any, TPauseAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/pause-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
        restartAutoPilot: builder.mutation<any, TRestartAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/restart-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
    }),
});

export const {
    useGetProjectAutopilotDataQuery,
    useTriggerAutoPilotMutation,
    usePauseAutoPilotMutation,
    useGetPrevAutoPilotDataQuery,
    useRestartAutoPilotMutation,
} = autoPilotApi;
