import React, { useState } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { IconButton } from "@mui/joy";
import { Stack } from "@mui/material";

import useCopyToClipboard from "../../../../hooks/useCopyToClipboard.hook";

export const CustomBotChatMsg = (props: any) => {
    const [copiedText, copyToClipboard] = useCopyToClipboard(!props.message ? { onSuccessfulCopy() {} } : undefined);
    const [showExtraBtns, setShowExtraBtns] = useState(false);

    const handleCopy = () => {
        copyToClipboard(props?.message?.props?.message as string, "Response copied to clipboard");
    };

    return (
        <Stack direction="column" 
            alignItems="end" 
            spacing={0.5} 
            onMouseEnter= {() => setShowExtraBtns(true)}
            onMouseLeave= {() => setShowExtraBtns(false)}
        >
            <div className="react-chatbot-kit-chat-bot-message">
                {props?.message}

                {showExtraBtns && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="end" sx={{
                        position: "absolute",
                        right: "0",
                        top: "0",
                    }}>
                        <IconButton onClick={handleCopy} sx={{ padding: 0, borderRadius: "0 10px 0 10px", bgcolor: "#f2f2f2", minHeight: "24px", minWidth: "24px" }}>
                            <ContentCopyRoundedIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                    </Stack>
                )}
            </div>
        </Stack>
    );
};
