import { all, fork } from "redux-saga/effects";

import exclusionListSaga from "../../components/ExclusionList/ExclusionList.saga";
import interActiveWalkthorugh from "../../components/InterActiveWalkthroughModal/InterActiveWalkthroughModal.sagas";
import CreateProject from "../../components/Navbar/CreateProject/CreateProject.saga";
import nudgesSaga from "../../components/Nudges/Nudges.sagas";
import appContainerSaga from "../../container/AppContainer/AppContainer.sagas";
import CandidatesSaga from "../../pages/AdvancedFilters/Components/Candidates/Cadidates.saga";
import advancedFilters from "../../pages/AdvancedFilters/Components/Filters/Filters.saga";
import analytics from "../../pages/Analytics/Analytics.saga";
import companyPageSaga from "../../pages/CompanyPage/CompanyPage.saga";
import marketingPageSaga from "../../components/Navbar/CreateProject/MarketingPage/MarketingPage.saga";
import contactListSaga from "../../pages/ContactList/contact-list.saga";
import contactListItemsSaga from "../../pages/ContactListItems/contact-list-items.saga";
import hcChatHistory from "../../pages/HumanCircles/ChatHistory/ChatHistory.saga";
import hcUserList from "../../pages/HumanCircles/UserList/UserList.saga";
import crons from "../../pages/superAdmin/crons/crons.sagas";
import internalRB2B from "../../pages/superAdmin/internalRB2B/internalRB2B.saga";
import signinSaga from "../../pages/Signin/Signin.sagas";
import Support from "../../pages/Support/Support.saga";
import allCandidatesReachoutSaga from "../../pages/all-candidates-reachout/all-candidates-reachout.saga";
import allCandidatesSaga from "../../pages/all-candidates/all-candidates.saga";
import allContactsSaga from "../../pages/all-contacts/all-contacts.saga";
import allProjectSaga from "../../pages/allProjects/index.sagas";
import forgotPasswordSaga from "../../pages/forgotPassword/forgotPassword.saga";
import integrationSaga from "../../pages/integrations/integrations.saga";
import manageTeamsSaga from "../../pages/manageTeams/manageTeams.saga";
import messageSaga from "../../pages/message/message.saga";
import myAccountSaga from "../../pages/myAccount/myAccount.saga";
import exportCandidatesSaga from "../../pages/project/components/exportCandidates/exportCandidates.saga";
import vettingCriteriaSaga from "../../pages/project/components/vettingCriteria/vettingCriteria.saga";
import projectSaga from "../../pages/project/index.saga";
import extensionTrack from "../../pages/superAdmin/extensionTrack/extensionTrack.sagas";
import editAutoSearchSaga from "../../pages/superAdmin/orgList/components/EditAutoSearchModal/editAutoSearch.saga";
import orgList from "../../pages/superAdmin/orgList/orgList.sagas";
import superAdmin from "../../pages/superAdmin/superAdmin.sagas";
import userList from "../../pages/superAdmin/userList/userList.sagas";
import featureList from "../../pages/superAdmin/featureList/featureList.sagas";
import templateSaga from "../../pages/template/index.saga";
import customTemplatesSaga from "../../pages/triggerWorkflow/sagas/customTemplates.saga";
import personalizedWorkflowSagas from "../../pages/triggerWorkflow/sagas/personalizeWorkflow.sagas";
import unsubscribeEmailSaga from "../../pages/unsubscribe/unsubscribe.saga";
import workflowSaga from "../../pages/workflow/index.saga";
import searchSagas from "./search.saga";
import searchCandidatesSaga from "./searchCandidates.saga";
import slackSaga from "./slackSend.saga";
import uploadWorkflowFileSaga from "./workflow-upload.saga";
import eventsSagas from "../../pages/project/components/upcomingEvents/upComingEvents.saga";
import leadRatingSaga from "../../easy-growth/components/LeadRating/LeadRating.saga";

export function* watchSagas() {
    yield all([
        fork(signinSaga),
        fork(allProjectSaga),
        fork(templateSaga),
        fork(workflowSaga),
        fork(projectSaga),
        fork(manageTeamsSaga),
        fork(forgotPasswordSaga),
        fork(appContainerSaga),
        fork(integrationSaga),
        fork(unsubscribeEmailSaga),
        fork(orgList),
        fork(userList),
        fork(featureList),
        fork(myAccountSaga),
        fork(extensionTrack),
        fork(interActiveWalkthorugh),
        fork(superAdmin),
        fork(nudgesSaga),
        fork(slackSaga),
        fork(personalizedWorkflowSagas),
        fork(hcUserList),
        fork(crons),
        fork(internalRB2B),
        fork(hcChatHistory),
        fork(advancedFilters),
        fork(CandidatesSaga),
        fork(CreateProject),
        fork(uploadWorkflowFileSaga),
        fork(exclusionListSaga),
        fork(exportCandidatesSaga),
        fork(companyPageSaga),
        fork(marketingPageSaga),
        fork(customTemplatesSaga),
        fork(contactListSaga),
        fork(contactListItemsSaga),
        fork(allContactsSaga),
        fork(analytics),
        fork(editAutoSearchSaga),
        fork(Support),
        fork(vettingCriteriaSaga),
        fork(searchSagas),
        fork(searchCandidatesSaga),
        fork(allCandidatesReachoutSaga),
        fork(allCandidatesSaga),
        fork(messageSaga),
        fork(eventsSagas),
        fork(leadRatingSaga),
    ]);
}
