import { createChatBotMessage } from "react-chatbot-kit";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";
import Options from "./Options";

const defaultConfig: any = {
    botName: "HQ Bot",
    customStyles: {
        chatButton: { backgroundColor: "#0d7a89" },
    },
    initialMessages: [
        createChatBotMessage("Hi, I am your Copilot. I can perform any actions for you. Just ask!", {
            widget: "initial-message-options",
        }),
    ],
    widgets: [
        //@ts-ignore
        {
            widgetName: "initial-message-options",
            widgetFunc: (props: any) => <Options {...props} />,
            props: {
                options: [
                    { text: "Mark all 3 star candidates as SHORTLISTED in this project", handler: "handleOption", id: 1 },
                    { text: "Delete all candidates from this project who have worked at Microsoft before", handler: "handleOption", id: 2 },
                    { text: "Reach out to all shortlisted candidates with email and follow-up messages from my campaign", handler: "handleOption", id: 3 },
                ],
                type: "initial-message-options"
            },
        },
        {
            widgetName: "user-confirmation",
            widgetFunc: (props: any) => <Options {...props} />, 
            props: {
                options: [
                    { text: "Yes", handler: "handleOption", id: 1 },
                    { text: "No", handler: "handleOption", id: 2 },
                ],
                type: "user-confirmation"
            },
        }
    ],
};

export const getConfig = (config: IConfig) => config;

export default defaultConfig;
