import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store/Store";
import { IActionPayload } from "../../../store/reducers/loaders.reducer";
import { IGetUserListPayload, IUser } from "./UserList.type";

const initialState = Object.freeze({
    users: [],
    total: 0,
    totalProjectsCreated: 0,
    totalCandidatesScraped: 0,
    totalEmailsExtracted: 0,
    totalWorkflowsTriggered: 0,
    sortBy: "createdAt",
});

const reducers = {
    getHcUserList(state: any, { payload }: { payload: IGetUserListPayload & IActionPayload }) {
        if (payload.sortBy) state.sortBy = payload.sortBy;
    },
    setHcUserList(state: any, { payload }: { payload: IUser[] }) {
        state.users = payload;
    },
    setTotalUserCount(state: any, { payload }: { payload: number }) {
        state.total = payload;
    },

    leavePage(state: any) {},
};

export const page = createSlice({
    name: "HcUserList",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const { getHcUserList, setHcUserList, leavePage, setTotalUserCount } = page.actions;

export const selectHCUsers = (state: RootState) => state.hcUserList.users;
export const selectTotalUsers = (state: RootState) => state.hcUserList.total;
export const selectSortBy = (state: RootState) => state.userList.sortBy;
