import { useEffect, useState } from "react";
import PrecisionManufacturingRoundedIcon from "@mui/icons-material/PrecisionManufacturingRounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import { Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CustomTab, CustomTabsContainer } from "../CustomTabs";
import { ProjectMode } from "../../pages/allProjects/allProjects.types";
import {
    selectProjectAutoSearchStatus,
    setAutoSearchStatus,
    submitAutoSearchStatus,
} from "../../pages/allProjects/index.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import TextLoading from "../TextLoading";

type IconWithTextProps = {
    text: string;
    children: React.ReactNode;
};

function IconWithText({ children, text }: IconWithTextProps) {
    return (
        <Stack alignItems="center" direction="row" spacing={0.5}>
            {children}
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                {text}
            </Typography>
        </Stack>
    );
}

export default function AutoPilotSwitch() {
    const { t } = useTranslation();
    const [showLongMessage, setShowLongMessage] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const isAutoSourceEnabled = useSelector(selectProjectAutoSearchStatus);
    const isUpdatingStatus = useSelector(checkIfLoading(submitAutoSearchStatus.type));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (isUpdatingStatus) {
            return;
        }

        const newStatus = newValue === 0 ? false : true;
        dispatch(setAutoSearchStatus({ status: newStatus, project: params?.id }));
        dispatch(submitAutoSearchStatus({ status: newStatus, project: params?.id }));
    };

    useEffect(() => {
        if (isAutoSourceEnabled) {
            setShowLongMessage(true);
            setTimeout(() => {
                setShowLongMessage(false);
            }, 5000);
        }
    }, [isAutoSourceEnabled]);

    const projectMode: ProjectMode = isAutoSourceEnabled ? "AUTO_PILOT" : "MANUAL";

    const value = projectMode === "MANUAL" ? 0 : 1;
    const showAlert = projectMode === "AUTO_PILOT" && !isUpdatingStatus;
    return (
        <Stack direction="row" spacing={1}>
            <CustomTabsContainer
                value={value}
                onChange={handleChange}
                sx={{
                    borderRadius: "2.5rem",
                    height: "fit-content",
                    minHeight: "fit-content",
                }}
            >
                <CustomTab
                    label={
                        <IconWithText text="Manual">
                            <TouchAppRoundedIcon fontSize="small" />
                        </IconWithText>
                    }
                    sx={{
                        padding: "0 0.5rem",
                        height: "fit-content",
                        minHeight: "33px",
                        margin: "5px",
                        borderRadius: "2.5rem",
                    }}
                />
                <CustomTab
                    label={
                        <IconWithText text="Auto Pilot">
                            <PrecisionManufacturingRoundedIcon fontSize="small" />
                        </IconWithText>
                    }
                    sx={{
                        padding: "0 0.5rem",
                        height: "fit-content",
                        minHeight: "33px",
                        margin: "5px",
                        borderRadius: "2.5rem",
                    }}
                />
            </CustomTabsContainer>
            {showAlert && (
                <Alert
                    icon={false}
                    severity="info"
                    sx={(theme) => ({
                        padding: theme.spacing(1 / 2),
                        paddingX: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                    })}
                >
                    {showLongMessage
                        ? `Your ${t(
                              "project"
                          )} is now on autopilot. We recommend that you also inform your Account Manager`
                        : `Your ${t("project")} is currently on autopilot`}
                </Alert>
            )}
            {isUpdatingStatus && <TextLoading text="Updating..." />}
        </Stack>
    );
}
