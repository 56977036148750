import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { IProject } from "../../../pages/project/project.types";
import { fetchBaseQueryWithZod } from "../../../utils/api-utils";
import { Queries } from "./enums";

export type TContactType = "email" | "phone";

export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [Queries.PROJECTS, Queries.CANDIDATE_SOURCE_TYPES, Queries.CANDIDATE_OUTREACH_TIMESTAMPS],
    endpoints: (builder) => ({
        getProjects: builder.query<IProject[], void | number>({
            query: (userId) => ({
                url: `v2/project/list${userId ? `?userId=${userId}` : ""}`,
                method: "GET",
            }),
            providesTags: [Queries.PROJECTS],
            transformResponse(baseQueryReturnValue: { data: IProject[] }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCandidateSourceTypes: builder.query<
            any,
            {
                profileUrl: string;
                data: {
                    contactType: TContactType;
                    value: string;
                }[];
            }
        >({
            query: (payload) => ({
                url: `v2/project/get/source-type`,
                method: "POST",
                body: payload,
            }),
            providesTags: [Queries.CANDIDATE_SOURCE_TYPES],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        getOutreachTimestamps: builder.query<
            any,
            {
                candidateId: string;
                projectId: number;
                bodyOutreachStatus: any
            }
        >({
            query: (payload) => ({
                url: `v2/project/get/outreach-timestamps`,
                method: "POST",
                body: payload,
            }),
            providesTags: [Queries.CANDIDATE_OUTREACH_TIMESTAMPS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
    }),
});

export const { useGetProjectsQuery, useGetCandidateSourceTypesQuery, useGetOutreachTimestampsQuery } = projectsApi;
