import { useEffect, useRef, useState } from "react";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { BlobProvider, pdf } from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useMenu from "../../../../hooks/useMenu";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    CSV_DATA_KEYS_ADMIN,
    CSV_DATA_KEYS_USER,
    CSV_HEADERS_ARRAY_ADMIN,
    CSV_HEADERS_ARRAY_USER,
} from "../../../../utils/Constants";
import { checkEasySource, isSuperAdminUser } from "../../../Signin/Signin.reducer";
import { selectProjectAllCandidates, selectSelectedCandidates } from "../../index.reducer";
import { fetchVettingCriteria, selectVettingCriteria } from "../vettingCriteria/vettingCriteria.slice";
import { EXPORT_MODES, exportCsv, exportPdf, selectCsvData, selectPdfData } from "./exportCandidates.reducer";
import PDFTemplateExtended from "./morePdfTemplates/pdfDataTemplateExtended";
import PDFTemplate from "./pdfDataTemplate";

const RenderPdf = ({ url, projectId, isExtended, loading, setIsPdfRendering, onComplete }: any) => {
    const pdfLink = useRef<HTMLAnchorElement | null>(null);

    useEffect(() => {
        setIsPdfRendering(loading);
    }, [loading]);

    useEffect(() => {
        if(pdfLink?.current) {
            pdfLink?.current?.click();
            // clear ref once download and unmount 
            onComplete();
            pdfLink.current = null
        }
    }, [pdfLink?.current]);

    if (!url || loading) return null;

    return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
            ref={pdfLink}
            href={url}
            style={{ display: "none" }}
            download={`Project id ${projectId} top vetted candidates${isExtended ? "(Extended)" : ""}`}
            target="_blank"
            rel="noreferrer"
        />
    );
};

type ExportCandidatesProps = {
    projectId: string;
};

export default function ExportCandidates({ projectId }: ExportCandidatesProps) {
    const { t } = useTranslation();
    const isEasySource = useSelector(checkEasySource);
    const csvLink = useRef<HTMLAnchorElement | null>(null);
    const dispatch = useDispatch();
    const csvData = useSelector(selectCsvData);
    const vettingCriteria = useSelector(selectVettingCriteria);
    const pdfData = useSelector(selectPdfData);
    const csvLoading = useSelector(checkIfLoading(exportCsv.type));
    const pdfLoading = useSelector(checkIfLoading(exportPdf.type));
    const vettingCriteriaLoading = useSelector(checkIfLoading(fetchVettingCriteria.type));
    const [mountPdf, setMountPdf] = useState(false);
    const [isExtended, setIsExtended] = useState(false);
    const [isPdfRendering, setIsPdfRendering] = useState(false);
    const { handleClose, anchorEl, open, handleClick, menuSX } = useMenu();

    const candidatesSelected = useSelector(selectSelectedCandidates).length > 0;
    const isAllCandidatesSelected = useSelector(selectProjectAllCandidates);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isCandidatesSelected = candidatesSelected || isAllCandidatesSelected;
    const isLoading = csvLoading || pdfLoading || vettingCriteriaLoading || isPdfRendering;

    const getNestedData = (keys: string[], data: any) => {
        let nestedValue = data;
        if (!nestedValue) return "";
        for (const key of keys) {
            if (nestedValue[key] !== undefined && nestedValue[key] !== null) {
                nestedValue = nestedValue[key];
            } else {
                return "";
            }
        }
        return nestedValue;
    };
    const formatDataForCSV = () => {
        let newCsvData = [...csvData];
        const data = newCsvData.map((row: any) => {
            return (isSuperAdmin ? CSV_DATA_KEYS_ADMIN : CSV_DATA_KEYS_USER).map((key) => {
                let value: string | number | string[] | number[] = "";
                if (typeof key === "string") {
                    value = row[key] ?? value;
                    if (typeof value === "string") {
                        value = value?.replace(/"/g, '""');
                    } else if (Array.isArray(value)) {
                        value = value?.join(" ")?.replace(/"/g, '""');
                    }
                } else {
                    value = getNestedData(key, row);
                }
                return value;
            });
        });
        return [isSuperAdmin ? CSV_HEADERS_ARRAY_ADMIN : CSV_HEADERS_ARRAY_USER, ...data];
    };

    const handleMenuItemClick = (mode: EXPORT_MODES) => () => {
        dispatch(
            exportCsv({
                projectId,
                mode,
                onSuccess: () => {
                    setTimeout(() => {
                        (csvLink?.current as any)?.link?.click();
                    }, 100);
                },
            })
        );
        handleClose();
    };

    const generateTopCandidateReport = (extended: boolean) => {
        if (!isExtended && extended) setIsExtended(true);
        if (isExtended && !extended) setIsExtended(false);

        dispatch(fetchVettingCriteria({ id: projectId }));
        dispatch(
            exportPdf({
                projectId,
                onSuccess: async () => {
                    setMountPdf(true);
                },
            })
        );
        handleClose();
    };

    return (
        <>
            <Tooltip title={t("projectsPage.candidateSection.exportCandidates")} placement="top">
                <IconButton disabled={isLoading} onClick={handleClick}>
                    {isLoading ? <CircularProgress size={24} /> : <FileDownloadRoundedIcon />}
                </IconButton>
            </Tooltip>
            <Menu
                sx={menuSX}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleMenuItemClick("ALL_CANDIDATES")}>
                    {t("projectsPage.export.exportAllCandidatesLabel")}
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick("FILTERED_CANDIDATES")}>
                    {t("projectsPage.export.exportFilteredCandidatesLabel")}
                </MenuItem>
                {!isEasySource && (
                    <MenuItem onClick={handleMenuItemClick("EXPORT_WITH_ACCOUNT_INFO")}>
                        {t("projectsPage.export.exportWithAccountInfoLabel")}
                    </MenuItem>
                )}
                {isCandidatesSelected ? (
                    <>
                        <MenuItem onClick={handleMenuItemClick("SELECTED_CANDIDATES")}>
                            {t("projectsPage.export.exportSelectedCandidatesCSVLabel")}
                        </MenuItem>
                        {isEasySource && (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        generateTopCandidateReport(false);
                                    }}
                                >
                                    {t("projectsPage.export.exportSelectedCandidatesPDFLabel")}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        generateTopCandidateReport(true);
                                    }}
                                >
                                    {t("projectsPage.export.exportSelectedCandidatesPDFLabelExtnded")}
                                </MenuItem>
                            </>
                        )}
                    </>
                ) : null}
            </Menu>
            <CSVLink
                filename={`project_${projectId}_${t("common")}.csv`}
                data={formatDataForCSV()}
                style={{ display: "none" }}
                ref={csvLink as any}
                target="_blank"
            />
            {mountPdf && (
                <BlobProvider
                    document={
                        isExtended ? (
                            <PDFTemplateExtended vettingCriteria={vettingCriteria} candidatesData={pdfData} />
                        ) : (
                            <PDFTemplate vettingCriteria={vettingCriteria} candidatesData={pdfData} />
                        )
                    }
                >
                    {({ loading, url }) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <RenderPdf
                                url={url}
                                loading={loading}
                                projectId={projectId}
                                isExtended={isExtended}
                                setIsPdfRendering={setIsPdfRendering}
                                onComplete={() => {
                                    setMountPdf(false);
                                }}
                            />
                        );
                    }}
                </BlobProvider>
            )}
        </>
    );
}
