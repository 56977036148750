import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import AdbIcon from "@mui/icons-material/Adb";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Call from "@mui/icons-material/Call";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import DvrRoundedIcon from "@mui/icons-material/DvrRounded";
import HubIcon from "@mui/icons-material/Hub";
import InboxIcon from "@mui/icons-material/Inbox";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import PersonIcon from "@mui/icons-material/Person";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SupportIcon from "@mui/icons-material/Support";
import SwitchAccountRoundedIcon from "@mui/icons-material/SwitchAccountRounded";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/img/hq-logo.svg";
import { RequireOnly } from "../../common";
import { easyGrowthLogo, easyGrowthSymbol, hqInitialsLogo } from "../../constant/Constant";
import {
    checkAnalyticsVersion,
    checkBlendedSearchVersion,
    checkEasyGrowth,
    checkEasySource,
    checkIfAccountRB2B,
    checkInboxVersion,
    checkLinkedinOutreachEnabled,
    selectUser,
} from "../../pages/Signin/Signin.reducer";
import { selectIsSuperAdminView, selectUserDetails } from "../../pages/superAdmin/superAdmin.reducer";
import { useAppDispatch } from "../../store/Store";
import { resetSearchState } from "../../store/reducers/search/search.slice";
import { resetSearchCandidatesState } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import handleCleverTap from "../../utils/clevertap";
import { JoyProvider } from "../JoyProvider";
import NAEmailAlert from "../NAEmailAlert/NAEmailAlert";
import { setCanShowAddProject, setCanShowIntegratedProjectModal } from "../Navbar/CreateProject/CreateProject.reducer";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import style from "./sidebar.module.scss";
import OrgsDropdown from "./orgsDropdown";
import { BDSidebarItem, ESSidebarItem, SidebarItem, SidebarItemProps, useMinimizeSidebar } from "./SidebarItem";

function RenderLogoBox({ minimize }: { minimize: boolean }) {
    const isEasySource = useSelector(checkEasySource);
    const textLogo = isEasySource ? logo : easyGrowthLogo;
    const symbolLogo = isEasySource ? hqInitialsLogo : easyGrowthSymbol;
    return (
        <div
            className={style["sidebar__logobox"]}
            style={{
                padding: "1rem",
                justifyContent: minimize ? "center" : "initial",
                height: "80px",
            }}
        >
            {minimize ? (
                <Link to="/">
                    <img
                        src={symbolLogo}
                        alt="hq-logo"
                        className={style["sidebar__logo-small"]}
                        height={100}
                        width={100}
                    />
                </Link>
            ) : (
                <Link to="/">
                    <img src={textLogo} alt="hq-logo" style={{ width: "80%" }} height={80} width={80} />
                </Link>
            )}
        </div>
    );
}

function UserInformation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const userDetails = useSelector(selectUserDetails);
    const isBlendedSearchEnabled = useSelector(checkBlendedSearchVersion);
    const newUser = isSuperAdminView ? userDetails : user;
    const { minimize } = useMinimizeSidebar();
    const isRB2BAccount = useSelector(checkIfAccountRB2B);

    const handleCreateNewProject = () => {
        const url = isEasyGrowth ? "/generate-prospects?name=" : "/search?name=";
        navigate(url);
        dispatch(resetSearchState());
        dispatch(resetSearchCandidatesState());
    };

    const openIntegratedProjectModal = () => {
        if (isEasyGrowth) {
            handleCreateNewProject();
        } else {
            dispatch(setCanShowIntegratedProjectModal(true));
        }
    };

    return (
        <Stack mt={2} px={2.5} spacing={2}>
            <Stack direction="row" alignItems="center">
                <ProfileAvatar />
                {!minimize ? (
                    <Stack>
                        <p className={style["sidebar__userdetails"]}>{newUser?.displayName}</p>
                        {user.isSuperAdmin && (
                            <div style={{ width: "200px", paddingRight: "0.5rem" }}>
                                <OrgsDropdown />
                            </div>
                        )}
                    </Stack>
                ) : null}
            </Stack>

            {!isRB2BAccount && (user?.isSuperAdmin || isBlendedSearchEnabled || !isEasyGrowth) ? (
                minimize ? (
                    <Tooltip title="Create new project" placement="right" onClick={handleCreateNewProject}>
                        <IconButton color="primary">
                            <AddCircleRoundedIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Button
                        id="cy-create-new-project-button"
                        variant="outlined"
                        onClick={openIntegratedProjectModal}
                        size="sm"
                        sx={{ color: "#479BD2" }}
                    >
                        {t("sidebar.createNewProject")}
                    </Button>
                )
            ) : null}
        </Stack>
    );
}

export type SidebarProps = {
    sidebarStyleVariant?: "default" | "sharp";
};

export default function Sidebar({ sidebarStyleVariant = "default" }: SidebarProps) {
    const isRB2BAccount = useSelector(checkIfAccountRB2B);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const user = useSelector(selectUser);
    const analyticsVersion = useSelector(checkAnalyticsVersion);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const linkeidnOutreachEnabled = useSelector(checkLinkedinOutreachEnabled);
    const isInboxEnabled = useSelector(checkInboxVersion) || !isEasyGrowth;
    const { minimize } = useMinimizeSidebar();

    const clevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    const handleCleverTapEvent = (event: string) => () => {
        clevertapEvent(event);
    };

    const openCreateProjectModal = () => dispatch(setCanShowAddProject(true));

    function getSidebarItemProps(
        item: RequireOnly<SidebarItemProps, "icon" | "link" | "text" | "isNewFeature">
    ): SidebarItemProps {
        const { icon, link, text, isNewFeature } = item;

        return {
            icon,
            link,
            text,
            onClick: handleCleverTapEvent(item.text),
            isNewFeature,
        };
    }

    return (
        <JoyProvider>
            <div
                className={`${style["sidebar"]} ${sidebarStyleVariant === "sharp" ? style["sidebar__sharp"] : null} ${
                    minimize && style["sidebar--minimize"]
                }`}
            >
                <RenderLogoBox minimize={minimize} />
                <UserInformation />
                <Stack
                    my={2}
                    mx={0.5}
                    spacing={1.8}
                    pl={1}
                    pr={0.5}
                    overflow="auto"
                    sx={{
                        maxHeight: "100vh",
                        "&::-webkit-scrollbar": {
                            width: "4px",
                            display: "none",
                        },
                        "&:hover": {
                            paddingRight: "0.5px",
                            "&::-webkit-scrollbar": {
                                display: "block",
                                width: "4px",
                            },
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent",
                        },
                        "&::-webkit-scrollbar-track:hover": {
                            background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888",
                            borderRadius: "6px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#666",
                        },
                    }}
                >
                    <List size="md">
                        <Stack
                            display="grid"
                            gridTemplateColumns={minimize ? "1fr" : "1fr auto"}
                            gridTemplateRows={minimize ? "1fr auto" : "1fr"}
                            alignItems="center"
                        >
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: isEasyGrowth ? "Build Your Campaign" : t("sidebar.allProjects"),
                                    icon: <DnsOutlinedIcon />,
                                    link: "/",
                                })}
                            />
                            {!isRB2BAccount && isEasyGrowth && (
                                <Tooltip title={t("sidebar.createProject")} placement={minimize ? "right" : "top"}>
                                    <IconButton
                                        sx={{ padding: 0, mr: 1, minHeight: "16px", minWidth: "16px" }}
                                        onClick={openCreateProjectModal}
                                        size="sm"
                                    >
                                        <AddCircleRoundedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                        {!isRB2BAccount && (
                            <BDSidebarItem
                                egProps={getSidebarItemProps({
                                    text: "Search Prospects",
                                    icon: <PersonSearchIcon />,
                                    link: "/generate-prospects",
                                })}
                            />
                        )}
                        {isInboxEnabled && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: "Super Inbox",
                                    icon: <InboxIcon />,
                                    link: "/inbox",
                                })}
                            />
                        )}
                        {user?.twilioPhoneNumber && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: "Phone",
                                    icon: <Call />,
                                    link: "/call-logs",
                                })}
                            />
                        )}

                        {user?.isSuperAdmin && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: `Search ${t("sidebar.candidate")}`,
                                    icon: <PersonSearchIcon />,
                                    link: "/advanced-filter",
                                })}
                            />
                        )}
                        <ESSidebarItem
                            esProps={getSidebarItemProps({
                                text: t("INTEGRATIONS"),
                                icon: <AccountTreeRoundedIcon />,
                                link: "/integrations",
                                isNewFeature: true,
                            })}
                        />
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: t("sidebar.contactOverview"),
                                icon: <PeopleOutlineRoundedIcon />,
                                link: isEasyGrowth ? "/manage-prospects/activities" : "/contact-overview/activities",
                            })}
                        />

                        {(linkeidnOutreachEnabled || user?.isSuperAdmin) && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: "LinkedIn Workflow",
                                    icon: <AlignHorizontalLeftIcon />,
                                    link: "/linkedin-workflow",
                                })}
                            />
                        )}
                        {(user?.isSuperAdmin || analyticsVersion) && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("ANALYTICS"),
                                    icon: <QueryStatsRoundedIcon />,
                                    link: "/analytics",
                                })}
                            />
                        )}
                        {user?.isSuperAdmin && (
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: "Create user",
                                    icon: <PersonIcon />,
                                    link: "/superAdmin/create-user",
                                })}
                            />
                        )}
                        {user?.isSuperAdmin && !isSuperAdminView && (
                            <>
                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: "Internal RB2B",
                                        icon: <AdbIcon />,
                                        link: "/superAdmin/internalRB2b",
                                    })}
                                />

                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("ORG_LIST"),
                                        icon: <DvrRoundedIcon />,
                                        link: "/superAdmin/orgList",
                                    })}
                                />
                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("ORG_TRACKER"),
                                        icon: <PageviewRoundedIcon />,
                                        link: "/superAdmin/orgTracker",
                                    })}
                                />

                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("USER_LIST"),
                                        icon: <SwitchAccountRoundedIcon />,
                                        link: "/superAdmin/userList",
                                    })}
                                />

                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("CRONS"),
                                        icon: <AssignmentTurnedInIcon />,
                                        link: "/superAdmin/crons",
                                    })}
                                />
                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("FEATURE_LIST"),
                                        icon: <HubIcon />,
                                        link: "/superAdmin/featureList",
                                    })}
                                />
                                {/* <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("FUNNEL_MOVEMENT"),
                                        icon: <MapRoundedIcon />,
                                        link: "/superAdmin/funnel-movement",
                                    })}
                                /> */}
                                {/* <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("HC_USER_LIST"),
                                        icon: <FeaturedPlayListRoundedIcon />,
                                        link: "/humanCircles/userList",
                                    })}
                                /> */}
                                {/* <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("SUPPORT_TICKETS"),
                                        icon: <SupportIcon />,
                                        link: "/support",
                                    })}
                                /> */}
                            </>
                        )}
                        {(user?.isSuperAdmin || user?.isBDUser) && (
                            <>
                                {/* <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("sidebar.contactList"),
                                        icon: <ImportContactsIcon />,
                                        link: "/contact-lists",
                                    })}
                                />
                                <SidebarItem
                                    {...getSidebarItemProps({
                                        text: t("sidebar.allContacts"),
                                        icon: <ContactsRoundedIcon />,
                                        link: "/all-contacts",
                                    })}
                                /> */}
                            </>
                        )}
                    </List>
                </Stack>
                <div className={style["sidebar__footer"]}>
                    <Experimental_CssVarsProvider>
                        <NAEmailAlert />
                    </Experimental_CssVarsProvider>
                    {/* <ExtensionBtn /> */}
                    {/* <CreditsPanel /> */}
                    {/* <div className={style["sidebar__copyBox"]}>
                    All rights reserved &copy; &nbsp;{" "}
                    <img
                        className={style["sidebar__copyImg"]}
                        src={hqLogo}
                        alt="hq-logo"
                    />
                </div> */}
                </div>
            </div>
        </JoyProvider>
    );
}
