import { Menu, MenuProps, Tooltip, IconButton, MenuItem } from "@mui/material";

import useMenu from "../../hooks/useMenu";

export type CustomDropdownOptionsType = {
    label: React.ReactNode;
    key: string;
    handleClick?: () => void;
    optionIcon?: React.ReactNode;
}

interface CustomDropdownProps extends Omit<MenuProps, 'open'> {
    children?: React.ReactNode;
    options: CustomDropdownOptionsType[];
    tooltipTitle?: string;
}

export default function CustomDropdown({ children, options = [], tooltipTitle = '', ...props }: CustomDropdownProps) {
    const { menuSX, open, anchorEl, handleClose, handleClick } = useMenu();
    return (
        <>
            <Tooltip title={tooltipTitle}>
                <IconButton
                    sx={{
                        alignSelf: "flex-end",
                        fontSize: "1rem",
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                    }}
                    disableRipple
                >
                    {children}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                {...props}
            >
                {options.map((item) => (
                    <MenuItem 
                        key={item?.key || `menu-item-${Math.random()}`} 
                        onClick={() => item?.handleClick?.()}
                        sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                        }}
                    >
                        {item?.optionIcon}
                        {item?.label || 'Unnamed Option'}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}