import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/joy/Button";
import { CircularProgress, Grid, IconButton, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import VettingCriteriaModal from "../../../pages/project/components/vettingCriteria/VettingCriteriaModal";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { resetSearchState } from "../../../store/reducers/search/search.slice";
import {
    resetSearchCandidatesState,
    setProjectPurpose,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { JoyProvider } from "../../JoyProvider";
import {
    createProject,
    resetState,
    selectCanShowIntegratedProjectModal,
    setCanShowIntegratedProjectModal,
    setProjectName,
    setPurpose,
    setVettingCriteriaText,
} from "./CreateProject.reducer";

export const IntegratedCreateProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canShowOpenIntegratedProjectModal = useSelector(selectCanShowIntegratedProjectModal);
    const [loading, setLoading] = useState<boolean>(false);
    const createProjectLoading = useSelector(checkIfLoading(createProject.type));

    type OptionType = "aiSourcing" | "importScreen";

    const [selectedOption, setSelectedOption] = useState<{ aiSourcing: boolean; importScreen: boolean }>({
        aiSourcing: false,
        importScreen: false,
    });

    const handleOptionClick = (option: OptionType) => {
        setSelectedOption((prev) => ({
            ...prev,
            [option]: !prev[option],
        }));
    };

    const [name, setName] = useState<string>("");

    const handleProceed = () => {
        const { aiSourcing, importScreen } = selectedOption;
        if (aiSourcing && importScreen) {
            navigate(`/search?name=${name}`);
            dispatch(resetState());
            dispatch(resetSearchState());
            dispatch(resetSearchCandidatesState());
            dispatch(setProjectPurpose("BOTH"));
        } else if (aiSourcing) {
            navigate(`/search?name=${name}`);
            dispatch(resetState());
            dispatch(resetSearchState());
            dispatch(resetSearchCandidatesState());
            dispatch(setProjectPurpose("AI_SOURCING"));
        } else if (importScreen) {
            dispatch(setProjectName(name));
            dispatch(setPurpose("IMPORT_CANDIDATES"));
            dispatch(setVettingCriteriaText(true));
            dispatch(
                createProject({
                    action: createProject.type,
                    navigate,
                })
            );
        }
    };

    return (
        <Dialog onClose={() => dispatch(resetState())} open={canShowOpenIntegratedProjectModal}>
            <DialogTitle sx={{ textAlign: "center", marginBottom: "18px", marginTop: "25px" }}>
                Select primary purpose of your project
                <IconButton
                    aria-label="close"
                    onClick={() => dispatch(resetState())}
                    disabled={createProjectLoading}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "grey",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ color: "darkgray", fontStyle: "italic" }}>
                    Don't worry, you can change this later
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginBottom: "20px", marginLeft: "10px", marginRight: "10px" }}>
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item>
                        <Paper
                            elevation={3}
                            onClick={() => handleOptionClick("aiSourcing")}
                            sx={{
                                padding: "16px",
                                cursor: "pointer",
                                border: selectedOption.aiSourcing ? "2px solid #479cd2" : "2px solid transparent",
                                textAlign: "center",
                                width: "250px",
                                // boxShadow:"none",
                                // border:"1px solid lightgray",
                            }}
                        >
                            <Typography
                                id="cy-create-project-AI-sourcing-text"
                                variant="h6"
                                sx={{ marginBottom: "15px" }}
                            >
                                AI Sourcing
                            </Typography>
                            <Typography variant="body2">
                                Find and screen highly relevant candidates to reach out using EasySource
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={3}
                            onClick={() => handleOptionClick("importScreen")}
                            sx={{
                                padding: "16px",
                                cursor: "pointer",
                                border: selectedOption.importScreen ? "2px solid #479cd2" : "2px solid transparent",
                                textAlign: "center",
                                width: "250px",
                            }}
                        >
                            <Typography variant="h6" sx={{ marginBottom: "15px" }}>
                                Import Candidates
                            </Typography>
                            <Typography variant="body2">
                                Screen through and enrich your existing candidate pool to reach out
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ marginBottom: "20px" }}>
                <TextField
                    inputProps={{ id: "cy-create-project-project-name-input" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter project name"
                    variant="outlined"
                    size="small"
                    sx={{ marginLeft: "10px", width: "420px", text: "5px" }}
                />
                <JoyProvider>
                    <Button
                        id="cy-create-project-proceed-button"
                        variant="solid"
                        onClick={handleProceed}
                        sx={{ marginRight: "30px", backgroundColor: "#0c6bcc", width: "85px" }}
                        disabled={(!selectedOption.aiSourcing && !selectedOption.importScreen) || !name}
                    >
                        {createProjectLoading ? <CircularProgress size={24} /> : "Proceed"}
                    </Button>
                </JoyProvider>
            </DialogActions>
        </Dialog>
    );
};
