import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MRT_PaginationState } from "material-react-table";

import { RootState } from "../../../../store/Store";
import { allCandidatesApi } from "../../../../store/reducers/all-candidates.api.slice";
import { objectEntries, objectKeys } from "../../../../utils/helper";
import {
    CompletedByKeys,
    ContactOverviewActivitiesSortState,
    ContactOverviewActivitiesSortStateKeys,
    ContactOverviewActivitiesState,
    ContactOverviewActivitiesStateFilters,
    ContactOverviewActivitiesStateFiltersKeys,
    FilterKeysFromFetchContactOverviewActivitiesPayload,
    SubFilterKey,
} from "./ContactOverviewActivities.types";

const initialState: ContactOverviewActivitiesState = {
    pagination: {
        pageIndex: 0,
        pageSize: 25,
    },
    filters: {
        "activity Types": {},
        assignee: {},
        priority: {
            HIGH: false,
            MEDIUM: false,
            LOW: false,
        },
        due: {
            "in 24 hours": false,
            "in 3 days": false,
            "in 7 days": false,
            "past due": false,
        },
        "completion Status": {
            completed: false,
            "to be completed": false,
        },
    },
    sort: {
        "created Date": false,
        "due Date": false,
        priority: false,
        schedule: false,
    },
    searchQuery: "",
};

const contactOverviewActivitiesSlice = createSlice({
    initialState,
    name: "contactOverviewActivities",
    reducers: {
        setActivityFilter(
            state,
            action: PayloadAction<{
                value: boolean;
                filterKey: ContactOverviewActivitiesStateFiltersKeys;
                subFilterKey: SubFilterKey;
            }>
        ) {
            const { filterKey, subFilterKey, value } = action.payload;
            const filter = state.filters[filterKey];
            if (subFilterKey in filter) {
                if (filterKey === "completion Status") {
                    if (subFilterKey === "to be completed") {
                        state.filters["completion Status"]["to be completed"] = value;
                        state.filters["completion Status"].completed = false;
                    }

                    if (subFilterKey === "completed") {
                        state.filters["completion Status"].completed = value;
                        state.filters["completion Status"]["to be completed"] = false;
                    }
                }

                if (filterKey === "due") {
                    Object.keys(filter).forEach((key) => {
                        const value = key as CompletedByKeys;
                        (filter as ContactOverviewActivitiesStateFilters["due"])[value] = false;
                    });
                }

                (filter[subFilterKey] as unknown) = value;
            }
        },
        resetActivityFilters(state) {
            objectKeys(state.filters).forEach((key) => {
                objectKeys(state.filters[key]).forEach((subKey) => {
                    if (subKey in state.filters[key]) {
                        state.filters[key][subKey] = false as never;
                    }
                });
            });
        },
        setActivitySortValues(
            state,
            action: PayloadAction<{
                key: ContactOverviewActivitiesSortStateKeys;
            }>
        ) {
            const { key } = action.payload;
            const sortValues = state.sort;
            objectKeys<ContactOverviewActivitiesSortState>(sortValues).forEach((sortKey) => {
                if (sortKey === key) {
                    sortValues[sortKey] = !sortValues[sortKey];
                } else {
                    sortValues[sortKey] = false;
                }
            });
        },
        setSearchQuery(state, action: PayloadAction<string>) {
            state.searchQuery = action.payload;
        },
        changePagination(state, action: PayloadAction<MRT_PaginationState>) {
            state.pagination = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(allCandidatesApi.endpoints.fetchActivityTags.matchFulfilled, (state, action) => {
            const activityTags = action.payload;

            activityTags.forEach((activityTag) => {
                const value = state.filters["activity Types"]?.[activityTag] || false;
                state.filters["activity Types"][activityTag] = value;
            });
        });
        builder.addMatcher(allCandidatesApi.endpoints.fetchOrganizationMembers.matchFulfilled, (state, action) => {
            const organizationMembers = action.payload;
            state.filters.assignee = organizationMembers?.reduce(
                (acc: ContactOverviewActivitiesStateFilters["assignee"], member) => {
                    const value = state.filters.assignee?.[member._id] || false;
                    acc[member._id] = value;

                    return acc;
                },
                {}
            );
        });
    },
});

export default contactOverviewActivitiesSlice.reducer;

export const { setActivityFilter, setActivitySortValues, setSearchQuery, changePagination, resetActivityFilters } =
    contactOverviewActivitiesSlice.actions;

export const selectContactOverviewActivitiesFilters = (state: RootState) => state.contactOverviewActivities.filters;

export const selectContactOverviewActivitiesSortValues = (state: RootState) => state.contactOverviewActivities.sort;

export const selectAppliedValues = (state: RootState) => {
    const filters = selectContactOverviewActivitiesFilters(state);
    const sortValues = selectContactOverviewActivitiesSortValues(state);

    const initialFilters: Partial<Record<FilterKeysFromFetchContactOverviewActivitiesPayload, string[]>> = {};

    const appliedFilters = objectKeys<ContactOverviewActivitiesStateFilters>(filters)?.reduce((acc, crr) => {
        const currentKey = crr.replace(" ", "") as FilterKeysFromFetchContactOverviewActivitiesPayload;
        const subFilters = filters[crr as ContactOverviewActivitiesStateFiltersKeys];

        acc[currentKey] = objectEntries(subFilters)?.reduce((acc: string[], [key, value]) => {
            if (value) {
                acc.push(key);
            }

            return acc;
        }, []);

        return acc;
    }, initialFilters);

    const appliedSortValues = Object.entries(sortValues)
        .filter(([_, value]) => value)
        .map(([key]) => key) as ContactOverviewActivitiesSortStateKeys[];

    return { filters: appliedFilters, sort: appliedSortValues };
};

export const selectSearchQuery = (state: RootState) => state.contactOverviewActivities.searchQuery;

export const selectContactOverviewActivitiesPagination = (state: RootState) =>
    state.contactOverviewActivities.pagination;
