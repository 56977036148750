import { createSlice } from "@reduxjs/toolkit";
import { MultiValue, SingleValue } from "react-select";

import { RootState } from "..";
import { Action, Option } from "../../common";

export type State = {
    showModal: boolean;
    person: SingleValue<Option>;
    allPersons: MultiValue<Option>;
};

const initialState: State = {
    showModal: false,
    person: { label: "", value: "" },
    allPersons: [],
};

const workflowReducers = {
    setShowModal(state: State, { payload }: { payload: boolean }) {
        state.showModal = payload;
    },
    fetchPersons(state: State, { payload }: { payload: Action }) {},
    setPersons(state: State, { payload }: { payload: Option[] }) {
        state.allPersons = payload;
    },
    changePerson(state: State, { payload }: { payload: SingleValue<Option> }) {
        state.person = payload;
    },
    sendSlackMessage(state: State, { payload }: { payload: Action }) {},
    resetState(state: State) {
        return initialState;
    },
    doNotCall(state: State) {},
};

export const slackSlice = createSlice({
    name: "slackSlice",
    initialState,
    reducers: workflowReducers,
});

export const { setShowModal, changePerson, fetchPersons, setPersons, doNotCall, sendSlackMessage, resetState } =
    slackSlice.actions;

export const selectShowModal = (state: RootState) => state.slackSlice.showModal;
export const selectPerson = (state: RootState) => state.slackSlice.person;
export const selectAllPersons = (state: RootState) => state.slackSlice.allPersons;

export default slackSlice.reducer;
