import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CsvHeaders from "../../components/CSVHeaders/CSVHeaders";
import {
    createProject,
    resetState,
    selectCanShowAddProject,
    selectCanShowUpdateJd,
    selectCsvFile,
    selectErrors,
    selectShowCsvHeaderDialog,
    setProjectName,
    setShowCsvHeaderDialog,
    updateJd,
    uploadCsv,
} from "../../components/Navbar/CreateProject/CreateProject.reducer";
// import { CsvHeaders } from "./CsvHeaders";

import { campaignObjective, outreachChannel, outreachRegions } from "../../pages/project/project.types";
import { useAppDispatch } from "../../store/Store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

export const BDCreateProject = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const canShowCreateProject = useSelector(selectCanShowAddProject);
    const canShowUpdateJd = useSelector(selectCanShowUpdateJd);
    const showCsvHeaderDialog = useSelector(selectShowCsvHeaderDialog);
    const csvFile = useSelector(selectCsvFile);
    const errors = useSelector(selectErrors);

    const isProjectCreating = useSelector(checkIfLoading(createProject.type));
    const isCsvUploading = useSelector(checkIfLoading(uploadCsv.type));
    const isJDUploading = useSelector(checkIfLoading(updateJd.type));
    const [objective, setObjective] = useState("");
    const [platform, setPlatform] = useState("");
    const [outreachRegion, setOutreachRegion] = useState("");
    useEffect(() => {
        dispatch(resetState());
    }, []);

    return (
        <Dialog
            onClose={() => dispatch(resetState())}
            open={canShowCreateProject}
            sx={{
                "& .MuiPaper-root": {
                    width: "500px",
                },
            }}
        >
            <DialogTitle>{canShowUpdateJd ? "Update Campaign" : "Create New Campaign"}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {!canShowUpdateJd && (
                        <Stack>
                            <TextField
                                placeholder="*Campaign name"
                                onBlur={(e) => dispatch(setProjectName(e.target.value))}
                                inputProps={{
                                    style: {
                                        padding: "0.7rem",
                                        fontSize: "14px",
                                    },
                                }}
                            />
                            {errors?.projectName && <Typography color="red">{errors?.projectName}</Typography>}
                        </Stack>
                    )}
                    {/* <FormControl size="small">
                        <InputLabel id="demo-select-small-label">Campaign Objective</InputLabel>
                        <Select
                            labelId="campaignObjective-select"
                            id="campaignObjective-select"
                            value={objective}
                            label="Campaign Objective"
                            sx={{ marginBottom: "10px" }}
                        >
                            {Object.keys(campaignObjective).map((item: string, i: number) => (
                                <MenuItem
                                    value={campaignObjective[item]}
                                    onClick={() => setObjective(campaignObjective[item])}
                                    key={i}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel id="demo-select-small-label">Outreach Channel</InputLabel>
                        <Select value={platform} onChange={(e) => setPlatform(e.target.value)} label="Outreach Channel">
                            {Object.keys(outreachChannel).map((item: string, i: number) => (
                                <MenuItem key={i} value={outreachChannel[item]}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <FormControl size="small">
                        <InputLabel id="outreach-region">Outreach Region</InputLabel>
                        <Select
                            value={outreachRegion}
                            onChange={(e) => {
                                setOutreachRegion(e.target.value);
                            }}
                            label="Outreach Region"
                        >
                            {Object.keys(outreachRegions).map((item: string, i: number) => {
                                return (
                                    <MenuItem key={i} value={outreachRegions[item]}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                {/* {canShowUpdateJd && (
                    <JDUpload
                        handleJdUpload={(val) => dispatch(setProjectJd(val[0]))}
                    />
                )} */}
                {/* {!canShowUpdateJd && (
                    <Stack spacing={1}>
                        <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                            mb="10px"
                        >
                            <Link
                                href="https://storage.googleapis.com/hq_cv_tech/bd%20candidate%20csv%20-%20Sheet1.csv"
                                underline="always"
                                target="_blank"
                            >
                                Sample CSV
                            </Link>
                            <Stack flexDirection="row" alignItems="center">
                                <Typography mr="10px">
                                    <strong>Imp headers:</strong>
                                </Typography>
                                <Chip
                                    label="name"
                                    sx={{ marginRight: "10px" }}
                                />
                                <Chip label="email" />
                            </Stack>
                        </Stack>
                        <CSVUpload />
                        {canShowCookie && (
                            <Stack>
                                <TextField
                                    fullWidth
                                    sx={{ marginBottom: "4px" }}
                                    placeholder="Linkedin cookie"
                                    onBlur={(e) =>
                                        dispatch(setLiCookie(e.target.value))
                                    }
                                />
                                {errors.liCookie && (
                                    <Typography fontSize={14} color="red">
                                        {errors.liCookie}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Stack>
                )} */}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={!isProjectCreating && !isCsvUploading && <AddIcon />}
                    onClick={() => {
                        canShowUpdateJd
                            ? dispatch(updateJd({}))
                            : csvFile
                              ? dispatch(setShowCsvHeaderDialog(true))
                              : dispatch(
                                    createProject({
                                        action: createProject.type,
                                        navigate,
                                        objective,
                                        platform,
                                        bdrRegions: outreachRegion,
                                    })
                                );
                    }}
                    disabled={isProjectCreating || isCsvUploading || isJDUploading}
                    sx={{
                        width: "fit-content",
                        padding: "5px 15px",
                        alignSelf: "center",
                    }}
                >
                    {canShowUpdateJd
                        ? "Update Project"
                        : isProjectCreating || isCsvUploading
                          ? "Creating project..."
                          : "Create"}
                </Button>
            </DialogActions>
            {showCsvHeaderDialog && csvFile && (
                <CsvHeaders
                    onSubmit={(mappedCsvHeaders: any) => {
                        dispatch(
                            createProject({
                                action: createProject.type,
                                navigate,
                                mappedCsvHeaders,
                                objective,
                                platform,
                                bdrRegions: outreachRegion,
                            })
                        );
                    }}
                />
            )}
        </Dialog>
    );
};
