import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";
import {
    AddAllContactsFilterPayload,
    AddContactsToExclusionListPayload,
    AllContactsCSVUploadPayload,
    AllContactsState,
    AllContactsTableItemKeys,
    Contact,
    SearchContactsPayload,
    SetAllContactsFilterValuePayload,
    SetAllContactsPayload,
} from "./all-contacts.types";

const initialState: AllContactsState = {
    tableState: {
        order: "asc",
        page: 0,
        rowsPerPage: 500,
        selected: [],
        searchQuery: "",
        orderBy: "First Name",
        selectedContactIdForAction: "",
        isAllRowsSelected: false,
    },
    rows: [],
    showFiltersDrawer: false,
    filters: {
        "First Name": {
            label: "Name",
            numeric: false,
            value: [],
        },
        Email: {
            label: "Email",
            numeric: false,
            value: [],
        },
        "Company Name": {
            label: "Company",
            numeric: false,
            value: [],
        },
    },
    contactReachouts: {
        contactInfo: {},
        reachout: {},
    },
};

export const allContactsSlice = createSlice({
    name: "allContacts",
    initialState,
    reducers: {
        setAllContactsFilterValue(state, action: PayloadAction<SetAllContactsFilterValuePayload>) {
            const { key, value } = action.payload;
            if (state.filters?.[key]?.value) {
                // @ts-ignore
                state.filters[key].value = value;
            }
        },
        allContactsCSVUpload(state, action: AllContactsCSVUploadPayload) {},
        toggleAllContactsFiltersDrawer(state) {
            if (state.showFiltersDrawer) {
                state.showFiltersDrawer = false;
            } else {
                state.showFiltersDrawer = true;
            }
        },
        setContactIdForAllContactsAction(state, action: PayloadAction<string>) {
            state.tableState.selectedContactIdForAction = action.payload;
        },
        handleRowClickInAllContacts(state, action: PayloadAction<string>) {
            const id = action.payload;
            const selected = state.tableState.selected;
            const selectedIndex = state.tableState.selected.indexOf(id);
            let newSelected: readonly string[] = [];

            if (!state.tableState.isAllRowsSelected) {
                if (selectedIndex === -1) {
                    state.tableState.selected = newSelected?.concat(selected, id);
                } else if (selectedIndex === 0) {
                    state.tableState.selected = newSelected?.concat(selected?.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    state.tableState.selected = newSelected?.concat(selected?.slice(0, -1));
                } else if (selectedIndex > 0) {
                    state.tableState.selected = newSelected?.concat(
                        selected?.slice(0, selectedIndex),
                        selected?.slice(selectedIndex + 1)
                    );
                }
            }
        },
        setAllContactsSearchQuery(state, action: SearchContactsPayload) {
            state.tableState.searchQuery = action.payload;
        },
        handleAllContactsSelectAllClick(state, action: PayloadAction<boolean>) {
            if (!state.tableState.isAllRowsSelected) {
                if (action.payload) {
                    state.tableState.selected = state.rows.map((n) => n._id);
                } else {
                    state.tableState.selected = [];
                }
            }
        },
        toggleSelectAllSearchResults(state) {
            state.tableState.isAllRowsSelected = !state.tableState.isAllRowsSelected;
            if (state.tableState.isAllRowsSelected) {
                state.tableState.selected = [];
            }
        },
        handleAllContactsRequestSort(state, action: PayloadAction<keyof Contact>) {
            const property = action.payload;
            const tableState = state.tableState;
            const isAsc = tableState.orderBy === property && tableState.order === "asc";
            state.tableState.order = isAsc ? "desc" : "asc";
            state.tableState.orderBy = property;
        },
        setAllContacts(state, action: PayloadAction<SetAllContactsPayload>) {
            state.rows = action.payload;
        },
        addContactsFromCSVResponse(state, action: PayloadAction<SetAllContactsPayload>) {
            state.rows.unshift(...action.payload);
        },
        addAllContactsFilter(
            state,
            { type: _, payload: { value = [], key, ...rest } }: PayloadAction<AddAllContactsFilterPayload>
        ) {
            state.filters[key] = { value, ...rest };
        },
        removeAllContactsFilter(state, action: PayloadAction<AllContactsTableItemKeys>) {
            const key = action.payload;
            delete state.filters[key];
        },
        clearAllSelectedContacts(state) {
            state.tableState.selected = [];
        },
        resetAllContacts(state) {
            return initialState;
        },
        applyAllContactsFilters(state) {},
        addContactsToExclusionList(state, action: AddContactsToExclusionListPayload) {},
        fetchContacts(state, action: PayloadAction) {},
        cancelActions(state) {},
        getContactReachout(state, { payload }: { payload: string }) {},
        setContactReachout(state, { payload }: { payload: any }) {
            state.contactReachouts = payload;
        },
    },
});

export default allContactsSlice.reducer;

export const {
    clearAllSelectedContacts,
    fetchContacts,
    cancelActions,
    setAllContactsSearchQuery,
    handleAllContactsSelectAllClick,
    handleAllContactsRequestSort,
    setContactIdForAllContactsAction,
    handleRowClickInAllContacts,
    toggleAllContactsFiltersDrawer,
    setAllContacts,
    allContactsCSVUpload,
    addContactsFromCSVResponse,
    toggleSelectAllSearchResults,
    resetAllContacts,
    addAllContactsFilter,
    removeAllContactsFilter,
    setAllContactsFilterValue,
    applyAllContactsFilters,
    addContactsToExclusionList,
    getContactReachout,
    setContactReachout,
} = allContactsSlice.actions;

export const selectAllContacts = (state: RootState) => state.allContacts.rows;

export const numberOfSelectedRowsInAllContacts = (state: RootState) => state.allContacts.tableState.selected.length;

export const selectSearchQueryInAllContacts = (state: RootState) => state.allContacts.tableState.searchQuery;

export const selectRowIdForActionsInAllContacts = (state: RootState) =>
    state.allContacts.tableState.selectedContactIdForAction;

export const selectAllContactsTotalCount = (state: RootState) => state.allContacts.rows.length;

export const selectAllContactsPage = (state: RootState) => state.allContacts.tableState.page;

export const selectAllContactsTableOrder = (state: RootState) => state.allContacts.tableState.order;

export const selectAllContactsTableOrderBy = (state: RootState) => state.allContacts.tableState.orderBy;

export const selectAllContactsRowsPerPage = (state: RootState) => state.allContacts.tableState.rowsPerPage;

export const selectAllContactsSelected = (state: RootState) => state.allContacts.tableState.selected;

export const selectAllContactsFilterDrawerState = (state: RootState) => state.allContacts.showFiltersDrawer;

export const selectAllContactsSearchResults = (state: RootState) => state.allContacts.tableState.isAllRowsSelected;

export const selectAllContactsFilters = (state: RootState) => state.allContacts.filters;
export const selectContactReachouts = (state: RootState) => state.allContacts.contactReachouts;
