import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { fetchBaseQueryWithZod } from "../../utils/api-utils";
import {
    FetchCandidatesByProjectResponse,
    fetchCandidatesByProjectResponse,
    FetchCandidatesByProjectResponseData,
    GetCandidatesByProjectRequestPayload,
    SubmitFeedbackPayload,
    SubmitFeedbackResponse,
} from "./deleteProject.types";

export const deleteProjectFeedbackApi = createApi({
    reducerPath: "deleteProjectFeedback",
    baseQuery: fetchBaseQueryWithZod,
    endpoints: (builder) => ({
        getCandidatesByProject: builder.query<
            FetchCandidatesByProjectResponseData,
            GetCandidatesByProjectRequestPayload
        >({
            query: ({ projectId }) => ({
                url: `/v2/project/${projectId}/candidate-details`,
            }),
            transformResponse: (response: FetchCandidatesByProjectResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchCandidatesByProjectResponse,
            },
        }),
        submitFeedback: builder.mutation<SubmitFeedbackResponse, SubmitFeedbackPayload>({
            query: (payload) => ({
                url: "/v2/project/closing-remarks",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const { useGetCandidatesByProjectQuery, useSubmitFeedbackMutation } = deleteProjectFeedbackApi;
