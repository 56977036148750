import { forwardRef, useEffect, useState } from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableContainerProps,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { isEmpty, sortBy } from "lodash";
import { TableVirtuoso } from "react-virtuoso";

import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader/Loader";
import { columnsType, rowsType } from "./CustomeTable.types";
import styles from "./CustomTable.module.css";

type CustomTableProps = {
    columns: columnsType[];
    rows: any;
    total: number;
    onPageChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    isLoading?: boolean;
    tableHeight?: number;
    showIndex?: boolean;
    canSort?: boolean;
    minHeight?: string;
    tableContainerStyles?: TableContainerProps["sx"];
    enableVirtualization?: boolean;
}

const TableWithVirtualization = ({
    columns,
    rows,
    total,
    onPageChange,
    page,
    pageSize,
    onPageSizeChange,
    isLoading,
    tableHeight,
    showIndex = false,
    canSort = false,
    minHeight,
    tableContainerStyles,
}: CustomTableProps) => {
    const size = useWindowSize();
    const overflowTableHeight = size.height - 200;
    const [data, setData] = useState<rowsType[]>([]);
    const [isAscOrder, setIsAscOrder] = useState(false);
    const [orderBy, setOrderBy] = useState("");

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const onClickSort = (dataIndex: any) => {
        //@ts-ignore
        const newData: rowsType[] = sortBy(rows, [dataIndex]);
        if (isAscOrder) {
            setData(newData);
        } else {
            setData(newData.reverse());
        }

        setIsAscOrder(!isAscOrder);
        setOrderBy(dataIndex);
    };

    const TableWrapper = ({ children }: { children: React.ReactNode }) => {
        return (
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                }}
            >
                {children}
            </div>
        );
    };

    const VirtuosoTableComponents = {
        Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props: React.HTMLAttributes<HTMLTableElement>) => {
            return (
                <Table
                    {...props}
                    style={{
                        borderCollapse: "separate",
                        tableLayout: "fixed",
                        position: "relative",
                        ...(data?.length ? {} : (!data?.length || isLoading) && { height: "100%" }),
                    }}
                />
            );
        },
        TableHead,
        TableRow: ({ item: _item, ...props }: any) => <TableRow {...props} />,
        TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => {
            if (isLoading && !data?.length) {
                return (
                    <TableWrapper>
                        <Loader />
                    </TableWrapper>
                );
            }

            if (!data?.length) {
                return (
                    <TableWrapper>
                        <p style={{ textAlign: "center" }}>No data...</p>
                    </TableWrapper>
                );
            }

            return <TableBody {...props} ref={ref} />;
        }),
    };

    const fixedHeaderContent = () => (
        <TableRow
            sx={{
                backgroundColor: "#fff",
            }}
        >
            {showIndex && <TableCell align="center">Sr N</TableCell>}
            {columns?.map((column, i) => {
                const title = column.title?.length < 12 ? column.title : `${column.title.substring(0, 12)}...`;
                return (
                    <TableCell
                        key={`${column.dataIndex}-${i}`}
                        align={column.align || "center"}
                        style={{
                            width: column.width || "auto",
                        }}
                        sortDirection={isAscOrder ? "asc" : "desc"}
                    >
                        <TableSortLabel
                            active={orderBy === column.dataIndex}
                            direction={isAscOrder ? "asc" : "desc"}
                            onClick={(e: any) => onClickSort(column.dataIndex)}
                        >
                            <Tooltip title={column.title}>
                                <span>{title}</span>
                            </Tooltip>
                            {orderBy === column.dataIndex ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {!isAscOrder ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                );
            })}
        </TableRow>
    );

    const rowContent = (_index: number, row: any) => {
        return (
            <>
                {showIndex && <TableCell align="center">{_index + 1}</TableCell>}
                {columns.map((column, i) => {
                    const value = row[column.dataIndex];
                    return (
                        <TableCell
                            key={`${column.dataIndex}-${i}`}
                            align={column.align || "center"}
                            style={{
                                width: column.width || "auto",
                            }}
                            {...(column?.style ? { style: column?.style } : {})}
                        >
                            {column?.render ? column?.render(row, _index) : value}
                        </TableCell>
                    );
                })}
            </>
        );
    };

    return (
        <Paper sx={{ width: "100%", position: "relative" }}>
            <TableVirtuoso
                style={{ height: tableHeight || overflowTableHeight }}
                data={data || []}
                components={VirtuosoTableComponents as any}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
            />
            {total && pageSize ? (
                //@ts-ignore
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 500]}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={(e) => onPageSizeChange && onPageSizeChange(parseInt(e.target.value))}
                />
            ) : null}
        </Paper>
    );
}

const TableWithoutVirtualization = ({
    columns,
    rows,
    total,
    onPageChange,
    page,
    pageSize,
    onPageSizeChange,
    isLoading,
    tableHeight,
    showIndex = false,
    canSort = false,
    minHeight,
    tableContainerStyles,
}: {
    columns: columnsType[];
    rows: any;
    total: number;
    onPageChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    isLoading?: boolean;
    tableHeight?: number;
    showIndex?: boolean;
    canSort?: boolean;
    minHeight?: string;
    tableContainerStyles?: TableContainerProps["sx"];
}) => {
    const size = useWindowSize();
    const overflowTableHeight = size.height - 290;
    const [data, setData] = useState<rowsType[]>([]);
    const [isAscOrder, setIsAscOrder] = useState(false);
    const [orderBy, setOrderBy] = useState("");

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const onClickSort = (dataIndex: any) => {
        //@ts-ignore
        const newData: rowsType[] = sortBy(rows, [dataIndex]);
        if (isAscOrder) {
            setData(newData);
        } else {
            setData(newData.reverse());
        }

        setIsAscOrder(!isAscOrder);
        setOrderBy(dataIndex);
    };

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer
                sx={{
                    maxHeight: tableHeight || overflowTableHeight,
                    minHeight: minHeight,
                    ...tableContainerStyles,
                }}
                component={Paper}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {showIndex && <TableCell align="center">Sr N</TableCell>}
                            {columns?.map((column, i) => {
                                const title =
                                    column.title?.length < 12 ? column.title : `${column.title.substring(0, 12)}...`;
                                return (
                                    <TableCell
                                        key={`${column.dataIndex}-${i}`}
                                        align={column.align || "center"}
                                        style={{
                                            minWidth: column.minWidth || 150,
                                        }}
                                        sortDirection={isAscOrder ? "asc" : "desc"}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.dataIndex}
                                            direction={isAscOrder ? "asc" : "desc"}
                                            onClick={(e: any) => onClickSort(column.dataIndex)}
                                        >
                                            <Tooltip title={column.title}>
                                                <span>{title}</span>
                                            </Tooltip>
                                            {orderBy === column.dataIndex ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {!isAscOrder ? "sorted descending" : "sorted ascending"}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {!isEmpty(data) && !isLoading ? (
                        <TableBody>
                            {data.map((row: any, index: number) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {showIndex && <TableCell align="center">{index + 1}</TableCell>}
                                        {columns.map((column, i) => {
                                            const value = row[column.dataIndex];
                                            return (
                                                <TableCell
                                                    key={`${column.dataIndex}-${i}`}
                                                    align={column.align || "center"}
                                                    {...(column?.style ? { style: column?.style} : {})}
                                                >
                                                    {column?.render ? column?.render(row, index) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    ) : null}
                </Table>
            </TableContainer>
            {isLoading && (
                <div style={{ height: overflowTableHeight - 80 }}>
                    <Loader />
                </div>
            )}
            {!isLoading && isEmpty(data) && (
                <div className={styles.centerAlign} style={{ height: overflowTableHeight - 80 }}>
                    No data...
                </div>
            )}
            {total && pageSize ? (
                //@ts-ignore
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 500]}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={(e) => onPageSizeChange && onPageSizeChange(parseInt(e.target.value))}
                />
            ) : null}
        </Paper>
    );
};

const CustomTable = ({
    columns,
    rows,
    total,
    onPageChange,
    page,
    pageSize,
    onPageSizeChange,
    isLoading,
    tableHeight,
    showIndex = false,
    canSort = false,
    minHeight,
    tableContainerStyles,
    enableVirtualization = false,
}: CustomTableProps) => {
    if(enableVirtualization) {
        return <TableWithVirtualization 
            columns={columns}
            rows={rows}
            total={total}
            onPageChange={onPageChange}
            page={page}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            isLoading={isLoading}
            tableHeight={tableHeight}
            tableContainerStyles={tableContainerStyles}
        />
    }

    return <TableWithoutVirtualization 
        columns={columns}
        rows={rows}
        total={total}
        onPageChange={onPageChange}
        page={page}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        isLoading={isLoading}
        minHeight={minHeight}
    />
};

export default CustomTable;

