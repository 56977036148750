import { createSelector, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import {
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
    MRT_VisibilityState,
} from "material-react-table";

import { RootState } from "../../store/Store";
import { allCandidatesApi } from "../../store/reducers/all-candidates.api.slice";
import { FILTERS_KEY, VISIBILITY_KEY } from "../../utils/Constants";
import { shouldColumnHidden, shouldColumnVisible } from "../all-candidates/all-candidates.utils";
import {
    AddAllCandidatesFilterPayload,
    AddCandidatesToExclusionListPayload,
    AllCandidatesCSVUploadPayload,
    AllCandidatesState,
    Candidate,
    CandidateFilters,
    CandidateFilterValue,
    isCandidateProject,
    SavedFiltersList,
    SearchCandidatesPayload,
    SetAllCandidatesFilterValuePayload,
    SetAllCandidatesPayload,
    SetErrorPayload,
} from "./all-candidates.types";
import { defaultSortOrder } from "./all-candidates.utils";

const initialState: AllCandidatesState = {
    visibility: {},
    errors: {},
    totalRows: 0,
    pagination: {
        pageIndex: 0,
        pageSize: 100,
    },
    sorting: [],
    tableState: {
        order: "asc",
        page: 0,
        rowsPerPage: 100,
        totalCandidates: 0,
        selected: [],
        searchQuery: "",
        orderBy: "Name",
        selectedCandidateIdForAction: "",
        isAllRowsSelected: false,
    },
    rows: [],
    showFiltersDrawer: false,
    filters: {
        order: [],
        values: {},
    },
    columnFilter: [],
    candidateReachouts: {
        candidateInfo: {},
        reachout: {},
    },
    emailThreadId: null,
    savedFiltersList: [],
};

function parseVisibilityKey() {
    try {
        return JSON.parse(localStorage?.getItem(VISIBILITY_KEY) || "{}");
    } catch (err) {
        return null;
    }
}

function getInitialState(): AllCandidatesState {
    return {
        ...initialState,
        visibility: {
            ...initialState.visibility,
            ...JSON.parse(localStorage?.getItem(VISIBILITY_KEY) || "{}"),
        },
        filters: {
            ...initialState.filters,
            values: JSON.parse(localStorage.getItem(FILTERS_KEY) || "{}"),
        },
    };
}

export const allCandidatesSlice = createSlice({
    name: "allCandidates",
    initialState: getInitialState,
    reducers: {
        syncFilters(state, action: PayloadAction<{ filters: CandidateFilterValue; onSuccess: () => void }>) {
            const visibleColumns = state.visibility;
            const newFilters: CandidateFilterValue = {};
            Object.entries(visibleColumns).forEach(([key, value]) => {
                if (value === true) {
                    if (action.payload.filters?.[key]) {
                        newFilters[key] = action.payload.filters[key];
                    } else {
                        newFilters[key] = {
                            label: key,
                            value: [],
                            hidden: false,
                        };
                    }
                }
            });
            state.filters.values = newFilters;
            action.payload.onSuccess();
        },
        setSavedFiltersList(state, action: PayloadAction<SavedFiltersList>) {
            state.savedFiltersList = action.payload;
        },
        setDefaultView(state) {
            localStorage.setItem(FILTERS_KEY, JSON.stringify(state.filters.values));
            localStorage.setItem(VISIBILITY_KEY, JSON.stringify(state.visibility));
        },
        setColumnVisibility(state, action: PayloadAction<MRT_VisibilityState>) {
            const newVisibility = Object.entries(action.payload).reduce((acc: MRT_VisibilityState, curr) => {
                const [key, value] = curr;

                if (key && !key.startsWith("mrt")) {
                    acc[key] = value;
                }

                return acc;
            }, {});
            state.visibility = { ...newVisibility, _id: false };
        },
        changePagination(state, action: PayloadAction<MRT_PaginationState>) {
            state.pagination = action.payload;
        },
        changeSorting(state, action: PayloadAction<MRT_SortingState>) {
            state.sorting = action.payload;
        },
        changeColumnFilter(state, action: PayloadAction<MRT_ColumnFiltersState>) {
            state.columnFilter = action.payload;
        },
        setEmailThreadId(state, action: PayloadAction<string>) {
            state.emailThreadId = action.payload;
        },
        changeOrder(state, action: PayloadAction<{ source: number; destination: number }>) {
            const { source, destination } = action.payload;
            const [removed] = state.filters.order.splice(source, 1);
            state.filters.order.splice(destination, 0, removed);
        },
        setErrors(state, action: SetErrorPayload) {
            const { key, text, action: operation = "ADD" } = action.payload;
            if (operation === "ADD") {
                state.errors[key] = text;
            }

            if (operation === "REMOVE") {
                delete state.errors[key];
            }
        },
        setTotalRows(state, action: PayloadAction<number>) {
            state.totalRows = action.payload;
        },
        setAllCandidatesFilterValue(state, action: PayloadAction<SetAllCandidatesFilterValuePayload>) {
            const { key, value } = action.payload;

            if (key === "List of projects" && !(key in state.filters.values)) {
                state.filters.values[key] = {
                    hidden: true,
                    label: "List of projects",
                    value: [...value],
                };
            }

            if (key in state.filters.values) {
                state.filters.values[key].value = value;
            }
        },
        allCandidatesCSVUpload(state, action: AllCandidatesCSVUploadPayload) {},
        createListFromFilters(
            state,
            action: PayloadAction<{
                projectName: string;
                filters: CandidateFilterValue;
                onSuccess: () => void;
            }>
        ) {},
        getSavedFiltersList(state, action: PayloadAction) {},
        toggleAllCandidatesFiltersDrawer(state) {
            if (state.showFiltersDrawer) {
                state.showFiltersDrawer = false;
            } else {
                state.showFiltersDrawer = true;
            }
        },
        setContactIdForAllCandidatesAction(state, action: PayloadAction<string>) {
            state.tableState.selectedCandidateIdForAction = action.payload;
        },
        handleRowClickInAllCandidates(state, action: PayloadAction<string>) {
            const id = action.payload;
            const selected = state.tableState.selected;
            const selectedIndex = state.tableState.selected.indexOf(id);
            const newSelected: readonly string[] = [];

            if (!state.tableState.isAllRowsSelected) {
                if (selectedIndex === -1) {
                    state.tableState.selected = newSelected.concat(selected, id);
                } else if (selectedIndex === 0) {
                    state.tableState.selected = newSelected.concat(selected.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    state.tableState.selected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    state.tableState.selected = newSelected.concat(
                        selected?.slice(0, selectedIndex),
                        selected?.slice(selectedIndex + 1)
                    );
                }
            }
        },
        setAllCandidatesSearchQuery(state, action: SearchCandidatesPayload) {
            state.tableState.searchQuery = action.payload;
        },
        handleAllCandidatesSelectAllClick(state, action: PayloadAction<string[]>) {
            if (!state.tableState.isAllRowsSelected) {
                state.tableState.selected = action.payload;
                // if (action.payload) {
                //     // state.tableState.selected = state.rows.map((n) => n._id);
                //     state.tableState.selected = action.payload;
                // } else {
                //     state.tableState.selected = [];
                // }
            }
        },
        toggleSelectAllSearchResults(state) {
            state.tableState.isAllRowsSelected = !state.tableState.isAllRowsSelected;
            if (state.tableState.isAllRowsSelected) {
                state.tableState.selected = [];
            }
        },
        handleAllCandidatesRequestSort(state, action: PayloadAction<keyof Candidate>) {
            const property = action.payload;
            const tableState = state.tableState;
            const isAsc = tableState.orderBy === property && tableState.order === "asc";
            state.tableState.order = isAsc ? "desc" : "asc";
            state.tableState.orderBy = property;
        },
        setAllCandidates(state, action: PayloadAction<SetAllCandidatesPayload>) {
            state.rows = action.payload;
        },
        setTotalCandidatesCount(state, { payload }: { payload: number }) {
            state.tableState.totalCandidates = payload;
        },
        addCandidatesFromCSVResponse(state, action: PayloadAction<SetAllCandidatesPayload>) {
            state.rows.unshift(...action.payload);
        },
        addAllCandidatesFilter(state, { payload }: PayloadAction<AddAllCandidatesFilterPayload>) {
            const { hidden, key } = payload;
            state.filters.order.push(key);
            state.filters.values[key].hidden = hidden;
        },
        removeAllCandidatesFilter(state, action: PayloadAction<string>) {
            const key = action.payload;

            if (state.filters.values[key]) {
                state.filters.values[key].hidden = true;
            }
        },
        clearAllSelectedCandidates(state) {
            state.tableState.selected = [];
        },
        resetAllCandidates(state) {
            return initialState;
        },
        applyAllCandidatesFilters(state) {},
        addCandidatesToExclusionList(state, action: AddCandidatesToExclusionListPayload) {},
        fetchCandidates(state, { payload }: { payload: { pageSize: number; start: number } }) {
            state.tableState.page = payload.start;
            state.tableState.rowsPerPage = payload.pageSize;
        },
        cancelActions(state) {},
        getContactReachout(state, { payload }: { payload: string }) {},
        setContactReachout(state, { payload }: { payload: any }) {
            state.candidateReachouts = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(allCandidatesApi.endpoints.fetchCandidates.matchFulfilled, (state, action) => {
            state.totalRows = action.payload.total;

            const visibilityLookup = action.payload.candidates.reduce((acc: Record<string, boolean>, i) => {
                Object.keys(i).forEach((key) => {
                    if (!acc?.[key]) {
                        const currentValue = i[key];
                        if (shouldColumnHidden(key)) {
                            acc[key] = false;
                        } else if (isCandidateProject(currentValue)) {
                            acc[key] = true;
                        } else if (
                            currentValue &&
                            currentValue.length &&
                            currentValue !== "-" &&
                            key !== "_id" &&
                            key !== "Multiple projects value" &&
                            key !== "greenhouseMetaData"
                        ) {
                            acc[key] = true;
                        } else {
                            acc[key] = false;
                        }
                    }
                });

                return acc;
            }, {});

            const filters = defaultSortOrder(
                action.payload.candidates.reduce((acc: string[], i) => {
                    return [...new Set([...acc, ...state.filters.order, ...Object.keys(i)])];
                }, [])
            );

            filters.forEach((filterKey) => {
                const isVisible = visibilityLookup[filterKey] || shouldColumnVisible(filterKey);
                if (!(filterKey in state.filters.values)) {
                    state.filters.values[filterKey] = {
                        label: filterKey,
                        value: [],
                        hidden: !isVisible,
                    };
                }

                if (isVisible) {
                    state.filters.order.push(filterKey);
                }
            });

            const existingKeys = Object.keys(state.visibility);

            const visibility = [...filters, ...existingKeys].reduce((acc: MRT_VisibilityState, colName: string) => {
                if (colName === "_id") {
                    acc[colName] = false;
                }

                if (colName in state.visibility) {
                    acc[colName] = visibilityLookup[colName] || state.visibility[colName];
                } else {
                    acc[colName] = visibilityLookup[colName] || shouldColumnVisible(colName);
                }

                return acc;
            }, {});

            const savedVisibilityKeys: MRT_VisibilityState = parseVisibilityKey();

            if (isEmpty(savedVisibilityKeys)) {
                state.visibility = visibility;
            } else {
                state.visibility = savedVisibilityKeys;
            }

            if (!isEmpty(savedVisibilityKeys) && "_id" in savedVisibilityKeys && savedVisibilityKeys?._id) {
                console.info("visibility state reset");
                state.visibility._id = false;
                localStorage.setItem(VISIBILITY_KEY, JSON.stringify(state.visibility));
            }
        });
        builder.addMatcher(allCandidatesApi.endpoints.fetchActivityTags.matchFulfilled, (state, action) => {
            // take existing filters,
            const activityTags = action.payload;
            const filters = defaultSortOrder([...new Set([...state.filters.order, ...activityTags])]);

            filters.forEach((filterKey, idx) => {
                const isVisible = shouldColumnVisible(filterKey);
                if (!(filterKey in state.filters.values)) {
                    state.filters.values[filterKey] = {
                        label: filterKey,
                        value: [],
                        hidden: !isVisible,
                    };
                }

                if (isVisible) {
                    state.filters.order.push(filterKey);
                }
            });

            const existingKeys = Object.keys(state.visibility);

            const visibility = [...filters, ...existingKeys].reduce((acc: MRT_VisibilityState, colName: string) => {
                if (colName === "_id") {
                    acc[colName] = false;
                }

                if (colName in state.visibility) {
                    acc[colName] = state.visibility[colName];
                } else {
                    acc[colName] = shouldColumnVisible(colName);
                }

                return acc;
            }, {});
            state.visibility = visibility;
        });
    },
});

export default allCandidatesSlice.reducer;

export const {
    setDefaultView,
    setColumnVisibility,
    changePagination,
    setEmailThreadId,
    changeOrder,
    setErrors,
    setTotalRows,
    clearAllSelectedCandidates,
    fetchCandidates,
    cancelActions,
    setAllCandidatesSearchQuery,
    handleAllCandidatesSelectAllClick,
    handleAllCandidatesRequestSort,
    setContactIdForAllCandidatesAction,
    handleRowClickInAllCandidates,
    toggleAllCandidatesFiltersDrawer,
    setAllCandidates,
    allCandidatesCSVUpload,
    addCandidatesFromCSVResponse,
    toggleSelectAllSearchResults,
    resetAllCandidates,
    addAllCandidatesFilter,
    removeAllCandidatesFilter,
    setAllCandidatesFilterValue,
    applyAllCandidatesFilters,
    addCandidatesToExclusionList,
    getContactReachout,
    setContactReachout,
    setTotalCandidatesCount,
    changeSorting,
    changeColumnFilter,
    createListFromFilters,
    getSavedFiltersList,
    setSavedFiltersList,
    syncFilters,
} = allCandidatesSlice.actions;

export const selectColumnVisibility = (state: RootState) => state.allCandidates.visibility;

export const selectPagination = (state: RootState) => state.allCandidates.pagination;

export const selectSorting = (state: RootState) => state.allCandidates.sorting;

export const selectColumnFilter = (state: RootState) => state.allCandidates.columnFilter;

export const selectEmailThreadId = (state: RootState) => state.allCandidates.emailThreadId;

export const selectTotalRows = (state: RootState) => state.allCandidates.totalRows;

export const numberOfSelectedRowsInAllCandidates = (state: RootState) => state.allCandidates.tableState.selected.length;

export const selectSearchQueryInAllCandidates = (state: RootState) => state.allCandidates.tableState.searchQuery;

export const selectRowIdForActionsInAllCandidates = (state: RootState) =>
    state.allCandidates.tableState.selectedCandidateIdForAction;

export const selectAllCandidatesTotalCount = (state: RootState) => state.allCandidates.rows.length;

export const selectAllCandidatesPage = (state: RootState) => state.allCandidates.tableState.page;
export const selectAllCandidatesTableOrder = (state: RootState) => state.allCandidates.tableState.order;

export const selectAllCandidatesTableOrderBy = (state: RootState) => state.allCandidates.tableState.orderBy;

export const selectAllCandidatesRowsPerPage = (state: RootState) => state.allCandidates.tableState.rowsPerPage;

export const selectAllCandidatesSelected = (state: RootState) => state.allCandidates.tableState.selected;

export const selectAllCandidatesFilterDrawerState = (state: RootState) => state.allCandidates.showFiltersDrawer;

export const selectAllCandidatesSearchResults = (state: RootState) => state.allCandidates.tableState.isAllRowsSelected;

export const selectContactReachouts = (state: RootState) => state.allCandidates.candidateReachouts;

export const selectActivityErrors = (state: RootState) => state.allCandidates.errors?.["Activity"];

export const selectSavedFiltersList = (state: RootState) => state.allCandidates.savedFiltersList;

export const isFiltersEmpty = (state: RootState) => {
    const filterValues = state.allCandidates.filters.values;
    const keys = state.allCandidates.filters.order;

    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i];
        if (filterValues[key].value.length !== 0) return false;
    }
    return true;
};

export const selectAllCandidatesFilters = (state: RootState) => {
    const visibleColumns = state.allCandidates.visibility;
    const filterValues = state.allCandidates.filters.values;

    const filters: CandidateFilterValue = {};

    Object.entries(visibleColumns).forEach(([key, value]) => {
        if (value === true) {
            filters[key] = filterValues[key];
        }
    });

    return filters;
};

export const selectAllDisabledFilters = (state: RootState) => {
    const filterValues = state.allCandidates.filters.values;
    const filters: CandidateFilterValue = {};

    Object.keys(filterValues).forEach((key) => {
        const value = filterValues[key];

        if (value.hidden && key !== "_id") {
            filters[key] = value;
        }
    });

    return filters;
};

export const selectFilterOrders = (state: RootState) => state.allCandidates.filters.order;

export const selectFilterValue = createSelector(
    [(state: RootState) => state.allCandidates.filters as CandidateFilters, (_: RootState, key: string) => key],
    (filters: CandidateFilters, key: string) => {
        return filters?.values[key]?.value;
    }
);

export const selectAllFiltersWithValue = (state: RootState) => {
    const filtersWithValue: Record<string, string[]> = {};

    Object.entries(state.allCandidates.filters.values).forEach(([filterKey, filterValue]) => {
        if (filterValue.value.length) {
            filtersWithValue[filterKey] = filterValue.value.map(({ value }) => value);
        }
    });

    return filtersWithValue;
};

export const isAllCandidatesSelected = createSelector([(state: RootState) => state], (state) => {
    const page = state.allCandidates.tableState.page;
    const pageSize = state.allCandidates.tableState.rowsPerPage;
    const filters = selectAllFiltersWithValue(state);
    const search = state.allCandidates.tableState.searchQuery;
    const selectedCandidates = state.allCandidates.tableState.selected;
    const candidates =
        allCandidatesApi.endpoints.fetchCandidates.select({
            pageSize,
            start: page,
            filterBy: filters,
            search,
        })(state).data?.candidates ?? [];

    if (!candidates.length || !selectedCandidates.length) {
        return false;
    }

    return candidates.every(({ _id }) => selectedCandidates.includes(_id));
});

export const selectAllCandidates = createSelector([(state: RootState) => state], (state) => {
    const page = state.allCandidates.tableState.page;
    const pageSize = state.allCandidates.tableState.rowsPerPage;
    const filters = selectAllFiltersWithValue(state);
    const search = state.allCandidates.tableState.searchQuery;
    const candidates =
        allCandidatesApi.endpoints.fetchCandidates.select({
            pageSize,
            start: page,
            filterBy: filters,
            search,
        })(state).data?.candidates ?? [];
    return candidates;
});
