import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";

import { RootState } from "..";

type SingleCookieInput = {
    id: string;
    cookie: string;
    count: string;
};

type CookiesInputState = {
    values: {
        [key: string]: SingleCookieInput;
    };
};

type ChangePayload = {
    index: string;
    value: string;
};

const initialState: CookiesInputState = {
    values: {
        "0": { id: nanoid(), cookie: "", count: "" },
    },
};

const cookiesInputSlice = createSlice({
    name: "cookiesInput",
    initialState,
    reducers: {
        resetCookieValues(state) {
            for (const key of Object.keys(state.values)) {
                state.values[key].cookie = "";
                state.values[key].count = "";
            }
        },
        changeCookie(state, action: PayloadAction<ChangePayload>) {
            const { value, index } = action.payload;
            state.values[index] = {
                id: state.values[index]?.id || nanoid(),
                cookie: value,
                count: state.values[index]?.count || "",
            };
        },
        // changeCount(state, action: PayloadAction<ChangePayload>) {
        //     const { value, index } = action.payload;

        //     if (Number(value) <= 40) {
        //         state.values[index] = {
        //             id: state.values[index]?.id || nanoid(),
        //             count: value,
        //             cookie: state.values[index]?.cookie || "",
        //         };
        //     }
        // },
    },
});

export default cookiesInputSlice.reducer;

export const { changeCookie, resetCookieValues } = cookiesInputSlice.actions;

export const getCookieInputValues = (state: RootState) => state.cookiesInput.values;
