// import { useCallback, useEffect, useRef, useState } from "react";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import CreateIcon from "@mui/icons-material/Create";
// import DeleteIcon from "@mui/icons-material/Delete";
// import DoneAllIcon from "@mui/icons-material/DoneAll";
// import {
//     Box,
//     Button,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     IconButton,
//     ListItemIcon,
//     MenuItem,
//     Radio,
//     Slider,
//     Stack,
//     TextField,
//     Tooltip,
//     Typography,
// } from "@mui/material";
// import dayjs from "dayjs";
// import { debounce } from "lodash";
// import { useDispatch, useSelector } from "react-redux";
// import { ActionMeta, MultiValue } from "react-select";

// import { Option } from "../../../common";
// import CreatableSelect from "../../../components/CreatableSelect/CreatableSelect";
// import CustomTable from "../../../components/CustomTable/CustomTable";
// import useWindowSize from "../../../hooks/useWindowSize";
// import {
//     getTypeAhead,
//     selectIndustryOptions,
//     selectJobTitleOptions,
//     selectLocationOptions,
// } from "../../../pages/AdvancedFilters/Components/Filters/Filters.reducer";
// import { selectCurrProject } from "../../../pages/allProjects/index.reducer";
// import { CustomListItemText } from "../../../pages/project/components/header/Header";
// import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
// import { BDProjectMenuItem } from "../bd.types";
// import styles from "./LeadRating.module.css";
// import {
//     addCriteria,
//     addInitNewCriteria,
//     addLeadRatingCriteria,
//     deleteCriteria,
//     editCriteria,
//     employeeCount,
//     getLeadRatingCriteriaList,
//     leadTagValues,
//     leadTypeValues,
//     selectCriteriaId,
//     selectCriteriaName,
//     selectLeadRatingCriteria,
//     selectLeadRatingCriteriaList,
//     setCriteria,
//     setCriteriaName,
//     setLeadRatingCriteriaList,
//     submitCriteriaForInsightGeneration,
//     updateLeadRatingCriteria,
// } from "./LeadRating.slice";

// export const LeadRatingList = ({ onClose }: { onClose: () => void }) => {
//     const dispatch = useDispatch();
//     const isSubmittingLeadScore = useSelector(checkIfLoading(submitCriteriaForInsightGeneration.type));
//     const leadRatingCriteriaList = useSelector(selectLeadRatingCriteriaList);
//     const criteriaId = useSelector(selectCriteriaId);
//     const isLoading = useSelector(checkIfLoading(getLeadRatingCriteriaList.type));
//     const [showCriteriaModal, setShowCriteriaModal] = useState(false);

//     useEffect(() => {
//         dispatch(getLeadRatingCriteriaList());
//     }, []);

//     const onClickCriteria = (item: any, index: number) => {
//         const criteriaList = generateCriteria(item);
//         dispatch(
//             setCriteria({
//                 criteriaId: item._id,
//                 criteria: criteriaList,
//                 criteriaName: item?.criteriaName,
//             })
//         );

//         setShowCriteriaModal(true);
//     };

//     const generateCriteria = (item: any) => {
//         const title: any = item.leadScoreAlgorithm.filter((item: any) => item.type === "role");
//         const titleData: any = {
//             data: {},
//         };
//         title.forEach((item: any) => {
//             titleData.id = item._id;
//             titleData.type = item.type;
//             titleData.order = item.order ? "high" : "medium";
//             titleData.data[item.tag] = item.values;
//         });

//         const country = item.leadScoreAlgorithm.filter((item: any) => item.type === "country");
//         const countryData: any = {
//             data: {},
//         };
//         country.forEach((item: any) => {
//             countryData.id = item._id;
//             countryData.type = item.type;
//             countryData.order = item.order ? "high" : "medium";
//             countryData.data[item.tag] = item.values;
//         });
//         const city = item.leadScoreAlgorithm.filter((item: any) => item.type === "city");
//         const cityData: any = {
//             data: {},
//         };
//         city.forEach((item: any) => {
//             cityData.id = item._id;
//             cityData.type = item.type;
//             cityData.order = item.order ? "high" : "medium";
//             cityData.data[item.tag] = item.values;
//         });
//         const industry = item.leadScoreAlgorithm.filter((item: any) => item.type === "industry");
//         const industryData: any = {
//             data: {},
//         };
//         industry.forEach((item: any) => {
//             industryData.id = item._id;
//             industryData.type = item.type;
//             industryData.order = item.order ? "high" : "medium";
//             industryData.data[item.tag] = item.values;
//         });
//         const empCount = item.leadScoreAlgorithm.filter((item: any) => item.type === "employeeCount");
//         const empCountData: any = {
//             data: {},
//         };
//         empCount.forEach((item: any) => {
//             empCountData.id = item._id;
//             empCountData.type = item.type;
//             empCountData.order = item.order ? "high" : "medium";
//             empCountData.data[item.tag] = item.values;
//         });
//         const criteriaList = [];
//         if (title?.length) {
//             criteriaList.push(titleData);
//         }
//         if (country?.length) {
//             criteriaList.push(countryData);
//         }
//         if (city?.length) {
//             criteriaList.push(cityData);
//         }
//         if (industry?.length) {
//             criteriaList.push(industryData);
//         }
//         if (empCount?.length) {
//             criteriaList.push(empCountData);
//         }
//         return criteriaList;
//     };

//     const onChangeSelect = (item: any, index: number) => {
//         const criteriaList = generateCriteria(item);
//         const newLeadRatingCriteria = JSON.parse(JSON.stringify(leadRatingCriteriaList));
//         newLeadRatingCriteria.map((criteria: any, i: number) => (criteria.isSelected = index === i ? true : false));

//         dispatch(
//             setCriteria({
//                 criteriaId: item._id,
//                 criteria: criteriaList,
//             })
//         );

//         dispatch(setLeadRatingCriteriaList(newLeadRatingCriteria));
//     };

//     const columns = [
//         {
//             title: "Select",
//             dataIndex: "type",
//             minWidth: 150,
//             canSort: true,
//             maxTitleLength: 12,
//             render: (record: any, index: number) => {
//                 // console.log({ isSelected: record?.isSelected });
//                 return <Radio checked={record.isSelected} onChange={() => onChangeSelect(record, index)} />;
//             },
//         },
//         {
//             title: "Name",
//             dataIndex: "type",
//             minWidth: 150,
//             canSort: true,
//             maxTitleLength: 12,
//             render: (record: any, index: number) => {
//                 const name = record?.criteriaName || `criteria-${index + 1}`;
//                 return (
//                     <Button variant="text" onClick={() => onClickCriteria(record, index)}>
//                         {name}
//                     </Button>
//                 );
//             },
//         },
//         {
//             title: "Created on",
//             dataIndex: "createdAt",
//             minWidth: 150,
//             canSort: true,
//             maxTitleLength: 15,
//             render: (record: any, index: number) => dayjs(record.createdAt).format("MMM D, h:mm A"),
//         },
//         {
//             title: "Criteria count",
//             dataIndex: "leadScoreAlgorithm",
//             minWidth: 250,
//             canSort: true,
//             maxTitleLength: 15,
//             render: (record: any, index: number) => record.leadScoreAlgorithm?.length,
//         },
//     ];

//     const size = useWindowSize();
//     const tableHeight = size.height - 450;

//     return (
//         <Dialog
//             open={true}
//             classes={{
//                 paper: styles.container,
//             }}
//         >
//             <DialogTitle>Lead Rating</DialogTitle>
//             <DialogContent>
//                 <CustomTable
//                     columns={columns}
//                     total={leadRatingCriteriaList?.length}
//                     rows={leadRatingCriteriaList}
//                     tableHeight={tableHeight}
//                     isLoading={isLoading}
//                 />
//             </DialogContent>
//             <DialogActions sx={{ justifyContent: "space-between" }}>
//                 <Button
//                     variant="outlined"
//                     onClick={() => {
//                         setShowCriteriaModal(true);
//                         dispatch(
//                             setCriteria({
//                                 criteriaId: "",
//                                 criteria: [],
//                             })
//                         );
//                         dispatch(addInitNewCriteria());
//                     }}
//                     startIcon={<AddCircleOutlineIcon />}
//                 >
//                     Add New
//                 </Button>
//                 <Stack flexDirection="row">
//                     <Button variant="outlined" onClick={onClose} sx={{ marginRight: "10px" }}>
//                         Cancel
//                     </Button>

//                     <Button
//                         variant="contained"
//                         disabled={isSubmittingLeadScore}
//                         onClick={() => {
//                             criteriaId &&
//                                 dispatch(
//                                     submitCriteriaForInsightGeneration({
//                                         onClose,
//                                     })
//                                 );
//                             !criteriaId && onClose();
//                         }}
//                     >
//                         {isSubmittingLeadScore ? "Submitting..." : "Submit"}
//                     </Button>
//                 </Stack>
//             </DialogActions>
//             {showCriteriaModal && <LeadRatingModal onClose={() => setShowCriteriaModal(false)} />}
//         </Dialog>
//     );
// };

// export function LeadRatingModal({ onClose }: { onClose: () => void }) {
//     const dispatch = useDispatch();
//     const projectData = useSelector(selectCurrProject);
//     const leadRatingCriteria = useSelector(selectLeadRatingCriteria);
//     const jobTitleOptions = useSelector(selectJobTitleOptions);
//     const locations = useSelector(selectLocationOptions);
//     const industryOptions = useSelector(selectIndustryOptions);
//     const criteriaId = useSelector(selectCriteriaId);
//     const inputContainerRefs = useRef<HTMLDivElement[]>([]);
//     const [typeAhed, setTypeAhead] = useState<Option>({
//         value: "",
//         label: "",
//     });

//     const creatableSelectOptions: any = {
//         role: jobTitleOptions,
//         location: locations,
//         industry: industryOptions,
//         employeeCount,
//     };
//     useEffect(() => {
//         if (!typeAhed.value) return;
//         const newTypehead = { ...typeAhed, projectId: projectData?._id };

//         if (newTypehead.label === "country" || newTypehead.label === "city") {
//             newTypehead.label = "location";
//         }
//         dispatch(getTypeAhead(newTypehead));
//     }, [typeAhed]);

//     const onChangeTypedField = ({
//         newValue,
//         action,
//         index,
//         type,
//         tag,
//         data,
//     }: {
//         newValue: MultiValue<Option>;
//         action: { action: string };
//         index: number;
//         type: string;
//         tag: string;
//         data: any;
//     }) => {
//         const newData = { ...data };
//         const isCreate =
//             action.action === "create-option" || action.action === "select-option" || action.action === "remove-value";
//         const canClear = action.action === "clear" || action.action === "remove-value";

//         if (isCreate) {
//             let item = [];
//             if (type === "country" || type === "city" || type === "industry") {
//                 item = newValue.map((value) => value.label);
//             } else {
//                 item = newValue.map((value) => value.value);
//             }
//             newData[tag] = item;
//             // console.log({ newData, index });
//             dispatch(editCriteria({ index, key: `data`, item: newData }));
//         } else if (canClear) {
//             newData[tag] = [];
//             dispatch(editCriteria({ index, key: "data", item: newData }));
//         }
//     };

//     const onSelectAll = (option: any, values: Option[], index: number, filterKey: string, tag: string, data: any) => {
//         const newValue = [...values, ...option];
//         const actionMeta = {
//             action: "create-option",
//         };
//         onChangeTypedField({
//             newValue,
//             action: actionMeta,
//             index,
//             type: filterKey,
//             tag,
//             data,
//         });
//     };

//     const columns = [
//         {
//             title: "Criteria",
//             dataIndex: "type",
//             minWidth: 100,
//             width: 200,
//             canSort: true,
//             maxTitleLength: 12,
//             render: (record: any, index: number) => (
//                 <SelectValues
//                     value={record.type}
//                     onChange={(item: Option, i: number) =>
//                         dispatch(
//                             editCriteria({
//                                 index,
//                                 key: "type",
//                                 item: item.value,
//                             })
//                         )
//                     }
//                     options={leadTypeValues}
//                 />
//             ),
//         },
//         {
//             title: "Importance",
//             dataIndex: "type",
//             minWidth: 100,
//             width: 100,
//             canSort: true,
//             maxTitleLength: 12,
//             render: (record: any, index: number) => {
//                 return <ImportanceSlider index={index} value={record.order} />;
//             },
//         },
//         {
//             title: "User Tag",
//             dataIndex: "tag",
//             minWidth: 100,
//             width: 150,
//             canSort: true,
//             maxTitleLength: 15,
//             render: (record: any, index: number) => {
//                 const data = record.data;

//                 return (
//                     <Stack alignItems="center">
//                         {Object.keys(data).map((value: string, i) => (
//                             <Typography
//                                 sx={{
//                                     padding: "10px 5px",
//                                     fontSize: "14px",
//                                     height: inputContainerRefs.current[index]?.children[i]?.clientHeight,

//                                     textAlign: "center",
//                                     display: "flex",
//                                     alignItems: "center",
//                                 }}
//                                 key={i}
//                             >
//                                 {
//                                     //@ts-ignore
//                                     leadTagValues[value]
//                                 }
//                             </Typography>
//                         ))}
//                     </Stack>
//                 );
//             },
//         },
//         {
//             title: "Input",
//             dataIndex: "text",
//             minWidth: 300,
//             width: 400,
//             canSort: true,
//             maxTitleLength: 15,
//             render: (record: any, index: number) => {
//                 const data = record.data;
//                 let type = record?.type;
//                 if (type === "country" || type === "city") {
//                     type = "location";
//                 }
//                 const option = creatableSelectOptions[type] || [];

//                 return (
//                     <Stack flexDirection="row" alignItems="center" sx={{ lineHeight: "2.2", width: "400px" }}>
//                         <Stack
//                             flex={1}
//                             ref={(el) => {
//                                 if (el) inputContainerRefs.current[index] = el;
//                             }}
//                         >
//                             {Object.keys(data).map((tag, i) => {
//                                 const newValues = data[tag]?.map((item: string) => {
//                                     return {
//                                         label: item,
//                                         value: item,
//                                     };
//                                 });

//                                 return (
//                                     // eslint-disable-next-line react/jsx-key
//                                     <Stack alignItems="flex-start" flexDirection="row">
//                                         <CreatableSelect
//                                             onChange={(newValue: MultiValue<Option>, action: ActionMeta<Option>) =>
//                                                 onChangeTypedField({
//                                                     newValue,
//                                                     action,
//                                                     index,
//                                                     type: record.type,
//                                                     tag,
//                                                     data,
//                                                 })
//                                             }
//                                             placeholder="Add value"
//                                             name={record.type}
//                                             onInputChange={debounce(setTypeAhead, 500)}
//                                             options={option}
//                                             key={i}
//                                             classNames={styles.mb5}
//                                             value={newValues}
//                                         />
//                                         {!!option?.length && (
//                                             <Tooltip title="Select all option">
//                                                 <IconButton
//                                                     onClick={() =>
//                                                         onSelectAll(option, newValues, index, type, tag, data)
//                                                     }
//                                                 >
//                                                     <DoneAllIcon />
//                                                 </IconButton>
//                                             </Tooltip>
//                                         )}
//                                     </Stack>
//                                 );
//                             })}
//                         </Stack>
//                     </Stack>
//                 );
//             },
//         },
//         {
//             title: "Action",
//             dataIndex: "text",
//             minWidth: 50,
//             width: 100,
//             canSort: true,
//             maxTitleLength: 15,
//             render: (record: any, index: number) => (
//                 <IconButton onClick={() => dispatch(deleteCriteria(index))} sx={{ height: "fit-content" }}>
//                     <Tooltip title={record.deleted ? "To be deleted" : "Delete"}>
//                         <DeleteIcon color={record.deleted ? "error" : "action"} />
//                     </Tooltip>
//                 </IconButton>
//             ),
//         },
//     ];

//     return (
//         <Dialog
//             open={true}
//             classes={{
//                 paper: styles.container,
//             }}
//             onClose={onClose}
//         >
//             <DialogTitle>
//                 <LeadRatingCriteriaName />
//             </DialogTitle>
//             <DialogContent>
//                 <CustomTable
//                     columns={columns}
//                     total={leadRatingCriteria?.length}
//                     rows={leadRatingCriteria}
//                     minHeight="400px"
//                     isLoading={false}
//                     tableContainerStyles={{
//                         boxShadow: "none",
//                     }}
//                 />
//             </DialogContent>
//             <DialogActions>
//                 <Tooltip title="Add lead score criteria">
//                     <IconButton onClick={() => dispatch(addCriteria())} sx={{ padding: 0 }}>
//                         <AddCircleOutlineIcon fontSize="large" sx={{ color: "#479bd2" }} />
//                     </IconButton>
//                 </Tooltip>
//                 <Button
//                     variant="outlined"
//                     onClick={() => {
//                         dispatch(
//                             setCriteria({
//                                 criteriaId: "",
//                                 criteria: [],
//                             })
//                         );
//                         onClose();
//                     }}
//                 >
//                     Cancel
//                 </Button>
//                 {!criteriaId && (
//                     <Button variant="contained" onClick={() => dispatch(addLeadRatingCriteria({ onClose }))}>
//                         Save
//                     </Button>
//                 )}
//                 {criteriaId && (
//                     <Button variant="contained" onClick={() => dispatch(updateLeadRatingCriteria({ onClose }))}>
//                         Update
//                     </Button>
//                 )}
//             </DialogActions>
//         </Dialog>
//     );
// }

// function LeadRatingCriteriaName() {
//     const value = useSelector(selectCriteriaName);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         return () => {
//             //reset global store
//             dispatch(setCriteriaName(""));
//         };
//     }, []);

//     const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
//         const newValue = event.target.value;
//         dispatch(setCriteriaName(newValue));
//     };

//     return (
//         <TextField
//             variant="standard"
//             value={value}
//             onChange={handleChange}
//             placeholder="Enter Criteria Name"
//             sx={{ width: "50%" }}
//             inputProps={{
//                 maxLength: 60,
//             }}
//         />
//     );
// }

// const marks = [
//     {
//         value: 1,
//     },
//     {
//         value: 2,
//     },
//     {
//         value: 3,
//     },
// ];

// const numberToImportanceMapping = ["low", "medium", "high"];

// function getImportanceMapping(mappedValue: string): number {
//     return numberToImportanceMapping.indexOf(mappedValue) + 1;
// }

// function ImportanceSlider({ index, value }: { index: number; value: (typeof numberToImportanceMapping)[number] }) {
//     const dispatch = useDispatch();
//     const [importanceValue, setImportanceValue] = useState<number>(() => getImportanceMapping(value) || 1);

//     const debouncedDispatch = useCallback(
//         debounce((value: string) => {
//             dispatch(
//                 editCriteria({
//                     index,
//                     key: "order",
//                     item: value,
//                 })
//             );
//         }, 500),
//         []
//     );

//     const handleChange = (event: Event, newValue: number | number[]) => {
//         const newValueAsNumber = newValue as number;
//         setImportanceValue(newValueAsNumber);
//         debouncedDispatch(numberToImportanceMapping[newValueAsNumber - 1]);
//     };

//     return (
//         <Box sx={{ width: 80 }}>
//             <Slider
//                 value={importanceValue}
//                 onChange={handleChange}
//                 max={3}
//                 min={1}
//                 marks={marks}
//                 valueLabelDisplay="auto"
//                 sx={(theme) => ({
//                     "& .MuiSlider-valueLabel": {
//                         paddingX: "0.5rem",
//                     },
//                     "& .MuiSlider-valueLabelLabel": {
//                         fontSize: theme.typography.caption,
//                     },
//                 })}
//             />
//         </Box>
//     );
// }
// const SelectValues = ({ value, onChange, options }: { value: string; onChange: Function; options: Option[] }) => {
//     return (
//         <TextField select variant="standard" sx={{ minWidth: "120px", fontSize: "14px" }} value={value}>
//             {options.map((item, i) => {
//                 return (
//                     <MenuItem key={i} value={item.value} onClick={() => onChange(item, i)} sx={{ fontSize: "14px" }}>
//                         {item.label}
//                     </MenuItem>
//                 );
//             })}
//         </TextField>
//     );
// };

// export const LeadRatingModalData = ({ onClose }: { onClose: () => void }) => {
//     return (
//         <Dialog
//             open={true}
//             classes={{
//                 paper: styles.container,
//             }}
//             onClose={onClose}
//         >
//             <DialogTitle>Lead Rating</DialogTitle>
//             <DialogContent>
//                 <LeadRatingList onClose={onClose} />
//             </DialogContent>
//         </Dialog>
//     );
// };

// export const LeadRatingMenuItem = ({ handleClose, sx, showLeadRatingModal }: BDProjectMenuItem) => {
//     return (
//         <MenuItem
//             onClick={() => {
//                 showLeadRatingModal?.();
//                 handleClose();
//             }}
//             sx={sx}
//         >
//             <ListItemIcon>
//                 <CreateIcon fontSize="small" />
//             </ListItemIcon>
//             <div className={styles.menuItemText}>Edit Lead Rating</div>
//             {/* <CustomListItemText>Edit Lead Rating</CustomListItemText> */}
//         </MenuItem>
//     );
// };


import { useEffect, useState, useCallback , useRef } from "react";
import { debounce } from "lodash";
import {
    IconButton,
    Tooltip,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Radio,
    Stack,
    TextField,
    MenuItem,
    Box,
    Slider,
    Typography,
    ListItemIcon,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import CreatableSelect from "../../../components/CreatableSelect/CreatableSelect";
import styles from "./LeadRating.module.css";
import {
    employeeCount,
    addCriteria,
    addInitNewCriteria,
    getLeadRatingCriteriaList,
    selectCriteriaId,
    setCriteria,
    setLeadRatingCriteriaList,
    submitCriteriaForInsightGeneration,
    updateLeadRatingCriteria,
    selectLeadRatingCriteriaList,
    selectLeadRatingCriteria,
    editCriteria,
    leadTagValues,
    leadTypeValues,
    deleteCriteria,
    addLeadRatingCriteria,
    selectCriteriaName,
    setCriteriaName,
} from "./LeadRating.slice";
import { selectCurrProject } from "../../../pages/allProjects/index.reducer";
import { Option } from "../../../common";
import {
    selectIndustryOptions,
    selectLocationOptions,
    selectJobTitleOptions,
    getTypeAhead,
} from "../../../pages/AdvancedFilters/Components/Filters/Filters.reducer";
import { MultiValue, ActionMeta } from "react-select";
import useWindowSize from "../../../hooks/useWindowSize";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { BDProjectMenuItem } from "../bd.types";
import { CustomListItemText } from "../../../pages/project/components/header";

export const LeadRatingList = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const isSubmittingLeadScore = useSelector(checkIfLoading(submitCriteriaForInsightGeneration.type));
    const leadRatingCriteriaList = useSelector(selectLeadRatingCriteriaList);
    const criteriaId = useSelector(selectCriteriaId);
    const isLoading = useSelector(checkIfLoading(getLeadRatingCriteriaList.type));
    const [showCriteriaModal, setShowCriteriaModal] = useState(false);


    useEffect(() => {
        dispatch(getLeadRatingCriteriaList());
    }, []);

    const onClickCriteria = (item: any, index: number) => {
        const criteriaList = generateCriteria(item);
        dispatch(
            setCriteria({
                criteriaId: item._id,
                criteria: criteriaList,
                criteriaName: item?.criteriaName,
            })
        );

        setShowCriteriaModal(true);
    };

    const generateCriteria = (item: any) => {
        let title: any = item.leadScoreAlgorithm.filter((item: any) => item.type === "role");
        const titleData: any = {
            data: {},
        };
        title.forEach((item: any) => {
            titleData.id = item._id;
            titleData.type = item.type;
            titleData.order = item.order ? "high" : "medium";
            titleData.data[item.tag] = item.values;
        });

        let country = item.leadScoreAlgorithm.filter((item: any) => item.type === "country");
        const countryData: any = {
            data: {},
        };
        country.forEach((item: any) => {
            countryData.id = item._id;
            countryData.type = item.type;
            countryData.order = item.order ? "high" : "medium";
            countryData.data[item.tag] = item.values;
        });
        let city = item.leadScoreAlgorithm.filter((item: any) => item.type === "city");
        const cityData: any = {
            data: {},
        };
        city.forEach((item: any) => {
            cityData.id = item._id;
            cityData.type = item.type;
            cityData.order = item.order ? "high" : "medium";
            cityData.data[item.tag] = item.values;
        });
        let industry = item.leadScoreAlgorithm.filter((item: any) => item.type === "industry");
        const industryData: any = {
            data: {},
        };
        industry.forEach((item: any) => {
            industryData.id = item._id;
            industryData.type = item.type;
            industryData.order = item.order ? "high" : "medium";
            industryData.data[item.tag] = item.values;
        });
        let empCount = item.leadScoreAlgorithm.filter((item: any) => item.type === "employeeCount");
        const empCountData: any = {
            data: {},
        };
        empCount.forEach((item: any) => {
            empCountData.id = item._id;
            empCountData.type = item.type;
            empCountData.order = item.order ? "high" : "medium";
            empCountData.data[item.tag] = item.values;
        });
        const criteriaList = [];
        if (title?.length) {
            criteriaList.push(titleData);
        }
        if (country?.length) {
            criteriaList.push(countryData);
        }
        if (city?.length) {
            criteriaList.push(cityData);
        }
        if (industry?.length) {
            criteriaList.push(industryData);
        }
        if (empCount?.length) {
            criteriaList.push(empCountData);
        }
        return criteriaList;
    };

    const onChangeSelect = (item: any, index: number) => {
        const criteriaList = generateCriteria(item);
        const newLeadRatingCriteria = JSON.parse(JSON.stringify(leadRatingCriteriaList));
        newLeadRatingCriteria.map((criteria: any, i: number) => (criteria.isSelected = index === i ? true : false));

        dispatch(
            setCriteria({
                criteriaId: item._id,
                criteria: criteriaList,
            })
        );

        dispatch(setLeadRatingCriteriaList(newLeadRatingCriteria));
    };

    const columns = [
        {
            title: "Select",
            dataIndex: "type",
            minWidth: 150,
            canSort: true,
            maxTitleLength: 12,
            render: (record: any, index: number) => {
                console.log({ isSelected: record?.isSelected });
                return <Radio checked={record.isSelected} onChange={() => onChangeSelect(record, index)} />;
            },
        },
        {
            title: "Name",
            dataIndex: "type",
            minWidth: 150,
            canSort: true,
            maxTitleLength: 12,
            render: (record: any, index: number) => {
                const name = record?.criteriaName || `criteria-${index + 1}`;
                return (
                    <Button variant="text" onClick={() => onClickCriteria(record, index)}>
                        {name}
                    </Button>
                );
            },
        },
        {
            title: "Created on",
            dataIndex: "createdAt",
            minWidth: 150,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => dayjs(record.createdAt).format("MMM D, h:mm A"),
        },
        {
            title: "Criteria count",
            dataIndex: "leadScoreAlgorithm",
            minWidth: 250,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => record.leadScoreAlgorithm?.length,
        },
    ];

    const size = useWindowSize();
    const tableHeight = size.height - 450;

    return (
        <Dialog
            open={true}
            classes={{
                paper: styles.container,
            }}
        >
            <DialogTitle>Lead Rating</DialogTitle>
            <DialogContent>
                <CustomTable
                    columns={columns}
                    total={leadRatingCriteriaList?.length}
                    rows={leadRatingCriteriaList}
                    tableHeight={tableHeight}
                    isLoading={isLoading}
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setShowCriteriaModal(true);
                        dispatch(
                            setCriteria({
                                criteriaId: "",
                                criteria: [],
                            })
                        );
                        dispatch(addInitNewCriteria());
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                >
                    Add New
                </Button>
                <Stack flexDirection="row">
                    <Button variant="outlined" onClick={onClose} sx={{ marginRight: "10px" }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        disabled={isSubmittingLeadScore}
                        onClick={() => {
                            criteriaId &&
                                dispatch(
                                    submitCriteriaForInsightGeneration({
                                        onClose,
                                    })
                                );
                            !criteriaId && onClose();
                        }}
                    >
                        {isSubmittingLeadScore ? "Submitting..." : "Submit"}
                    </Button>
                </Stack>
            </DialogActions>
            {showCriteriaModal && <LeadRatingModal onClose={() => setShowCriteriaModal(false)} />}
        </Dialog>
    );
};

export function LeadRatingModal({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const projectData = useSelector(selectCurrProject);
    const leadRatingCriteria = useSelector(selectLeadRatingCriteria);
    const jobTitleOptions = useSelector(selectJobTitleOptions);
    const locations = useSelector(selectLocationOptions);
    const industryOptions = useSelector(selectIndustryOptions);
    const criteriaId = useSelector(selectCriteriaId);
    const inputContainerRefs = useRef<HTMLDivElement[]>([]);
    const [typeAhed, setTypeAhead] = useState<Option>({
        value: "",
        label: "",
    });

    const creatableSelectOptions: any = {
        role: jobTitleOptions,
        location: locations,
        industry: industryOptions,
        employeeCount,
    };
    useEffect(() => {
        if (!typeAhed.value) return;
        const newTypehead = { ...typeAhed, projectId: projectData?._id };

        if (newTypehead.label === "country" || newTypehead.label === "city") {
            newTypehead.label = "location";
        }
        dispatch(getTypeAhead(newTypehead));
    }, [typeAhed]);

    const onChangeTypedField = ({
        newValue,
        action,
        index,
        type,
        tag,
        data,
    }: {
        newValue: MultiValue<Option>;
        action: { action: string };
        index: number;
        type: string;
        tag: string;
        data: any;
    }) => {
        const newData = { ...data };
        const isCreate =
            action.action === "create-option" || action.action === "select-option" || action.action === "remove-value";
        const canClear = action.action === "clear" || action.action === "remove-value";

        if (isCreate) {
            let item = [];
            if (type === "country" || type === "city" || type === "industry") {
                item = newValue.map((value) => value.label);
            } else {
                item = newValue.map((value) => value.value);
            }
            newData[tag] = item;
            console.log({ newData, index });
            dispatch(editCriteria({ index, key: `data`, item: newData }));
        } else if (canClear) {
            newData[tag] = [];
            dispatch(editCriteria({ index, key: "data", item: newData }));
        }
    };

    const onSelectAll = (option: any, values: Option[], index: number, filterKey: string, tag: string, data: any) => {
        const newValue = [...values, ...option];
        const actionMeta = {
            action: "create-option",
        };
        onChangeTypedField({
            newValue,
            action: actionMeta,
            index,
            type: filterKey,
            tag,
            data,
        });
    };

    const columns = [
        {
            title: "Criteria",
            dataIndex: "type",
            minWidth: 100,
            canSort: true,
            maxTitleLength: 12,
            render: (record: any, index: number) => (
                <SelectValues
                    value={record.type}
                    onChange={(item: Option, i: number) =>
                        dispatch(
                            editCriteria({
                                index,
                                key: "type",
                                item: item.value,
                            })
                        )
                    }
                    options={leadTypeValues}
                />
            ),
        },
        {
            title: "Importance",
            dataIndex: "type",
            minWidth: 100,
            canSort: true,
            maxTitleLength: 12,
            render: (record: any, index: number) => {
                return <ImportanceSlider index={index} value={record.type} />;
            },
        },
        {
            title: "User Tag",
            dataIndex: "tag",
            minWidth: 100,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => {
                const data = record.data;

                return (
                    <Stack alignItems="center">
                        {Object.keys(data).map((value: string, i) => (
                            <Typography sx={{ padding: "10px 5px", fontSize: "14px" ,  height: inputContainerRefs.current[index]?.children[i]?.clientHeight,

                     textAlign: "center", display : "flex", alignItems : "center"
                             }} key={i}>
                                {
                                    //@ts-ignore
                                    leadTagValues[value]
                                }
                            </Typography>
                        ))}
                    </Stack>
                );
            },
        },
        {
            title: "Input",
            dataIndex: "text",
            minWidth: 300,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => {
                const data = record.data;
                let type = record?.type;
                if (type === "country" || type === "city") {
                    type = "location";
                }
                const option = creatableSelectOptions[type] || [];

                return (
                    <Stack flexDirection="row" alignItems="center" sx={{ lineHeight: "2.2", width: "400px" }}  >
                        <Stack flex={1} ref = {el => { if (el) inputContainerRefs.current[index] = el }}>
                            {Object.keys(data).map((tag, i) => {
                                const newValues = data[tag]?.map((item: string) => {
                                    return {
                                        label: item,
                                        value: item,
                                    };
                                });

                            

                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <Stack alignItems="flex-start" flexDirection="row"  >
                                        <CreatableSelect 
                                            onChange={(newValue: MultiValue<Option>, action: ActionMeta<Option>) =>
                                                onChangeTypedField({
                                                    newValue,
                                                    action,
                                                    index,
                                                    type: record.type,
                                                    tag,
                                                    data,
                                                })
                                            }
                                        
                                            placeholder="Add value"
                                            name={record.type}
                                            onInputChange={debounce(setTypeAhead, 500)}
                                            options={option}
                                            key={i}
                                            classNames={styles.mb5}
                                            value={newValues}
                                        />
                                        {!!option?.length && (
                                            <Tooltip title="Select all option">
                                                <IconButton
                                                    onClick={() =>
                                                        onSelectAll(option, newValues, index, type, tag, data)
                                                    }
                                                >
                                                    <DoneAllIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "text",
            minWidth: 50,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => (
                <IconButton onClick={() => dispatch(deleteCriteria(index))} sx={{ height: "fit-content" }}>
                    <Tooltip title={record.deleted ? "To be deleted" : "Delete"}>
                        <DeleteIcon color={record.deleted ? "error" : "action"} />
                    </Tooltip>
                </IconButton>
            ),
        },
    ];

    return (
        <Dialog
            open={true}
            classes={{
                paper: styles.container,
            }}
            onClose={onClose}
        >
            <DialogTitle>
                <LeadRatingCriteriaName />
            </DialogTitle>
            <DialogContent>
                <CustomTable
                    columns={columns}
                    total={leadRatingCriteria?.length}
                    rows={leadRatingCriteria}
                    minHeight="400px"
                    isLoading={false}
                    tableContainerStyles={{
                        boxShadow: "none",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Tooltip title="Add lead score criteria">
                    <IconButton onClick={() => dispatch(addCriteria())} sx={{ padding: 0 }}>
                        <AddCircleOutlineIcon fontSize="large" sx={{ color: "#479bd2" }} />
                    </IconButton>
                </Tooltip>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatch(
                            setCriteria({
                                criteriaId: "",
                                criteria: [],
                            })
                        );
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                {!criteriaId && (
                    <Button variant="contained" onClick={() => dispatch(addLeadRatingCriteria({ onClose }))}>
                        Save
                    </Button>
                )}
                {criteriaId && (
                    <Button variant="contained" onClick={() => dispatch(updateLeadRatingCriteria({ onClose }))}>
                        Update
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

function LeadRatingCriteriaName() {
    const value = useSelector(selectCriteriaName);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            //reset global store
            dispatch(setCriteriaName(""));
        };
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setCriteriaName(newValue));
    };

    return (
        <TextField
            variant="standard"
            value={value}
            onChange={handleChange}
            placeholder="Enter Criteria Name"
            sx={{ width: "50%" }}
            inputProps={{
                maxLength: 60,
            }}
        />
    );
}

const marks = [
    {
        value: 1,
    },
    {
        value: 2,
    },
    {
        value: 3,
    },
];

const numberToImportanceMapping = ["low", "medium", "high"];

function getImportanceMapping(mappedValue: string): number {
    return numberToImportanceMapping.indexOf(mappedValue);
}

function ImportanceSlider({ index, value }: { index: number; value: string }) {
    const dispatch = useDispatch();
    const [importanceValue, setImportanceValue] = useState<number>(() => getImportanceMapping(value) || 0);

    const debouncedDispatch = useCallback(
        debounce((value: string) => {
            dispatch(
                editCriteria({
                    index,
                    key: "order",
                    item: value,
                })
            );
        }, 500),
        []
    );

    const handleChange = (event: Event, newValue: number | number[]) => {
        const newValueAsNumber = newValue as number;
        setImportanceValue(newValueAsNumber);
        debouncedDispatch(numberToImportanceMapping[newValueAsNumber - 1]);
    };

    return (
        <Box sx={{ width: 80 }}>
            <Slider
                value={importanceValue}
                onChange={handleChange}
                max={3}
                min={1}
                marks={marks}
                valueLabelDisplay="auto"
                sx={(theme) => ({
                    "& .MuiSlider-valueLabel": {
                        paddingX: "0.5rem",
                    },
                    "& .MuiSlider-valueLabelLabel": {
                        fontSize: theme.typography.caption,
                    },
                })}
            />
        </Box>
    );
}

const SelectValues = ({ value, onChange, options }: { value: string; onChange: Function; options: Option[] }) => {
    return (
        <TextField select variant="standard" sx={{ minWidth: "120px", fontSize: "14px" }} value={value}>
            {options.map((item, i) => {
                return (
                    <MenuItem key={i} value={item.value} onClick={() => onChange(item, i)} sx={{ fontSize: "14px" }}>
                        {item.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export const LeadRatingModalData = ({ onClose }: { onClose: () => void }) => {
    return (
        <Dialog
            open={true}
            classes={{
                paper: styles.container,
            }}
            onClose={onClose}
        >
            <DialogTitle>Lead Rating</DialogTitle>
            <DialogContent>
                <LeadRatingList onClose={onClose} />
            </DialogContent>
    
        </Dialog>
    );
}



export const LeadRatingMenuItem = ({ handleClose, sx, showLeadRatingModal }: BDProjectMenuItem) => {
    return (
        <MenuItem
            onClick={() => {
                showLeadRatingModal?.();
                handleClose();
            }}
            sx={sx}
        >
            <ListItemIcon>
                <CreateIcon fontSize="small" />
            </ListItemIcon>
            <div className={styles.menuItemText}>Edit Lead Rating</div>
            {/* <CustomListItemText>Edit Lead Rating</CustomListItemText> */}
        </MenuItem>
    );
};
