import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { fetchBaseQueryWithZod } from "../../../../utils/api-utils";
import { DataSchema, FetchUserCreditRequest, FetchUserCreditResponse } from "./credit.types";

export const userCreditApi = createApi({
    reducerPath: "userCreditApi",
    baseQuery: fetchBaseQueryWithZod,
    endpoints: (builder) => ({
        fetchUserCredits: builder.query<DataSchema[], FetchUserCreditRequest>({
            query: (filters) => {
                return {
                    url: `candidate/credits-stats?${queryString.stringify(filters)}`,
                    method: "POST",
                };
            },
            transformResponse: (baseQueryReturnValue: { data: DataSchema[] }) => {
                return baseQueryReturnValue?.data;
            },
        }),
    }),
});

export const { useFetchUserCreditsQuery } = userCreditApi;
