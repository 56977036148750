import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { fetchBaseQueryWithZod } from "../../../utils/api-utils";
import { TChatList } from "../types";
import { EQueries } from "./enums";

const { GET_CONVERSATIONS, GET_LATEST_MESSAGE } = EQueries;

export const chatbotApi = createApi({
    reducerPath: "chatbotApi",
    baseQuery: fetchBaseQueryWithZod,
    keepUnusedDataFor: 10,
    tagTypes: [GET_CONVERSATIONS, GET_LATEST_MESSAGE],
    endpoints: (builder) => ({
        getConversations: builder.query<TChatList[], number>({
            query: (userId) => ({
                url: `/assistant-chatbot/list/${userId}`,
                method: "GET",
            }),
            providesTags: [GET_CONVERSATIONS],
            transformResponse(baseQueryReturnValue: { data: TChatList[] }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        createChart: builder.mutation<string, any>({
            query: (textMessage) => {
                return {
                    url: `/assistant-chatbot/create`,
                    method: "POST",
                    body: { textMessage },
                };
            },
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
            invalidatesTags: [GET_CONVERSATIONS],
        }),
        getChat: builder.query<TChatList, string | undefined>({
            query: (threadId) => ({
                url: `/assistant-chatbot/thread/${threadId}`,
                method: "GET",
            }),
            providesTags: [GET_LATEST_MESSAGE],
            transformResponse(baseQueryReturnValue: { data: TChatList }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        updateChat: builder.mutation<any, { threadId: string; textMessage: string }>({
            query: ({ threadId, textMessage }) => ({
                url: `/assistant-chatbot/thread/${threadId}`,
                method: "PUT",
                body: { textMessage },
            }),
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        createActionChat: builder.mutation<string, {query: string; projectId: any}>({
            query: ({query, projectId}) => {
                return {
                    url: `/action-assistant/create-thread`,
                    method: "POST",
                    body: { query, projectId },
                };
            },
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
            invalidatesTags: [GET_CONVERSATIONS],
        }),
        updateActionChat: builder.mutation<any, { threadId: string; userResponse: string }>({
            query: ({ threadId, userResponse }) => ({
                url: `/action-assistant/continue-action`,
                method: "POST",
                body: { threadId, userResponse },
            }),
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
    }),
});

export const {
    // queries
    useGetConversationsQuery,
    useGetChatQuery,
    // lazy queries
    useLazyGetConversationsQuery,
    useLazyGetChatQuery,
    // mutations
    useCreateChartMutation,
    useUpdateChatMutation,
    useCreateActionChatMutation,
    useUpdateActionChatMutation,
} = chatbotApi;
