import React, { useEffect, useRef, useState } from "react";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import ErrorIcon from "@mui/icons-material/Error";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, IconButton, Link, Skeleton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Stack from "@mui/system/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Search from "../../components/Search/Search";
import { setTourIsOpen } from "../../container/TourContainer/TourContainer.reducer";
import { uploadprojectStatsFile } from "../../pages/allProjects/index.reducer";
import {
    acceptInvite,
    fetchPendingInvitations,
    getNotification,
    markActivityNotificationAsRead,
    markReadNotification,
    rejectInvite,
    selectNotification,
    selectPendingInvitations,
} from "../../pages/manageTeams/manageTeams.reducers";
import { INotification, NotificationSMSType } from "../../pages/manageTeams/manageTeams.types";
import {
    checkEasyGrowth,
    checkIfAccountRB2B,
    isSuperAdminUser,
    selectFromInvitation,
    selectShowAllProjects,
    selectUser,
    setFromInvitation,
    updateUser,
} from "../../pages/Signin/Signin.reducer";
import { selectIsSuperAdminView, setIsSuperAdminView } from "../../pages/superAdmin/superAdmin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectAddExclusionListModal, selectEditExclusionListModal } from "../../store/reducers/modals.slice";
import handleException from "../../utils/sentry";
import AutoPilot from "../AutoPilot/AutoPilot";
import AutoPilotSwitch from "../AutoPilot/AutoPilotSwitch";
import AddExclusionList from "../ExclusionList/AddExclusionList";
import { EditExclusionList } from "../ExclusionList/EditExclustionList";
// import CreateProjectModal from "../ProjectModal/ProjectModal";
// import ReferralButton from "../ReferralButton/ReferralButton";
// import CreateWorkflowModal from "../WorkflowModal/WorkflowModal";
import AccountMenu from "./accountMenu/AccountMenu";
import { CreateProject } from "./CreateProject/CreateProject";
import UploadMarketingPage from "./CreateProject/MarketingPage/Component/UploadMarketingPage";
import { selectShowUploadMarketingCompanyPage } from "./CreateProject/MarketingPage/MarketingPage.reducer";
import GlobalSearch from "./GlobalSearch";
// import ExtensionBtn from "./extensionBtn";
import style from "./navbar.module.scss";

// removed antd modal
// const { confirm } = Modal;

export type INotificationTile = {
    body: string;
    title: string;
    _id: string;
    candidateId: string;
    updatedAt: string;
    createdAt: string;
    onClick?: () => void;
    isSms?: boolean;
};

function getElapsedTimeFormatted(creationTime: string) {
    const currentTime = new Date();
    const _creationTime = new Date(creationTime);
    const timeDifference = currentTime.getTime() - _creationTime.getTime();

    const elapsedHours = Math.floor(timeDifference / (1000 * 60 * 60));

    if (elapsedHours < 24) {
        return `${elapsedHours} hours ago`;
    } else {
        const day = _creationTime.getDate();
        const month = _creationTime.toLocaleString("default", {
            month: "short",
        });
        let daySuffix = "th";
        if (day === 1 || day === 21 || day === 31) {
            daySuffix = "st";
        } else if (day === 2 || day === 22) {
            daySuffix = "nd";
        } else if (day === 3 || day === 23) {
            daySuffix = "rd";
        }

        return `${day}${daySuffix} ${month}`;
    }
}

function groupSMSByProjectName(smsNotifications: NotificationSMSType[]) {
    const grouped: { [key: string]: NotificationSMSType[] } = {};

    smsNotifications.forEach((obj) => {
        const projectName = obj.projectName;
        if (!grouped[projectName]) {
            grouped[projectName] = [];
        }
        grouped[projectName].push(obj);
    });
    return grouped;
}

function EmptyNotificationsIcon() {
    return (
        <Stack justifyContent="center" alignItems="center" m={8}>
            <svg
                style={{ alignSelf: "center" }}
                fill="#479BD2"
                width="75px"
                height="75px"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#479BD2"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M888 853q-21-48-56-123-7-15 2-28 32-54 46-115t8-123q-8-93-58-172-49-77-127-125-79-49-172-56-88-7-170 24-78 29-137.5 88.5T135 362q-31 81-24 170 7 92 56 172 48 78 125 127 79 50 172 58 62 6 123-8t115-47q13-8 28-2l123 56q11 5 21 .5t14.5-14.5-.5-21zM485 721q-11 27-38.5 38.5t-55 0-39-39 0-55 39-38.5 55 0 38.5 38.5 0 54.5v1zm-25-127q-20-8-28-28t0-40l73-248q8-19 24.5-32.5t38-16 41 5.5 32.5 25 15.5 38-5.5 41L527 566q-8 19-27.5 27.5t-39.5.5z"></path>
                </g>
            </svg>
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[500] })} fontWeight={500}>
                You have no new notifications
            </Typography>
        </Stack>
    );
}

type ShowConfirmProps = {
    orgName: string;
    handleAccept: () => void;
};

// const showConfirm = ({ orgName, handleAccept }: ShowConfirmProps) => {
//     confirm({
//         title: `Do You Really want to join ${orgName}?`,
//         icon: <ErrorIcon />,
//         content: "All Your existing Projects and Data will be lost.",
//         centered: true,
//         closable: true,
//         okText: "Accept",
//         style: { padding: "20px 30px" },
//         onOk() {
//             handleAccept();
//             return new Promise((resolve, reject) => {
//                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
//             }).catch((error: any) => {
//                 handleException(error);
//                 console.error("Oops errors!");
//             });
//         },
//         onCancel() {},
//     });
// };

type IInvitationTile = {
    sender: string;
    org: string;
    inviteId: string;
};

// function InviteTile({ inviteId, org, sender }: IInvitationTile) {
//     const dispatch = useDispatch();
//     const isAcceptLoading = useSelector(checkIfLoading(acceptInvite.type));
//     const isRejectLoading = useSelector(checkIfLoading(rejectInvite.type));
//     const isDisable = isAcceptLoading || isRejectLoading;

//     const handleAccept = () => {
//         dispatch(
//             acceptInvite({
//                 _id: inviteId,
//                 status: "ACCEPTED",
//                 action: acceptInvite.type,
//             })
//         );
//     };

//     const handleReject = () => {
//         dispatch(
//             rejectInvite({
//                 _id: inviteId,
//                 status: "REJECTED",
//                 action: rejectInvite.type,
//             })
//         );
//     };

//     return (
//         <Stack
//             sx={(theme) => ({
//                 borderRadius: 1,
//                 ":hover": {
//                     cursor: "pointer",
//                     background: theme.palette.grey[200],
//                 },
//             })}
//             width={"full"}
//             padding={2}
//             px={4}
//             spacing={1}
//         >
//             <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
//                 <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
//                     <DraftsRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
//                     <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
//                         Invitation
//                     </Typography>
//                 </Stack>
//             </Stack>
//             <Stack fontWeight={"light"} direction="column" spacing={0.5}>
//                 <Typography sx={(theme) => ({ color: theme.palette.grey[600] })} fontSize={"15px"}>
//                     Please Review the invitation request for {org}
//                 </Typography>
//             </Stack>
//             <Stack alignItems={"center"} direction={"row"} gap={2}>
//                 <Button
//                     variant="outlined"
//                     disabled={isDisable}
//                     onClick={() => showConfirm({ orgName: org, handleAccept })}
//                     color="primary"
//                     sx={{ boxShadow: "none" }}
//                 >
//                     Accept
//                 </Button>

//                 <Button
//                     onClick={handleReject}
//                     variant="outlined"
//                     disabled={isDisable}
//                     color="error"
//                     sx={{ boxShadow: "none" }}
//                 >
//                     Decline
//                 </Button>
//             </Stack>
//         </Stack>
//     );
// }

function NotificationTile({
    title,
    body,
    _id,
    candidateId,
    createdAt,
    onClick,
    redirectUrl,
}: INotification & INotificationTile) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(markReadNotification([_id]));
        dispatch(markActivityNotificationAsRead(_id));
        if (redirectUrl) {
            window.open(`/candidate-reachout${redirectUrl}`);
        }
        window.open(`/candidate-reachout/${candidateId}`);
        if (onClick) {
            onClick();
        }
    };

    return (
        <Stack
            sx={(theme) => ({
                borderRadius: 1,
                ":hover": {
                    cursor: "pointer",
                    background: theme.palette.grey[200],
                },
            })}
            width={"full"}
            padding={2}
            px={4}
            spacing={1}
            onClick={handleClick}
        >
            <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                    <NotificationsRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                    <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                        Activity
                    </Typography>
                </Stack>
                <Typography sx={(theme) => ({ color: theme.palette.grey[600] })} fontSize={"15px"}>
                    {getElapsedTimeFormatted(createdAt)}
                </Typography>
            </Stack>

            <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                <Typography sx={(theme) => ({ color: theme.palette.grey[600] })} fontSize={"15px"}>
                    {body}
                </Typography>
            </Stack>
        </Stack>
    );
}

function SMSTile({ onClick, notifications }: { onClick: () => void; notifications: NotificationSMSType[] }) {
    const uniqueCandidateIds = new Set();
    notifications = notifications.filter((notification) => {
        if (uniqueCandidateIds.has(notification.candidateId)) {
            return false;
        } else {
            uniqueCandidateIds.add(notification.candidateId);
            return true;
        }
    });

    const dispatch = useDispatch();

    const handleClick = (notificationIds: string[], redirectUrl: string) => {
        dispatch(markReadNotification(notificationIds));
        window.open(redirectUrl);
        onClick();
    };

    if (notifications?.length >= 4) {
        return (
            <Stack
                sx={(theme) => ({
                    borderRadius: 1,
                    ":hover": {
                        cursor: "pointer",
                        background: theme.palette.grey[200],
                    },
                })}
                width={"full"}
                padding={2}
                px={4}
                spacing={1}
                onClick={() =>
                    handleClick(
                        notifications.map((i) => i._id),
                        notifications[0].redirectUrl
                    )
                }
            >
                <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                    <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                        <MessageRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                        <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                            Message
                        </Typography>
                    </Stack>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                        fontSize={"15px"}
                    >
                        {getElapsedTimeFormatted(notifications[0].createdAt)}
                    </Typography>
                </Stack>

                <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                        fontSize={"15px"}
                    >
                        {`You received ${notifications?.length} SMS replies in your project '${notifications[0]?.projectName}' with the latest SMS received from ${notifications[0]?.candidateName}`}
                    </Typography>
                </Stack>
            </Stack>
        );
    }

    return (
        <>
            {notifications.map((notification, index) => {
                return (
                    <Stack
                        key={index}
                        sx={(theme) => ({
                            borderRadius: 1,
                            ":hover": {
                                cursor: "pointer",
                                background: theme.palette.grey[200],
                            },
                        })}
                        width={"full"}
                        padding={2}
                        px={4}
                        spacing={1}
                        onClick={() => handleClick([notification._id], notification.redirectUrl)}
                    >
                        <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                            <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                                <MessageRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                                <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                                    Message
                                </Typography>
                            </Stack>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                })}
                                fontSize={"15px"}
                            >
                                {getElapsedTimeFormatted(notification.createdAt)}
                            </Typography>
                        </Stack>

                        <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                })}
                                fontSize={"15px"}
                            >
                                {`You received an SMS reply from ${notification.candidateName} in your project '${notification.projectName}' `}
                            </Typography>
                        </Stack>
                    </Stack>
                );
            })}
        </>
    );
}

function NotificationSkeleton() {
    return (
        <Stack width={"full"} spacing={1}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="rounded" sx={{ height: 20, width: 100 }} />
            </Stack>
            <Skeleton variant="rounded" height={45} />
        </Stack>
    );
}

function Notifications() {
    const invitations = useSelector(selectPendingInvitations);
    const fromInvitation = useSelector(selectFromInvitation);
    const isNotificationLoading = useSelector(checkIfLoading(getNotification.type));
    const isPendingInvitationsLoading = useSelector(checkIfLoading(fetchPendingInvitations.type));

    let notifications = useSelector(selectNotification);

    const dispatch = useDispatch();
    const notificationIconRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (!anchorEl && fromInvitation) {
            dispatch(setFromInvitation(false));
        }

        if (anchorEl) {
            dispatch(fetchPendingInvitations({ action: fetchPendingInvitations.type }));
            dispatch(getNotification());
        }
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const pendingInvitations = invitations.filter(({ status }) => status === "PENDING");

    notifications = notifications.filter((i) => i?.active);

    if (!notifications?.length) {
        return null;
    }

    const activityNotifications = notifications?.filter((notification) => !notification.isSms);

    const smsNotifications: NotificationSMSType[] = notifications?.filter((notification) => notification.isSms);

    const groupedSmsNotification = groupSMSByProjectName(smsNotifications);

    const totalNotifications =
        activityNotifications?.length +
        pendingInvitations?.length +
        Object.values(groupedSmsNotification)?.reduce((sum, i) => {
            return sum + (i?.length >= 4 ? 1 : i?.length);
        }, 0);

    return (
        <div style={{ position: "relative" }}>
            <IconButton size="small" ref={notificationIconRef} onClick={handleClick}>
                <Badge badgeContent={totalNotifications} color="primary" max={100} style={{ fontFamily: "Poppins" }}>
                    <NotificationsNoneIcon htmlColor="#C2CFE0" sx={{ height: "28px", width: "28px" }} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-elevation": {
                        borderRadius: "0.5rem",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    },
                }}
                style={{
                    borderRadius: "1rem",
                    marginTop: "10px",
                    zIndex: 99,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack
                    pb={2}
                    sx={{
                        width: "430px",
                        height: notifications?.length === 0 ? "300px" : "500px",
                    }}
                >
                    {isNotificationLoading || isPendingInvitationsLoading ? (
                        <Stack direction={"column"} justifyContent="center" alignItems="stretch" gap={3} px={2} py={2}>
                            {[...Array(5)].map((_, idx) => {
                                return <NotificationSkeleton key={idx} />;
                            })}
                        </Stack>
                    ) : (
                        <>
                            {notifications?.length === 0 ? (
                                <>
                                    <EmptyNotificationsIcon />
                                </>
                            ) : (
                                <>
                                    <Typography
                                        px={4}
                                        py={2}
                                        fontWeight={"medium"}
                                        fontSize={"15px"}
                                        sx={(theme) => ({
                                            borderBottom: "1px solid",
                                            borderColor: theme.palette.grey[200],
                                        })}
                                    >
                                        Notifications
                                    </Typography>
                                    <Box
                                        style={{
                                            maxHeight: "calc(100% - 40px - 1rem)",
                                            overflow: "auto",
                                        }}
                                        sx={(theme) => ({
                                            "& > div": {
                                                borderBottom: "1px solid",
                                                borderColor: theme.palette.grey[200],
                                            },
                                        })}
                                    >
                                        {activityNotifications
                                            .filter((item) => item?.active || item?.status === "PENDING")
                                            .map((item, index: number) => {
                                                return (
                                                    <NotificationTile
                                                        {...item}
                                                        onClick={handleClose}
                                                        key={`notification-${index}`}
                                                    />
                                                );
                                            })}
                                        {/* {pendingInvitations.map(({ senderName, org, id }, index) => {
                                            return (
                                                <InviteTile
                                                    key={`invite-tile-${index}`}
                                                    sender={senderName}
                                                    org={org}
                                                    inviteId={id}
                                                />
                                            );
                                        })} */}
                                        {Object.keys(groupedSmsNotification).map((key, index) => {
                                            return (
                                                <SMSTile
                                                    key={`sms-tile-${index}`}
                                                    onClick={handleClose}
                                                    notifications={groupedSmsNotification[key]}
                                                />
                                            );
                                        })}
                                    </Box>
                                </>
                            )}
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack mt={"1rem"} px={2} display={"flex"} justifyContent={"center"} height={"40px"}>
                                <Link target="_blank" href={"/teams"}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            borderRadius: "0.5rem",
                                            boxShadow: "none",
                                        }}
                                    >
                                        See More
                                    </Button>
                                </Link>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Popover>
        </div>
    );
}

type NavbarProps = {
    variant?: "base" | "modified";
    containerStyle?: React.CSSProperties;
    isAdmin?: boolean;
};

export default function Navbar({ variant = "base", containerStyle, isAdmin }: NavbarProps) {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const isAddExclusionListModalOpen = useSelector(selectAddExclusionListModal);
    // const [showReferralModal, setShowReferralModal] = useState<boolean>(true);
    // const [isProjectModalOpen, setIsProjectModalOpen] = useState<boolean>(false);
    // const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState<boolean>(false);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isRB2BAccount = useSelector(checkIfAccountRB2B);

    const isSuperAdmin = useSelector(isSuperAdminUser);
    const showUploadMarketingCompanyPage = useSelector(selectShowUploadMarketingCompanyPage);
    const showAllProjects = useSelector(selectShowAllProjects);
    // const closeProjectModal = () => setIsProjectModalOpen(false);
    // const closeWorkflowModal = () => setIsWorkflowModalOpen(false);

    // const handleStartWalkthrough = () => {
    //     navigate(`/projects/9999`, {
    //         replace: true,
    //     });
    //     dispatch(setTourIsOpen(true));
    // };

    const isProjectPage = pathname.startsWith("/projects");
    const isContactOverviewPage = pathname.startsWith("/contact-overview") || pathname.startsWith("/manage-prospects");
    const currPath = pathname.match("/");
    const currData = currPath?.input;
    let showSearchAllProject = false;
    if (currData === "/") {
        showSearchAllProject = true;
    }

    return (
        <div className={style["nav__container"]} style={containerStyle}>
            <Stack direction="row" justifyContent={isProjectPage ? "space-between" : "flex-end"}>
                <Stack direction="row" alignItems="center" gap={1}>
                    {!isRB2BAccount && isProjectPage && <AutoPilotSwitch />}
                    {!isRB2BAccount && isSuperAdmin && <AutoPilot />}
                </Stack>
                <TextField
                    type="file"
                    id="uploadStatsCsv"
                    //@ts-ignore
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        e.target.files[0] && dispatch(uploadprojectStatsFile(e.target.files[0]));
                    }}
                    sx={{ display: "none" }}
                />
                {isSuperAdminView && (
                    <Button
                        onClick={() => {
                            navigate("/superAdmin/userList");
                            dispatch(setIsSuperAdminView(false));
                        }}
                    >
                        Disable super admin view
                    </Button>
                )}
                {showUploadMarketingCompanyPage && <UploadMarketingPage />}
                {isAddExclusionListModalOpen && <AddExclusionList />}
                <CreateProject />

                <Stack direction="row" alignItems="center">
                    {isAdmin && (
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography>{showAllProjects ? "All projects" : "My projects"}</Typography>
                            <Switch
                                checked={showAllProjects}
                                onChange={(e) => {
                                    // @ts-ignore
                                    dispatch(updateUser({ showAllProjects: e.target.checked }));
                                }}
                            />
                        </Stack>
                    )}
                    <Notifications />

                    <GlobalSearch />
                    <AccountMenu />
                    {/* <ReferralButton showReferralModal={showReferralModal} setShowReferralModal={setShowReferralModal} /> */}
                </Stack>
            </Stack>
            {/* <CreateProjectModal open={isProjectModalOpen} close={closeProjectModal} /> */}
            {/* <CreateWorkflowModal open={isWorkflowModalOpen} close={closeWorkflowModal} /> */}
        </div>
    );
}
