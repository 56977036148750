import { ChipTypeMap } from "@mui/material";
import { AutocompleteRenderInputParams, AutocompleteProps } from "@mui/material/Autocomplete";
import { CustomTextFieldForAutocomplete } from "./CustomTextFieldForAutocomplete";
import { commonPaperProps } from "../pages/all-candidates/components/ContactOverviewActivities/EditActivityModal";
export function useAutocompleteProps<
    T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>(isLoading: boolean = false) {
    const getAutoCompleteProps: (isLoading?: boolean) => Partial<
        Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "renderInput">
    > & {
        renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
    } = (isLoading) => {
        return {
            sx: {
                "&.MuiAutocomplete-root": {
                    "& .MuiTextField-root": {
                        "& .MuiOutlinedInput-root": {
                            paddingRight: "0.7rem",
                        },
                    },
                },
            },
            slotProps: {
                paper: {
                    sx: commonPaperProps,
                },
            },
            renderInput: (params: AutocompleteRenderInputParams) =>
                (<CustomTextFieldForAutocomplete {...params} isLoading={isLoading} />) as React.ReactNode,
        };
    };

    return { getAutoCompleteProps };
}
