import { z } from "zod";

const companyNamesSchema = z.array(z.string());
export type CompanyNames = z.infer<typeof companyNamesSchema>;
const locationSchema = z.object({
    city: z.string().optional(),
    region: z.string().optional(),
    country: z.string().optional(),
    street: z.string().optional(),
    country_code: z.string().optional(),
    postal_code: z.string().optional(),
  });

const revenueSchema = z.object({
    amount: z.string().optional(),
    lastUpdated: z.string().optional(),
  });
const companyDetailsSchema = z.object({
    organizationName: z.string().optional(),
    linkedin:z.string().optional(),
    image: z.string().optional(),
    description: z.string().optional(),
    location: locationSchema.optional(),
    revenue: revenueSchema.optional(),
    employeeCount: z.nullable(z.string()).optional(),
    industries: z.array(z.string()).optional(),
    latestFundingRound: z.string().optional(),
    latestFundingRoundAmount: z.number().optional(),
    organization_funding_total: z.nullable(z.number().optional()),
    fundingDate:z.string().optional()
  });

export const companyDetailsListSchema = companyDetailsSchema;

export type CompanyDetailsList = z.infer<typeof companyDetailsListSchema>;