import { BDCreateProject } from "../../../easy-growth/components/BDCreateProject";
import { withConfigModeHOC } from "../../../hocs/withConfigMode";
import { ESCreateProject } from "./ESCreateProject";
import { IntegratedCreateProject } from "./IntegratedCreateProject";

export const CreateProject = withConfigModeHOC({
    Eg: BDCreateProject,
    // Es: ESCreateProject,
    Es: IntegratedCreateProject,
});
