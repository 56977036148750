import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";
import {
    AllContactsState,
    SubmitCandidateActivityPayload,
    SetCandidatesReachoutTabPayload,
    SelectedProjectPayload,
    ActivityFormState,
    ActivityFormStateKeys,
    ActivityFormStateValues,
    ReferencesFormState,
} from "./all-candidates-reachout.types";
import { allCandidatesApi } from "../../store/reducers/all-candidates.api.slice";
import { isOption, isOptionWithValueAsNumber } from "../../common";
import { ACTIVITIES_SELECTED_PROJECT } from "../all-candidates/all-candidates.utils";

export const initialEditActivityState: ActivityFormState = {
    body: "",
    project: null,
    tag: null,
    assignee: null,
    id: "",
    dueDate: null,
    priority: "",
};

export const initialEditReferencesState: ReferencesFormState = {
    id: "",
    contact: "",
    email: "",
    name: "",
    relation: "",
    linkedin: "",
    networkType: "",
    notes: "",
};

const initialState: AllContactsState = {
    activityFormSubmitMode: "CREATE",
    activityFormState: initialEditActivityState,
    activitiesSelectedProject: {
        label: ACTIVITIES_SELECTED_PROJECT.label,
        value: ACTIVITIES_SELECTED_PROJECT.value,
    },
    insightsSelectedProject: {
        label: "",
        value: -1,
    },
    openEditDrawer: false,
    tab: 0,
    activityLogs: [],
    references: [],
    projects: [],
    orgUsers: [],
    editReferencesState: initialEditReferencesState,
    editFormStateSubmitMode: "CREATE",
    contactReachouts: {
        contactInfo: {},
        reachout: {},
    },
};

export const allContactsReachout = createSlice({
    name: "allCandidatesReachout",
    initialState,
    reducers: {
        editReferences(state, action: PayloadAction<ReferencesFormState>) {
            state.editReferencesState = action.payload;
            state.editFormStateSubmitMode = "EDIT";
            state.openEditDrawer = true;
        },
        editActivity(state, action: PayloadAction<ActivityFormState>) {
            state.activityFormState = action.payload;
            state.activityFormSubmitMode = "EDIT";
            state.openEditDrawer = true;
        },
        setActivityFormFields(
            state,
            action: PayloadAction<{
                key: ActivityFormStateKeys;
                value: ActivityFormStateValues;
            }>
        ) {
            const { key, value } = action.payload;
            if (typeof value === "string" && key === "body") {
                state.activityFormState[key] = value;
            }

            if (isOption(value) && key === "tag") {
                state.activityFormState[key] = value;
            }

            if (isOptionWithValueAsNumber(value) && (key === "assignee" || key === "project")) {
                state.activityFormState[key] = value;
            }
        },
        setInsightsSelectedProject(state, action: SelectedProjectPayload) {
            state.insightsSelectedProject = action.payload;
        },
        setActivitiesSelectedProject(state, action: SelectedProjectPayload) {
            state.activitiesSelectedProject = action.payload;
        },
        setEditDrawer(state, action: PayloadAction<"TOGGLE" | "CLOSE" | "OPEN">) {
            if (action.payload === "TOGGLE") {
                state.openEditDrawer = !state.openEditDrawer;
            }

            if (action.payload === "CLOSE") {
                state.openEditDrawer = false;
            }

            if (action.payload === "OPEN") {
                state.openEditDrawer = true;
            }

            state.activityFormSubmitMode = "CREATE";
            state.activityFormState = initialEditActivityState;

            state.editFormStateSubmitMode = "CREATE";
            state.editReferencesState = initialEditReferencesState;
        },
        setCandidatesReachoutTab(state, action: SetCandidatesReachoutTabPayload) {
            state.tab = action.payload;
        },
        submitCandidateActivity(state, action: SubmitCandidateActivityPayload) {},
        getContactReachout(state, { payload }: { payload: string }) {},
        setContactReachout(state, { payload }: { payload: any }) {
            state.contactReachouts = payload;
        },
        setContactInfo(state, { payload }: { payload: any }) {
            state.contactReachouts.contactInfo = payload;
        },
        setActivityEditMode(state, { payload }: { payload: { index: number; value: boolean } }) {
            state.activityLogs[payload.index].isEdit = payload.value;
        },
        getActivities(state, { payload }: { payload: { candidateId: string } }) {},
        setActivities(state, { payload }: { payload: any }) {
            state.activityLogs = payload;
        },
        addNewActivity(
            state,
            {
                payload,
            }: {
                payload: {
                    createdBy: string;
                    titleTag: string;
                    description: string;
                    candidateId: string;
                    projectId: {
                        name: string;
                        _id: number;
                    };
                    referTo: {
                        name: string;
                        _id: number;
                    };
                    onDone?: () => void;
                };
            }
        ) {},
        addNewReferences(
            state,
            {
                payload,
            }: {
                payload: {
                    name: string;
                    title: string;
                    relation: string;
                    email: string;
                    linkedinUrl: string;
                    body: string;
                    candidateId: string;
                    onDone?: Function;
                };
            }
        ) {},
        deleteActivity(state, { payload }: { payload: { index: number } }) {
            if (state.activityLogs[payload.index].isNewActivity) {
                state.activityLogs.splice(payload.index, 1);
            } else if (state.activityLogs[payload.index].isEdit) {
                state.activityLogs[payload.index].isEdit = false;
            } else {
                state.activityLogs.splice(payload.index, 1);
            }
        },
        getReference(state, { payload }: { payload: string }) {},
        setReference(state, { payload }: { payload: any }) {
            state.references = payload;
        },
        deleteReference(state, { payload }: { payload: { _id: string; candidateId: string } }) {},
        getProjects(state, { payload }: { payload: string }) {},
        setProjects(state, { payload }: { payload: any }) {
            state.projects = payload;
        },
        changeProjectStage(state, { payload }: { payload: any }) {},
        getOrgUser(state) {},
        setOrgUser(state, { payload }: { payload: any }) {
            state.orgUsers = payload;
        },
        cancelActions(state) {},
    },
    extraReducers: (builder) => {
        builder.addMatcher(allCandidatesApi.endpoints.fetchCandidateProjects.matchFulfilled, (state, action) => {
            const projects = action.payload;
            if (projects.length) {
                state.insightsSelectedProject = {
                    label: projects[0].projectName,
                    value: projects[0].projectId,
                };
            }
        });
    },
});

export default allContactsReachout.reducer;

export const {
    setActivitiesSelectedProject,
    setInsightsSelectedProject,
    editReferences,
    setActivityFormFields,
    editActivity,
    setEditDrawer,
    setCandidatesReachoutTab,
    submitCandidateActivity,
    getContactReachout,
    setContactReachout,
    getActivities,
    setActivities,
    addNewActivity,
    deleteActivity,
    setActivityEditMode,
    getReference,
    setReference,
    cancelActions,
    setContactInfo,
    getProjects,
    setProjects,
    changeProjectStage,
    getOrgUser,
    setOrgUser,
    addNewReferences,
    deleteReference,
} = allContactsReachout.actions;

export const selectReferencesFormMode = (state: RootState) => state.allCandidatesReachout.editFormStateSubmitMode;
export const selectReferencesState = (state: RootState) => state.allCandidatesReachout.editReferencesState;
export const selectActivityId = (state: RootState) => state.allCandidatesReachout.activityFormState.id;
export const selectActivitySubmitMode = (state: RootState) => state.allCandidatesReachout.activityFormSubmitMode;
export const selectActivityState = (state: RootState) => state.allCandidatesReachout.activityFormState;
export const selectActivityProject = (state: RootState) => state.allCandidatesReachout.activityFormState.project;
export const selectActivityAssignee = (state: RootState) => state.allCandidatesReachout.activityFormState.assignee;
export const selectActivityBody = (state: RootState) => state.allCandidatesReachout.activityFormState.tag;
export const selectActivity = (state: RootState) => state.allCandidatesReachout.activityFormState.body;
export const selectContactReachouts = (state: RootState) => state.allCandidatesReachout.contactReachouts;
export const selectActivityLogs = (state: RootState) => state.allCandidatesReachout.activityLogs;
export const selectReferences = (state: RootState) => state.allCandidatesReachout.references;
export const selectProjects = (state: RootState) => {
    return state.allCandidatesReachout.projects;
};
export const selectOrgUsers = (state: RootState) => state.allCandidatesReachout.orgUsers;
export const selectCandidatesReachoutsTab = (state: RootState) => state.allCandidatesReachout.tab;
export const selectCandidateReachoutsEditDrawer = (state: RootState) => state.allCandidatesReachout.openEditDrawer;
export const selectActivitiesProject = (state: RootState) => state.allCandidatesReachout.activitiesSelectedProject;
export const selectInsightsSelectedProject = (state: RootState) => state.allCandidatesReachout.insightsSelectedProject;
