import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { FileWithPath } from "react-dropzone";

import { RootState } from "..";

export type WorkflowAttachFile = {
    id: string;
    file: string;
    name: string;
};

type WorkflowUploadState = {
    files: WorkflowAttachFile[];
};

const initialState: WorkflowUploadState = {
    files: [],
};

export type AddFilesPayload = {
    files: FileWithPath[];
    projectId: string;
    close: () => void;
};

export type SetFilesPayload = {
    name: string;
    file: string;
}[];

export const workflowUploadSlice = createSlice({
    name: "workflowUpload",
    initialState,
    reducers: {
        setFilesInState(state, action: PayloadAction<SetFilesPayload>) {
            state.files = action.payload.map((file) => ({
                id: nanoid(),
                ...file,
            }));
        },
        addFiles(state, action: PayloadAction<AddFilesPayload>) {},
        removeFile(state, action: PayloadAction<string>) {
            state.files = state.files.filter((file) => {
                return file.id !== action.payload;
            });
        },
        resetWorkflowUploadState() {
            return initialState;
        },
        cancelActions: (state) => {},
    },
});

export default workflowUploadSlice.reducer;

export const { addFiles, cancelActions, setFilesInState, removeFile, resetWorkflowUploadState } =
    workflowUploadSlice.actions;

export const selectWorkflowUploadFiles = (state: RootState) => state.workflowUpload.files;
