import React from "react";

import ChatbotLoader from "./ChatbotLoader";
import Render from "./Render";

interface ActionProviderProps {
    createChatBotMessage: any;
    setState: any;
    children: any;
    state: any;
}

const ActionProvider = ({ createChatBotMessage, setState, children, state }: ActionProviderProps) => {
    const addResponse = (message: any) => {
        setState((prevState: any) => {
            const messages = [...prevState.messages];
            const loaderId = message.loaderId;

            if (loaderId) {
                const loaderIndex = messages.findIndex((msg: any) => msg.loaderId === loaderId);
                if (loaderIndex !== -1) {
                    messages[loaderIndex] = message;
                } else {
                    messages.push(message);
                }
            } else {
                messages.push(message);
            }

            return { ...prevState, messages };
        });
    };

    const greet = (): void => {
        const message = createChatBotMessage("Hello friend", { widget: "message" });
        addResponse(message);
    };

    const reply = ({
        message,
        citations,
        loaderId,
    }: {
        message: string;
        loaderId: string;
        citations?: any[];
    }): void => {
        const botMessage = createChatBotMessage(<Render message={message} citations={citations} />, {
            delay: 0,
        });
        botMessage.loaderId = loaderId;
        addResponse(botMessage);
    };

    const wait = (loaderId: string) => {
        const botMessage = createChatBotMessage(<ChatbotLoader />, {
            delay: 0,
        });
        botMessage.loaderId = loaderId;
        addResponse(botMessage);
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        createChatBotMessage,
                        setState,
                        greet,
                        reply,
                        wait,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
