import { useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { checkEasySource, isSuperAdminUser } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import {
    scrapeCandidates,
    selectShowSrapeCandidateModal,
    setLiCookie,
    setScrapeCandidateModal,
} from "../../index.reducer";

const ScrapeCandidateModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrapeCandidateModal = useSelector(selectShowSrapeCandidateModal);
    const isEasySource = useSelector(checkEasySource);
    const isUpdating = useSelector(checkIfLoading(scrapeCandidates.type));
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const [cookie, setCookie] = useState<string>(null);

    const handleRefresh = () => {
        if (!cookie) {
            dispatch(setLiCookie("abcd"));
        } else {
            dispatch(setLiCookie(cookie));
        }

        dispatch(scrapeCandidates());
    };

    if (!scrapeCandidateModal) {
        return null;
    }

    return (
        <BaseModal
            onClose={() => dispatch(setScrapeCandidateModal(false))}
            overlayStyles={{
                padding: "1.7rem 1.5rem",
                width: 600,
            }}
            hideCloseButton={false}
        >
            <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "1.75rem" }}>
                Refresh all profiles?
            </Typography>
            
            {isEasySource ? (
                 <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    EasySource will refresh all the profiles in your project over 30 days old, with up-to-date
                    information triangulated across data sources.
                </Typography>
            ): (
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    EasyGrowth will refresh profile information for all prospects with data older than 30 days.
                </Typography>
            )}
           

            {isSuperAdmin && (
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter linkedin cookie"
                    onChange={(e) => setCookie(e.target.value)}
                />
            )}

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ marginTop: "2rem" }}>
                <Typography sx={{ fontSize: "12px", fontStyle: "italic", color: "gray" }}>
                    This process should take 5 mins or less for every set of 500 profiles, but ocassionally may take
                    several hours.
                </Typography>
                <Button
                    variant="contained"
                    sx={{ borderRadius: "4px", fontSize: "12px", border: "none", minWidth: "fit-content" }}
                    onClick={handleRefresh}
                    disabled={isUpdating}
                >
                    {isUpdating ? "Refreshing..." : "Yes, refresh"}
                </Button>
            </Stack>
        </BaseModal>
    );
};

export default ScrapeCandidateModal;
