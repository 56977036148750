import { PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { get, uniq } from "lodash";
import { NavigateFunction } from "react-router-dom";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { Option } from "../../common";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import { CompanySuggestion } from "../../pages/AdvancedFilters/Components/Filters/Filters.type";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { closeLinkedinScrapingModal } from "../reducers/linkedin-scraping/linkedin-scraping.slice";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import {
    cancelSagas,
    fetchCompanySuggestions,
    fetchOptions,
    setErrors,
    setFields,
    setValues,
    submitCompanySearch,
    submitResumeSearch,
    submitSearch,
    submitSearchLite,
} from "../reducers/search/search.slice";
import {
    FetchOptionsPayload,
    FetchOptionsResponse,
    SearchField,
    SearchFieldValue,
    SearchStateKeys,
} from "../reducers/search/search.types";
import {
    setCandidates,
    setCandidatesFetchStatus,
    setCompanies,
    setCompaniesFetchStatus,
    setSearch,
} from "../reducers/searchCandidates/searchCandidates.slice";
import {
    ScrapStatuses,
    SubmitResumeSearchResponse,
    SubmitSearchResponse,
} from "../reducers/searchCandidates/searchCandidates.types";

const keyToLabelMapping: Record<SearchStateKeys, string> = {
    geography: "location",
    headquartersGeography: "location",
    technologyUsed: "technology",
    postalCode: "postalCode",
    postalCodeCSVUrl: "postalCodeCSVUrl",
    jobTitle: "role",
    yearsOfExperience: "yearsOfExperience",
    jobFunction: "roleFunction",
    industry: "industry",
    industryCompany: "industryCompany",
    currentCompany: "currentCompany",
    pastCompany: "pastCompany",
    keywords: "keywords",
    pastJobTitle: "role",
    seniority: "seniority",
    companyHeadcount: "companyHeadcount",
    companyHeadcount2: "companyHeadcount2",
    noOfFollowers: "noOfFollowers",
    noOfCompanies: "noOfCompanies",
    department: "department",
    department2: "department2",
    fortune: "fortune",
    jobOpportunity: "jobOpportunity",
    recentActivity: "recentActivity",
    currency: "currency",
    companyHeadGrowthRange: "companyHeadGrowthRange",
    annualRevenueRange: "annualRevenueRange",
    departmentHeadCountRange: "departmentHeadCountRange",
    departmentHeadCountGrowthRange: "departmentHeadCountGrowthRange",
    // resume builder
    distance: "distance",
    salaryRange: "salaryRange",
    jobType: "jobType",
    education: "education",
    activeWithin: "activeWithin",
    // lite / growth
    companyHeadQuarterLocation: "location",
    school: "school",
    degree: "degree",
    currentCompanyExperience: "currentCompanyExperience",
    currentPositionExperience: "currentPositionExperience",
    companyType: "companyType",
    profileLanguage: "profileLanguage",
    ipoStatus: "ipoStatus",
    fundingType: "fundingType",
    ipoDateRange: "ipoDateRange",
    revenue: "revenue",
    lastFundingDateRange: "lastFundingDateRange",
    fundingTotalAmountRange: "fundingTotalAmountRange",
    investorName: "investorName",
    sharesInvestorsWith: "sharesInvestorsWith",
    lastFundingTotalAmountRange: "lastFundingTotalAmountRange",
    location: "location",
};

function isStringArray(data: Option[] | string[]): data is string[] {
    return Array.isArray(data) && typeof data[0] === "string";
}

function formatValues(values: SearchFieldValue[], options?: SearchField["options"]) {
    return values.map((i) => (options ? { label: i.value, value: options[i.value] } : i.value)).filter(Boolean);
}

function formatExcluded(values: SearchFieldValue[], options?: SearchField["options"]) {
    return formatValues(
        values.filter((option) => option.excluded),
        options
    );
}

function formatIncluded(values: SearchFieldValue[], options?: SearchField["options"]) {
    return formatValues(
        values.filter((option) => !option.excluded),
        options
    );
}

function* fetchOptionsSaga(action: FetchOptionsPayload): SagaIterator {
    const { key, value } = action.payload;
    const loadingType = `${action.type}-${key}`;

    try {
        if (key === "yearsOfExperience") {
            return;
        }

        yield put(startAction({ action: loadingType }));

        const response: FetchOptionsResponse = yield call(new API().post, `/v2/linkedin-type-ahead/type-ahead`, [
            {
                label: keyToLabelMapping[key],
                value,
                category: "",
            },
        ]);

        let options: { [k: string]: string };

        if (isStringArray(response.data)) {
            options = response.data.reduce((acc: { [k: string]: string }, i) => {
                acc[i] = i;
                return acc;
            }, {});
        } else {
            options = response.data?.reduce((acc: { [k: string]: string }, { label, value }) => {
                acc[label] = value;

                return acc;
            }, {});
        }

        const state = yield select();
        const currentOptions: { [k: string]: string } = get(state, `search.${key}.options`);

        options = { ...options, ...currentOptions };

        yield put(setFields({ key, fieldValues: { options } }));
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("error while fetching options"));
    } finally {
        yield put(stopAction({ action: loadingType }));
    }
}

// currentCompany : []
// education : []
// experience : ["3-5", "10", "1-2"]
// industry : []
// jobFunction : "Sales"
// location : ["United States (USA)"]
// postalCode: ["94412"]
// pastCompany : []
// roleFunction : [{label: "sales", value: "sales", __isNew__: true}]
// skills : ["Account Executive"]
// title : ["Account Executive"]
// postalCodeCSVUrl: "https://aws..."
// visa : "yes"

function* submitSearchSaga(
    action: PayloadAction<{ navigate: NavigateFunction; isFromLinkedinScraperModal?: boolean }>
): SagaIterator {
    try {
        const state = yield select();
        const urlParams = new URLSearchParams(window.location.search);
        const projectName = urlParams.get("name");
        const projectId = urlParams.get("project");
        const isFromLinkedinScraperModal = !!action.payload.isFromLinkedinScraperModal;

        let hasError = false;

        if (!projectName) {
            action.payload?.navigate?.("?step=0&error=Project name is required");
            hasError = true;
        }

        const locationValues: SearchField = get(state, "search.geography");
        const experienceValues: SearchField = get(state, "search.yearsOfExperience");
        const jobTitleValues: SearchField = get(state, "search.jobTitle");
        const pastTitleValues: SearchField = get(state, "search.pastJobTitle");
        const jobFunctionValues: SearchField = get(state, "search.jobFunction");
        const industryValues: SearchField = get(state, "search.industry");
        const currentCompanyValues: SearchField = get(state, "search.currentCompany");
        const pastCompanyValues: SearchField = get(state, "search.pastCompany");
        const keywordsValues: SearchField = get(state, "search.keywords");
        const postalCodeValues: SearchField = get(state, "search.postalCode");
        const postalCodeCSVUrlValues: SearchField = get(state, "search.postalCodeCSVUrl");
        // linkedin scraping modal values
        const linkedinUrl = get(state, "linkedinScraping.linkedinUrl");
        // const scrapeSource = get(state, "linkedinScraping.scrapeSource");
        const fromPage = get(state, "linkedinScraping.fromPage");
        const profileCount = get(state, "linkedinScraping.profileCount");

        // included
        const location = formatIncluded(locationValues.values, locationValues.options);
        const roleFunction = formatIncluded(jobFunctionValues.values, jobFunctionValues.options);
        const industry = formatIncluded(industryValues.values, industryValues.options);
        // without options
        const experience = experienceValues.values.map((i) => experienceValues.options[i.value]).filter(Boolean);
        const title = formatIncluded(jobTitleValues.values);
        const currentCompany = formatIncluded(currentCompanyValues.values);
        const pastCompany = formatIncluded(pastCompanyValues.values);
        const keywords = formatIncluded(keywordsValues.values);
        const postalCode = formatIncluded(postalCodeValues.values);
        const postalCodeCSVUrl = formatIncluded(postalCodeCSVUrlValues.values)[0];
        const pastTitle = formatIncluded(pastTitleValues.values);

        // excluded
        const excludedLocation = formatExcluded(locationValues.values, locationValues.options);
        const excludedRoleFunction = formatExcluded(jobFunctionValues.values, jobFunctionValues.options);
        const excludedIndustry = formatExcluded(industryValues.values, industryValues.options);
        // without options
        const excludedTitle = formatExcluded(jobTitleValues.values);
        const excludedCurrentCompany = formatExcluded(currentCompanyValues.values);
        const excludedPastCompany = formatExcluded(pastCompanyValues.values);
        const excludedPastTitle = formatExcluded(pastTitleValues.values);

        if (!isFromLinkedinScraperModal) {
            // validate fields if source is not linkedInScraperModal
            if (!location.length && !postalCode.length) {
                yield put(
                    setErrors({
                        key: "geography",
                        error: "*One of geography or pin codes (in CSV) is required",
                    })
                );
                hasError = true;
            } else {
                yield put(
                    setErrors({
                        key: "geography",
                        error: null,
                    })
                );
            }

            if (!title.length && !roleFunction.length) {
                yield put(
                    setErrors({
                        key: "jobFunction",
                        error: "*Atleast one of Job Title, Job Function is required",
                    })
                );
                hasError = true;
            } else {
                yield put(
                    setErrors({
                        key: "jobFunction",
                        error: null,
                    })
                );
            }

            if (keywords.length > 10) {
                yield put(
                    setErrors({
                        key: "keywords",
                        error: "Maximum number of keywords limit reached!",
                    })
                );
                hasError = true;
            } else {
                yield put(
                    setErrors({
                        key: "keywords",
                        error: null,
                    })
                );
            }
        }

        if (hasError) return;

        yield put(startAction({ action: action.type }));

        const body = {
            location,
            experience,
            title,
            pastTitle,
            roleFunction,
            currentCompany,
            pastCompany,
            industry,
            postalCode,
            postalCodeCSVUrl,
            skills: keywords,
            projectId,
            // excluded
            excludedLocation,
            excludedTitle,
            excludedRoleFunction,
            excludedCurrentCompany,
            excludedIndustry,
            excludedPastCompany,
            excludedPastTitle,
            // from linkedin scraping modal
            projectName,
            isFromLinkedinScraperModal,
            linkedinUrl,
            fromPage,
            profileCount,
        };

        const response: SubmitSearchResponse = yield call(new API().post, `/searches/salesNav-search-profiles`, body);

        if (response?.data?._id) {
            yield put(
                setSearch({
                    _id: response.data._id,
                    status: response.data.status as ScrapStatuses,
                    updatedAt: response.data.updatedAt,
                })
            );
            if (isFromLinkedinScraperModal) {
                yield put(closeLinkedinScrapingModal());
                if (response.data.projectId) {
                    action.payload.navigate(`/projects/${response.data.projectId}?fromSearch=true`);
                }
            }
        } else {
            throw new Error("error while submitting filters");
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitSearchLiteSaga(action: PayloadAction<{ navigate: NavigateFunction }>): SagaIterator {
    try {
        const state = yield select();
        const urlParams = new URLSearchParams(window.location.search);
        const projectName = urlParams.get("name");
        const projectId = urlParams.get("project");

        let hasError = false;

        if (!projectName) {
            action.payload?.navigate?.("?error=Project name is required");
            hasError = true;
        }

        const locationValues: SearchField = get(state, "search.geography");
        const experienceValues: SearchField = get(state, "search.yearsOfExperience");
        const jobTitleValues: SearchField = get(state, "search.jobTitle");
        const currentCompanyExperienceValues: SearchField = get(state, "search.currentCompanyExperience");
        const currentPositionExperienceValues: SearchField = get(state, "search.currentPositionExperience");
        const jobFunctionValues: SearchField = get(state, "search.jobFunction");
        const keywordsValues: SearchField = get(state, "search.keywords");
        const schoolValues: SearchField = get(state, "search.school");
        const degreeValues: SearchField = get(state, "search.degree");
        const profileLanguageValues: SearchField = get(state, "search.profileLanguage");
        const currentCompanyValues: SearchField = get(state, "search.currentCompany");
        const industryValues: SearchField = get(state, "search.industry");
        const companyHeadQuarterLocationValues: SearchField = get(state, "search.companyHeadQuarterLocation");
        const companyHeadcountValues: SearchField = get(state, "search.companyHeadcount");
        const companyTypeValues: SearchField = get(state, "search.companyType");
        const ipoStatusValues: SearchField = get(state, "search.ipoStatus");
        const fundingTypeValues: SearchField = get(state, "search.fundingType");
        const ipoDateRangeValues: SearchField = get(state, "search.ipoDateRange");
        const lastFundingTotalAmountRangeValues: SearchField = get(state, "search.lastFundingTotalAmountRange");
        const revenueRangeValues: SearchField = get(state, "search.revenue");
        const lastFundingDateRangeValues: SearchField = get(state, "search.lastFundingDateRange");
        const fundingTotalAmountRangeValues: SearchField = get(state, "search.fundingTotalAmountRange");
        const investorNameValues: SearchField = get(state, "search.investorName");
        const sharesInvestorsWithValues: SearchField = get(state, "search.sharesInvestorsWith");
        const seniorityLevelValues: SearchField = get(state, "search.seniority");

        const headquartersGeographyValue: SearchField = get(state, "search.headquartersGeography");
        const technologyUsedValue: SearchField = get(state, "search.technologyUsed");
        const currencyValue: SearchField = get(state, "search.currency");
        const revenueValue: SearchField = get(state, "search.annualRevenueRange");
        const departmentValue: SearchField = get(state, "search.department");
        const department2Value: SearchField = get(state, "search.department2");
        const departmentHeadCountRangeValue: SearchField = get(state, "search.departmentHeadCountRange");
        const departmentHeadCountGrowthRangeValue: SearchField = get(state, "search.departmentHeadCountGrowthRange");
        const companyHeadcount2Values: SearchField = get(state, "search.companyHeadcount2");
        const companyHeadGrowthRangeValue: SearchField = get(state, "search.companyHeadGrowthRange");
        const noOfFollowersValue: SearchField = get(state, "search.noOfFollowers");
        const noOfCompaniesValue: SearchField = get(state, "search.noOfCompanies");
        const fortuneValue: SearchField = get(state, "search.fortune");
        const industryCompanyValue: SearchField = get(state, "search.industryCompany");
        const jobOpportunityValue: SearchField = get(state, "search.jobOpportunity");
        const recentActivityValue: SearchField = get(state, "search.recentActivity");

        // included (Array<string>)
        const experience = experienceValues.values.map((i) => experienceValues.options[i.value]).filter(Boolean);
        const title = formatIncluded(jobTitleValues.values);
        const skills = formatIncluded(keywordsValues.values);
        const currentCompany = formatIncluded(currentCompanyValues.values);
        const ipoDateRange = formatIncluded(ipoDateRangeValues.values)?.map((d: any) => dayjs(d).format("D/MM/YYYY"));
        const lastFundingDateRange = formatIncluded(lastFundingDateRangeValues.values)?.map((d: any) =>
            dayjs(d).format("D/MM/YYYY")
        );
        const lastFundingTotalAmountRange = formatIncluded(lastFundingTotalAmountRangeValues.values);
        const fundingTotalAmountRange = formatIncluded(fundingTotalAmountRangeValues.values);
        const investorName = formatIncluded(investorNameValues.values);
        const sharesInvestorsWith = formatIncluded(sharesInvestorsWithValues.values);

        // included (Array<Object(label:string, value:string)>)
        const location = formatIncluded(locationValues.values, locationValues.options);
        const roleFunction = formatIncluded(jobFunctionValues.values, jobFunctionValues.options);
        const school = formatIncluded(schoolValues.values, schoolValues.options);
        const degree = formatIncluded(degreeValues.values, degreeValues.options);
        const profileLanguage = formatIncluded(profileLanguageValues.values, profileLanguageValues.options);
        const industry = formatIncluded(industryValues.values, industryValues.options);
        const companyHeadQuarterLocation = formatIncluded(
            companyHeadQuarterLocationValues.values,
            companyHeadQuarterLocationValues.options
        );
        const currentCompanyHeadCount = formatIncluded(companyHeadcountValues.values, companyHeadcountValues.options);
        const companyType = formatIncluded(companyTypeValues.values, companyTypeValues.options);
        const ipoStatus = formatIncluded(ipoStatusValues.values, ipoStatusValues.options);
        const fundingType = formatIncluded(fundingTypeValues.values, fundingTypeValues.options);
        const revenueRange = formatIncluded(revenueRangeValues.values);
        const currentCompanyExperience = formatIncluded(
            currentCompanyExperienceValues.values,
            currentCompanyExperienceValues.options
        );
        const currentPositionExperience = formatIncluded(
            currentPositionExperienceValues.values,
            currentPositionExperienceValues.options
        );
        const seniorityLevel = formatIncluded(seniorityLevelValues.values, seniorityLevelValues.options);

        // excluded (Array<string>)
        const excludedCurrentCompany = formatExcluded(currentCompanyValues.values);
        const excludedTitle = formatExcluded(jobTitleValues.values);
        // excluded Array<Object(label:string, value:string)>
        const excludedSchool = formatExcluded(schoolValues.values, schoolValues.options);
        const excludedLocation = formatExcluded(locationValues.values, locationValues.options);
        const excludedCompanyHeadQuarterLocation = formatExcluded(
            companyHeadQuarterLocationValues.values,
            companyHeadQuarterLocationValues.options
        );
        const excludedRoleFunction = formatExcluded(jobFunctionValues.values, jobFunctionValues.options);
        const excludedIndustry = formatExcluded(industryValues.values, industryValues.options);
        const excludedSeniorityLevel = formatExcluded(seniorityLevelValues.values, seniorityLevelValues.options);


        const currency = currencyValue.values[0]?.value.slice(0, 3) || "";
        const minRevenue = revenueValue.values[0]?.value || "";
        const maxRevenue = revenueValue.values[1]?.value || "";
        const department = departmentValue.values[0]?.value || "";
        const department2 = department2Value.values[0]?.value || "";
        const noOfCompanies = noOfCompaniesValue.values[0]?.value || "";
        const companyHeadcount2 = companyHeadcount2Values.values
            .map((i) => companyHeadcount2Values.options[i.value])
            .filter(Boolean);
        const noOfFollowers = noOfFollowersValue.values.map((i) => noOfFollowersValue.options[i.value]).filter(Boolean);
        const fortune = fortuneValue.values.map((i) => fortuneValue.options[i.value]).filter(Boolean);
        const minDepartmentHeadCount = departmentHeadCountRangeValue.values[0]?.value || "";
        const maxDepartmentHeadCount = departmentHeadCountRangeValue.values[1]?.value || "";
        const minDepartmentHeadCountGrowth = departmentHeadCountGrowthRangeValue.values[0]?.value || "";
        const maxDepartmentHeadCountGrowth = departmentHeadCountGrowthRangeValue.values[1]?.value || "";
        const mincompanyHeadGrowth = companyHeadGrowthRangeValue.values[0]?.value || "";
        const maxcompanyHeadGrowth = companyHeadGrowthRangeValue.values[1]?.value || "";
        const jobOpportunity = jobOpportunityValue.values[0]?.value || "";
        const recentActivity0 = recentActivityValue.values[0]?.value || "";
        const recentActivity1 = recentActivityValue.values[1]?.value || "";
        const industryCompany = formatIncluded(industryCompanyValue.values, industryCompanyValue.options);
        const headquartersGeography = formatIncluded(
            headquartersGeographyValue.values,
            headquartersGeographyValue.options
        );
        const technologyUsed = formatIncluded(
            technologyUsedValue.values,
            technologyUsedValue.options
        );
        const excludedIndustryCompany = formatExcluded(industryCompanyValue.values, industryCompanyValue.options);
        const excludedHeadquarterGeography = formatExcluded(
            headquartersGeographyValue.values,
            headquartersGeographyValue.options
        );

        if (!location.length) {
            yield put(
                setErrors({
                    key: "geography",
                    error: "*Geography is required",
                })
            );
            hasError = true;
        } else {
            yield put(
                setErrors({
                    key: "geography",
                    error: null,
                })
            );
        }

        // if (!title.length && !roleFunction.length) {
        //     yield put(
        //         setErrors({
        //             key: "jobFunction",
        //             error: "*Atleast one of Job Title, Job Function is required",
        //         })
        //     );
        //     hasError = true;
        // } else {
        //     yield put(
        //         setErrors({
        //             key: "jobFunction",
        //             error: null,
        //         })
        //     );
        // }

        if (skills.length > 10) {
            yield put(
                setErrors({
                    key: "keywords",
                    error: "Maximum number of keywords limit reached!",
                })
            );
            hasError = true;
        } else {
            yield put(
                setErrors({
                    key: "keywords",
                    error: null,
                })
            );
        }

        if (hasError) return;

        yield put(startAction({ action: action.type }));
        const body = {
            projectName,
            location,
            experience,
            title,
            currentCompanyExperience,
            currentPositionExperience,
            companyHeadQuarterLocation,
            school,
            degree,
            roleFunction,
            currentCompany,
            profileLanguage,
            industry,
            currentCompanyHeadCount,
            companyType,
            skills,
            projectId,
            ipoStatus,
            fundingType,
            ipoDateRange,
            lastFundingTotalAmountRange,
            revenueRange,
            lastFundingDateRange,
            fundingTotalAmountRange,
            investorName,
            sharesInvestorsWith,
            pastTitle: [],
            pastCompany: [],
            postalCode: [],
            seniorityLevel,
            // excluded
            excludedLocation,
            excludedCurrentCompany,
            excludedTitle,
            excludedRoleFunction,
            excludedCompanyHeadQuarterLocation,
            excludedIndustry,
            excludedSchool,
            excludedPastTitle: [],
            excludedPastCompany: [],
           
            advanceCompanyFilters:{
                total:noOfCompanies,
                currency,
                minRevenue,
                maxRevenue,
                department,
                department2,
                noOfFollowers,
                fortune,
                minDepartmentHeadCount,
                maxDepartmentHeadCount,
                minDepartmentHeadCountGrowth,
                maxDepartmentHeadCountGrowth,
                mincompanyHeadGrowth,
                maxcompanyHeadGrowth,
                headquartersGeography,
                excludedHeadquarterGeography,
                technologiesUsed:technologyUsed,
                companyHeadcount:companyHeadcount2,
                industryCompany,
                excludedIndustryCompany,
                jobOpportunity,
                recentActivity0,
                recentActivity1,
            },
            excludedSeniorityLevel,
        };

        const url = "/searches/salesNav-search-profiles-lite";

        const response: SubmitSearchResponse = yield call(new API().post, url, body);

        if (response?.data?._id) {
            yield put(
                setSearch({
                    _id: response.data._id,
                    status: response.data.status as ScrapStatuses,
                    updatedAt: response.data.updatedAt,
                })
            );
            // action.payload?.navigate(`/candidates?${window.location.search}`);
        } else {
            throw new Error("error while submitting filters");
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitResumeSearchSaga(action: PayloadAction<{ navigate: NavigateFunction }>): SagaIterator {
    try {
        const state = yield select();
        const urlParams = new URLSearchParams(window.location.search);
        const projectName = urlParams.get("name");
        const projectId = urlParams.get("project");

        let hasError = false;

        if (!projectName) {
            action.payload?.navigate?.("/search?step=0&error=Project name is required");
            hasError = true;
        }

        const keywordsValues: SearchField = get(state, "search.keywords");
        const locationValue: SearchField = get(state, "search.location");
        const distanceValue: SearchField = get(state, "search.distance");
        const salaryRangeValue: SearchField = get(state, "search.salaryRange");
        const jobTypeValues: SearchField = get(state, "search.jobType");
        const activeWithinValue: SearchField = get(state, "search.activeWithin");
        const educationValues: SearchField = get(state, "search.education");
        const experienceValues: SearchField = get(state, "search.yearsOfExperience");

        yield put(startAction({ action: action.type }));

        const skills = keywordsValues.values[0]?.value;
        const location = locationValue.values[0]?.value;
        const distance = distanceValue.values[0]?.value;
        const minSalary = salaryRangeValue.values[0]?.value;
        const maxSalary = salaryRangeValue.values[1]?.value;
        const jobTypes = jobTypeValues.values.map((i) => jobTypeValues.options[i?.value]).filter(Boolean);
        const activeWithin = activeWithinValue.values[0]?.value;
        const education = educationValues.values.map((i) => educationValues.options[i?.value]).filter(Boolean);
        const experience = experienceValues.values.map((i) => experienceValues.options[i?.value]).filter(Boolean);

        if (!location?.length) {
            yield put(
                setErrors({
                    key: "location",
                    error: "*Location is required",
                })
            );
            hasError = true;
        } else {
            yield put(
                setErrors({
                    key: "location",
                    error: null,
                })
            );
        }

        if (hasError) return;

        const body = {
            location,
            distance,
            minSalary,
            maxSalary,
            jobTypes,
            activeWithin,
            education,
            experience,
            projectId,
            skills,
        };

        yield put(setCandidatesFetchStatus("LOADING"));

        const response: SubmitResumeSearchResponse = yield call(
            new API().post,
            `/searches/resumeLibrary-candidates`,
            body
        );

        if (response?.data?.profiles) {
            const formattedProfiles = response.data.profiles?.map((profile: any) => ({
                photo: "",
                title: "",
                name: profile.firstName,
                latestjobTitle: profile.latestjobTitle,
                desiredjobTitle: profile.desiredjobTitle,
                desireMaxSalary: profile.desireMaxSalary,
                desireMinSalary: profile.desireMinSalary,
                about: "",
                company: {
                    name: "",
                    url: "",
                },
                location: profile.homeTown,
                profileUrl: "",
                stability: null,
                _id: profile.resumeId,
            }));

            yield put(setCandidatesFetchStatus("SUCCESS"));

            yield put(
                setSearch({
                    _id: response.data.searchId,
                    status: "COMPLETED",
                    updatedAt: response.data.updatedAt,
                })
            );

            yield put(
                setCandidates({
                    candidates: formattedProfiles,
                    totalCandidates: response.data.total?.toString(),
                })
            );
        }
    } catch (error) {
        yield put(setCandidatesFetchStatus("ERROR"));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitCompanySearchSaga(action: PayloadAction<{ navigate: NavigateFunction }>): SagaIterator {
    try {
        const state = yield select();

        const headquartersGeographyValue: SearchField = get(state, "search.headquartersGeography");
        const technologyUsedValue: SearchField = get(state, "search.technologyUsed");
        const currencyValue: SearchField = get(state, "search.currency");
        const revenueValue: SearchField = get(state, "search.annualRevenueRange");
        const departmentValue: SearchField = get(state, "search.department");
        const department2Value: SearchField = get(state, "search.department2");
        const departmentHeadCountRangeValue: SearchField = get(state, "search.departmentHeadCountRange");
        const departmentHeadCountGrowthRangeValue: SearchField = get(state, "search.departmentHeadCountGrowthRange");
        const companyHeadcount2Values: SearchField = get(state, "search.companyHeadcount2");
        const companyHeadGrowthRangeValue: SearchField = get(state, "search.companyHeadGrowthRange");
        const noOfFollowersValue: SearchField = get(state, "search.noOfFollowers");
        const fortuneValue: SearchField = get(state, "search.fortune");
        const industryCompanyValue: SearchField = get(state, "search.industryCompany");
        const jobOpportunityValue: SearchField = get(state, "search.jobOpportunity");
        const recentActivityValue: SearchField = get(state, "search.recentActivity");
        yield put(startAction({ action: action.type }));

        const currency = currencyValue.values[0]?.value.slice(0, 3) || "";
        const minRevenue = revenueValue.values[0]?.value || "";
        const maxRevenue = revenueValue.values[1]?.value || "";
        const department = departmentValue.values[0]?.value || "";
        const department2 = department2Value.values[0]?.value || "";
        const companyHeadcount2 = companyHeadcount2Values.values
            .map((i) => companyHeadcount2Values.options[i.value])
            .filter(Boolean);
        const noOfFollowers = noOfFollowersValue.values.map((i) => noOfFollowersValue.options[i.value]).filter(Boolean);
        const fortune = fortuneValue.values.map((i) => fortuneValue.options[i.value]).filter(Boolean);
        const minDepartmentHeadCount = departmentHeadCountRangeValue.values[0]?.value || "";
        const maxDepartmentHeadCount = departmentHeadCountRangeValue.values[1]?.value || "";
        const minDepartmentHeadCountGrowth = departmentHeadCountGrowthRangeValue.values[0]?.value || "";
        const maxDepartmentHeadCountGrowth = departmentHeadCountGrowthRangeValue.values[1]?.value || "";
        const mincompanyHeadGrowth = companyHeadGrowthRangeValue.values[0]?.value || "";
        const maxcompanyHeadGrowth = companyHeadGrowthRangeValue.values[1]?.value || "";
        const jobOpportunity = jobOpportunityValue.values[0] || "";
        const recentActivity0 = recentActivityValue.values[0]?.value || "";
        const recentActivity1 = recentActivityValue.values[1]?.value || "";

   
        //included
        const industryCompany = formatIncluded(industryCompanyValue.values, industryCompanyValue.options);
        const headquartersGeography = formatIncluded(
            headquartersGeographyValue.values,
            headquartersGeographyValue.options
        );
        const technologyUsed = formatIncluded(
            technologyUsedValue.values,
            technologyUsedValue.options
        );

        //excluded
        const excludedIndustryCompany = formatExcluded(industryCompanyValue.values, industryCompanyValue.options);
        const excludedHeadquarterGeography = formatExcluded(
            headquartersGeographyValue.values,
            headquartersGeographyValue.options
        );

        const body = {
            currency,
            minRevenue,
            maxRevenue,
            department,
            department2,
            companyHeadcount:companyHeadcount2,
            noOfFollowers,
            fortune,
            minDepartmentHeadCount,
            maxDepartmentHeadCount,
            minDepartmentHeadCountGrowth,
            maxDepartmentHeadCountGrowth,
            mincompanyHeadGrowth,
            maxcompanyHeadGrowth,
            headquartersGeography,
            excludedHeadquarterGeography,
            technologiesUsed:technologyUsed,
            industryCompany,
            excludedIndustryCompany,
            jobOpportunity,
            recentActivity0,
            recentActivity1,
        };

        yield put(setCompaniesFetchStatus("LOADING"));

        const response: any = yield call(new API().post, `/searches/companyUrlData`, body);

        if (response?.data) {
            yield put(setCompaniesFetchStatus("SUCCESS"));
            yield put(
                setCompanies({
                    companies: response?.data,
                    totalCompanies: response?.total.toString(),
                })
            );
        }
    } catch (error) {
        yield put(setCompaniesFetchStatus("ERROR"));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchCompanySuggestionsSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const currentCompanyValues: SearchField = get(state, "search.currentCompany");
        const payload = currentCompanyValues.values.map((option) => option.value);
        if (!payload.length) {
            return;
        }
        if (payload.length > 4) {
            throw new Error("Please keep 4 companies or fewer before finding similar companies");
        }

        const response: CompanySuggestion[] = yield call(new API().post, `/company/similarCompany`, {
            companyNames: payload,
        });

        if (!response?.length) {
            throw new Error("Oops! Similar companies could not be fetched at this moment.");
        }

        const similarCompanies = response.map(({ company_name }) => company_name);
        const newCurrentCompanyValues = uniq([...payload, ...similarCompanies]);
        yield put(
            setValues({
                key: "currentCompany",
                value: newCurrentCompanyValues.map((value) => {
                    const matchingOption = currentCompanyValues.values.find((option) => option.value === value);
                    const excluded = matchingOption?.excluded ?? false;
                    return { value, excluded };
                }),
            })
        );
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        //@ts-ignore
        yield takeLatest(fetchOptions.type, fetchOptionsSaga),
        //@ts-ignore
        yield takeLatest(submitSearch.type, submitSearchSaga),
        //@ts-ignore
        yield takeLatest(fetchCompanySuggestions.type, fetchCompanySuggestionsSaga),
        //@ts-ignore
        // yield takeLatest(submitLinkedinScrapingForm.type, submitSearchFromLinkedinScrapingModalSaga),
        //@ts-ignore
        yield takeLatest(submitResumeSearch.type, submitResumeSearchSaga),
        //@ts-ignore
        yield takeLatest(submitCompanySearch.type, submitCompanySearchSaga),
        //@ts-ignore
        yield takeLatest(submitSearchLite.type, submitSearchLiteSaga),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
