import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Input, List, ListItem, Typography } from "@mui/joy";

const helpTopics = [
    { title: "How to create a new project?", link: "https://openainew.s3.ap-southeast-1.amazonaws.com/file-AAv1GXobFhIsiP9cXpW452De.pdf" },
    { title: "How to update AI vetting criteria?", link: "https://openainew.s3.ap-southeast-1.amazonaws.com/file-qLVJDeJPCtLwFcDZgJ42qZ8h.pdf" },
    { title: "How to build a candidate pool?", link: "https://openainew.s3.ap-southeast-1.amazonaws.com/file-szVsF08hRjEVwBUCoD49mXNH.pdf" },
    { title: "How to see the AI vetting insights?", link: "https://openainew.s3.ap-southeast-1.amazonaws.com/file-qLVJDeJPCtLwFcDZgJ42qZ8h.pdf" },
    { title: "Easysource contact data control.", link:"https://openainew.s3.ap-southeast-1.amazonaws.com/file-673u2yWEc8oAKha4VSxo5o8g.pdf"},
];

const HelpComponent = () => {
    return (
        <Box  sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", padding: "16px", backgroundColor: "white" }}>
            {/* <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <Input placeholder="Search for help" fullWidth />
                    <IconButton type="submit" aria-label="search">
                        <SearchIcon style={{ color: "#0d7a89" }} />
                    </IconButton>
                </Box>
            </Box> */}

            <Box>
                <Typography
                    sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        transition: "color 0.3s ease",
                        "&:hover": {
                            color: "#1e81b0",
                            cursor: "pointer",
                        },
                    }}
                >
                    Search for help
                </Typography>
            </Box>
            <List>
                {helpTopics.map((topic, index) => (
                    <ListItem
                        key={index}
                        component="a"
                        href={topic.link}
                        target="_blank"
                        sx={{
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: "4px",
                            padding: "8px",
                            transition: "backgroundColor 0.5s ease",
                            "&:hover": {
                                color: "#0d7a89",
                                cursor: "pointer",
                                backgroundColor:"#d8e7ea",
                            },
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                "&:hover": {
                                    color: "#0d7a89",
                                },
                            }}
                        >
                            {topic.title}
                        </Typography>
                        <ArrowForwardIosIcon style={{ color: "#0d7a89", fontSize: "12px" }} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default HelpComponent;
