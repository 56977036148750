import { useRef, useState, useEffect } from "react";
import { useAppDispatch } from "../../../../store/Store";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { CustomOutlineInput } from "../../AllCandidatesToolbar";
import { debounce } from "lodash";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { setSearchQuery } from "./contact-overview-activities.slice";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useTranslation } from "react-i18next";

export function ContactOverviewActivitiesSearch() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const inputRef = useRef<null | HTMLInputElement>();
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setSearchQuery(""));
        };
    }, []);

    useEffect(() => {
        if (showSearch) {
            inputRef?.current?.focus();
        }
    }, [showSearch]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        dispatch(setSearchQuery(value));
    };

    const handleClick = () => {
        if (showSearch) {
            dispatch(setSearchQuery(""));
        }
        setShowSearch((prev) => !prev);
    };

    return (
        <>
            <Tooltip title={t("projectsPage.candidateSection.searchCandidates")}>
                <IconButton aria-label="delete" onClick={handleClick}>
                    {showSearch ? <CancelRoundedIcon fontSize="small" /> : <SearchRoundedIcon fontSize="inherit" />}
                </IconButton>
            </Tooltip>
            {showSearch && (
                <CustomOutlineInput
                    inputRef={inputRef}
                    type="text"
                    placeholder="Search for any attribute"
                    onChange={debounce(handleChange, 500)}
                />
            )}
        </>
    );
}
