import ReactDOM from "react-dom/client";

import "./index.css";

import { ThemeProvider } from "@mui/material/styles";
import firebase from "firebase/compat/app";

import App from "./App";

import "firebase/compat/auth";

import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";

import store from "./store/Store";
import theme from "./theme";

import "./config";

// import { hardRefresh } from "./utils/refresh";

const config = {
    apiKey: import.meta.env.VITE_REACT_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_REACT_APP_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

if (import.meta.env.VITE_REACT_APP_ENV === "production") {
    Sentry.init({
        dsn: import.meta.env.VITE_REACT_APP_SENTRY_KEY,
        // @ts-ignore
        integrations: [new Sentry.BrowserTracing({ enableHTTPTimings: true }), new Sentry.Replay()],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event) {
            if (event.exception) {
                const exceptionType = event.exception.values?.[0]?.type || "";
                const firebaseExceptionPattern = /FirebaseError/gi;

                if (firebaseExceptionPattern.test(exceptionType)) {
                    return null;
                }
            }
            return event;
        },
        ignoreErrors: [
            "Object captured as exception",
            "Firebase: The email address is already in use by another account. (auth/email-already-in-use)",
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications.",
            "Network Error",
            "Request failed with status code 500",
            "Cannot read properties of undefined (reading 'src')",
            "Cannot read properties of undefined (reading 'cancelToken')",
            "Firebase: A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
            "Firebase: The password is invalid or the user does not have a password",
            "Firebase: The popup has been closed by the user before finalizing the operation",
            "Firebase: This operation has been cancelled due to another conflicting popup being opened",
            "The popup has been closed by the user before finalizing the operation",
            "Unable to establish a connection with the popup",
            "TypeError: window.opener is null",
            "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)",
            "Firebase: Unable to establish a connection with the popup. It may have been blocked by the browser. (auth/popup-blocked)",
            "Firebase: This operation has been cancelled due to another conflicting popup being opened. (auth/cancelled-popup-request)",
            "Firebase: The popup has been closed by the user before finalizing the operation. (auth/popup-closed-by-user)",
            "Firebase: Error (auth/internal-error)",
            "Please keep 4 companies or fewer before finding similar companies",
            "Firebase: The project's quota for this operation has been exceeded. (auth/quota-exceeded)",
            "Oops! Similar companies could not be fetched at this moment",
            "Error in input stream",
            "No error message",
            "error while fetching all contacts",
            "error while fetching names",
            "Firebase: The popup has been closed by the user before finalizing the operation. (auth/popup-closed-by-user).",
            "Firebase: Password should be at least 6 characters (auth/weak-password).",
            "null",
            "No user logged in",
        ],
    });

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (const registration of registrations) {
                    registration.unregister();
                }
            })
            .catch(function (err) {
                console.error("Service worker unregister failed: ", err);
            });
        // Function to show a simple alert for the update notification
        // function showUpdateNotification() {
        //     if (confirm("A new version of the application is available. Reload to update?")) {
        //         hardRefresh();
        //     }
        // }

        // window.addEventListener("load", () => {
        //     navigator.serviceWorker
        //         .register("/service-worker.js")
        //         .then((registration) => {
        //             console.log("Service Worker registered with scope:", registration.scope);

        //             // Function to check for updates
        //             function checkForUpdates() {
        //                 registration.update().then(() => {
        //                     navigator.serviceWorker.controller?.postMessage({ type: "check-version" });
        //                 });
        //             }

        //             const duration = 1 * 60 * 1000;
        //             // Check for updates every minute
        //             setInterval(checkForUpdates, duration);

        //             // Check for updates when the page becomes visible
        //             document.addEventListener("visibilitychange", () => {
        //                 if (!document.hidden) {
        //                     checkForUpdates();
        //                 }
        //             });

        //             // Listen for messages from the service worker
        //             navigator.serviceWorker.addEventListener("message", (event) => {
        //                 if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
        //                     showUpdateNotification();
        //                 }
        //             });
        //         })
        //         .catch((error) => {
        //             console.error("Service Worker registration failed:", error);
        //         });
        // });
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>
);
