import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store/Store";

const initialState = Object.freeze({
    users: [],
    total: 0,
    pageSize: 500,
    currentPage: 0,
    orgId: -1,
});

const reducers = {
    updateUserFeature(state: any, action:PayloadAction<any>) {
    },
    
    getUserList(state: any, action:PayloadAction<any>) {
    },
    setUserList(state: any, action: PayloadAction<any[]>) {
        state.users = action.payload;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "featureList",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const {
    updateUserFeature,
    getUserList,
    setUserList,
    leavePage,
} = page.actions;

export const selectUsers = (state: RootState) => state.featureList.users;
export const selectOrgId = (state: RootState) => state.featureList.orgId;
