import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileWithPath } from "react-dropzone";
import { NavigateFunction } from "react-router-dom";

import { SubmitValuePropositionPayload } from "../../../easy-growth/components/AddValueProposition";
import { IMarketing, IProject } from "../../../pages/project/project.types";
import { RootState } from "../../../store/Store";
import { IActionPayload } from "../../../store/reducers/loaders.reducer";
import { TCVSource } from "./CVSource";

export interface Option {
    label: string;
    value: string;
}

export type ICreateProjectErrors = {
    projectName?: string;
    projectJd?: string;
    liCookie?: string;
};

type IRootState = {
    projectName: string;
    purpose: string;
    projectJd: File | null;
    csvFile: FileWithPath | null;
    liCookie: string;
    detailScrape: boolean;
    canShowAddProject: boolean;
    canShowIntegratedProjectModal: boolean;
    canScrapeEmail: boolean;
    errors: ICreateProjectErrors;
    canShowUpdateJd: boolean;
    isSalesNav: boolean;
    showCsvHeaderDialog: boolean;
    unlockTalentMarketing: boolean;
    intent: undefined | string;
    showVettingCriteriaText: boolean;
};

export type ICreateJdProjectPayload = {
    navigate: NavigateFunction;
    mappedCsvHeaders?: any;
    objective?: string;
    platform?: string;
    callback?: any;
    bdrRegions?: any;
};

export type IUpdateMarketingProjectPayload = {
    projectId: string | number;
    marketing: IMarketing;
};

export type ICreateProjectResponse = {
    message: string;
    data: IProject;
};

export interface UploadCSVPayload {
    projectId: string;
    onSuccess?: () => void;
    mappedCsvHeaders?: any;
    cvSource?: TCVSource;
}

export type CreateProjectPayload = PayloadAction<IActionPayload & ICreateJdProjectPayload>;

export type MarketingMode = "ON" | "OFF";

const initialState: IRootState = {
    projectName: "",
    purpose: "",
    projectJd: null,
    csvFile: null,
    liCookie: "",
    detailScrape: false,
    canShowAddProject: false,
    canShowIntegratedProjectModal: false,
    errors: {
        projectName: "",
        projectJd: "",
        liCookie: "",
    },
    canScrapeEmail: false,
    canShowUpdateJd: false,
    isSalesNav: false,
    showCsvHeaderDialog: false,
    unlockTalentMarketing: false,
    intent: "enhance",
    showVettingCriteriaText: false,
};

const reducers = {
    addContactListToProject(state: typeof initialState, action: PayloadAction<string>) {},
    setShowCsvHeaderDialog(state: typeof initialState, { payload }: { payload: boolean }) {
        state.showCsvHeaderDialog = payload;
    },
    submitValuePropositionInputs(state: typeof initialState, action: SubmitValuePropositionPayload) {},
    setErrors(state: typeof initialState, { payload }: { payload: any }) {
        state.errors = payload;
    },
    setCanShowAddProject(state: typeof initialState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors = {
                projectName: "",
                projectJd: "",
                liCookie: "",
            };
        }
        state.canShowAddProject = payload;
    },
    setCanShowIntegratedProjectModal(state: typeof initialState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors = {
                projectName: "",
                projectJd: "",
                liCookie: "",
            };
        }
        state.canShowIntegratedProjectModal = payload;
    },
    setProjectName(state: typeof initialState, { payload }: { payload: string }) {
        state.projectName = payload;
        state.errors.projectName = "";
    },
    setPurpose(state: typeof initialState, { payload }: { payload: string }) {
        state.purpose = payload;
    },
    setProjectJd(state: typeof initialState, { payload }: { payload: File }) {
        state.projectJd = payload;
        state.errors.projectJd = "";
    },
    setCsvFile(state: typeof initialState, { payload }: { payload: FileWithPath | null }) {
        state.csvFile = payload;
    },
    setDetailScrape(state: typeof initialState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors.liCookie = "";
        }
        state.detailScrape = payload;
    },
    setCanScrapeEmail(state: typeof initialState, { payload }: { payload: boolean }) {
        state.canScrapeEmail = payload;
    },
    setLiCookie(state: typeof initialState, { payload }: { payload: string }) {
        state.liCookie = payload;
    },
    createProject(state: typeof initialState, action: CreateProjectPayload) {},
    updateMarketingProject(state: typeof initialState, action: PayloadAction<IUpdateMarketingProjectPayload>) {},
    uploadCsv(state: typeof initialState, action: PayloadAction<UploadCSVPayload>) {},
    setCanShowUpdateJd(state: typeof initialState, { payload }: { payload: boolean }) {
        state.canShowUpdateJd = payload;
        state.canShowAddProject = payload;
    },
    updateJd(state: typeof initialState, { payload }: { payload: { objective?: string; platform?: string } }) {},
    setIsSalesNav(state: typeof initialState, { payload }: { payload: boolean }) {
        state.isSalesNav = payload;
    },
    resetState(state: typeof initialState) {
        return initialState;
    },
    leavePage(state: any) {},
    setTalentMarketing(state: any, action: { payload: boolean }) {
        state.unlockTalentMarketing = action.payload;
    },
    setIntent(state: typeof initialState, { payload }: { payload: string }) {
        state.intent = payload;
    },
    setVettingCriteriaText(state: IRootState, { payload }: { payload: boolean }) {
        state.showVettingCriteriaText = payload;
    },
};

export const page = createSlice({
    name: "createProject",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    addContactListToProject,
    submitValuePropositionInputs,
    setErrors,
    setProjectName,
    setPurpose,
    setCsvFile,
    setLiCookie,
    createProject,
    setDetailScrape,
    setCanScrapeEmail,
    uploadCsv,
    setCanShowAddProject,
    setCanShowIntegratedProjectModal,
    resetState,
    setProjectJd,
    setCanShowUpdateJd,
    updateJd,
    setIsSalesNav,
    leavePage,
    setShowCsvHeaderDialog,
    setTalentMarketing,
    setIntent,
    updateMarketingProject,
    setVettingCriteriaText,
} = page.actions;

export const selectCreateProjectName = (state: RootState) => state.createProject.projectName;
export const selectDetailScrape = (state: RootState) => state.createProject.detailScrape;
export const selectCanShowAddProject = (state: RootState) => state.createProject.canShowAddProject;
export const selectCanShowIntegratedProjectModal = (state: RootState) =>
    state.createProject.canShowIntegratedProjectModal;
export const selectCanShowUpdateJd = (state: RootState) => state.createProject.canShowUpdateJd;
export const selectErrors = (state: RootState) => state.createProject.errors;
export const selectCsvFile = (state: RootState) => state.createProject.csvFile;
export const selectProjectName = (state: RootState) => state.createProject.projectName;
export const selectShowCsvHeaderDialog = (state: RootState) => state.createProject.showCsvHeaderDialog;
export const selectUnlockTalentMarketing = (state: RootState) => state.createProject.unlockTalentMarketing;
export const selectIntent = (state: RootState) => state.createProject.intent;
export const selectVettingCriteriaText = (state: RootState) => state.createProject.showVettingCriteriaText;
export const selectPurpose = (state: RootState) => state.createProject.purpose;
