import { ComponentType, lazy } from "react";

import { hardRefresh } from "./refresh";

const importErrorMessages = ["load module script", "dynamically imported module", "Unable to preload CSS"];

function extractModuleName(input: string) {
    if (!input) {
        return "";
    }
    const regex = /(?<=import\(["'].*\/)([^\/.]+)(?=\.js|\.ts|["'])|(?<=default:e\.)\w+/;
    const match = input.match(regex);
    return match ? match[0] : "";
}

async function retryImport<T>(importFunction: () => Promise<T>): Promise<T> {
    const moduleName = extractModuleName(importFunction?.toString?.());
    try {
        console.log(`Importing... ${moduleName}`);
        return await importFunction();
    } catch (error: any) {
        console.log(`Failed to import... ${moduleName}`, error?.message);
        if (importErrorMessages.some((message) => error?.message?.includes(message))) {
            console.log(`Retrying... ${moduleName}`);
            hardRefresh();
            throw "WAITING_FOR_REFRESH";
        } else {
            throw error;
        }
    }
}

export function lazyImport<T extends { default: ComponentType<any> }>(importFunction: () => Promise<T>) {
    return lazy(() => retryImport(importFunction));
}
