import { ChipProps } from "@mui/joy/Chip";

import { TScrapeStatus } from "./types";

export enum QUERIES {
    AUTO_PILOT_STATS = "auto_pilot_stats",
    AUTO_PILOT_PREVOIUS_STATS = "auto_pilot_previous_stats",
}

export enum SET_INDEX {
    "SET-ONE" = 1,
    "SET-TWO" = 2,
}

export const STATUS_COLOR: { [key in TScrapeStatus]: ChipProps["color"] } = {
    PENDING: "neutral",
    IN_PROGRESS: "warning",
    COMPLETED: "success",
    CANCELLED: "danger",
    PAUSED: "warning",
} as const;
