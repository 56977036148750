import {
    Box,
    TableCell,
    TableCellProps,
    TableHead,
    TableRow,
    TableSortLabel,
    styled,
    tableCellClasses,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useFetchCandidatesWithPayload } from "./AllCandidatesContent";
import {
    handleAllCandidatesRequestSort,
    handleAllCandidatesSelectAllClick,
    isAllCandidatesSelected,
    selectAllCandidatesFilters,
    selectAllCandidatesSearchResults,
    selectAllCandidatesTableOrder,
    selectAllCandidatesTableOrderBy,
    toggleSelectAllSearchResults,
} from "./all-candidates.slice";
import { Candidate } from "./all-candidates.types";

interface CustomTableCellProps extends TableCellProps {
    sticky?: boolean;
    removeBottomBorder?: boolean;
    defaultwidth?: boolean;
}

const StyledTableCell = styled(TableCell)<CustomTableCellProps>(
    ({ theme, sticky, removeBottomBorder, defaultwidth = true }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontSize: "14px",
            fontWeight: "500",
        },
        ...(defaultwidth && {
            minWidth: "130px",
        }),
        ...(sticky && {
            position: "sticky",
            left: 0,
            backgroundColor: theme.palette.common.white,
            zIndex: theme.zIndex.appBar + 2,
        }),
        ...(removeBottomBorder && {
            borderBottom: "0",
        }),
    })
);

export default function AllCandidatesTableHead() {
    const dispatch = useDispatch();
    const order = useSelector(selectAllCandidatesTableOrder);
    const orderBy = useSelector(selectAllCandidatesTableOrderBy);
    const filters = useSelector(selectAllCandidatesFilters);
    const { data } = useFetchCandidatesWithPayload();
    const selected = useSelector(isAllCandidatesSelected);
    const isAllSelected = useSelector(selectAllCandidatesSearchResults);

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        if (checked) {
            if (isAllSelected) {
                dispatch(toggleSelectAllSearchResults());
            } else {
                const ids = data?.candidates.map(({ _id }) => _id);
                if (ids !== undefined) {
                    dispatch(handleAllCandidatesSelectAllClick(ids));
                }
            }
        } else {
            dispatch(handleAllCandidatesSelectAllClick([]));
        }
    };

    const createSortHandler = (property: keyof Candidate) => (_: React.MouseEvent<unknown>) => {
        dispatch(handleAllCandidatesRequestSort(property));
    };

    const { Name: nameFilter, Label: labelFilter } = filters;

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox" sticky>
                    <StyledTableCell removeBottomBorder defaultwidth={false}>
                        <Checkbox color="primary" checked={selected || isAllSelected} onChange={handleSelect} />
                    </StyledTableCell>
                    {labelFilter && (
                        <StyledTableCell
                            removeBottomBorder
                            sortDirection={orderBy === "Label" ? order : false}
                            defaultwidth={false}
                            sx={{
                                minWidth: "90px",
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === "Label"}
                                direction={orderBy === "Label" ? order : "asc"}
                                onClick={createSortHandler("Label")}
                            >
                                {labelFilter.label}
                                {orderBy === "Label" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    )}
                    {nameFilter && (
                        <StyledTableCell removeBottomBorder sortDirection={orderBy === "Name" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "Name"}
                                direction={orderBy === "Name" ? order : "asc"}
                                onClick={createSortHandler("Name")}
                            >
                                {nameFilter.label}
                                {orderBy === "Name" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    )}
                </StyledTableCell>
                {Object.entries(filters).map(([filter, value]) => {
                    const isSticky = filter === "Label" || filter === "Name";

                    if (isSticky) {
                        return null;
                    }

                    return (
                        <StyledTableCell
                            key={filter}
                            sortDirection={orderBy === filter ? order : false}
                            sticky={isSticky}
                        >
                            <TableSortLabel
                                active={orderBy === filter}
                                direction={orderBy === filter ? order : "asc"}
                                onClick={createSortHandler(filter)}
                            >
                                {capitalize(value.label)}
                                {orderBy === filter ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
