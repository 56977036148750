export const hardRefresh = (forceReload = false) => {
    const time = new Date().getTime();

    if (forceReload) {
        window.location.href = "/" + "?refresh=" + time;
        return;
    }

    if (window.location.href.indexOf("refresh=") > -1) {
        // already refreshing
        return;
    }

    if (window.location.href.indexOf("?") > -1) {
        window.location.href = window.location.href + "&refresh=" + time;
    } else {
        window.location.href = window.location.href + "?refresh=" + time;
    }
};
