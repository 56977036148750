import { useCallback, useEffect, useRef, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button as JoyButton } from "@mui/joy";
import {
    Box,
    Button,
    Collapse,
    Divider,
    IconButton,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { ErrorPlaceholder } from "../../../../components/ErrorPlaceholder";
import { JoyProvider } from "../../../../components/JoyProvider";
import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import uselocalStorage from "../../../../hooks/useLocalStorage";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import Delay from "../../../editWorkflow/components/delay/Delay";
import Email from "../../../editWorkflow/components/email/Email";
import { ReadOnlyStatus } from "../../../editWorkflow/components/ReadOnlyStatus";
import Reminder from "../../../editWorkflow/components/reminder/Reminder";
import { checkEasyGrowth } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { SMS } from "../../../triggerWorkflow/components/Sms";
import { resumeProjectWorkflow, stopProjectWorkflow } from "../../../workflow/index.reducer";
import { Step } from "../../../workflow/workflow.types";
import {
    checkErrorWhileFetchingAssociatedReachouts,
    getProjectWorkflows,
    getWorkflowsLogsById,
    selectProjectWorkflows,
} from "../../index.reducer";
import { IProjectWorkflows, TWorkflowStatus } from "../../project.types";
import style from "./associatedReachout.module.scss";
import Loader from "../../../../components/Loader/Loader";

const STATUS_MAP: { [key in TWorkflowStatus]: string } = {
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    PENDING: "Pending",
    STOPPED: "Stopped",
    FAILED: "Failed",
    CANCELLED: "Cancelled",
    RESUME: "Resume",
} as const;

interface IWorkflowModal {
    projectId: number;
    onClose: () => void;
}

type WorkflowTableProps = {
    index: string;
    workflow: IProjectWorkflows;
    handleSelectWorkflow: (key: Step[]) => void;
    parentProps?: {
        open: boolean;
        setOpen: (open: boolean) => void;
        childrenList: IProjectWorkflows[];
    };
    isChildren?: boolean;
    bgColor?: string;
};

export interface statsLogLoaderType {
    [key: string]: boolean;
}

const toDateString = (date: string) => {
    return new Date(date).toLocaleDateString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "short",
        year: "2-digit",
    });
};

function WorkflowTable({
    index,
    workflow,
    handleSelectWorkflow,
    parentProps,
    isChildren,
    bgColor = "white",
}: WorkflowTableProps) {
    const params = useParams();
    const [statsLogLoader, setStatsLogLoader] = useState<statsLogLoaderType>({});
    const dispatch = useDispatch();
    const isResumingWorkflow = useSelector(checkIfLoading(`${workflow._id}${resumeProjectWorkflow.type}`));
    const isStoppingWorkflow = useSelector(checkIfLoading(`${workflow._id}${stopProjectWorkflow.type}`));
    const isStoppingParentWorkflow = useSelector(checkIfLoading(`parent-${workflow._id}${stopProjectWorkflow.type}`));
    const workflowsByIdLoading = useSelector(checkIfLoading(getWorkflowsLogsById.type));
    const isLinkedInWorkflow = workflow.steps.find((item) => item.eventEnum === 4 || item.eventEnum === 6);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const status = workflow.status;

    const handleRowClick = () => {
        const steps = isChildren
            ? workflow?.steps
            : workflow?.parentSteps?.length
              ? workflow.parentSteps
              : workflow?.steps;

        if (!steps?.length) return;

        const tmpArr = steps.map((step) => {
            return {
                ...step,
                isOpen: false,
            };
        });

        handleSelectWorkflow(tmpArr);
    };

    const handleStopWorkflow = () => {
        dispatch(
            stopProjectWorkflow({
                action: `${workflow._id}${stopProjectWorkflow.type}`,
                workflowId: workflow._id,
                projectId: Number(params.id || ""),
            })
        );
    };

    const onClickWorkflowsLogs = ({
        type,
        workflowId,
        workflowLogId,
        workflowLogIds,
    }: {
        type: string;
        workflowId: string;
        workflowLogId: string;
        workflowLogIds: string[];
    }) => {
        const newLogsLoader = {
            ...statsLogLoader,
            [`${workflowId}_${type}`]: true,
        };
        setStatsLogLoader(newLogsLoader);
        dispatch(
            getWorkflowsLogsById({
                workflowLogId,
                workflowId,
                workflowLogIds,
                type,
                callBack: () => removeWorkflowsLogsLoader({ type, workflowId }),
            })
        );
    };

    const removeWorkflowsLogsLoader = ({ type, workflowId }: { type: string; workflowId: string }) => {
        const newLogsLoader = { ...statsLogLoader };
        delete newLogsLoader[`${workflowId}_${type}`];
        setStatsLogLoader(newLogsLoader);
    };

    const stopParentWorkflow = () => {
        if (!parentProps) return;
        const { childrenList } = parentProps;
        const incompleteWorkflows = childrenList.filter((workflow) => {
            return workflow.status === "IN_PROGRESS";
        });

        for (let i = 0; i < incompleteWorkflows.length; ++i) {
            dispatch(
                stopProjectWorkflow({
                    action: `parent-${workflow._id}${stopProjectWorkflow.type}`,
                    workflowId: incompleteWorkflows[i]._id,
                    projectId: Number(params.id || ""),
                })
            );
        }
    };

    const isTriggeredLoading = statsLogLoader[`${workflow?._id}_TRIGGERED_CANDIDATE`];
    const isContactedLoading = statsLogLoader[`${workflow?._id}_CONTACTED`];
    const isClickedLoading = statsLogLoader[`${workflow?._id}_CLICKED`];
    const isOpenedLoading = statsLogLoader[`${workflow?._id}_OPENED`];
    const isBounceLoading = statsLogLoader[`${workflow?._id}_BOUNCE`];
    const isEmailCountLoading = statsLogLoader[`${workflow?._id}_EMAIL_COUNT`];

    const workflowLogIds = Array.from(
        new Set([workflow?.workflowLogId, ...(parentProps?.childrenList?.map((i) => i.workflowLogId) ?? [])])
    );

    return (
        <TableRow sx={{ background: bgColor }}>
            {parentProps ? (
                <TableCell>
                    <Tooltip title={"Overall workflow with aggregate stats (click to view split workflow steps)"}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => parentProps?.setOpen(!parentProps?.open)}
                        >
                            {parentProps?.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </TableCell>
            ) : (
                <TableCell></TableCell>
            )}
            <TableCell>{index}</TableCell>
            <TableCell>{workflow.email || workflow.authorizedEmailAddress}</TableCell>
            <TableCell >{workflow.triggeredAt ? toDateString(workflow.triggeredAt) : ""}</TableCell>
            <TableCell>{workflow.scheduledFor ? toDateString(workflow.scheduledFor) : ""}</TableCell>
            {/* <TableCell align="center">{workflow.noOfSteps}</TableCell> */}
            <TableCell align="center">
                <Stack flexDirection="row" alignItems="center">
                    {isTriggeredLoading ? (
                        <div className={style["rvp__loading-wrapper"]}>
                            <Loader size="sm" />
                        </div>
                    ) : (
                        <p
                            onClick={() =>
                                onClickWorkflowsLogs({
                                    workflowLogId: workflow?.workflowLogId,
                                    workflowId: workflow?._id,
                                    type: "TRIGGERED_CANDIDATE",
                                    workflowLogIds,
                                })
                            }
                            className={style.statsCount}
                        >
                            {workflow.numberOfCandidates || 0}
                        </p>
                    )}
                </Stack>
                {/* {workflow.numberOfCandidates} */}
            </TableCell>
            <TableCell align="center">
                {isContactedLoading ? (
                    <div className={style["rvp__loading-wrapper"]}>
                        <Loader size="sm" />
                    </div>
                ) : (
                    <p
                        onClick={() => {
                            onClickWorkflowsLogs({
                                workflowLogId: workflow?.workflowLogId,
                                workflowId: workflow?._id,
                                workflowLogIds,
                                type: "CONTACTED",
                            });
                        }}
                        className={style.statsCount}
                    >
                        {workflow.contacted || 0}
                    </p>
                )}
            </TableCell>

            <TableCell>
                {isEmailCountLoading ? (
                    <div className={style["rvp__loading-wrapper"]}>
                        <Loader size="sm" /> 
                    </div>
                ) : (
                    <p
                        onClick={
                            () =>
                                // dispatch(
                                onClickWorkflowsLogs({
                                    workflowLogId: workflow?.workflowLogId,
                                    workflowId: workflow?._id,
                                    type: "EMAIL_COUNT",
                                    workflowLogIds,
                                })
                            // )
                        }
                        className={style.statsCount}
                    >
                        {workflow.emailCount || 0}
                    </p>
                )}
            </TableCell>
            <TableCell>
                {isEmailCountLoading ? (
                    <div className={style["rvp__loading-wrapper"]}>
                        <Loader size="sm" />
                    </div>
                ) : (
                    <p
                        onClick={
                            () =>
                                // dispatch(
                                onClickWorkflowsLogs({
                                    workflowLogId: workflow?.workflowLogId,
                                    workflowId: workflow?._id,
                                    type: "FOLLOWUP_COUNT",
                                    workflowLogIds,
                                })
                            // )
                        }
                        className={style.statsCount}
                    >
                        {workflow.followupEmailCount || 0}
                    </p>
                )}
            </TableCell>
            <TableCell align="center">
                <Stack flexDirection="row" alignItems="center">
                    {isBounceLoading ? (
                        <div className={style["rvp__loading-wrapper"]}>
                            <Loader size="sm" />
                        </div>
                    ) : (
                        <p
                            onClick={() =>
                                onClickWorkflowsLogs({
                                    workflowLogId: workflow?.workflowLogId,
                                    workflowId: workflow?._id,
                                    type: "BOUNCE",
                                    workflowLogIds,
                                })
                            }
                            className={style.statsCount}
                        >
                            {workflow.bounced || 0}
                        </p>
                    )}
                </Stack>
            </TableCell>
            <TableCell align="center">
                <Stack flexDirection="row" alignItems="center">
                    {isClickedLoading ? (
                        <div className={style["rvp__loading-wrapper"]}>
                            <Loader size="sm" />
                        </div>
                    ) : (
                        <p
                            onClick={() =>
                                onClickWorkflowsLogs({
                                    workflowLogId: workflow?.workflowLogId,
                                    workflowId: workflow?._id,
                                    workflowLogIds,
                                    type: "CLICKED",
                                })
                            }
                            className={style.statsCount}
                        >
                            {workflow.clicked || 0}
                        </p>
                    )}
                </Stack>
            </TableCell>
            {isEasyGrowth && (
                <TableCell align="center">
                    <Stack flexDirection="row" alignItems="center">
                        {isOpenedLoading ? (
                            <div className={style["rvp__loading-wrapper"]}>
                                <Loader size="sm" />
                            </div>
                        ) : (
                            <p
                                onClick={() =>
                                    onClickWorkflowsLogs({
                                        workflowLogId: workflow?.workflowLogId,
                                        workflowId: workflow?._id,
                                        type: "OPENED",
                                        workflowLogIds,
                                    })
                                }
                                className={style.statsCount}
                            >
                                {workflow.opened || 0}
                            </p>
                        )}
                    </Stack>
                </TableCell>
            )}
            <TableCell align="center">{workflow.liConnectionRequestCount || 0}</TableCell>
            <TableCell>{workflow.liInmailCount}</TableCell>
            <TableCell>{workflow.smsSent}</TableCell>

            <TableCell>
                <Tooltip
                    title={
                        status === "STOPPED"
                            ? "This workflow has been stopped. Click 'View' to see this workflow's status."
                            : undefined
                    }
                >
                    <Typography
                        sx={{
                            fontSize: "13px",
                            color: status === "STOPPED" ? "red" : "black",
                        }}
                    >
                        {STATUS_MAP[status] || status}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {/* {status === "STOPPED" && (
                        <Tooltip
                            title={
                                isLinkedInWorkflow
                                    ? 'Before clicking "Resume," please make sure to sign in to your LinkedIn account.'
                                    : null
                            }
                        >
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleResumeWorkflow}
                                disabled={isResumingWorkflow}
                            >
                                <ButtonTextWithLoading
                                    isLoading={isResumingWorkflow}
                                    text="Resume"
                                />
                            </Button>
                        </Tooltip>
                    )} */}
                    {isChildren && (status === "PENDING" || status === "IN_PROGRESS") && (
                        <Tooltip title="If you stop this workflow, all future steps will be stopped. However, ongoing step will be completed. Click 'View' to see this workflow's status.">
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={handleStopWorkflow}
                                disabled={isStoppingWorkflow}
                            >
                                <ButtonTextWithLoading isLoading={isStoppingWorkflow} text="Stop" />
                            </Button>
                        </Tooltip>
                    )}
                    {!isChildren &&
                        parentProps?.childrenList.filter((item) => {
                            return item.status === "IN_PROGRESS";
                        })?.length !== 0 && (
                            <Tooltip title="If you stop this workflow, all future steps will be stopped. However, ongoing step will be completed. Click 'View' to see this workflow's status.">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    onClick={stopParentWorkflow}
                                    disabled={isStoppingParentWorkflow}
                                >
                                    <ButtonTextWithLoading isLoading={isStoppingParentWorkflow} text="Stop" />
                                </Button>
                            </Tooltip>
                        )}
                    <Button variant="contained" size="small" onClick={handleRowClick}>
                        View
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    );
}

function addIndexField(data: IProjectWorkflows[]) {
    // Group by scheduledFor field
    const grouped = data?.reduce((acc: { [key: string]: IProjectWorkflows[] }, item: any) => {
        const workflowGroup = item?.name?.split("::")?.[1] || item?._id;
        (acc[workflowGroup] = acc[workflowGroup] || []).push(item);
        return acc;
    }, {});

    const result: { parent: IProjectWorkflows; children: IProjectWorkflows[] }[] = [];

    // // Process each group
    Object.keys(grouped).forEach((key, groupIndex) => {
        let group = grouped[key];

        // cloning group for immutability

        group = group.map((obj) => ({ ...obj }));

        // Sort group by numberOfCandidates in descending order
        // group.sort((a, b) => b.numberOfCandidates - a.numberOfCandidates);
        group.sort((a, b) => new Date(a.scheduledFor).getTime() - new Date(b.scheduledFor).getTime());

        // take the first item as parent
        const [parent, ...children] = group as IProjectWorkflows[];
        // parent = { ...parent, index: (groupIndex + 1).toString() };
        //Keep an original parent
        const originalParent = { ...parent };

        // // Sort the rest of the items by triggeredAt in ascending order
        // children.sort((a, b) => new Date(a.triggeredAt).getTime() - new Date(b.triggeredAt).getTime());

        // children = children.map((item, index) => ({ ...item, index: `${groupIndex + 1}.${index + 1}` }));
        const contactedSet = new Set([
            ...(Array.isArray(parent.contactedArray) ? parent.contactedArray : []),
            ...children.flatMap((i) => (Array.isArray(i.contactedArray) ? i.contactedArray : [])),
        ]);
        const noOfCandidatesSet = new Set([
            ...(Array.isArray(parent.candidateIds) ? parent.candidateIds : []),
            ...children.flatMap((i) => (Array.isArray(i.candidateIds) ? i.candidateIds : [])),
        ]);

        children.forEach((item) => {
            // parent.contacted += item.contacted;
            parent.emailCount += item.emailCount;
            parent.bounced += item.bounced;
            parent.clicked += item.clicked;
            parent.opened += item.opened;
            parent.liConnectionRequestCount += item.liConnectionRequestCount;
            parent.liInmailCount += item.liInmailCount;
            parent.smsSent += item.smsSent;
            parent.followupEmailCount = (parent.followupEmailCount ?? 0) + (item?.followupEmailCount ?? 0);

            switch (item.status) {
                case "IN_PROGRESS":
                    parent.status = "IN_PROGRESS";
                    break;
                case "STOPPED":
                    parent.status = "STOPPED";
                    break;
                case "PENDING":
                    parent.status = "PENDING";
                    break;
                case "CANCELLED":
                    parent.status = "CANCELLED";
                    break;
                case "FAILED":
                    parent.status = "FAILED";
                    break;
                default:
                    break;
            }
        });

        parent.contacted = contactedSet.size;
        parent.numberOfCandidates = noOfCandidatesSet.size;

        result.push({ parent, children: children.length === 0 ? [] : [originalParent, ...children] });
    });

    result.sort((a, b) => new Date(b.parent.triggeredAt).getTime() - new Date(a.parent.triggeredAt).getTime());

    return result;
}

function Row({
    parent,
    childrenList,
    index,
    handleSelectWorkflow,
}: {
    parent: IProjectWorkflows;
    childrenList: IProjectWorkflows[];
    index: number;
    handleSelectWorkflow: (wk: any) => void;
}) {
    const [open, setOpen] = useState(false);
    const isParent = childrenList.length > 0;

    return (
        <>
            <WorkflowTable
                index={`${index}`}
                key={parent._id}
                workflow={parent}
                handleSelectWorkflow={handleSelectWorkflow}
                isChildren={!isParent}
                {...(isParent && {
                    parentProps: {
                        open: open,
                        setOpen: (open) => setOpen(open),
                        childrenList: childrenList,
                    },
                })}
            />
            {open &&
                childrenList.map((item, i) => {
                    return (
                        <WorkflowTable
                            index={`${index}.${i + 1}`}
                            key={item._id}
                            workflow={item}
                            handleSelectWorkflow={handleSelectWorkflow}
                            isChildren={true}
                            bgColor="#EEEEEE"
                        />
                    );
                })}
        </>
    );
}

export function AssociatedReachouts({ projectId, onClose }: IWorkflowModal) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const projectWorkflows = useSelector(selectProjectWorkflows);
    const isErrorWhileFetchingAssociatedReachouts = useSelector(checkErrorWhileFetchingAssociatedReachouts);
    // console.log({ isErrorWhileFetchingAssociatedReachouts });
    const checkWorkflowsLoading = useSelector(checkIfLoading(getProjectWorkflows.type));
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const [selectedWorkflow, setSelectedWorkflow] = useState<IProjectWorkflows | null>(null);
    const csvLink = useRef();
    const [csvData, setCsvData] = useState<string[][]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [isShownWarning, setIsShownWarning] = uselocalStorage("isShownWarning", false);

    const groupedProjectWorkflows = addIndexField(projectWorkflows);

    useEffect(() => {
        if (!projectId) return;

        dispatch(
            getProjectWorkflows({
                action: getProjectWorkflows.type,
                projectId,
                // paginated: {
                //     start: (currentPage - 1),
                //     limit: itemsPerPage,
                // }
            })
        );
    }, [projectId]);

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            if (isEmpty(selectedWorkflow)) return;

            let tmpArr = [...selectedWorkflow.steps];
            tmpArr[tarInd].isOpen = !selectedWorkflow.steps[tarInd].isOpen;
            setSelectedWorkflow({
                ...selectedWorkflow,
                steps: tmpArr,
            });
        },
        [selectedWorkflow]
    );

    const handleDownload = () => {
        const data = [];
        data.push([
            "S. No",
            "Triggered by",
            "Triggered at",
            "Scheduled for",
            t("associatedReachoutsTable.candidatesSelected"),
            "Contacted",
            "Emails sent",
            "Follow-up emails",
            "#Bounced emails",
            "#Clicks",
            ...(isEasyGrowth ? ["#Opens"] : []),
            "Connections sent",
            "InMails sent",
            "SMS sent",
            "Status",
        ]);

        groupedProjectWorkflows.forEach(({ parent }, index) => {
            const d = [];
            d.push(index + 1);
            d.push(parent.email || parent.authorizedEmailAddress);
            d.push(parent.triggeredAt ? toDateString(parent.triggeredAt) : "");
            d.push(parent.scheduledFor ? toDateString(parent.scheduledFor) : "");
            d.push(parent.numberOfCandidates || 0);
            d.push(parent.contacted || 0);
            d.push(parent.emailCount || 0);
            d.push(parent.followupEmailCount || 0);
            d.push(parent.bounced || 0);
            d.push(parent.clicked || 0);
            if (isEasyGrowth) {
                d.push(parent.opened || 0);
            }
            d.push(parent.liConnectionRequestCount || 0);
            d.push(parent.liInmailCount || 0);
            d.push(parent.smsSent || 0);
            d.push(STATUS_MAP[parent.status] || parent.status);
            data.push(d);
        });

        setCsvData(data);
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
            dispatch(setSuccessNotification("Associated reachout CSV downloaded successfulluy."));
        });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setCurrentPage(newPage);
    };
    const currentItems = groupedProjectWorkflows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                width: "90.25%",
                padding: "1rem 1.5rem",
                // maxHeight: "80vh",
                // overflow: "auto",
            }}
        >
            <Stack alignItems={"center"} direction="row" mb={1} gap={1}>
                <Typography fontSize={20} fontWeight={500} color="#334d6e">
                    {t("projectsPage.headerMenuLabels.associatedReachouts")}
                </Typography>
                <IconButton
                    disabled={checkWorkflowsLoading || isErrorWhileFetchingAssociatedReachouts}
                    onClick={handleDownload}
                >
                    <DownloadIcon />
                </IconButton>
            </Stack>
            <Divider
                sx={{
                    borderBottomWidth: "initial",
                }}
            />
            {isErrorWhileFetchingAssociatedReachouts ? (
                <ErrorPlaceholder
                    reload={() =>
                        dispatch(
                            getProjectWorkflows({
                                action: getProjectWorkflows.type,
                                projectId,
                                // paginated: {
                                //     start: (currentPage - 1),
                                //     limit: itemsPerPage,
                                // }
                            })
                        )
                    }
                    containerProps={{
                        minHeight: "50vh",
                        justifyContent: "center",
                    }}
                >
                    <CachedIcon fontSize="small" />
                </ErrorPlaceholder>
            ) : checkWorkflowsLoading ? (
                <div className={style["rvp__loading-wrapper__main"]}>
                    <Loader size="sm" />
                    {/* <Pagination
                        count={Math.ceil(groupedProjectWorkflows.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        sx={{ marginTop: "51vh", justifyContent: 'center', display: 'flex'}}
                    /> */}
                </div>
            ) : selectedWorkflow?.name ? (
                <>
                    <div className={style["rvp__workflowHeader"]}>
                        <IconButton onClick={() => setSelectedWorkflow(null)}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <p className={style["rvp__workflowHeaderName"]}>{selectedWorkflow.name?.split?.("::")[0]}</p>
                    </div>
                    <div style={{ maxHeight: "56.5vh", overflowY: "auto" }}>
                        {selectedWorkflow.steps.map((val, i) => {
                            let emailEncountered = false;
                            for (let j = i - 1; j >= 0; --j) {
                                const element = selectedWorkflow.steps[j];
                                if (element.eventEnum === 1 || element.eventEnum === 12) {
                                    emailEncountered = true;
                                    break;
                                }
                            }
                            if (val.eventEnum === 1 || val.eventEnum === 12) {
                                return (
                                    <Email
                                        emailEncountered={emailEncountered}
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.subject}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"email"}
                                        readable={true}
                                        status={<ReadOnlyStatus status={val?.status} sx={{ right: "-90px" }} />}
                                    />
                                );
                            } else if (val.eventEnum === 3) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"rem"}
                                        readable={true}
                                        // status={
                                        //     <ReadOnlyStatus
                                        //         status={val?.status}
                                        //     />
                                        // }
                                        inMailProps={{
                                            inmailStatus: val.eventBody?.inmailStatus || false,
                                            inmailSubject: val.eventBody?.inmailSubject || "",
                                            inmailBody: val.eventBody?.inmailBody || "",
                                        }}
                                    />
                                );
                            } else if (val.eventEnum === 2) {
                                return (
                                    <Delay
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        toggleCollapse={handleCollapseChange}
                                        hours={val.eventBody.hours}
                                        mins={val.eventBody.mins}
                                        days={val.eventBody.days}
                                        weeks={val.eventBody.weeks}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 5) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"linked-in-msg"}
                                        readable={true}
                                        // status={
                                        //     <ReadOnlyStatus
                                        //         status={val?.status}
                                        //     />
                                        // }
                                    />
                                );
                            } else if (val.eventEnum === 4 && val.eventBody?.connReqStatus) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"connection-request"}
                                        readable={true}
                                        status={
                                            <ReadOnlyStatus
                                                status={val?.status}
                                                sx={{
                                                    right: "-90px",
                                                }}
                                            />
                                        }
                                        inMailProps={{
                                            inmailStatus: val.eventBody?.inmailStatus || false,
                                            inmailSubject: val.eventBody?.inmailSubject || "",
                                            inmailBody: val.eventBody?.inmailBody || "",
                                        }}
                                    />
                                );
                            } else if (val.eventEnum === 4 && val.eventBody?.inmailStatus) {
                                return (
                                    <Email
                                        emailEncountered={emailEncountered}
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.inmailSubject}
                                        editorVal={val.eventBody.inmailBody}
                                        toggleCollapse={handleCollapseChange}
                                        type={"in-mail"}
                                        readable={true}
                                        status={<ReadOnlyStatus status={val?.status} />}
                                    />
                                );
                            } else if (val.eventEnum === 11) {
                                return (
                                    <SMS
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type="sms"
                                        readable={true}
                                        status={
                                            <ReadOnlyStatus
                                                status={val?.status}
                                                sx={{
                                                    right: "-90px",
                                                }}
                                            />
                                        }
                                    />
                                );
                            } else return null;
                        })}
                    </div>
                </>
            ) : (
                <div
                    className={style["rvp"]}
                    style={{
                        maxHeight: "70vh",
                        //  overflowY:"scroll"
                    }}
                >
                    {!isShownWarning && isEasyGrowth && (
                        <Stack
                            sx={{
                                my: 1,
                                background: "#D1FFBD",
                                borderRadius: "0.5rem",
                            }}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            padding={1}
                            direction={"row"}
                        >
                            <Typography sx={{ color: "black" }} textAlign={"center"} variant="body2">
                                With recent changes to Gmail and Outlook governing open rate tracking, EasyGrowth has
                                decided to let go of open rate tracking and instead optimize your email delivery
                            </Typography>
                            <JoyProvider>
                                <Stack gap={1} alignItems={"center"} direction={"row"}>
                                    <JoyButton
                                        variant="outlined"
                                        onClick={() => {
                                            setIsShownWarning(true);
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </JoyButton>
                                </Stack>
                            </JoyProvider>
                        </Stack>
                    )}

                    {projectWorkflows.length ? (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    // maxHeight: "calc(100vh - 180px)",
                                    maxHeight: "56.5vh",
                                    // overflowY:"scroll",
                                    marginTop: 2,
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>S. No.</TableCell>
                                            <TableCell>Triggered by</TableCell>
                                            <TableCell>Triggered at</TableCell>
                                            <TableCell>Scheduled for</TableCell>
                                            <TableCell>{t("associatedReachoutsTable.candidatesSelected")}</TableCell>
                                            <TableCell>Contacted</TableCell>
                                            <TableCell>Emails sent</TableCell>
                                            <TableCell>Follow-up emails</TableCell>
                                            <TableCell>#Bounced emails</TableCell>
                                            <TableCell>#Clicks</TableCell>
                                            {isEasyGrowth && <TableCell>#Opens</TableCell>}
                                            <TableCell>Connections sent</TableCell>
                                            <TableCell>InMails sent</TableCell>
                                            <TableCell>SMS sent</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentItems.map(({ parent, children }, index) => (
                                            <Row
                                                key={parent._id}
                                                parent={parent}
                                                childrenList={children}
                                                index={index + 1 + (currentPage - 1) * itemsPerPage}
                                                handleSelectWorkflow={(wk) => {
                                                    setSelectedWorkflow({
                                                        ...parent,
                                                        steps: wk,
                                                    });
                                                }}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination
                                count={Math.ceil(groupedProjectWorkflows.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handleChangePage}
                                sx={{ marginTop: 2, justifyContent: "center", display: "flex" }}
                            />
                        </>
                    ) : (
                        <p className={style["rvp__noItem"]}>
                            No reachouts triggered. To reach out to {t("common")}, start your reachout now.
                        </p>
                    )}
                </div>
            )}
            <CSVLink
                headers={[]}
                data={csvData || []}
                filename={`Associated-Reachout-${projectId}.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </BaseModal>
    );
}
