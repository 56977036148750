import { Children, cloneElement } from "react";
import { uniqueId } from "lodash";

import { useCreateChartMutation, useUpdateChatMutation } from "../../api/chatbot.api.slice";
import { useChatbotContext } from "../../Context";

interface MessageParserProps {
    children: any;
    actions: {
        reply: (response: { message: string; loaderId: string; citations?: any[] }) => void;
        wait: (loaderId: string) => void;
    };
}

function MessageParser({ children, actions }: MessageParserProps) {
    const { chatbot, setChatbot } = useChatbotContext();
    const [createChat] = useCreateChartMutation();
    const [updateChat] = useUpdateChatMutation();

    const parse = async (message: any) => {
        // if (message.includes("hello")) {
        //     actions.greet();
        // }

        const loaderId = uniqueId();

        actions.wait(loaderId);

        let res: any;

        if (chatbot?.chatId) {
            res = await updateChat({ threadId: chatbot.chatId, textMessage: message });
        } else {
            res = await createChat(message);
            setChatbot({ ...chatbot, chatId: res?.data?.threadId, reload: false });
        }

        const replyMsg = res?.data?.response ?? "Opps! Something went wrong. Please try again later.";

        actions.reply({ message: replyMsg, loaderId, citations: res?.data?.citations });
    };

    return (
        <div>
            {Children.map(children, (child) => {
                return cloneElement(child, {
                    parse: parse,
                    actions: actions,
                });
            })}
        </div>
    );
}

export default MessageParser;
