import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/Store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { IAccountRootState, IAlias, IAllOrgMember, ICheckoutPayload, IEmailType, IUserPlan } from "./myAccount.types";

const initialState: IAccountRootState = {
    plans: [],
    userPlan: {
        name: "Free",
        createdAt: "2023-04-27T07:49:01.798Z",
        validTill: "Forever",
        remainingCredits: 30,
        totalCredits: 100,
    },
    emailType: "",
    aliasName: {
        alias: "",
        outboundEmail: "",
    },
    defaultAlias: "",
    virtualUserSignature: "",
    virtualUser: {
        alias: "",
        outboundEmail: "",
    },
    allOrgMembers: [],
};

const reducers = {
    fetchPlans(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setPlans(state: typeof initialState, { payload }: { payload: any[] }) {
        state.plans = payload;
    },
    setVirtualUser(state: typeof initialState, { payload }: { payload: any }) {
        state.allOrgMembers = payload;
    },
    setVirtualUserSignature(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUserSignature = payload;
    },
    fetchUserPlan(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setUserPlan(state: typeof initialState, { payload }: { payload: IUserPlan }) {
        state.userPlan = payload;
    },
    createCheckoutSession(state: typeof initialState, { payload }: { payload: IActionPayload & ICheckoutPayload }) {},
    resetMyAccount(state: typeof initialState) {},
    setEmailType(state: typeof initialState, { payload }: { payload: IEmailType }) {
        state.emailType = payload;
    },
    addVirtualUserAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.aliasName = payload;
    },
    setDefaultAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.defaultAlias = payload;
    },
    setSmtpAuthorization(state: typeof initialState, { payload }: { payload: any }) {},
    updateAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUser = payload;
    },
    deleteAlias(state: typeof initialState, { payload }: { payload: any }) {},
    fetchAlias(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    disconnectAlias(state: typeof initialState, { payload: any }) {},
};

export const myAccountSlice = createSlice({
    name: "myAccount",
    initialState,
    reducers,
});

export default myAccountSlice.reducer;

export const fetchPlans = myAccountSlice.actions.fetchPlans;
export const setPlans = myAccountSlice.actions.setPlans;
export const fetchUserPlan = myAccountSlice.actions.fetchUserPlan;
export const setUserPlan = myAccountSlice.actions.setUserPlan;
export const createCheckoutSession = myAccountSlice.actions.createCheckoutSession;
export const resetMyAccount = myAccountSlice.actions.resetMyAccount;
export const setEmailType = myAccountSlice.actions.setEmailType;
export const setDefaultAlias = myAccountSlice.actions.setDefaultAlias;
export const setSmtpAuthorization = myAccountSlice.actions.setSmtpAuthorization;
export const addVirtualUserAlias = myAccountSlice.actions.addVirtualUserAlias;
export const fetchAlias = myAccountSlice.actions.fetchAlias;
export const setVirtualUser = myAccountSlice.actions.setVirtualUser;
export const setVirtualUserSignature = myAccountSlice.actions.setVirtualUserSignature;
export const updateAlias = myAccountSlice.actions.updateAlias;
export const deleteAlias = myAccountSlice.actions.deleteAlias;
export const disconnectAlias = myAccountSlice.actions.disconnectAlias;

export const selectalias = (state: RootState) => state.myAccount.aliasName;
export const selectDefaultAlias = (state: RootState) => state.myAccount.defaultAlias;
export const selectEmailType = (state: RootState) => state.myAccount.emailType;
export const selectUserPlan = (state: RootState) => state.myAccount.userPlan;
export const selectVirtualUser = (state: RootState) => state.myAccount.allOrgMembers;
