import { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    getFromBambooHR,
    addToBambooHR,
    getBambooHRJobs,
    getBambooHRStatuses,
    selectBambooHRJobs,
    selectBambooHRStatuses,
} from "../../../integrations/integrations.reducer";

type BambooHRModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function BambooHRModal({ open, onClose, id, selectedCandidateIds, variant }: BambooHRModalProps) {
    const dispatch = useDispatch();
    const bambooHRJobs = useSelector(selectBambooHRJobs);
    const bambooHRStatuses = useSelector(selectBambooHRStatuses);

    const isJobsLoading = useSelector(checkIfLoading(getBambooHRJobs.type));
    const isStatusesLoading = useSelector(checkIfLoading(getBambooHRStatuses.type));
    
    const isBambooHRFetching = useSelector(checkIfLoading(getFromBambooHR.type));
    const isBambooHRSubmitting = useSelector(checkIfLoading(addToBambooHR.type));

    const isDataLoading = isJobsLoading || isStatusesLoading;
    const isDataSubmitting = isBambooHRSubmitting || isBambooHRFetching;
    const isDisabled = isDataLoading || isDataSubmitting;
    const isVariantFetch = variant === "FETCH";

    const [jobId, setJobId] = useState("");
    const [statusId, setStatusId] = useState("");

    useEffect(() => {
        if (open) {
            dispatch(getBambooHRJobs());
            dispatch(getBambooHRStatuses());
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        setStatusId("");
        onClose();
    };

    const handlePostCandidate = () => {
        if (jobId === "") {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        if (isVariantFetch) {
            dispatch(
                getFromBambooHR({
                    projectId: Number(id),
                    jobId,
                    statusId,
                    onSuccess: handleCloseModal,
                })
            );
        } else {
            dispatch(
                addToBambooHR({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    jobId,
                    statusId,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"xs"}>
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} BambooHR</DialogTitle>
            <DialogContent>
                {isDataLoading ? (
                    <Stack alignItems="center" mt={2}>
                        <CircularProgress size={24} />
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        {bambooHRJobs.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select Job:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={jobId}
                                    onChange={(e) => setJobId(e.target.value)}
                                    select
                                    placeholder="Select..."
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {bambooHRJobs.map((job) => (
                                        <MenuItem key={job.id} value={job.id.toString()}>
                                            {job.title.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <Typography fontSize={14} textAlign="center" color="red">
                                No jobs found. Create one on your dashboard
                            </Typography>
                        )}
                        {bambooHRStatuses.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select Status:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={statusId}
                                    onChange={(e) => setStatusId(e.target.value)}
                                    select
                                    placeholder="Select..."
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {bambooHRStatuses.map((status) => (
                                        <MenuItem key={status.id} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <Typography fontSize={14} textAlign="center" color="red">
                                No statuses found. Create one on your dashboard
                            </Typography>
                        )}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button disabled={isDisabled} variant="contained" onClick={handlePostCandidate}>
                    <ButtonTextWithLoading isLoading={isDataSubmitting} text="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
