import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setUserCredits } from "../Signin/Signin.reducer";
import {
    addVirtualUserAlias,
    createCheckoutSession,
    deleteAlias,
    disconnectAlias,
    fetchAlias,
    fetchPlans,
    fetchUserPlan,
    resetMyAccount,
    setDefaultAlias,
    setEmailType,
    setPlans,
    setSmtpAuthorization,
    setUserPlan,
    setVirtualUser,
    updateAlias,
    setVirtualUserSignature,
} from "./myAccount.reducer";
import {
    IAddVirtualUser,
    IAlias,
    ICheckoutPayload,
    IDefaultAlias,
    IDisconnectAlias,
    IEmailType,
    IOrgPlanResponse,
} from "./myAccount.types";
import { isSignatureModalOpen } from "../OutReach/outreach.slice";

function* fetchUserPlanSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IOrgPlanResponse = yield call(new API().get, "/organization/get-credit");
        const totalCredits = response.data.totalEmailFetchCredit;
        const remainingCredits = response.data.remainingEmailFetchCredit;
        const currentCredits = totalCredits - remainingCredits;
        const remainingCreditsPercent = (remainingCredits / totalCredits) * 100;
        yield put(
            setUserPlan({
                totalCredits,
                remainingCredits,
                name: response.data.plan.name,
                createdAt: response.data.plan.createdAt,
                validTill: response.data.plan.validTill,
            })
        );
        yield put(
            setUserCredits({
                currentCredits,
                totalCredits,
                remainingCredits: remainingCreditsPercent,
            })
        );
    } catch (error) {
        console.error("**fetchUserPlanSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchPlansSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/payment/list-products");
        yield put(setPlans(response.data));
    } catch (error) {
        console.error("**fetchPlansSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* setEmailTypeSaga({ payload }: { payload: IActionPayload & IEmailType }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().post, "/v2/project/update-emailType", { type: payload.emailType });
        yield put(setSuccessNotification("Email preference updated successfully"));
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"));
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addVirtualUserSaga(action: PayloadAction<IActionPayload & IAddVirtualUser>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/organization/virtual-user/add", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response?.message));
            yield put(fetchAlias({ action: fetchAlias.type }));
        }
    } catch (error: any) {
        console.error("**setEmailTypeSagaError", { error });
        const message = error?.response?.data?.error;
        yield put(setErrorNotification(message));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setVirtualUserSignatureSaga(action: PayloadAction<IActionPayload & any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "/auth/update-user-info", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response?.message));
            yield put(fetchAlias({ action: fetchAlias.type }));
        }
    } catch (error: any) {
        console.error("**setEmailTypeSagaError", { error });
        const message = error?.response?.data?.error;
        yield put(setErrorNotification(message));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchAliasSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "organization/members");
        const setData = response.data.members.map(
            ({ _id, name, email, authorizedEmailAddress, emailAuthorized, createdAt, virtualUser, signature }) => ({
                virtualUserId: _id,
                alias: name,
                availableEmail: email,
                outboundEmail: authorizedEmailAddress,
                authorizedStatus: emailAuthorized,
                createdOn: createdAt,
                default: virtualUser?.isDefault,
                signature: signature,
            })
        );
        yield put(setVirtualUser(setData));
    } catch (error) {
        console.error("**fetchPlansSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setDefaultAliasSaga(action: PayloadAction<IActionPayload & IDefaultAlias>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "organization/virtual-user/default", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        }
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"))
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setSmtpAuthorizationSaga(action: PayloadAction<{ payload: IActionPayload & any }>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/auth/update-smtp-auth", action.payload);
        yield put(setSuccessNotification(response?.message));
        yield put(fetchAlias());
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"))
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateAliasSaga(action: PayloadAction<{ payload: IActionPayload & IAlias }>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        yield call(new API().post, "/v2/project/update-alias", action.payload);
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* deleteAliasSaga(action: PayloadAction<IActionPayload & IDefaultAlias>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        yield call(new API().post, "/v2/project/delete-alias", action.payload);
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* disconnectAliasSaga(action: PayloadAction<IDisconnectAlias & IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { virtualUserId } = action.payload;
        const response = yield call(new API().get, `/auth/unauthorize-email?virtualUserId=${virtualUserId}`);
        yield put(setSuccessNotification(response?.message));
        yield put(fetchAlias());
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* createCheckoutSessionSaga({ payload }: { payload: IActionPayload & ICheckoutPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/payment/create-checkout-session", {
            priceId: payload.default_price,
        });
        window.location.href = response.url;
    } catch (error) {
        console.error("**createCheckoutSessionSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchUserPlan.type, fetchUserPlanSaga),
        // @ts-ignore
        yield takeLatest(fetchPlans.type, fetchPlansSaga),
        // @ts-ignore
        yield takeLatest(createCheckoutSession.type, createCheckoutSessionSaga),
        // @ts-ignore
        yield takeLatest(setEmailType.type, setEmailTypeSaga),
        // @ts-ignore
        yield takeLatest(addVirtualUserAlias.type, addVirtualUserSaga),
        // @ts-ignore
        yield takeLatest(setDefaultAlias.type, setDefaultAliasSaga),
        // @ts-ignore
        yield takeLatest(setSmtpAuthorization.type, setSmtpAuthorizationSaga),
        // @ts-ignore
        yield takeLatest(fetchAlias.type, fetchAliasSaga),
        // @ts-ignore
        yield takeLatest(updateAlias.type, updateAliasSaga),
        // @ts-ignore
        yield takeLatest(deleteAlias.type, deleteAliasSaga),
        // @ts-ignore
        yield takeLatest(disconnectAlias.type, disconnectAliasSaga),
        // @ts-ignore
        yield takeLatest(setVirtualUserSignature.type, setVirtualUserSignatureSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetMyAccount.type, CancelSagas, tasks);
}
