import { ReactNode } from "react";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import StartIcon from "@mui/icons-material/PlayCircle";
import RestartIcon from "@mui/icons-material/RestartAlt";
import StopIcon from "@mui/icons-material/StopCircle";
import { Stack, Tooltip } from "@mui/joy";
import Chip from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import Table from "@mui/joy/Table";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import CopyableText from "../../atoms/CopyableText";
import { DROPDOWN_CONTAINER } from "../../constant/Constant";
import { setErrorNotification, setSuccessNotification } from "../Notification/index.reducer";
import { usePauseAutoPilotMutation, useRestartAutoPilotMutation, useTriggerAutoPilotMutation } from "./autopilot.slice";
import { SET_INDEX, STATUS_COLOR } from "./enums";
import { TAutoPilotStats, TPageUrl, TUrlType } from "./types";

const MAX_PROFILES_COUNT = 1000;

function groupColumns(columns: TPageUrl[] = []): { [key in TUrlType]?: TPageUrl[] } {
    return columns.reduce((acc: any, col) => {
        if (acc[col.urlType]) {
            acc[col.urlType].push(col);
        } else {
            acc[col.urlType] = [];
            acc[col.urlType].push(col);
        }
        return acc;
    }, {});
}

function TableBodyWrapper({ children }: { children: ReactNode }) {
    return (
        <tbody style={{ width: "55vw", display: "grid", placeItems: "center" }}>
            <tr>
                <td>{children}</td>
            </tr>
        </tbody>
    );
}

interface SetThProps {
    disabled?: boolean;
    started: boolean;
    paused: boolean;
    text: string;
    onResume: () => void;
    onPause: () => void;
    onStart: () => void;
}

function SetTh(props: SetThProps) {
    const { paused, started, text, onPause, onResume, onStart, ...rest } = props;

    const renderActionButtons = () => {
        if (paused) {
            return (
                <Tooltip placement="top" title="Resume">
                    <IconButton size="sm" onClick={onResume} {...rest}>
                        <StartIcon color="warning" />
                    </IconButton>
                </Tooltip>
            );
        }

        return (
            <>
                <Tooltip placement="top" title="Start">
                    <IconButton size="sm" onClick={onStart} {...rest}>
                        <StartIcon color="primary" />
                    </IconButton>
                </Tooltip>
                {started && (
                    <Tooltip placement="top" title="Pause">
                        <IconButton size="sm" onClick={onPause} {...rest}>
                            <StopIcon color="warning" />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        );
    };

    return (
        <>
            <th style={{ width: 100 }}>{text}</th>
            <th style={{ width: 120 }}>
                <Stack direction="row" gap={1}>
                    {renderActionButtons()}
                </Stack>
            </th>
        </>
    );
}

function ActionHeader({ data }: { data?: TAutoPilotStats }) {
    const { id: projectId } = useParams();
    const [triggerAutoPilot, { isLoading }] = useTriggerAutoPilotMutation();
    const [pauseAutoPilot, { isLoading: isPausing }] = usePauseAutoPilotMutation();
    const dispatch = useDispatch();

    if (!projectId) return null;

    const handleTriggerAutoPilot = (setNumber: number, resume: boolean = false) => {
        triggerAutoPilot({
            setNumber,
            projectId: Number(projectId),
            profilesCount: MAX_PROFILES_COUNT,
            resume,
        });
    };

    const handlePauseAutoPilot = (setNumber: number) => {
        if (data?._id) {
            pauseAutoPilot({
                setNumber,
                searchId: data?._id,
            }).then(({ data }: any) => {
                if (data.success) {
                    dispatch(setSuccessNotification(data.message));
                } else {
                    dispatch(setErrorNotification(data.message));
                }
            });
        }
    };

    const grouped = groupColumns(data?.pageUrl ?? []);

    const set1Cols = grouped["SET-ONE"];
    const set2Cols = grouped["SET-TWO"];

    const set1Paused = !!data?.set1Paused;
    const set2Paused = !!data?.set2Paused;
    const canNotGenerateSe2Urls = data?.setState === "SET-TWO" && data?.apScrapeStatus === "PENDING";

    return (
        <tr>
            <th style={{ width: 150 }}>
                Autopilot
                {isLoading && <CircularProgress sx={{ ml: 1, "--CircularProgress-size": "14px" }} size="sm" />}
            </th>
            <SetTh
                disabled={isPausing}
                started={!!set1Cols}
                paused={set1Paused}
                text="Set 1"
                onPause={() => handlePauseAutoPilot(1)}
                onResume={() => handleTriggerAutoPilot(1, true)}
                onStart={() => handleTriggerAutoPilot(1)}
            />
            <SetTh
                disabled={!set1Cols || canNotGenerateSe2Urls || isPausing}
                started={!!set2Cols}
                paused={set2Paused}
                text="Set 2"
                onPause={() => handlePauseAutoPilot(2)}
                onResume={() => handleTriggerAutoPilot(2, true)}
                onStart={() => handleTriggerAutoPilot(2)}
            />
            <th />
            <th />
            {/* <th /> */}
            <th />
        </tr>
    );
}

interface TableBodyProps {
    data: TAutoPilotStats;
    loading: boolean;
    active: boolean;
}

function TableBody(props: TableBodyProps) {
    const { id: projectId } = useParams();
    const [restartAutoPilot, { isLoading }] = useRestartAutoPilotMutation();
    const [triggerAutoPilot] = useTriggerAutoPilotMutation();

    const { data, loading, active } = props;

    if (loading) {
        return (
            <TableBodyWrapper>
                <CircularProgress size="sm" />
            </TableBodyWrapper>
        );
    }

    if (!data.pageUrl?.length) {
        return (
            <TableBodyWrapper>
                <small>Not available!</small>
            </TableBodyWrapper>
        );
    }

    const grouped = groupColumns(data?.pageUrl ?? []);

    return (
        <>
            <tbody>
                {Object.values(grouped).map((cols) => {
                    return (cols as TPageUrl[]).map((col, index) => {
                        // const extractedCount = Number(
                        //     col.count?.split(" ")[0]?.replaceAll("K", "").replaceAll("M", "")
                        // );
                        // const maxCount = isNaN(extractedCount) ? 0 : extractedCount;
                        return (
                            <tr key={index} style={{ marginTop: 20 }}>
                                <td>{dayjs(col.startTime).format("DD-MM-YYYY, h:mm A")}</td>
                                <td style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                    {[SET_INDEX[col.urlType]]}.{index + 1}
                                    {active && col.status === "COMPLETED" && (
                                        <Tooltip placement="top" title="Force restart">
                                            <IconButton
                                                color="warning"
                                                disabled={isLoading}
                                                size="sm"
                                                onClick={() =>
                                                    restartAutoPilot({
                                                        searchId: data._id,
                                                        pageUrlIndex: index,
                                                    }).then((res: any) => {
                                                        if (res.data?.success && projectId) {
                                                            triggerAutoPilot({
                                                                setNumber: SET_INDEX[col.urlType],
                                                                projectId: Number(projectId),
                                                                profilesCount: MAX_PROFILES_COUNT,
                                                                resume: true,
                                                            });
                                                        }
                                                    })
                                                }
                                            >
                                                <RestartIcon
                                                    {...(isLoading && { sx: { animation: "spin 2s linear infinite" } })}
                                                    color="warning"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </td>
                                <td>
                                    <CopyableText textToCopy={col.url} sx={{ fontSize: "inherit" }}>
                                        <Link to={col.url} target="_blank">
                                            link {[SET_INDEX[col.urlType]]}.{index + 1}
                                        </Link>
                                    </CopyableText>
                                </td>
                                <td>
                                    <Chip variant="soft" color={STATUS_COLOR[col.status]} size="sm">
                                        {capitalize(col.status === "IN_PROGRESS" ? "In progress" : col.status)}
                                    </Chip>
                                </td>
                                <td>{col.count}</td>
                                <td>{MAX_PROFILES_COUNT}</td>
                                <td>{col.profiles?.length}</td>
                                {/* <td>N/A</td> */}
                                <td>{col.qualifiedProfiles}</td>
                            </tr>
                        );
                    });
                })}
            </tbody>
            {typeof data?.totalQualifiedProfiles === "number" && (
                <TableBodyWrapper>
                    <small>
                        {data?.addedProfiles >= MAX_PROFILES_COUNT && (
                            <Tooltip title="Target completed, if you still want to add more candidates click on the start icon to continue">
                                <CompleteIcon
                                    fontSize="small"
                                    color="success"
                                    sx={{ verticalAlign: "bottom", mr: 0.5 }}
                                />
                            </Tooltip>
                        )}
                        Total candidates added: {data?.addedProfiles}, Total qualified profiles:{" "}
                        {data?.totalQualifiedProfiles},{" "}
                        {(data?.set1Paused || data?.set2Paused) && `Total candidates paused: ${data?.pausedProfiles}, `}
                        Aggregate fetched: {data?.allProfiles?.length}
                    </small>
                </TableBodyWrapper>
            )}
        </>
    );
}

export interface AutoPilotTableProps {
    data: TAutoPilotStats;
    isLoading: boolean;
    enableActionHeader: boolean;
}

export default function AutoPilotTable(props: AutoPilotTableProps) {
    const { data, isLoading, enableActionHeader } = props;
    return (
        <Table size="sm" id={DROPDOWN_CONTAINER}>
            <thead>
                {enableActionHeader && <ActionHeader data={data} />}
                <tr>
                    <th style={{ width: 150 }}>Start time</th>
                    <th style={{ width: 100 }}>Search</th>
                    <th style={{ width: 120 }}>Links</th>
                    <th style={{ width: 100 }}>Status</th>
                    <th>Total in link</th>
                    <th>Target candidates</th>
                    <th>Fetched so far</th>
                    {/* <th>Vetted so far</th> */}
                    <th>5*,4* added so far</th>
                </tr>
            </thead>
            <TableBody active={enableActionHeader} loading={isLoading} data={data} />
        </Table>
    );
}
