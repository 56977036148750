import { Zoom } from "@mui/material";
import Popover from "@mui/material/Popover";

import { useChatbotContext } from "../Context";
import ChatbotKit from "./ChatbotKit/ChatbotKit";
import Tabs from "./Tabs/Tabs";

function ChatbotPopup() {
    const { anchorEl, setAnchorEl, chatbot } = useChatbotContext();
    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{ top: "-16px" }}
            slotProps={{
                paper: {
                    style: {
                        borderRadius: "16px",
                        height: "min(704px, 100% - 104px)",
                        width: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 5px 40px",
                    },
                },
            }}
        >
            <div style={{ height: "100%" }}>
                <ChatbotKit />
                <Tabs />
            </div>
        </Popover>
    );
}

export default ChatbotPopup;
