import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { IconButton, IconButtonProps, Stack } from "@mui/joy";

import { JoyProvider } from "../../../components/JoyProvider";
import { CS_STATUS } from "../project.types";

const StageMap: { [key: string]: CS_STATUS } = {
    "CS Accepted": "ACCEPTED",
    "CS Pending": "PENDING",
    "CS Rejected": "REJECTED",
};

const StyledIconButton = (props: IconButtonProps) => {
    const { children, ...rest } = props;
    return (
        <IconButton size="md" {...rest}>
            {children}
        </IconButton>
    );
};

type CSReviewStatusProps = {
    variant?: "card" | "modal";
    stage?: string;
    handleStatusChange: (type: CS_STATUS) => void;
};

export function CSReviewStatus({ variant, stage, handleStatusChange }: CSReviewStatusProps) {
    const currentStatus: CS_STATUS = StageMap[stage as string];

    const handleClickStatus = (status: CS_STATUS) => {
        handleStatusChange(currentStatus === status ? "PENDING" : status);
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <JoyProvider>
                <Stack direction="row" gap={2}>
                    <StyledIconButton
                        variant={currentStatus === "ACCEPTED" ? "solid" : "plain"}
                        onClick={() => handleClickStatus("ACCEPTED")}
                        color={currentStatus === "ACCEPTED" ? "primary" : "neutral"}
                    >
                        <ThumbUpIcon sx={{ fontSize: 18 }} />
                    </StyledIconButton>
                    <StyledIconButton
                        variant={currentStatus === "REJECTED" ? "solid" : "plain"}
                        onClick={() => handleClickStatus("REJECTED")}
                        color={currentStatus === "REJECTED" ? "danger" : "neutral"}
                    >
                        <ThumbDownIcon sx={{ fontSize: 18 }} />
                    </StyledIconButton>
                </Stack>
            </JoyProvider>
        </div>
    );
}
