import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/Store";

export type FetchEventsPayload = {
    id: string;
};

export type IEventType = {
    _id: string;
    projectId: string;
    profileImage : string;
    payload: {
        cancel_url: string;
        reschedule_url: string;
        email: string;
        name: string;
        scheduled_event: {
            name: string;
            start_time: string;
            end_time: string;
            created_at: string;
            updated_at: string;
        };
        questions_and_answers?: Array<{
            question: string;
            answer?: string;
        }>;
    };
    rescheduleInfo?: {
        name: string;
        reason?: string;
    };
};

type IRootState = {
    events: {
        upcomingEvents: IEventType[];
        pastEvents: IEventType[];
    };
};

const initialState: IRootState = {
    events: {
        upcomingEvents: [],
        pastEvents: [],
    },
};

export const upcomingEventSlice = createSlice({
    name: "upcomingEvents",
    initialState,
    reducers: {
        setEvents(state, action: PayloadAction<{
            upcomingEvents : IEventType[],
            pastEvents : IEventType[]
        }>) {
            state.events = action.payload;
        },
        fetchEvents(state, action: PayloadAction<FetchEventsPayload>) {},
        cancelActions(state) {},
    },
});

export default upcomingEventSlice.reducer;

export const { setEvents, cancelActions, fetchEvents } = upcomingEventSlice.actions;

export const selectEvent = (state: RootState) => state.upcomingEvents.events;
