import { Middleware } from "@reduxjs/toolkit";

import { getlocalStorage } from "../../hooks/useLocalStorage";
import { clearRTKQueryCachedState } from "../../hooks/useRTKQueryCachedState";
import { analyticsApi } from "../../pages/analytics-v2/apis/analytics.api.slice";

const cachingApis = [analyticsApi];

export const getPreloadedState = () => {
    clearRTKQueryCachedState();
    return {};
};

export const cachingMiddleware: Middleware = () => (next) => (action) => {
    const result = next(action);

    // Find if the action type belongs to one of the caching APIs
    const api = cachingApis.find((api) => action.type.startsWith(api.reducerPath));

    if (!api) {
        // If no matching API is found, return the result without caching
        return result;
    }

    // Extract meta information from the action
    const isRtkBatch = action?.meta?.RTK_autoBatch;
    const isQuery = action?.meta?.arg?.type === "query";

    if (isRtkBatch && isQuery) {
        // Handle the case when the request is fulfilled and is a query
        const requestStatus = action.meta?.requestStatus;
        const queryCacheKey = action.meta?.arg?.queryCacheKey;
        const keyName = `rtk/${api.reducerPath}/${queryCacheKey}`;

        if (requestStatus === "fulfilled") {
            // Store the result in local storage
            getlocalStorage.set(keyName, {
                data: action.payload,
                timestamp: Date.now(), // Store the timestamp to check the validity of the cache
            });
        }
    }

    return result;
};
