import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectHyperPersonalizeMode } from "../../workflow/index.reducer";
import { selectEnableSMSSwitch } from "../../template/index.reducer";
import { Draggable, type DraggableProvided } from "react-beautiful-dnd";
import style from "../../../pages/editWorkflow/components/reminder/reminder.module.scss";
import { fieldName } from "../../editWorkflow/components/reminder/Reminder";
import { chevronDownIconBlue } from "../../../constant/Constant";
import { Collapse, Skeleton, Stack, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import DisableWorkflowComponentOverlay from "../../../components/DisableWorkflowComponentOverlay";
import { DisableSMSSwitch } from "../../../components/DisableSMSSwitch";
import { checkSMSEnabled } from "../../Signin/Signin.reducer";
import { setEditTemplatesModal } from "../reducers/personalizedWorkflow.slice";

interface reminderPropsType {
    isOpen: boolean;
    index: number;
    editorVal?: string;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    isLoading?: boolean;
    handleBlurChange?: () => void;
    status?: React.ReactNode;
}

export function SMS(props: reminderPropsType) {
    const dispatch = useDispatch();
    const {
        index,
        editorVal = "",
        setEditorVal,
        isOpen,
        toggleCollapse,
        type = "sms",
        isLoading,
        handleBlurChange,
        readable,
        handleDuplicate,
        handleRemove,
        status,
    } = props;
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    const enableConnectionReq = useSelector(selectEnableSMSSwitch);
    const bodyRef = useRef(null);

    if (readable || !handleDuplicate || !handleRemove || !setEditorVal) {
        return (
            <div className={style["rem__container"]}>
                <div className={`${style["rem__legendBox"]} ${index === 0 ? style["rem__legendBox-start"] : ""}`}>
                    <p className={style["rem__legend"]} onClick={() => toggleCollapse(index)}>
                        {type && fieldName(type, isOpen)}{" "}
                        <img className={isOpen ? style["rem__invert"] : ""} src={chevronDownIconBlue} alt="" />
                        {status}
                    </p>
                </div>
                <Collapse in={isOpen}>
                    <div className={style["rem__body"]}>
                        {readable ? (
                            <Typography
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                    // background: "#eeeeee71",
                                    border: "1px solid #ccc",
                                    fontSize: "14px",
                                    color: "#000000",
                                }}
                                dangerouslySetInnerHTML={{ __html: editorVal }}
                            />
                        ) : (
                            <textarea
                                // ref={bodyRef}
                                value={editorVal}
                                disabled={readable}
                                rows={5}
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                }}
                            />
                        )}
                    </div>
                </Collapse>
            </div>
        );
    }

    if (!isSMSEnabled) {
        return null;
    }

    const handleReactQuillClick = () => {
        dispatch(setEditTemplatesModal(true));
    };

    return (
        <Draggable draggableId={`rem${index}`} index={index}>
            {(dragProvider: DraggableProvided) => (
                <div
                    className={style["rem"]}
                    ref={dragProvider.innerRef}
                    {...dragProvider.dragHandleProps}
                    {...dragProvider.draggableProps}
                >
                    <div className={style["rem__container"]} id="workflow-reminder">
                        <div className={style["rem__legendBox"]}>
                            <p className={style["rem__legend"]} onClick={() => toggleCollapse(index)}>
                                {type && fieldName(type)}{" "}
                                <img className={isOpen ? style["rem__invert"] : ""} src={chevronDownIconBlue} alt="" />
                            </p>
                        </div>
                        <Collapse in={isOpen}>
                            {
                                <div className={style["rem__body"]}>
                                    {isLoading ? (
                                        <Skeleton variant="rounded" width="100%" height={200} />
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem",
                                                }}
                                                onClick={isHyperPersonalized ? handleReactQuillClick : undefined}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                    onBlur={handleBlurChange}
                                                >
                                                    {!enableConnectionReq && (
                                                        <DisableWorkflowComponentOverlay>
                                                            Enable this step to send sms.
                                                        </DisableWorkflowComponentOverlay>
                                                    )}
                                                    <ReactQuill
                                                        ref={bodyRef}
                                                        value={editorVal}
                                                        className={isHyperPersonalized ? "sms-quill" : ""}
                                                        onChange={(value, delta, source, editor) => {
                                                            if (source === "user") {
                                                                setEditorVal?.(index, value);
                                                            }
                                                        }}
                                                        readOnly={isHyperPersonalized}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <Stack
                                        style={{
                                            maxHeight: "50px",
                                            padding: "0.3rem 1rem",
                                            position: "absolute",
                                            top: "-40px",
                                            right: "50px",
                                            backgroundColor: "white",
                                            borderTopLeftRadius: "0.7rem",
                                            borderTopRightRadius: "0.7rem",
                                        }}
                                    >
                                        <DisableSMSSwitch />
                                    </Stack>
                                </div>
                            }
                        </Collapse>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
