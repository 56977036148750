import { useEffect } from "react";
import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { NavigateFunction } from "react-router-dom";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { ICreateProjectResponse } from "../../components/Navbar/CreateProject/CreateProject.reducer";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import { setProject } from "../../pages/allProjects/index.reducer";
import { IGetProjectCandidatesResponse, IProjectFiltersRequestPayload } from "../../pages/project/project.types";
import API from "../../utils/API";
import { ADD_TO_PROJECT } from "../../utils/Constants";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { checkIfResumeLibraryFiltersAreSet, setProjectSearchFilters } from "../reducers/search/search.slice";
import {
    addAIcandidatesToProject,
    addGitcandidatesToProject,
    addSearchCandidatesToProject,
    cancelSagas,
    fetchCandidates,
    gotoScreenWithAI,
    sendEmailNotification,
    setCandidates,
    setCandidatesFetchStatus,
    setSalesNavScrapStatus,
    setSearch,
    setSearchType,
} from "../reducers/searchCandidates/searchCandidates.slice";
import {
    AddToProjectResponse,
    IAddSearchCandidatesToProject,
    IAddToProjectPayload,
    ISearch,
    SearchCandidateResponse,
    SetSearchIdPayload,
} from "../reducers/searchCandidates/searchCandidates.types";

function* fetchCandidatesSaga(action: SetSearchIdPayload): SagaIterator {
    try {
        const state = yield select();
        const search: ISearch = get(state, "searchCandidates.search");
        const isEasySource = get(state, "signin.isEasySource");
        yield put(startAction({ action: action.type }));
        yield put(setCandidatesFetchStatus("LOADING"));
        const id = action.payload;
        let api = "";
        if (!isEasySource) {
            api = `/searches/candidates/${id}/growth`;
        } else {
            api = `/searches/candidates/${id}`;
        }

        const response: SearchCandidateResponse = yield call(new API().get, api);

        if (response?.data && Array.isArray(response.data.candidates)) {
            yield put(setCandidates(response.data));
            yield put(
                setSearch({
                    ...search,
                    status: response.data.status,
                })
            );
            if (["COMPLETED", "ZERO_PROFILE", "IN_PROGRESS"].includes(response.data.status)) {
                yield put(setCandidatesFetchStatus("SUCCESS"));
            }
            if (response?.data.salesNavScrapStatus) {
                yield put(setSalesNavScrapStatus(response.data.salesNavScrapStatus));
            }
        } else {
            throw new Error(response?.error);
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
        // yield put(setCandidatesFetchStatus("ERROR"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addSearchCandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        yield put(startAction({ action: type }));

        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, addCandidateCount } =
            payload;

        if (projectId) {
            yield call(addToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                addCandidateCount,
            });
        } else if (projectName) {
            yield call(
                createProjectAndAdd,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                addCandidateCount
            );
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* sendEmailNotificationSaga(
    action: PayloadAction<{ projectId: string | null; searchId: string }>
): SagaIterator {
    const { type, payload } = action;
    try {
        yield put(startAction({ action: type }));

        const { projectId, searchId } = payload;

        yield call(new API().post, "/searches/email-notification", {
            projectId,
            searchId,
            type: "ERROR_FETCHING_CANDIDATES",
        });
    } catch (error: any) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addAIcandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        // yield put(startAction({ action: type }));

        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, onSuccess } = payload;
        if (projectId) {
            yield call(addAIToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                onSuccess,
            });
        } else if (projectName) {
            yield call(createProjectAndAdd, projectName, searchId, navigate, canNavigateNextStep, profilesCount);
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addGitcandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        // yield put(startAction({ action: type }));

        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, onSuccess } = payload;
        if (projectId) {
            yield call(addGitToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                onSuccess,
            });
        } else if (projectName) {
            yield call(createProjectAndAdd, projectName, searchId, navigate, canNavigateNextStep, profilesCount);
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addAIToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    onSuccess,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    onSuccess?: () => void;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const isResumeLibrarySearch = get(state, "searchCandidates.searchType") === "resume-library";
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");
        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }
        const url = isResumeLibrarySearch
            ? "/searches/add-resumeLibrary-candidates-to-project"
            : "/searches/add-aiCandidates";

        const response: AddToProjectResponse = yield call(new API().post, url, payload);

        if ("error" in response) {
            throw new Error(response.error);
        }

        const project_id = response.data._id ?? (response.data as any).projectId;

        if (!fromJD) {
            const url = canNavigateNextStep ? `/projects/${project_id}` : `/projects/${project_id}?fromSearch=true`;
            if (!canNavigateNextStep) {
                onSuccess?.();
            }
            navigate(url);
        }

        yield put(setProject(response.data));
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err);
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* addGitToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    onSuccess,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    onSuccess?: () => void;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const isResumeLibrarySearch = get(state, "searchCandidates.searchType") === "resume-library";
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");
        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }
        const url = isResumeLibrarySearch ? "/github/add-git-candidates" : "/github/add-git-candidates"; // name to be given

        const response: AddToProjectResponse = yield call(new API().post, url, payload);

        if ("error" in response) {
            throw new Error(response.error);
        }

        const project_id = response.data._id ?? (response.data as any).projectId;

        if (!fromJD) {
            const url = canNavigateNextStep ? `/projects/${project_id}` : `/projects/${project_id}?fromSearch=true`;
            if (!canNavigateNextStep) {
                onSuccess?.();
            }
            navigate(url);
        }

        yield put(setProject(response.data));
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err);
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* addToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    addCandidateCount,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    addCandidateCount?: number;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const isResumeLibrarySearch = get(state, "searchCandidates.searchType") === "resume-library";
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");

        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }
        const url = isResumeLibrarySearch
            ? "/searches/add-resumeLibrary-candidates-to-project"
            : addCandidateCount
              ? "/searches/add-to-project-lite"
              : "/searches/add-to-project";

        const response: AddToProjectResponse = yield call(new API().post, url, payload);

        if ("error" in response) {
            throw new Error(response.error);
        }
        const project_id = response.data._id ?? (response.data as any).projectId;

        if (addCandidateCount) {
            yield call(new API().post, "/searches/salesNav-search-profiles-lite-next", {
                projectId: Number(project_id),
                profilesCount: addCandidateCount,
            });
        }

        if (!fromJD) {
            const url = canNavigateNextStep
                ? `/search?step=1&project=${project_id}&name=${response.data.name}`
                : `/projects/${project_id}?fromSearch=true`;
            navigate(url);
        }

        yield put(setProject(response.data));
        const isResumeLibraryEnabled = checkIfResumeLibraryFiltersAreSet(response.data?.resumeLibrary);
        if (isResumeLibraryEnabled) {
            yield put(setSearchType("resume-library"));
        }
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err);
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* createProjectAndAdd(
    projectName: string,
    searchId: string,
    navigate: NavigateFunction,
    canNavigateNextStep?: boolean,
    profilesCount?: number,
    addCandidateCount?: number
): SagaIterator {
    try {
        const formData = new FormData();
        formData.append("name", projectName);
        const state = yield select();
        const purpose = get(state, "searchCandidates.projectpurpose");

        if (purpose) formData.append("purpose", purpose);

        const response: ICreateProjectResponse = yield call(new API().post, "/v2/project/create-jd-project", formData);

        if (!("data" in response)) {
            throw new Error();
        }

        const projectId = String(response.data._id);

        const url = canNavigateNextStep
            ? `/search?step=1&project=${response.data._id}&name=${response.data.name}`
            : `/projects/${response.data._id}?fromSearch=true`;

        navigate(url);

        yield call(addToProject, {
            projectId,
            searchId,
            navigate,
            canNavigateNextStep: false,
            profilesCount,
            fromJD: true,
            addCandidateCount,
        });
    } catch (err) {
        handleException(err);
        console.log("***createProjectAndAddErr=", err);
        yield put(setErrorNotification("error while creating project & adding candidates to it"));
    }
}

export default function* rootSagas() {
    const tasks = [
        //@ts-ignore
        yield takeLatest(fetchCandidates.type, fetchCandidatesSaga),
        //@ts-ignore
        yield takeLatest(addSearchCandidatesToProject.type, addSearchCandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(gotoScreenWithAI.type, addSearchCandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(addAIcandidatesToProject.type, addAIcandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(addGitcandidatesToProject.type, addGitcandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(sendEmailNotification.type, sendEmailNotificationSaga),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
