import { zodResolver } from "@hookform/resolvers/zod";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Stack, styled, TextField, TextFieldProps, Typography } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Dropzone, { FileWithPath } from "react-dropzone";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { CustomFormHelperText } from "../../../components/CustomFormHelperText";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { TextButton } from "../../../components/TextButton";
import { useAppDispatch } from "../../../store/Store";
import { useManageCandidateMutation } from "../../../store/reducers/all-candidates.api.slice";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle, splitSpaceOnce } from "../../../utils/helper";
import { selectAllProjects } from "../../allProjects/index.reducer";
import { checkEasyGrowth } from "../../Signin/Signin.reducer";
import { Candidate, isCandidateProject, isString, isStringArray } from "../all-candidates.types";
import { labelOptions } from "../all-candidates.utils";
import { commonPaperProps, ProjectOption, projectSchema } from "./ContactOverviewActivities/EditActivityModal";
import { CustomTextField as CustomTextFieldFromEdit } from "./CustomTextField";
import { selectGeography, setValues } from "../../../store/reducers/search/search.slice";
import useSearchState from "../../../hooks/useSearchState";
import Loader from "../../../components/Loader/Loader";

const CustomTextField = styled(
    (
        props: TextFieldProps & {
            legendWidth?: string | number;
            isLoading?: boolean;
        }
    ) => {
        return (
            <TextField
                {...props}
                sx={{
                    ...props.sx,
                    "& .MuiFormHelperText-root": {
                        margin: "4px 0 0",
                    },
                    ...(props?.label || props?.legendWidth
                        ? {
                              "& .MuiOutlinedInput-notchedOutline legend": {
                                  width: props.legendWidth,
                              },
                          }
                        : {}),
                }}
                size="small"
                variant="outlined"
            />
        );
    }
)(({ theme }) => {
    const fontSize = theme.typography.body2;
    return {
        "& .MuiInputBase-root": {
            padding: 0,
        },
        "& .MuiFormLabel-root": {
            ...fontSize,
            top: "0px",
            '&[data-shrink="true"]': {
                top: "0px",
            },
        },
        "& .MuiInputBase-input": {
            ...fontSize,
        },
        "& .MuiInputBase-input::placeholder": {
            ...fontSize,
        },
    };
});

export const candidateType = z.object({
    label: z.string().trim().min(1, { message: "Candidate Type is required" }),
    value: z.string().trim().min(1, { message: "Candidate Type is required" }),
});

export const locationSchema = z.object({
    label: z.string().trim(),
    value: z.string().trim(),
});

const createContactFormSchema = z.object({
    project: projectSchema,
    firstName: z.string().trim().min(1, { message: "First name is required" }),
    lastName: z.string().trim().min(1, { message: "Last name is required" }),
    email: z.string().email().optional().or(z.literal("")),
    linkedin: z
        .string()
        .trim()
        .refine((value) => value === "" || /^(https?):\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/i.test(value), {
            message: "Please enter a valid LinkedIn URL",
        })
        .or(z.literal("")),
    jobTitle: z.string().optional(),
    companyInformation: z.string().optional(),
    contact: z
        .string()
        .regex(/^\+?[0-9]{1,}$/, { message: "Invalid phone number" })
        .or(z.literal(""))
        .optional(),
    location: locationSchema,
    resume: z.any().optional(),
    labels: z.array(z.string().trim()).min(1, { message: "Please select labels" }),
});

type CreateContactModalFormType = z.infer<typeof createContactFormSchema> & {
    resume: FileWithPath;
};

function useCreateContactFormState() {
    const { ...rest } = useFormContext<CreateContactModalFormType>();

    return { ...rest };
}

function getEditInitialValues(data: Candidate): Omit<CreateContactModalFormType, "resume"> {
    const nameParts = isString(data?.["Name"]) ? splitSpaceOnce(data?.["Name"] || "") : ["", ""];
    return {
        firstName: nameParts[0],
        lastName: nameParts[1],
        project: {
            label: isCandidateProject(data?.["List of projects"]) ? data?.["List of projects"]?.name : "",
            value: isCandidateProject(data?.["List of projects"]) ? String(data?.["List of projects"]?.id) : "",
        },
        email: isString(data?.["Email"]) ? data?.["Email"] : "",
        linkedin: isString(data?.["Linkedin URL"]) ? data?.["Linkedin URL"] : "",
        jobTitle: isString(data?.["Job Title"]) ? data?.["Job Title"] : "",
        companyInformation: isString(data?.["Company Name"]) ? data?.["Company Name"] : "",
        location: {
            label: isString(data?.["location"]) ? data?.["location"] : "",
            value: isString(data?.["location"]) ? data?.["location"] : "",
        },
        labels: isStringArray(data?.["Label"]) ? data?.["Label"] : ["Candidate"],
    };
}

export function AddCandidateModal({
    handleClose,
    variant = "CREATE",
    data: editData,
}: {
    data?: Candidate;
    handleClose: () => void;
    variant?: "EDIT" | "CREATE";
}) {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isEdit = variant === "EDIT";
    const dispatch = useAppDispatch();
    const [createCandidate, { isLoading }] = useManageCandidateMutation();
    const methods = useForm<CreateContactModalFormType>({
        resolver: zodResolver(createContactFormSchema),
        defaultValues:
            isEdit && editData !== undefined
                ? getEditInitialValues(editData)
                : {
                      firstName: "",
                      lastName: "",
                      project: {
                          label: "",
                          value: "",
                      },
                      email: "",
                      linkedin: "",
                      jobTitle: "",
                      companyInformation: "",
                      contact: "",
                      location: {
                          label: "",
                          value: "",
                      },
                      labels: [isEasyGrowth ? "Prospects" : "Candidate"],
                  },
    });

    const prevProjectId = (editData?.["List of projects"] as any)?.id;

    const onSubmit: SubmitHandler<CreateContactModalFormType> = (data) => {
        const {
            companyInformation,
            contact,
            email,
            firstName,
            jobTitle,
            lastName,
            linkedin,
            location,
            project,
            resume,
            labels,
        } = data;

        createCandidate({
            url: isEdit ? `/candidate/editSingleCandidate/${editData?._id}` : "/candidate/createSingleCandidate",
            companyInformation,
            contact,
            email,
            firstName,
            jobTitle,
            lastName,
            profileUrl: linkedin,
            location: location.label,
            project: project.value,
            prevProject: prevProjectId,
            resume: resume?.[0],
            labels,
        })
            .unwrap()
            .then(() => {
                handleClose();
                dispatch(setValues({ key: "geography", value: [] }));
            })
            .catch(() => {
                dispatch(
                    setErrorNotification(
                        isEdit
                            ? "Something went wrong while editing contact..."
                            : "Something went wrong while creating contact..."
                    )
                );
            });
    };

    return (
        <FormProvider {...methods}>
            <Stack component="form" onSubmit={methods.handleSubmit(onSubmit)} gap={1.5}>
                <SelectProjects />
                <FullName />
                <Email />
                <ContactNumber />
                <LinkedIn />
                <JobTitle />
                <CompanyInformation />
                <Location />
                <Labels />
                {!isEasyGrowth && <ResumeUpload />}
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: "flex-end", marginTop: "1rem" }}
                    disabled={isLoading}
                >
                    <ButtonTextWithLoading isLoading={isLoading} text={isEdit ? "Edit" : "Save"} progressSize={16} />
                </CustomButton>
            </Stack>
        </FormProvider>
    );
}

function FullName() {
    const { control } = useCreateContactFormState();
    return (
        <Stack gap={0.3}>
            <Stack direction="row" gap={0.5}>
                <Controller
                    control={control}
                    name="firstName"
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <CustomTextField
                                {...field}
                                label="First name"
                                legendWidth={63}
                                helperText={error?.message}
                                error={Boolean(error?.message)}
                                sx={{ flex: 1 }}
                                placeholder="First name"
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name="lastName"
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <CustomTextField
                                {...field}
                                legendWidth={63}
                                label="Last name"
                                sx={{ flex: 1 }}
                                placeholder="Last name"
                                helperText={error?.message}
                                error={Boolean(error?.message)}
                            />
                        );
                    }}
                />
            </Stack>
        </Stack>
    );
}

function Email() {
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => {
                return (
                    <CustomTextField
                        {...field}
                        legendWidth={37}
                        label="Email"
                        type="text"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        placeholder="Email"
                    />
                );
            }}
        />
    );
}

function LinkedIn() {
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="linkedin"
            render={({ field, fieldState: { error } }) => {
                return (
                    <CustomTextField
                        {...field}
                        label="LinkedIn"
                        legendWidth={50}
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        placeholder="Linkedin URL"
                    />
                );
            }}
        />
    );
}

function JobTitle() {
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="jobTitle"
            render={({ field, fieldState: { error } }) => {
                return (
                    <CustomTextField
                        {...field}
                        legendWidth={50}
                        label="Job title"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        placeholder="Enter job title"
                    />
                );
            }}
        />
    );
}

function CompanyInformation() {
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="companyInformation"
            render={({ field, fieldState: { error } }) => {
                return (
                    <CustomTextField
                        {...field}
                        legendWidth={97}
                        label="Company name"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        placeholder="Company name"
                    />
                );
            }}
        />
    );
}

function SelectProjects() {
    const allProjects = useSelector(selectAllProjects);
    const mappedProjectOptions: ProjectOption[] = allProjects.map((i) => ({
        value: String(i._id),
        label: i.name,
    }));
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="project"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                const options = mappedProjectOptions.filter((i) => i.value !== value?.value);
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.label}
                            options={options}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextFieldFromEdit
                                    {...params}
                                    label="Select Projects"
                                    error={error !== undefined}
                                    legendWidth={84}
                                    // isLoading={isLoading}
                                    inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                                />
                            )}
                        />
                        {error !== undefined && (
                            <CustomFormHelperText>
                                {/* TODO */}
                                {/* {error?.label?.message || "Please Select Project."} */}
                                Please select project
                            </CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function ContactNumber() {
    const { control } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="contact"
            render={({ field, fieldState: { error } }) => {
                return (
                    <CustomTextField
                        {...field}
                        label="Contact Number"
                        legendWidth={95}
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        placeholder="Phone number"
                    />
                );
            }}
        />
    );
}

function Location() {
    const { control } = useCreateContactFormState();
    const appDispatch = useAppDispatch();
    const { query, setQuery, isLoading } = useSearchState("geography");
    const geographyState = useSelector(selectGeography);
    console.log("geographyState: ",geographyState);
    const { options, error } = geographyState;

    return (
        <Controller
            control={control}
            name="location"
            render={({ field: { onChange, value }, fieldState: { error: fieldError }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(fieldError)}>
                    <Autocomplete
                        disabled={isSubmitting}
                        value={value}
                        getOptionLabel={(option) => option.label}
                        options={Object.keys(options).map((label) => ({ label, value: label }))}
                        onChange={(e, v) => {
                            e.stopPropagation();
                            if (v) {
                                onChange(v);
                                appDispatch(setValues({
                                    key: "geography",
                                    value: [{ value: v.value, excluded: false }],
                                }));
                            }
                        }}
                        size="small"
                        loading={isLoading}
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextFieldFromEdit
                                {...params}
                                error={fieldError !== undefined}
                                label="Location"
                                legendWidth={53}
                                inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                                onChange={(e) => setQuery(e.target.value)}
                                value={query}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? <Loader size="sm" /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    {fieldError && (
                        <CustomFormHelperText>
                            {fieldError.message || "Please select Location"}
                        </CustomFormHelperText>
                    )}
                    {error && (
                        <CustomFormHelperText>
                            {error}
                        </CustomFormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

function ResumeUpload() {
    const { control, setValue } = useCreateContactFormState();
    return (
        <Controller
            control={control}
            name="resume"
            render={({ field: { onChange, onBlur }, fieldState }) => (
                <Dropzone
                    maxSize={10000000}
                    noClick
                    accept={{
                        "application/pdf": [".pdf"],
                    }}
                    onDrop={(acceptedFiles) => {
                        setValue("resume", acceptedFiles as unknown as FileList, {
                            shouldValidate: true,
                        });
                    }}
                >
                    {({ getRootProps, getInputProps, open, acceptedFiles, isFocused, isDragAccept, isDragReject }) => {
                        const style = {
                            ...baseStyle,
                            ...(isFocused ? focusedStyle : {}),
                            ...(isDragAccept ? acceptStyle : {}),
                            ...(isDragReject ? rejectStyle : {}),
                        };
                        return (
                            <div>
                                <div style={{ ...style, textAlign: "center" }} {...getRootProps()}>
                                    <input
                                        {...getInputProps({
                                            id: "spreadsheet",
                                            onDrop: onChange,
                                            onChange,
                                            onBlur,
                                            multiple: false,
                                            maxSize: 10000000,
                                        })}
                                    />
                                    <Stack gap={1} alignItems="center">
                                        <TextButton
                                            type="button"
                                            onClick={open}
                                            sx={{
                                                border: 1,
                                                padding: 0.5,
                                                paddingX: 1,
                                                fontSize: "12px",
                                                marginTop: "0.7rem",
                                            }}
                                        >
                                            Choose a Resume
                                        </TextButton>{" "}
                                        <Typography variant="body2">or drag and drop</Typography>
                                        <Typography variant="body2">
                                            {acceptedFiles.length ? acceptedFiles[0].name : "No file selected."}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={(theme) => ({
                                                color: fieldState.error ? theme.palette.error.main : undefined,
                                            })}
                                        >
                                            {fieldState.error && <span role="alert">{fieldState.error.message}</span>}
                                        </Typography>
                                    </Stack>
                                </div>
                            </div>
                        );
                    }}
                </Dropzone>
            )}
        />
    );
}

function Labels() {
    const { control } = useCreateContactFormState();

    return (
        <Controller
            control={control}
            name="labels"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        multiple
                        disabled={isSubmitting}
                        value={value}
                        options={labelOptions.map((option) => option.value)}
                        onChange={(e, v) => {
                            e.stopPropagation();
                            if (v) {
                                onChange(v);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextFieldFromEdit
                                {...params}
                                error={error !== undefined}
                                label="Labels"
                                legendWidth={53}
                                inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                            />
                        )}
                    />
                    {error !== undefined && (
                        <CustomFormHelperText>
                            {/* TODO */}
                            {/* {error?.label?.message || "Please Select Project."} */}
                            {error.message}
                        </CustomFormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

export function AddContactModalContainer({
    handleClose,
    title = "Create Contact",
    children,
}: {
    handleClose: () => void;
    children: React.ReactNode;
    title?: string;
}) {
    return (
        <Stack>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent
                sx={{
                    overflowY: "auto",
                    maxHeight: "70vh",
                    "&.MuiDialogContent-root": {
                        paddingTop: "1rem",
                    },
                }}
            >
                {children}
            </DialogContent>
            <IconButton
                onClick={handleClose}
                size="small"
                sx={{
                    top: 8,
                    right: 8,
                    background: "white",
                    color: (theme) => theme.palette.grey[500],
                }}
                style={{
                    position: "absolute",
                    marginTop: 0,
                    zIndex: 100,
                }}
            >
                <CloseRoundedIcon />
            </IconButton>
        </Stack>
    );
}
