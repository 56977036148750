import { Suspense } from "react";
import { lazyImport } from "../../../../utils/lazy-import";
const ExportCandidatesComponent = lazyImport(() => import("./ExportCandidates"));

export default function ExportCandidates(props: Parameters<typeof ExportCandidatesComponent>[0]) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ExportCandidatesComponent {...props} />
        </Suspense>
    );
}
