import { createChatBotMessage } from "react-chatbot-kit";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";

function Message(props: any) {
    return <h2>hello </h2>;
}

const defaultConfig: any = {
    botName: "HQ Bot",
    customStyles: {
        chatButton: { backgroundColor: "#0d7a89" },
    },
    customComponents: {
        // header: (props?: any) => null,
        // botAvatar: (props?: any) => <h1>avatar</h1>,
        // botChatMessage?: (props?: any) => ReactElement;
        // userAvatar: (props?: any) => <p></p>,
        // userChatMessage?: (props?: any) => ReactElement;
    },
    initialMessages: [
        createChatBotMessage(`Hello. How may I help you`, {
            widget: "options",
        }),
    ],
    widgets: [
        //@ts-ignore
        {
            widgetName: "message",
            widgetFunc: (props: any) => <Message {...props} />,
        },
    ],
};

export const getConfig = (config: IConfig) => config;

export default defaultConfig;
