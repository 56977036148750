import { useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack, { StackProps } from "@mui/material/Stack";

const styles: StackProps["sx"] = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 3,
    boxShadow: 24,
    borderRadius: 2,
    border: "none",
    outline: 0,
    maxHeight: "90vh",
};

type Props = {
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: React.ReactNode;
    overlayStyles?: StackProps["sx"];
    hideCloseButton?: boolean;
};

export default function BaseModal(props: Props) {
    const { children, overlayStyles, onClose, hideCloseButton } = props;
    return (
        <Modal
            open={true}
            onClose={(_, reason) => {
                onClose();
            }}
        >
            <Stack sx={(theme) => ({ ...styles, ...overlayStyles })}>
                {children}
                {!hideCloseButton && (
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{
                            top: 8,
                            right: 8,
                            background: "white",
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{
                            position: "absolute",
                            marginTop: 0,
                            zIndex: 100,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                )}
            </Stack>
        </Modal>
    );
}
