import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store/Store";
import {
    AddCreditsPayload,
    SubmitAutoSearchStatusPayload,
    SubmitTrackResponsesPayload,
    ToggleAutoSearchStatusPayload,
    orgTypes,
} from "./orgList.types";

const initialState = Object.freeze({
    organizations: [],
    projectsTrackers: [],
    candidateLoading: false,
    contactedLoading: false,
    workflowLoading: false,
});

const reducers = {
    getOrgList(state: any, action: PayloadAction) {},
    setOrgList(state: any, { payload }: { payload: orgTypes[] }) {
        state.organizations = payload;
    },
    setProjectsTrackers(state: any, { payload }: { payload: any }) {
        state.projectsTrackers = payload.filter((i: any) => i?.dashboardStatus !== "CLOSED");
    },
    setisCandidateLoading(state: any, { payload }: { payload: boolean }) {
        state.candidateLoading = payload;
    },
    setContactedLoading(state: any, { payload }: { payload: boolean }) {
        state.contactedLoading = payload;
    },
    setWorkflowLoading(state: any, { payload }: { payload: boolean }) {
        state.workflowLoading = payload;
    },
    toggleAutoSearch(state: any, action: ToggleAutoSearchStatusPayload) {
        const { id, status } = action.payload;
        state.projectsTrackers.forEach((value: orgTypes) => {
            if (id === value._id) {
                value.autoSource = status;
            }
        });
    },
    submitAutoSearchStatus(state: any, action: SubmitAutoSearchStatusPayload) {},
    submitTrackResponses(state: any, action: SubmitTrackResponsesPayload) {},
    addCredits(state: any, action: PayloadAction<AddCreditsPayload>) {},
    getOrgListTracker(state: any, { payload }: { payload: any }) {
        if (!payload.hideLoader) {
            state.candidateLoading = true;
            state.contactedLoading = true;
            state.workflowLoading = true;
        }
    },
    triggerToContacted(state: any, { payload }: { payload: number }) {},
    setDefaultOrgs(state: any, { payload }: { payload: string[] }) {},
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "orgList",
    initialState,
    reducers,
});

export default page.reducer;
export const {
    setDefaultOrgs,
    submitTrackResponses,
    getOrgList,
    setOrgList,
    leavePage,
    addCredits,
    getOrgListTracker,
    setProjectsTrackers,
    toggleAutoSearch,
    submitAutoSearchStatus,
    setisCandidateLoading,
    setContactedLoading,
    setWorkflowLoading,
    triggerToContacted,
} = page.actions;

export const selectOrganizations = (state: RootState) => state.orgList.organizations;
export const selectProjectsTrackers = (state: RootState) => state.orgList.projectsTrackers;
export const selectCandidateLoading = (state: RootState) => state.orgList.candidateLoading;
export const selectContactedLoading = (state: RootState) => state.orgList.contactedLoading;
export const selectWorkflowLoading = (state: RootState) => state.orgList.workflowLoading;

export const selectOrgTrackerRow = createSelector(
    [(state: RootState) => state.orgList.projectsTrackers as orgTypes[], (state: RootState, id?: number) => id],
    (items: orgTypes[], id?: number) => {
        return items.find((value) => id && id === value._id);
    }
);
