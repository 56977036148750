import ApolloLogo from "../../../../assets/img/email-providers-logo/apollo-logo.jpeg";
import BouncifyLogo from "../../../../assets/img/email-providers-logo/bouncify-logo.jpeg";
import ContactsOutLogo from "../../../../assets/img/email-providers-logo/contacts-out-logo.jpeg";
import EasySourceLogo from "../../../../assets/img/email-providers-logo/easysource-logo.jpeg";
import PeopleDataLabLogo from "../../../../assets/img/email-providers-logo/people-data-lab-logo.png";
import RetentionLogo from "../../../../assets/img/email-providers-logo/retention-logo.png";
import RocketReachLogo from "../../../../assets/img/email-providers-logo/rocket-reach-logo.png";
import ZeroBounceLogo from "../../../../assets/img/email-providers-logo/zero-bounce-logo.avif";
import ZoomInfoLogo from "../../../../assets/img/email-providers-logo/zoom-info-logo.png";


export const FREE_EMAIL_PROVIDERS = [
    {
        name: "People Data Labs",
        value: "PEOPLE_DATA_LABS",
        logo: PeopleDataLabLogo,
        description: "Personal contact information from a leading people data provider ($0.15/credit pricing)",
    },
    {
        name: "RocketReach",
        value: "ROCKETREACH",
        logo: RocketReachLogo,
        description: "Most versatile contact data providers globally ($0.2/credit pricing)",
    },
];

export const EASYSOURCE_PROVIDERS = [
    {
        name: "EasySource Data",
        value: "EASYSOURCE_DATA",
        logo: EasySourceLogo,
        description: "World class contact data provisioned for most of your high-relevance profiles",
    },
];

export const EASY_GROWTH_PROVIDERS = [
    {
        name:"EasyGrowth Data",
        value: "EASYGROWTH_DATA",
        logo: EasySourceLogo,
        description: "World class contact data provisioned for most of your high-relevance profiles",
    },
];


export const EASYSOURCE_FREE_PROVIDERS = [
    {
        name: "ContactOut",
        value: "CONTACTS_OUT",
        logo: ContactsOutLogo,
        description:
            "Highly valued, hard-to-find personal email addresses and phone numbers in the US ($0.25/credit pricing)",
    },
];

export const LICENSED_EMAIL_PROVIDERS = [
    {
        name: "Apollo.io",
        value: "APOLLOIO",
        logo: ApolloLogo,
        description: "Largest data intelligence solution with low-price, reliable contact info ($0.025/credit pricing)",
    },
    // {
    //     name: "ZoomInfo",
    //     value: "ZOOMINFO",
    //     logo: ZoomInfoLogo,
    //     description: "World's most trusted contact and B2B solution; verified, high-quality data ($0.4/credit pricing)",
    // },
    {
        name: "RB2B",
        value: "RB2B",
        logo: RetentionLogo,
        description:
            "Specialized contact info provider with a focus on reliability and sector-specific accuracy ($0.4/credit pricing)",
    },
];

export const FREE_EMAIL_VERIFICATION_PROVIDERS = [
    {
        name: "Bouncify",
        value: "BOUNCIFY",
        logo: BouncifyLogo,
        description:
            "Leading email verification service globally, free and enabled by default with your EasySource license ($0.005/email pricing)",
    },
];
export const FREE_EMAIL_VERIFICATION_PROVIDERS_EASYGROWTH = [
    {
        name: "Bouncify",
        value: "BOUNCIFY",
        logo: BouncifyLogo,
        description:
            "Leading email verification service globally, free and enabled by default with your EasyGrowth license ($0.005/email pricing)",
    },
];

export const EMAIL_VERIFICATION_PROVIDERS = [
    {
        name: "ZeroBounce",
        value: "ZEROBOUNCE",
        logo: ZeroBounceLogo,
        description:
            "World's most accurate and trusted bounce solution, to drive your email deliverability upwards of 95% ($0.01/email pricing)",
    },
];
