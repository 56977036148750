import { combineReducers } from "redux";
import { withReduxStateSync } from "redux-state-sync";

import { autoPilotApi } from "../../components/AutoPilot/autopilot.slice";
import { chatbotApi } from "../../components/Chatbot/api/chatbot.api.slice";
import { deleteProjectFeedbackApi } from "../../components/DeleteProjectFeedbackModal/deleteProjectFeedback.api";
import exclusionListReducer from "../../components/ExclusionList/ExclusionList.reducer";
import InterActiveWalkthorugh from "../../components/InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import mailAlertReducers from "../../components/MailAlert/mailAlert.reducers";
import CreateProject from "../../components/Navbar/CreateProject/CreateProject.reducer";
import MarketingPageReducer from "../../components/Navbar/CreateProject/MarketingPage/MarketingPage.reducer";
import Notification from "../../components/Notification/index.reducer";
import NudgesReducer from "../../components/Nudges/Nudges.reducer";
import AppContainerReducer from "../../container/AppContainer/AppContainer.reducer";
import TourContainerReducer from "../../container/TourContainer/TourContainer.reducer";
import leadRatingReducer from "../../easy-growth/components/LeadRating/LeadRating.slice";
import AdvancedFilterCandidates from "../../pages/AdvancedFilters/Components/Candidates/Candidates.reducer";
import AdvancedFilters from "../../pages/AdvancedFilters/Components/Filters/Filters.reducer";
import allCandidatesReachoutSlice from "../../pages/all-candidates-reachout/all-candidates-reachout.slice";
import allCandidatesReducer from "../../pages/all-candidates/all-candidates.slice";
import contactOverviewActivities from "../../pages/all-candidates/components/ContactOverviewActivities/contact-overview-activities.slice";
import allContactsSlice from "../../pages/all-contacts/all-contacts.slice";
import AllProjects from "../../pages/allProjects/index.reducer";
import { analyticsApi } from "../../pages/analytics-v2/apis/analytics.api.slice";
import Analytics from "../../pages/Analytics/Analytics.reducer";
import CompanyPageReducer from "../../pages/CompanyPage/CompanyPage.reducer";
import contactListSlice from "../../pages/ContactList/contact-list.slice";
import contactListItemsSlice from "../../pages/ContactListItems/contact-list-items.slice";
import passwordsReducer from "../../pages/forgotPassword/forgotPassword.reducer";
import HcChatHistory from "../../pages/HumanCircles/ChatHistory/ChatHistory.reducer";
import HcUserList from "../../pages/HumanCircles/UserList/UserList.reducers";
import integrationsReducer from "../../pages/integrations/integrations.reducer";
import { invitationsApi } from "../../pages/Invitations/invitations.api.slice";
import { linkedinWorkflowApi } from "../../pages/linkedin-workflow/apis/linkedin-workflow.api.slice";
import teamsReducer from "../../pages/manageTeams/manageTeams.reducers";
import messageSlice from "../../pages/message/message.slice";
import { userCreditApi } from "../../pages/myAccount/components/stats/credit.api.slice";
import myAccountReducer from "../../pages/myAccount/myAccount.reducer";
import { outreachApi } from "../../pages/OutReach/outreach.api.slice";
import outreachReducer from "../../pages/OutReach/outreach.slice";
import exportCandidatesReducer from "../../pages/project/components/exportCandidates/exportCandidates.reducer";
import upComingEventsReducer from "../../pages/project/components/upcomingEvents/upComingEvents.slice";
import vettingCriteriaReducer from "../../pages/project/components/vettingCriteria/vettingCriteria.slice";
import IProject from "../../pages/project/index.reducer";
import Signin from "../../pages/Signin/Signin.reducer";
import { superInboxApi } from "../../pages/super-inbox/apis/super-inbox.api.slice";
import ExtTrackReducers from "../../pages/superAdmin/extensionTrack/extensionTrack.reducers";
import FeatureList from "../../pages/superAdmin/featureList/feature.reducers";
import editAutoSearchSlice from "../../pages/superAdmin/orgList/components/EditAutoSearchModal/editAutoSearch.slice";
import OrgList from "../../pages/superAdmin/orgList/orgList.reducers";
import Crons from "../../pages/superAdmin/crons/crons.reducer";
import InternalRB2B from "../../pages/superAdmin/internalRB2B/internalRB2B.reducer";
import SuperAdmin from "../../pages/superAdmin/superAdmin.reducer";
import UserList from "../../pages/superAdmin/userList/userList.reducers";
import SupportReducer from "../../pages/Support/Support.reducer";
import Template from "../../pages/template/index.reducer";
import customTemplatesReducer from "../../pages/triggerWorkflow/reducers/customTemplates.slice";
import personalizedWorkflowReducer from "../../pages/triggerWorkflow/reducers/personalizedWorkflow.slice";
import standardWorkflowSlice from "../../pages/triggerWorkflow/reducers/standardWorkflow.slice";
import unsubscribeEmailReducer from "../../pages/unsubscribe/unsubscribe.reducer";
import Workflow from "../../pages/workflow/index.reducer";
import { allCandidatesApi } from "../reducers/all-candidates.api.slice";
import { companyDetailsApi } from "../reducers/companyDetails.api.slice";
import cookiesInputReducer from "./cookiesInput.slice";
import { externalConnectApis } from "./externalConnect.api.slice";
import linkedinScraping from "./linkedin-scraping/linkedin-scraping.slice";
import loadersReducer from "./loaders.reducer";
import modalSlice from "./modals.slice";
import { organizationsApi } from "./organizations/organizations.api.slice";
import { projectsApi } from "./projects/projects.api.slice";
import searchReducer from "./search/search.slice";
import searchCandidatesReducer from "./searchCandidates/searchCandidates.slice";
import slackReducer from "./slackSend.reducer";
import stepsReducer from "./steps.slice";
import workflowUploadSlice from "./workflow-upload.slice";

export default withReduxStateSync(
    combineReducers({
        signin: Signin,
        allProjects: AllProjects,
        project: IProject,
        template: Template,
        workflow: Workflow,
        notification: Notification,
        teams: teamsReducer,
        loaders: loadersReducer,
        forgotPassword: passwordsReducer,
        appContainerReducer: AppContainerReducer,
        integrations: integrationsReducer,
        unsubscribeEmail: unsubscribeEmailReducer,
        orgList: OrgList,
        crons: Crons,
        internalRB2B: InternalRB2B,
        userList: UserList,
        featureList: FeatureList,
        tour: TourContainerReducer,
        myAccount: myAccountReducer,
        extensionTrack: ExtTrackReducers,
        interActiveWalkthorugh: InterActiveWalkthorugh,
        superAdmin: SuperAdmin,
        nudges: NudgesReducer,
        slackSlice: slackReducer,
        personalizedWorkflow: personalizedWorkflowReducer,
        mailAlert: mailAlertReducers,
        hcUserList: HcUserList,
        hcChatHistory: HcChatHistory,
        advancedFilters: AdvancedFilters,
        advancedFilterCandidates: AdvancedFilterCandidates,
        createProject: CreateProject,
        workflowUpload: workflowUploadSlice,
        vettingCriteria: vettingCriteriaReducer,
        standardWorkflow: standardWorkflowSlice,
        exclusionList: exclusionListReducer,
        cookiesInput: cookiesInputReducer,
        steps: stepsReducer,
        exportCandidates: exportCandidatesReducer,
        companyPageReducer: CompanyPageReducer,
        marketingPageReducer: MarketingPageReducer,
        customTemplates: customTemplatesReducer,
        modals: modalSlice,
        contactList: contactListSlice,
        contactListItems: contactListItemsSlice,
        allContacts: allContactsSlice,
        analytics: Analytics,
        editAutoSearch: editAutoSearchSlice,
        support: SupportReducer,
        search: searchReducer,
        searchCandidates: searchCandidatesReducer,
        allCandidatesReachout: allCandidatesReachoutSlice,
        allCandidates: allCandidatesReducer,
        outreach: outreachReducer,
        [outreachApi.reducerPath]: outreachApi.reducer,
        [companyDetailsApi.reducerPath]: companyDetailsApi.reducer,
        [allCandidatesApi.reducerPath]: allCandidatesApi.reducer,
        [externalConnectApis.reducerPath]: externalConnectApis.reducer,
        [deleteProjectFeedbackApi.reducerPath]: deleteProjectFeedbackApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        [organizationsApi.reducerPath]: organizationsApi.reducer,
        [analyticsApi.reducerPath]: analyticsApi.reducer,
        [superInboxApi.reducerPath]: superInboxApi.reducer,
        [linkedinWorkflowApi.reducerPath]: linkedinWorkflowApi.reducer,
        [autoPilotApi.reducerPath]: autoPilotApi.reducer,
        [invitationsApi.reducerPath]:invitationsApi.reducer,
        [userCreditApi.reducerPath]: userCreditApi.reducer,
        [chatbotApi.reducerPath]: chatbotApi.reducer,
        message: messageSlice,
        upcomingEvents: upComingEventsReducer,
        leadRating: leadRatingReducer,
        contactOverviewActivities: contactOverviewActivities,
        linkedinScraping: linkedinScraping,
    })
);
