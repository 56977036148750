import { Button } from "@mui/joy";
import { Stack } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

function Options(props: any) {
    const { options, type } = props;
    const handleOption = (text: string, submit: boolean): void => {
        // Select Chat Input box and set the option's value to it
        const inputBox: HTMLInputElement | null = document.querySelector(
            ".react-chatbot-kit-chat-input-container input"
        );

        if (inputBox) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            )?.set;
            if (nativeInputValueSetter) {
                nativeInputValueSetter.call(inputBox, text);
            }

            const event = new Event("input", { bubbles: true });
            inputBox.dispatchEvent(event);

            if(submit) {
                const submitBtn = document.querySelector(".react-chatbot-kit-chat-btn-send")

                if(submitBtn) {
                    setTimeout(() => {
                        (submitBtn as HTMLButtonElement).click()
                    }, 100)
                }
            }
        }
    };

    return (
        <Fragment>
            {type === "initial-message-options" && <InitialMessageOptions options={options} handleOption={handleOption} />}
            {type === "user-confirmation" && <UserConfirmation options={options} handleOption={handleOption} />}    
        </Fragment>
    );
}

export default Options;

const InitialMessageOptions = (props: any) => {
    const { handleOption, options } = props;
    return (
        <Stack spacing={1} alignItems="flex-end" >
            {options?.map((option: any) => (
                <Button
                    variant="soft"
                    // sx={{ margin: "4px", marginLeft: "40px" }}
                    sx={{maxWidth: `calc(365px - 42.5px)`}}
                    key={option.id}
                    onClick={() => handleOption(option.text)}
                >
                    {option?.text}
                </Button>
            ))}
        </Stack>
    )
} 

type OptionTypes = {
    text: string;
    id: number; 
    handler: () => void;
}

const UserConfirmation = (props: any) => {
    const { handleOption, options } = props;
    return (
        <Stack spacing={1} direction="row" alignItems="flex-start" sx={{marginLeft: "42.5px"}}>
            {options?.map((option: OptionTypes) => (
                <Button
                    variant="soft"
                    sx={{maxWidth: `calc(365px - 42.5px)`}}
                    key={option.id}
                    onClick={() => handleOption(option.text, true)}
                >
                    {option?.text}
                </Button>
            ))}
        </Stack>
    )
}