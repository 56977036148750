import { createApi } from "@reduxjs/toolkit/dist/query/react";

import {
    CompanyDetailsList,
    companyDetailsListSchema,
    CompanyNames,
} from "../../pages/project/components/CompanyDetails/CompanyDetails.types";
import { fetchBaseQueryWithZod } from "../../utils/api-utils";

export const companyDetailsApi = createApi({
    reducerPath: "companyDetailsApi",
    baseQuery: fetchBaseQueryWithZod,
    endpoints: (builder) => ({
        getCompaniesDetails: builder.query<CompanyDetailsList, CompanyNames>({
            query: (companyNames = []) => ({
                url: `/companyDesc/desc?company_names=${companyNames}`,
                method: "GET",
            }),
            extraOptions: {
                dataSchema: companyDetailsListSchema,
            },
        }),
    }),
});

export const { useGetCompaniesDetailsQuery } = companyDetailsApi;
